import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Row,
  Tabs,
  TabsProps,
  Input,
  Form,
  Typography,
  message,
} from "antd";
import { MyBreadcrumb, FirstLevelTabs } from "../../../components";
import "../../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { Cookies } from "react-cookie";
import {
  CreateEmployeeRequest,
  EditEmployeeAuth,
  EditEmployeeAward,
  EditEmployeeCategory,
  EditEmployeeContacts,
  EditEmployeeCourse,
  EditEmployeeEdu,
  EditEmployeeID,
  EditEmployeeIDBirth,
  EditEmployeeIDPass,
  EditEmployeeJobPosition,
  EditEmployeeLanguages,
  EditEmployeeStage,
} from "../../InterfacesApi/InterfacesApi";
import { initialStates } from "./EmployeeInputStates";
import { HeadersAuth } from "../../../constants/Auth";

export const EmployeeEdit = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [inputStates, setInputStates] = useState(initialStates);
  const { Title } = Typography;
  const [formData, setFormData] = useState<CreateEmployeeRequest>({
    firstname: "",
    middlename: "",
    secondname: "",
    lang: "",
    email: "",
    date_birth: "",
    gender: "",
    iin: "",
    place_of_birth: "",
    citizenship: "",
    nationality: "",
    college_id: 1,
    role_ids: [],
  });

  const [formDataContact, setFormDataContact] = useState<EditEmployeeContacts>({
    college_id: 1,
    home_phone: "",
    mobile_phone: "",
    email: "",
    home_address: "",
    registration_address: "",
  });

  const [formDataID, setFormDataID] = useState<EditEmployeeID>({
    college_id: 1,
    id_card_number: "",
    card_valid_until: "",
    card_who_issued: "",
    card_date_of_issue: "",
  });

  const [formDataIDBirth, setFormDataIDBirth] = useState<EditEmployeeIDBirth>({
    college_id: 1,
    birth_date_of_issue: "",
    number_birth_certificate: "",
    series_birth_certificate: "",
  });

  const [formDataPassport, setFormDataPassport] = useState<EditEmployeeIDPass>({
    college_id: 1,
    passport_valid_until: "",
    passport_who_issued: "",
    passport_date_of_issue: "",
    passport_series: 1,
    passport_number: 1,
  });

  const [formDataAwards, setFormDataAwards] = useState<EditEmployeeAward>({
    college_id: 1,
    award: "",
    personal_name: "",
    academic_degree: "",
  });

  const [formDataLang, setFormDataLang] = useState<EditEmployeeLanguages>({
    college_id: 1,
    lang: "",
    lang_skills: "",
  });

  const [formDataJob, setFormDataJob] = useState<EditEmployeeJobPosition>({
    college_id: 1,
    job_title: "",
    working_rate: "",
  });

  const [formDataAuth, setFormDataAuth] = useState<EditEmployeeAuth>({
    college_id: 1,
    email: "",
  });

  const [formDataStage, setFormDataStage] = useState<EditEmployeeStage>({
    college_id: 1,
    id: 1,
    start_date: "",
    end_date: "",
    work_experience: "",
    order_number: "",
    order_date: "",
  });
  const [formDataEduStage, setFormDataEduStage] = useState<EditEmployeeEdu>({
    id: 1,
    college_id: 1,
    education: "",
    institution_title: "",
    faculty_title: "",
    speciality_title: "",
    diploma_number: "",
    graduate_date: "",
    pedagogical: "",
  });
  const [formDataCourse, setFormDataCourse] = useState<EditEmployeeCourse>({
    college_id: 1,
    id: 1,
    course_name: "",
    start_date: "",
    end_date: "",
    result: "",
  });
  const [formDataCategory, setFormDataCategory] =
    useState<EditEmployeeCategory>({
      college_id: 1,
      id: 1,
      category_title: "",
      order_number: "",
      id_number: "",
      date_of_issue: "",
      valid_until: "",
    });

  useEffect(() => {
    ///////Personal data////////
    const fetchEmployeeData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getEmployeePrivateInfo?id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          setFormData({
            firstname: data.firstname,
            secondname: data.secondname,
            middlename: data.middlename,
            lang: data.lang,
            date_birth: data.date_birth,
            gender: data.gender,
            iin: data.iin,
            email: data.email,
            place_of_birth: data.place_of_birth,
            citizenship: data.citizenship,
            nationality: data.nationality,
            college_id: 1,
            role_ids: data.role_ids,
          });
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных");
      }
    };
    ///////CONTACT////////
    const fetchEmployeeContact = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getContactInfo?id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          setFormDataContact({
            college_id: 1,
            home_phone: data.home_phone,
            mobile_phone: data.mobile_phone,
            email: data.email,
            home_address: data.home_address,
            registration_address: data.registration_address,
          });
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных");
      }
    };
    ///////Documents////////

    const fetchEmployeeID = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getEmployeeIdentificationCard?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          setFormDataID({
            college_id: 1,
            id_card_number: data.id_card_number,
            card_valid_until: data.card_valid_until,
            card_who_issued: data.card_who_issued,
            card_date_of_issue: data.card_date_of_issue,
          });
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных");
      }
    };

    const fetchEmployeeIDBirth = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getPersonBirthCertificate?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          setFormDataIDBirth({
            college_id: 1,
            birth_date_of_issue: data.birth_date_of_issue,
            number_birth_certificate: data.number_birth_certificate,
            series_birth_certificate: data.series_birth_certificate,
          });
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных");
      }
    };

    const fetchEmployeePassport = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getPersonPassport?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          setFormDataPassport({
            college_id: 1,
            passport_valid_until: data.passport_valid_until,
            passport_who_issued: data.passport_who_issued,
            passport_date_of_issue: data.passport_date_of_issue,
            passport_series: data.passport_series,
            passport_number: data.passport_number,
          });
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных");
      }
    };

    const fetchEmployeeAward = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getEmployeeAwards?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();

          if (data.awards && data.awards.length > 0) {
            const firstAward = data.awards[0];
            setFormDataAwards({
              college_id: 1,
              award: firstAward.award,
              personal_name: firstAward.personal_name,
              academic_degree: firstAward.academic_degree,
            });
          } else {
            console.error("Данные о наградах не найдены");
          }
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных:", error);
      }
    };

    const fetchEmployeeLang = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getEmployeeLanguages?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          if (data.languages && data.languages.length > 0) {
            const firstLanguage = data.languages[0];
            setFormDataLang({
              college_id: 1,
              lang: firstLanguage.lang,
              lang_skills: firstLanguage.lang_skills,
            });
          } else {
            console.error("Данные о языках не найдены");
          }
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных:", error);
      }
    };

    const fetchEmployeeJob = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/GetEmployeePosition?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          setFormDataJob({
            college_id: 1,
            job_title: data.position.job_title,
            working_rate: data.position.working_rate,
          });
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных:", error);
      }
    };

    const fetchEmployeeAuthSync = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/GetAuth?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          setFormDataAuth({
            college_id: 1,
            email: data.email,
          });
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных:", error);
      }
    };

    const fetchEmployeeStage = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/GetPedagogicalExperience?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          const stage = data[0];
          setFormDataStage({
            id: stage.id,
            college_id: 1,
            start_date: stage.start_date,
            end_date: stage.end_date,
            work_experience: stage.work_experience,
            order_number: stage.order_number,
            order_date: stage.order_date,
          });
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных:", error);
      }
    };

    const fetchEmployeeEdu = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/GetEducation?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          const eduStage = data.education[0];
          setFormDataEduStage({
            college_id: 1,
            id: eduStage.id,
            education: eduStage.education,
            institution_title: eduStage.institution_title,
            faculty_title: eduStage.faculty_title,
            speciality_title: eduStage.speciality_title,
            diploma_number: eduStage.diploma_number,
            graduate_date: eduStage.graduate_date,
            pedagogical: eduStage.pedagogical,
          });
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных:", error);
      }
    };

    const fetchEmployeeCourse = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/GetQualificationCourses?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          const courseStage = data[0];
          setFormDataCourse({
            college_id: 1,
            id: courseStage.id,
            course_name: courseStage.course_name,
            start_date: courseStage.start_date,
            end_date: courseStage.end_date,
            result: courseStage.result,
          });
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных:", error);
      }
    };

    const fetchEmployeeCategory = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/GetTeacherCategories?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          const categoryStage = data[0];
          setFormDataCategory({
            id: categoryStage.id,
            college_id: 1,
            category_title: categoryStage.category_title,
            order_number: categoryStage.order_number,
            id_number: categoryStage.id_number,
            date_of_issue: categoryStage.date_of_issue,
            valid_until: categoryStage.valid_until,
          });
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных:", error);
      }
    };

    fetchEmployeeData(),
      fetchEmployeeContact(),
      fetchEmployeeID(),
      fetchEmployeeIDBirth(),
      fetchEmployeePassport(),
      fetchEmployeeLang(),
      fetchEmployeeAward(),
      fetchEmployeeJob();
    fetchEmployeeAuthSync(),
      fetchEmployeeStage(),
      fetchEmployeeEdu(),
      fetchEmployeeCourse(),
      fetchEmployeeCategory();
  }, [id]);

  //////////////// EDIT ////////
  const handleUpdateEmployee = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/updateEmployeePrivateInfo?id=${id}`,
        formData,
        HeadersAuth
      );
      if (response.status === 200) {
        message.success("Данные о сотруднике успешно обновлены!");
      } else {
        message.error("Ошибка при обновлении данных о сотруднике");
      }
    } catch (error) {
      message.error("Ошибка при обновлении данных о сотруднике");
    }
  };

  const handleUpdateEmployeeContacts = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/updateСontactInfo?id=${id}`,
        formDataContact,
        HeadersAuth
      );
      if (response.status === 200) {
        message.success("Данные о сотруднике успешно обновлены!");
      } else {
        message.error("Ошибка при обновлении данных о сотруднике");
      }
    } catch (error) {
      message.error("Ошибка при обновлении данных о сотруднике");
    }
  };

  const handleUpdateDocs = async () => {
    try {
      const formData = {
        ...formDataPassport,
        ...formDataID,
        ...formDataIDBirth,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/updateDocuments?person_id=${id}`,
        formData,
        HeadersAuth
      );
      console.log(response);
      if (response.status === 200) {
        message.success("Данные о сотруднике успешно обновлены!");
      } else {
        message.error("Ошибка при обновлении данных о сотруднике");
      }
    } catch (error) {
      message.error("Ошибка при обновлении данных о сотруднике");
    }
  };

  const handleUpdateJobPosition = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/UpdateEmployeePosition?person_id=${id}`,
        formDataContact,
        HeadersAuth
      );
      if (response.status === 200) {
        message.success("Данные о сотруднике успешно обновлены!");
      } else {
        message.error("Ошибка при обновлении данных о сотруднике");
      }
    } catch (error) {
      message.error("Ошибка при обновлении данных о сотруднике");
    }
  };

  const handleUpdateJobLang = async () => {
    const formData = {
      ...formDataLang,
      ...formDataAwards,
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getEmployeeUpdateDetails?person_id=${id}`,
        formData,
        HeadersAuth
      );
      if (response.status === 200) {
        message.success("Данные о сотруднике успешно обновлены!");
      } else {
        message.error("Ошибка при обновлении данных о сотруднике");
      }
    } catch (error) {
      message.error("Ошибка при обновлении данных о сотруднике");
    }
  };

  const handleUpdateStage = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/UpdatePedagogicalExperience?id=${formDataStage.id}`,
        formDataStage,
        HeadersAuth
      );
      if (response.status === 200) {
        message.success("Данные о сотруднике успешно обновлены!");
      } else {
        message.error("Ошибка при обновлении данных о сотруднике");
      }
    } catch (error) {
      message.error("Ошибка при обновлении данных о сотруднике");
    }
  };

  const handleUpdateEdu = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/UpdateEducation?id=${formDataEduStage.id}`,
        formDataEduStage,
        HeadersAuth
      );
      if (response.status === 200) {
        message.success("Данные о сотруднике успешно обновлены!");
      } else {
        message.error("Ошибка при обновлении данных о сотруднике");
      }
    } catch (error) {
      message.error("Ошибка при обновлении данных о сотруднике");
    }
  };

  const handleUpdateEduCourse = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/UpdateQualificationCourse?id=${formDataCourse.id}`,
        formDataCourse,
        HeadersAuth
      );
      if (response.status === 200) {
        message.success("Данные о сотруднике успешно обновлены!");
      } else {
        message.error("Ошибка при обновлении данных о сотруднике");
      }
    } catch (error) {
      message.error("Ошибка при обновлении данных о сотруднике");
    }
  };

  const handleUpdateCategory = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/UpdateTeacherCategory?id=${formDataCategory.id}`,
        formDataCategory,
        HeadersAuth
      );
      if (response.status === 200) {
        message.success("Данные о сотруднике успешно обновлены!");
      } else {
        message.error("Ошибка при обновлении данных о сотруднике");
      }
    } catch (error) {
      message.error("Ошибка при обновлении данных о сотруднике");
    }
  };

  const handleInputChange = (inputName) => (event) => {
    const value = event.target.value;

    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [inputName]: !!value,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));

    setFormDataContact((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));

    setFormDataID((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));

    setFormDataIDBirth((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));

    setFormDataPassport((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));

    setFormDataAwards((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));

    setFormDataLang((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));

    setFormDataAuth((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));

    setFormDataJob((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));

    setFormDataStage((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));

    ////////
    setFormDataStage((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));

    setFormDataEduStage((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));

    setFormDataCourse((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));

    setFormDataCategory((prevFormData) => ({
      ...prevFormData,
      [inputName]: value,
    }));
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Стаж педагогической работы",
      children: (
        <Content
          style={{
            width: "100%",
            height: "420px",
            backgroundColor: "white",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <div
            className="employee_experience_table"
            style={{ padding: "20px" }}
          >
            <Form>
              <Row style={{ padding: "5px 0px 0 20px" }}>
                <Col span={24}>
                  <Title level={4}>Стаж педагогической работы</Title>
                  <p />
                </Col>

                {/* <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                  Организации
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={inputStates.id_org ? "success" : ""}
                  >
                    <Input
                      style={{
                        width: "90%",
                        borderColor: inputStates.id_org ? "#8B00FF" : undefined,
                      }}
                      onChange={handleInputChange("id_org")}
                    />
                  </Form.Item>
                </Col> */}
                <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                  Дата начала работы
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      inputStates.start_date_stage ? "success" : ""
                    }
                  >
                    <Input
                      value={formDataStage.start_date}
                      style={{
                        width: "90%",
                        borderColor: inputStates.start_date_stage
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("start_date")}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                  Дата окончания работы
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={inputStates.end_date_stage ? "success" : ""}
                  >
                    <Input
                      value={formDataStage.end_date}
                      style={{
                        width: "90%",
                        borderColor: inputStates.end_date_stage
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("end_date")}
                    />
                  </Form.Item>
                </Col>

                <Col
                  span={8}
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  Опыт работы
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      inputStates.work_experience ? "success" : ""
                    }
                  >
                    <Input
                      value={formDataStage.work_experience}
                      style={{
                        width: "90%",
                        borderColor: inputStates.work_experience
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("work_experience")}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={8}
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    marginTop: "15px",
                  }}
                >
                  Номер приказа при приеме на работу
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={inputStates.order_number ? "success" : ""}
                  >
                    <Input
                      value={formDataStage.order_number}
                      style={{
                        width: "90%",
                        borderColor: inputStates.order_number
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("order_number")}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={8}
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    marginTop: "15px",
                  }}
                >
                  Дата приказа при приеме на работу
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={inputStates.order_date ? "success" : ""}
                  >
                    <Input
                      value={formDataStage.order_date}
                      style={{
                        width: "90%",
                        borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                      }}
                      onChange={handleInputChange("order_date")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate(`/employee/view/${id}`)}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Отмена
              </Button>
              <Button onClick={handleUpdateStage} className="CreateButton">
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "2",
      label: "Полученное образование",
      children: (
        <Content
          style={{
            width: "100%",
            height: "530px",
            backgroundColor: "white",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <div
            className="employee_experience_table"
            style={{ padding: "20px" }}
          >
            <Form>
              <Row style={{ padding: "5px 0px 0 20px" }}>
                <Col span={24}>
                  <Title level={4}>Полученное образование</Title>
                  <p />
                </Col>

                <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                  Образование
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={inputStates.education ? "success" : ""}
                  >
                    <Input
                      value={formDataEduStage.education}
                      style={{
                        width: "90%",
                        borderColor: inputStates.education
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("education")}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                  Учебное заведение
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      inputStates.institution_title ? "success" : ""
                    }
                  >
                    <Input
                      value={formDataEduStage.institution_title}
                      style={{
                        width: "90%",
                        borderColor: inputStates.institution_title
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("institution_title")}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                  Факультет
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={inputStates.faculty_title ? "success" : ""}
                  >
                    <Input
                      value={formDataEduStage.faculty_title}
                      style={{
                        width: "90%",
                        borderColor: inputStates.faculty_title
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("faculty_title")}
                    />
                  </Form.Item>
                </Col>

                <Col
                  span={8}
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    marginTop: "15px",
                  }}
                >
                  Специальность
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      inputStates.speciality_title ? "success" : ""
                    }
                  >
                    <Input
                      value={formDataEduStage.speciality_title}
                      style={{
                        width: "90%",
                        borderColor: inputStates.speciality_title
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("speciality_title")}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={8}
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    marginTop: "15px",
                  }}
                >
                  № диплома
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={inputStates.diploma_number ? "success" : ""}
                  >
                    <Input
                      value={formDataEduStage.diploma_number}
                      style={{
                        width: "90%",
                        borderColor: inputStates.diploma_number
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("diploma_number")}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={8}
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    marginTop: "15px",
                  }}
                >
                  Когда окончил
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={inputStates.graduate_date ? "success" : ""}
                  >
                    <Input
                      value={formDataEduStage.graduate_date}
                      style={{
                        width: "90%",
                        borderColor: inputStates.graduate_date
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("graduate_date")}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={8}
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    marginTop: "15px",
                  }}
                >
                  Педагогическое
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={inputStates.pedagogical ? "success" : ""}
                  >
                    <Input
                      value={formDataEduStage.pedagogical}
                      style={{
                        width: "90%",
                        borderColor: inputStates.pedagogical
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("pedagogical")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate(`/employee/view/${id}`)}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Отмена
              </Button>
              <Button onClick={handleUpdateEdu} className="CreateButton">
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "3",
      label: "Курсы повышенной квалификации",
      children: (
        <Content
          style={{
            width: "100%",
            height: "420px",
            backgroundColor: "white",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <div
            className="employee_experience_table"
            style={{ padding: "20px" }}
          >
            <Form>
              <Row style={{ padding: "5px 0px 0 20px" }}>
                <Col span={24}>
                  <Title level={4}>Курсы повышенной квалификации</Title>
                  <p />
                </Col>

                <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                  Название курса
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={inputStates.course_name ? "success" : ""}
                  >
                    <Input
                      value={formDataCourse.course_name}
                      style={{
                        width: "90%",
                        borderColor: inputStates.course_name
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("course_name")}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                  Дата начало курса
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      inputStates.start_date_course ? "success" : ""
                    }
                  >
                    <Input
                      value={formDataCourse.start_date}
                      style={{
                        width: "90%",
                        borderColor: inputStates.start_date_course
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("start_date_course")}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                  Дата окончания курса
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      inputStates.end_date_course ? "success" : ""
                    }
                  >
                    <Input
                      value={formDataCourse.end_date}
                      style={{
                        width: "90%",
                        borderColor: inputStates.end_date_course
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("end_date_course")}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={8}
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    marginTop: "15px",
                  }}
                >
                  Результаты/Итоги
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={inputStates.result ? "success" : ""}
                  >
                    <Input
                      value={formDataCourse.result}
                      style={{
                        width: "90%",
                        borderColor: inputStates.result ? "#8B00FF" : undefined,
                      }}
                      onChange={handleInputChange("result")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate(`/employee/view/${id}`)}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Отмена
              </Button>
              <Button onClick={handleUpdateEduCourse} className="CreateButton">
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "4",
      label: "Категории",
      children: (
        <Content
          style={{
            width: "100%",
            height: "420px",
            backgroundColor: "white",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <div
            className="employee_experience_table"
            style={{ padding: "20px" }}
          >
            <Form>
              <Row style={{ padding: "5px 0px 0 20px" }}>
                <Col span={24}>
                  <Title level={4}>Категория</Title>
                  <p />
                </Col>

                <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                  Название
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={inputStates.category_title ? "success" : ""}
                  >
                    <Input
                      value={formDataCategory.category_title}
                      style={{
                        width: "90%",
                        borderColor: inputStates.category_title
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("category_title")}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                  № приказа
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={inputStates.order_number ? "success" : ""}
                  >
                    <Input
                      value={formDataCategory.order_number}
                      style={{
                        width: "90%",
                        borderColor: inputStates.order_number
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("order_number")}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                  Дата приказа
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      inputStates.date_of_issue_category ? "success" : ""
                    }
                  >
                    <Input
                      value={formDataCategory.date_of_issue}
                      style={{
                        width: "90%",
                        borderColor: inputStates.date_of_issue_category
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("date_of_issue_category")}
                    />
                  </Form.Item>
                </Col>

                <Col
                  span={8}
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    marginTop: "15px",
                  }}
                >
                  № Удостоверения
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={inputStates.id_number ? "success" : ""}
                  >
                    <Input
                      value={formDataCategory.id_number}
                      style={{
                        width: "90%",
                        borderColor: inputStates.id_number
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("id_number")}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={8}
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    marginTop: "15px",
                  }}
                >
                  Дата выдачи удостоверения
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      inputStates.date_of_issue_category ? "success" : ""
                    }
                  >
                    <Input
                      value={formDataCategory.date_of_issue}
                      style={{
                        width: "90%",
                        borderColor: inputStates.date_of_issue_category
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("date_of_issue_category")}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={8}
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    marginTop: "15px",
                  }}
                >
                  Срок действия удостоверения
                  <p />
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      inputStates.valid_until_category ? "success" : ""
                    }
                  >
                    <Input
                      value={formDataCategory.valid_until}
                      style={{
                        width: "90%",
                        borderColor: inputStates.valid_until_category
                          ? "#8B00FF"
                          : undefined,
                      }}
                      onChange={handleInputChange("valid_until_category")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate(`/employee/view/${id}`)}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Отмена
              </Button>
              <Button onClick={handleUpdateCategory} className="CreateButton">
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
  ];

  const firstLevelTabs = [
    {
      key: "1",
      label: "Личные данные",
      children: (
        <Content
          style={{
            width: "100%",
            height: "530px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Имя
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.firstname ? "success" : ""}
                >
                  <Input
                    value={formData.firstname}
                    style={{
                      width: "90%",
                      borderColor: inputStates.firstname
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("firstname")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Фамилия
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.secondname ? "success" : ""}
                >
                  <Input
                    value={formData.secondname}
                    style={{
                      width: "90%",
                      borderColor: inputStates.secondname
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("secondname")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Отчество (при наличии)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.middlename ? "success" : ""}
                >
                  <Input
                    value={formData.middlename}
                    style={{
                      width: "90%",
                      borderColor: inputStates.middlename
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("middlename")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                ИИН
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.iin ? "success" : ""}
                >
                  <Input
                    value={formData.iin}
                    style={{
                      width: "90%",
                      borderColor: inputStates.iin ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("iin")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дата рождения
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.date_birth ? "success" : ""}
                >
                  <Input
                    value={formData.date_birth}
                    style={{
                      width: "90%",
                      borderColor: inputStates.date_birth
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("date_birth")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Место рождения
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.place_of_birth ? "success" : ""}
                >
                  <Input
                    value={formData.place_of_birth}
                    style={{
                      width: "90%",
                      borderColor: inputStates.place_of_birth
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("place_of_birth")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Гражданство
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.citizenship ? "success" : ""}
                >
                  <Input
                    value={formData.citizenship}
                    style={{
                      width: "90%",
                      borderColor: inputStates.citizenship
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("citizenship")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Пол
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.gender ? "success" : ""}
                >
                  <Input
                    value={formData.gender}
                    style={{
                      width: "90%",
                      borderColor: inputStates.gender ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("gender")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Национальность
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nationality ? "success" : ""}
                >
                  <Input
                    value={formData.nationality}
                    style={{
                      width: "90%",
                      borderColor: inputStates.nationality
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("nationality")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Язык обучения
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.lang ? "success" : ""}
                >
                  <Input
                    value={formData.lang}
                    style={{
                      width: "90%",
                      borderColor: inputStates.lang ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("lang")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Роль
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.role_ids ? "success" : ""}
                >
                  <Input
                    value={formData.role_ids}
                    style={{
                      width: "90%",
                      borderColor: inputStates.role_ids ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("role_ids")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate(`/employee/view/${id}`)}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Отмена
              </Button>
              <Button onClick={handleUpdateEmployee} className="CreateButton">
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "2",
      label: "Контакты",
      children: (
        <Content
          style={{
            width: "100%",
            height: "330px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Домашний телефон
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.home_phone ? "success" : ""}
                >
                  <Input
                    value={formDataContact.home_phone}
                    style={{
                      width: "90%",
                      borderColor: inputStates.home_phone
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("home_phone")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Мобильный телефон
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.mobile_phone ? "success" : ""}
                >
                  <Input
                    value={formDataContact.mobile_phone}
                    style={{
                      width: "90%",
                      borderColor: inputStates.mobile_phone
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("mobile_phone")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Адрес прописки
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    inputStates.registration_address ? "success" : ""
                  }
                >
                  <Input
                    value={formDataContact.registration_address}
                    style={{
                      width: "90%",
                      borderColor: inputStates.registration_address
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("registration_address")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Домашний адрес
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.home_address ? "success" : ""}
                >
                  <Input
                    value={formDataContact.home_address}
                    style={{
                      width: "90%",
                      borderColor: inputStates.home_address
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("home_address")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                E-mail
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.email ? "success" : ""}
                >
                  <Input
                    value={formDataContact.email}
                    style={{
                      width: "90%",
                      borderColor: inputStates.email ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("email")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate(`/employee/view/${id}`)}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Отмена
              </Button>
              <Button
                onClick={handleUpdateEmployeeContacts}
                className="CreateButton"
              >
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "3",
      label: "Документы",
      children: (
        <Content
          style={{
            width: "100%",
            height: "790px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}>
                <Title level={4}>Свидетельство о рождении</Title>
                <p />
              </Col>

              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Номер свидетельство о рождении
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    inputStates.number_birth_certificate ? "success" : ""
                  }
                >
                  <Input
                    value={formDataIDBirth.number_birth_certificate}
                    style={{
                      width: "90%",
                      borderColor: inputStates.number_birth_certificate
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("number_birth_certificate")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дата выдачи
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    inputStates.birth_date_of_issue ? "success" : ""
                  }
                >
                  <Input
                    value={formDataIDBirth.birth_date_of_issue}
                    style={{
                      width: "90%",
                      borderColor: inputStates.birth_date_of_issue
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("birth_date_of_issue")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Серия свидетельство о рождении
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    inputStates.series_birth_certificate ? "success" : ""
                  }
                >
                  <Input
                    value={formDataIDBirth.series_birth_certificate}
                    style={{
                      width: "90%",
                      borderColor: inputStates.series_birth_certificate
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("series_birth_certificate")}
                  />
                </Form.Item>
              </Col>

              <Col span={24} style={{ marginTop: "20px" }}>
                <Title level={4}>Удостоверение личности</Title>
                <p />
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Номер удостоверения личности
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.id_card_number ? "success" : ""}
                >
                  <Input
                    value={formDataID.id_card_number}
                    style={{
                      width: "90%",
                      borderColor: inputStates.id_card_number
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("id_card_number")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дата выдачи
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    inputStates.card_date_of_issue ? "success" : ""
                  }
                >
                  <Input
                    value={formDataID.card_date_of_issue}
                    style={{
                      width: "90%",
                      borderColor: inputStates.card_date_of_issue
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("card_date_of_issue")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Действителен до
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.card_valid_until ? "success" : ""}
                >
                  <Input
                    value={formDataID.card_valid_until}
                    style={{
                      width: "90%",
                      borderColor: inputStates.card_valid_until
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("card_valid_until")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Кем выдано
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.card_who_issued ? "success" : ""}
                >
                  <Input
                    value={formDataID.card_who_issued}
                    style={{
                      width: "90%",
                      borderColor: inputStates.card_who_issued
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("card_who_issued")}
                  />
                </Form.Item>
              </Col>

              <Col span={24} style={{ marginTop: "20px" }}>
                <Title level={4}>Паспорт</Title>
                <p />
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Номер паспорта
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.passport_number ? "success" : ""}
                >
                  <Input
                    value={formDataPassport.passport_number}
                    style={{
                      width: "90%",
                      borderColor: inputStates.passport_number
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("passport_number")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дата выдачи
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    inputStates.passport_date_of_issue ? "success" : ""
                  }
                >
                  <Input
                    value={formDataPassport.passport_date_of_issue}
                    style={{
                      width: "90%",
                      borderColor: inputStates.passport_date_of_issue
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("passport_date_of_issue")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Действителен до
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    inputStates.passport_valid_until ? "success" : ""
                  }
                >
                  <Input
                    value={formDataPassport.passport_valid_until}
                    style={{
                      width: "90%",
                      borderColor: inputStates.passport_valid_until
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("passport_valid_until")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Кем выдано
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    inputStates.passport_who_issued ? "success" : ""
                  }
                >
                  <Input
                    value={formDataPassport.passport_who_issued}
                    style={{
                      width: "90%",
                      borderColor: inputStates.passport_who_issued
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("passport_who_issued")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Серия
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.passport_series ? "success" : ""}
                >
                  <Input
                    value={formDataPassport.passport_series}
                    style={{
                      width: "90%",
                      borderColor: inputStates.passport_series
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("passport_series")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate(`/employee/view/${id}`)}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Отмена
              </Button>
              <Button onClick={handleUpdateDocs} className="CreateButton">
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "4",
      label: "Образование и опыт работы",
      children: (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Tabs
              tabBarStyle={{ padding: "0 20px 0 20px" }}
              tabPosition="top"
              size="large"
              defaultActiveKey="1"
              items={items}
            />
          </Col>
        </Row>
      ),
    },
    {
      key: "5",
      label: "Награды и Языки",
      children: (
        <Content
          style={{
            width: "100%",
            height: "440px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}>
                <Title level={4}>Награды</Title>
                <p />
              </Col>

              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Награда
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.award ? "success" : ""}
                >
                  <Input
                    value={formDataAwards.award}
                    style={{
                      width: "90%",
                      borderColor: inputStates.award ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("award")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Персональное название
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.personal_name ? "success" : ""}
                >
                  <Input
                    value={formDataAwards.personal_name}
                    style={{
                      width: "90%",
                      borderColor: inputStates.personal_name
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("personal_name")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Ученая степень
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.academic_degree ? "success" : ""}
                >
                  <Input
                    value={formDataAwards.academic_degree}
                    style={{
                      width: "90%",
                      borderColor: inputStates.academic_degree
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("academic_degree")}
                  />
                </Form.Item>
              </Col>

              <Col span={24} style={{ marginTop: "20px" }}>
                <Title level={4}>Языки</Title>
                <p />
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Язык
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.lang_name ? "success" : ""}
                >
                  <Input
                    value={formDataLang.lang}
                    style={{
                      width: "90%",
                      borderColor: inputStates.lang_name
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("lang")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Владение языком
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.lang_skills ? "success" : ""}
                >
                  <Input
                    value={formDataLang.lang_skills}
                    style={{
                      width: "90%",
                      borderColor: inputStates.lang_skills
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("lang_skills")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate(`/employee/view/${id}`)}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Отмена
              </Button>
              <Button onClick={handleUpdateJobLang} className="CreateButton">
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "6",
      label: "Должность",
      children: (
        <Content
          style={{
            width: "100%",
            height: "250px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Название
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.job_title ? "success" : ""}
                >
                  <Input
                    value={formDataJob.job_title}
                    style={{
                      width: "90%",
                      borderColor: inputStates.job_title
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("job_title")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Ставка
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.working_rate ? "success" : ""}
                >
                  <Input
                    value={formDataJob.working_rate}
                    style={{
                      width: "90%",
                      borderColor: inputStates.working_rate
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("working_rate")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate(`/employee/view/${id}`)}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Отмена
              </Button>
              <Button
                onClick={handleUpdateJobPosition}
                className="CreateButton"
              >
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "7",
      label: "Авторизационные записи",
      children: (
        <Content
          style={{
            width: "100%",
            height: "250px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Логин
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.email_auth ? "success" : ""}
                >
                  <Input
                    value={formDataAuth.email}
                    style={{
                      width: "90%",
                      borderColor: inputStates.email_auth
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("email_auth")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Пароль
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate(`/employee/view/${id}`)}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Отмена
              </Button>
              <Button className="CreateButton">Редактировать</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Редактирование данных сотрудника" />
      <Row justify="center">
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="1" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>

      <p />
    </>
  );
};
