import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Row, Spin, Table, Tooltip } from "antd";
import { MyBreadcrumb, FirstLevelTabs } from "../../components";
import { useTheme } from "../../hooks";
import { Cookies } from "react-cookie";

import "../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { HeadersAuth } from "../../constants/Auth";
interface StudentCommonInfo {
  person_id: number;
  place_of_birth: string;
  citizenship: string;
  nationality: string;
  language: string;
  speciality_id: number;
  speciality_title: string;
  qualification_id: number;
  qualification_title: string;
  course_id: number;
  course_title: string;
  group_id: number;
  group_title: string;
  basis_title: string;
  study_period: string;
}

interface Student {
  id: number;
  firstname: string;
  middlename: string;
  secondname: string;
  lang: string;
  date_birth: string;
  gender: string;
  iin: number;
  created_at: string;
  fired_status: boolean;
  student_common_info: StudentCommonInfo;
}

interface ApiResponse {
  current_page: number;
  data: Student[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: {
    url: string | null;
    label: string;
    active: boolean;
  }[];
  next_page_url: string | null;
  path: string;
  per_page: number;
  prev_page_url: string | null;
  to: number;
  total: number;
}

export const Students = () => {
  const [studentsData, setStudentsData] = useState<Student[]>([]);
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    fetchData();
  }, [pagination.current]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/student/indexStudents?college_id=1&page=${pagination.current}&per_page=${pagination.pageSize}`,
        HeadersAuth
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setStudentsData(data.data);
      setPagination({
        ...pagination,
        total: data.total,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
  };

  const deleteIcon = (
    <DeleteOutlined
      className="speciality_delete"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
    />
  );
  const editIcon = (
    <EditOutlined
      onClick={() => navigate("/speciality/edit")}
      className="speciality_edit"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
    />
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const [qualificationsData, setQualificationsData] = useState({
    current_page: 1,
    data: [],
    last_page: 1,
    per_page: 20,
    total: 0,
  });

  const editIconClick = (id, item) => {
    const isValidSpecialQualId =
      Number.isInteger(item.special_qual_id) && item.special_qual_id > 0;

    if (isValidSpecialQualId) {
      navigate(`/speciality/edit/${id}`, {
        state: {
          speciality_id: item.id,
          special_qual_id: item.special_qual_id,
        },
      });
    } else {
      console.error(`Invalid special_qual_id: ${item.special_qual_id}`);
    }
  };

  const columns = [
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>№</span>,
      dataIndex: "id",
      key: "id",
      style: { fontSize: "26px", fontWeight: "bold", color: "#773DBD" },
      width: 50,
    },
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>Фамилия</span>,
      dataIndex: "middlename",
      key: "middlename",
      style: { fontSize: "26px", color: "#773DBD" },
      sorter: false,
    },
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>Имя</span>,
      dataIndex: "firstname",
      key: "firstname",
      style: { fontSize: "26px", color: "#773DBD" },
      sorter: false,
    },
    {
      title: (
        <span style={{ color: "#773DBD", padding: "10px" }}>Отчество</span>
      ),
      dataIndex: "secondname",
      key: "secondname",
      style: { fontSize: "26px", color: "#773DBD" },
      sorter: false,
    },
    {
      title: (
        <span style={{ color: "#773DBD", padding: "10px" }}>Дата рождения</span>
      ),
      dataIndex: "date_birth",
      key: "date_birth",
      style: { fontSize: "26px" },
    },
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>ИИН</span>,
      dataIndex: "iin",
      key: "iin",
      style: { fontSize: "26px" },
    },
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>Группа</span>,
      dataIndex: ["student_common_info", "group_title"],
      key: "group_title",
      style: { fontSize: "26px" },
      render: (groupTitle) => <span>{groupTitle}</span>,
    },

    {
      title: <span style={{ padding: "2px", color: "#773DBD" }}>Действие</span>,
      dataIndex: "delete",
      key: "delete",
      width: 50,
      render: (_, record) => (
        <span>
          <Tooltip title="Редактировать">
            <EditOutlined
              onClick={() => editIconClick(record.id, record)}
              className="speciality_edit"
              style={{
                display: "inline",

                fontSize: "24px",
                marginLeft: "25px",
              }}
              twoToneColor="red"
            />
          </Tooltip>
          <Tooltip title="Удалить"></Tooltip>
        </span>
      ),
    },
  ];

  const firstLevelTabs = [
    {
      key: "1",
      label: "Все обучающиеся",
      children: (
        <Content
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={16}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            >
              <span style={{ fontWeight: "500", fontSize: "24px" }}>
                Обучающихся ({pagination.total})
              </span>
            </Col>

            <Col
              span={8}
              style={{
                textAlign: "end",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "0 20px 0 0",
              }}
            >
              <Button className="speciality_delete_button">
                <CloseOutlined
                  style={{ display: "inline-block", fontSize: "16px" }}
                />
                <span
                  style={{
                    fontWeight: "400",
                    marginTop: "-20px",
                    lineHeight: "0px",
                  }}
                >
                  Отчислить
                </span>
              </Button>
              <Button
                onClick={() => navigate("/students/create")}
                className="speciality_create_button"
              >
                <PlusOutlined
                  style={{ display: "inline-block", fontSize: "16px" }}
                />
                <span
                  style={{
                    fontWeight: "400",
                    marginTop: "-20px",
                    lineHeight: "0px",
                  }}
                >
                  Добавить
                </span>
              </Button>
            </Col>
          </Row>
          <div
            className="speciality_table"
            style={{ fontSize: "20px", padding: "20px" }}
          >
            <Table
              dataSource={studentsData}
              bordered
              rowSelection={rowSelection}
              columns={columns}
              pagination={{
                ...pagination,
                onChange: handlePageChange,
              }}
            />
          </div>

          <Row>
            <Col
              span={12}
              style={{ paddingBottom: "30px", marginTop: "-55px" }}
            >
              <span
                style={{
                  marginLeft: "28px",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                {`Показаны ${
                  (pagination.current - 1) * pagination.pageSize + 1
                }-${Math.min(
                  pagination.current * pagination.pageSize,
                  pagination.total
                )} из ${pagination.total} записей`}
              </span>
            </Col>
          </Row>
        </Content>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Картотека обучающихся" />
      <Row justify="center">
        <Col span={22}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="1" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>
      <p />
    </>
  );
};
