import {DefaultTokenTheme} from "./defaultTheme";
import {ThemeConfig} from "antd/es/config-provider/context";

export const DarkTheme: ThemeConfig | undefined = {
  token: {
    ...DefaultTokenTheme,
    colorPrimary: DefaultTokenTheme.primary,
  },
  components: {
    Layout: {
      headerHeight: 60,
      padding: 0,
      headerBg: DefaultTokenTheme.backgroundDark
    },
    Button: {
      colorPrimary: DefaultTokenTheme.primary,
      colorPrimaryHover: DefaultTokenTheme.hover,
      colorPrimaryActive: DefaultTokenTheme.pressed,
      controlHeight: 48,
    },
    Input: {
      controlHeight: 48,
      colorBgContainer: 'transparent',
      colorText: '#000',
      colorTextPlaceholder: DefaultTokenTheme.disabled,
      colorBorder: DefaultTokenTheme.lineDark,
      fontSize: 18,
    },
    Select: {
      colorTextPlaceholder: DefaultTokenTheme.textTitle,
      controlHeight: 48,
    },
    Form: {
      marginLG: 12,
      labelColor: '#6B6C7E',
      labelFontSize: 14
    },
    Divider: {
      margin: 0,
      marginLG: 0,
      marginXS: 0,
      colorSplit: DefaultTokenTheme.line
    },
    Table: {
      padding: 6,
      paddingContentVerticalLG: 6,
      borderColor: DefaultTokenTheme.lineDark,
      colorBgContainer: DefaultTokenTheme.backgroundDark,
      headerColor: '#fff',
      colorText: '#fff',
      colorTextDisabled: DefaultTokenTheme.disabledDark
    },
    Checkbox: {
      fontSize: 14
    },
    Card: {
      colorBgContainer: 'transparent',
      colorBorderSecondary: DefaultTokenTheme.lineDark
    },

    Tabs: {
      itemColor: DefaultTokenTheme.textDark,
    },

    Empty: {
      colorBgContainer: DefaultTokenTheme.backgroundDark,
      colorText: '#fff'
    },
    Badge: {
      colorText: '#fff'
    },
  },
}
