import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Row,
  Input,
  Cascader,
  Form,
  message,
} from "antd";
import { MyBreadcrumb, FirstLevelTabs } from "../../../components";
import { useTheme } from "../../../hooks";
import "../../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { SpecialityVM } from "../../InterfacesApi/InterfacesApi";
import { HeadersAuth } from "../../../constants/Auth";

export const InstitutionDepartmentCreate = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { id } = useParams();
  const { theme } = useTheme();
  const [specialities, setSpecialities] = useState<SpecialityVM[]>([]);
  const [inputStates, setInputStates] = useState({
    nameRu: false,
    nameKz: false,
    disciplines: false,
    speciality: false,
    teacher: false,
  });

  const [formData, setFormData] = useState({
    nameRu: "",
    nameKz: "",
    speciality: undefined,
  });

  const handleInputChange = (inputName) => (e) => {
    const inputValue = e.target ? e.target.value : e;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: inputValue,
    }));

    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [inputName]: inputValue !== "",
    }));
  };

  useEffect(() => {
    const fetchSpecialities = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/specialitiesIndex`,
          HeadersAuth
        );

        setSpecialities(response.data);
      } catch (error) {
        console.error("Ошибка получения данных:", error);
      }
    };

    fetchSpecialities();
  }, []);

  const handleAddDepartment = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/department/add`,
        {
          caption: formData.nameRu,
          college_id: 1,
          speciality_id: formData.speciality,
        },
        HeadersAuth
      );

      message.success("Кафедра успешно добавлена");

      navigate("/speciality");
    } catch (error) {
      message.error("Ошибка при добавлении кафедры");
    }
  };

  const redirectToInstitutionDiscipline = () => {
    navigate("/institution_discipline/create");
  };
  const redirectGroup = () => {
    navigate("/group/create");
  };
  const redirectToSpeciality = () => {
    navigate("/speciality/create");
  };
  const firstLevelTabs = [
    {
      key: "1",
      label: (
        <div onClick={redirectToSpeciality} style={{ cursor: "pointer" }}>
          Специальности
        </div>
      ),
      chilren: "",
    },
    {
      key: "2",
      label: (
        <div
          onClick={redirectToInstitutionDiscipline}
          style={{ cursor: "pointer" }}
        >
          Дисциплины
        </div>
      ),
      children: "",
    },
    {
      key: "3",
      label: (
        <div onClick={redirectGroup} style={{ cursor: "pointer" }}>
          Группы
        </div>
      ),
      children: "",
    },
    {
      key: "4",
      label: "Кафедры",
      children: (
        <Content
          style={{
            width: "100%",
            height: "400px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />

          {/* <Tabs onTabClick={(key) => handleTabClick(key)} defaultActiveKey="1" items={items} /> */}

          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Название на русском
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameRu ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameRu ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameRu")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Название на казахском
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              {/* <Col span={8} style={{fontWeight: '500', fontSize: '14px'}}>Дисциплины<p/><Form.Item hasFeedback
            validateStatus={inputStates.disciplines ? "success" : ""}><Cascader style={{ width: '90%', borderColor: inputStates.nameRu ? '#8B00FF' : undefined }}
            onChange={handleInputChange('disciplines')}/></Form.Item></Col> */}
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Специальности
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.speciality ? "success" : ""}
                >
                  <Cascader
                    style={{
                      width: "90%",
                      ...(inputStates.speciality
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    options={specialities.map((spec) => ({
                      value: spec.id,
                      label: spec.title,
                    }))}
                    onChange={handleInputChange("speciality")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Преподаватели
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.teacher ? "success" : ""}
                >
                  <Cascader
                    style={{
                      width: "90%",
                      ...(inputStates.teacher
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    onChange={handleInputChange("teacher")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "80px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/speciality")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton" onClick={handleAddDepartment}>
                Добавить
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Добавить кафедры" />
      <Row justify="center">
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="4" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>
      <p />
    </>
  );
};
