import {axiosConfig as axios} from "../../utils/axios";
import {IFormType, ISendFormType} from "./types";

let url = 'rest'

export const apiSetForm = (body: ISendFormType) => axios({
  method: body.method,
  url: `${url}/${body.path}`,
  data: body.fields
});


export const apiGetCtp = (body: any) => axios.get(`${url}/edu/ctp`, {
  params: {
    ...(body?.year ? { year: body?.year} : {}),
    ...(body?.type || body?.type === 0 ? { type: body?.type} : {}),
    ...(body?.is_shared ? {is_shared: body?.is_shared} : {}),
    ...(body?.group_id ? {group_id: body?.group_id} : {}),
    ...(body?.subject_id ? {subject_id: body?.subject_id} : {}),
    ...(body?.teacher_id ? {teacher_id: body?.teacher_id} : {}),
  }
});

export const apiGetCtpFilter = (form: any) => axios.get(`${url}/edu/ctp-filter`, {
  params: {
    ...(form?.year ? { year: form?.year} : {}),
    ...(form?.type || form?.type === 0 ? { type: form?.type} : {}),
  }
});

export const apiGetForm = (form: any) => axios.get(`${url}/forms/form`, {
  params: {
    name: form
  }
});


export const apiGetPeriod = (body: any) => axios.get(`${url}/edu/period`, {params: {type: 2, ...body}});
