import {all} from "redux-saga/effects";

import common from "./common/saga";
import journal from "./pages/journal/saga";
import profile from "./pages/profile/saga";
import plans from "./pages/plans/saga";
// import schedule from "./pages/schedule/saga";
import printJournal from "./pages/printJournal/saga";
import rup from "./pages/rup/saga";

export default function* rootSaga(){
  yield all([
    common(),
    journal(),
    profile(),
    plans(),
    // schedule(),
    printJournal(),
    rup(),
  ])
}
