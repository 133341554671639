import * as React from "react";
import cl from "classnames";
import {useCallback, useState} from "react";
import {Col, Row, Typography, Form, Modal, Checkbox, theme as configTheme} from "antd";

import {MyButton, MyImage, MyInput, MyText} from "../../components";
import {LeftCol, RestorePage, RightCol} from "./index.styles";
import LogoImage from "../../assets/icons/logo.svg";
import RestoreImage from "../../assets/images/restore-image.png";
import {useTheme} from "../../hooks";
import {useTranslation} from "react-i18next";

const {useToken} = configTheme
export const Restore = (): React.ReactElement => {
  const {t} = useTranslation("common")
  const [restoreForm] = Form.useForm()

  const {theme, themeConfig} = useTheme()

  const onFinish = useCallback((values: any) => {
    console.log('values', values)
  }, [])

  return (
    <RestorePage>
      <LeftCol>
        <Row justify='center'>
          <Col span={14}>
            <Row>
              <Col span={24} style={{marginBottom: 90}}>
                <MyImage src={LogoImage} width={164} height={40}/>
              </Col>
              <Col span={24} style={{marginBottom: 50}}>
                <Row justify='center' align='middle'>
                  <Col span={24} style={{marginBottom: 50}}>
                    <MyText size={40} color='#262626' weight={700}>
                      Восстановление пароля
                    </MyText>
                  </Col>
                  <Col span={24}>
                    <MyText size={18} weight={500} color={themeConfig.token.primary} className={cl('line')}>
                      Введите email указанный при регистрации
                    </MyText>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form onFinish={onFinish} form={restoreForm}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label='Email'
                        name="email"
                        labelCol={{
                          span: 24,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                      >
                        <MyInput placeholder={'Введите адрес электронной почты'}/>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item>
                        <MyButton width='100%' type="primary" ghost htmlType="submit" title='Отправить'/>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </LeftCol>

      <RightCol>
        <MyImage src={RestoreImage} rootClassName={cl('background')} className={cl('image')}/>
      </RightCol>
    </RestorePage>
  )
}
