import styled from "styled-components";
import {Col, Row} from "antd";
import {DefaultTokenTheme} from "../../assets/themes/defaultTheme";

export const AuthPage = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  width: 100%;
`

export const LeftCol = styled.div`
  background-color: #fff;
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .line {
    display: flex;
    align-items: center;
    gap: 16px;

    &:before {
      content: '';
      background-color: ${DefaultTokenTheme.primary};
      display: block;
      width: 90px;
      height: 4px;
      border-radius: 10px;
    }
  }

  .ant-input, .ant-input-affix-wrapper{
    color: ${DefaultTokenTheme.textTitle} !important;
    border-color: ${DefaultTokenTheme.line} !important;
  }
`

export const RightCol = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  background-color: rgba(205, 223, 247, 1);


  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .image {
    position: absolute;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
