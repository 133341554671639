import {Col, Collapse, Empty, Flex, Form, Input, List, Row, Select, Spin, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {
  SecondLevelTabs,
  MyBreadcrumb, MyButton,
  MyText,
  FirstLevelTabs, ThirdLevelTabs
} from "../../components";
import {CardItem, StyledList} from "./index.styles";
import {JOURNAL_TYPES, JOURNAL_TYPES_API, JOURNAL_YEAR, ROUTE_PATH} from "../../constants";
import {createSearchParams, generatePath, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getJournalListAction, getJournalListActionDone} from "../../store/pages/journal/actions";
import {selectStore} from "../../store/selector";
import {RootState} from "../../store";
import {useTranslation} from "react-i18next";
import {useTheme} from "../../hooks";
import {getCtpFilterAction, getPeriodByGroupActionReset} from "../../store/common/actions";
import {setThemeColor} from "../../utils/setThemeColor";
import {DefaultTokenTheme} from "../../assets/themes/defaultTheme";
import {ArrowRightOutlined} from "@ant-design/icons";

const ADMIN_TAB_KEY = {
  MY: 'my',
  ALL: 'all'
}

export const Journal = () => {
  const {t} = useTranslation("common")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {theme} = useTheme()

  const [adminForm] = Form.useForm()

  const [currentYear, setCurrentYear] = useState<string>(JOURNAL_YEAR.CURRENT)
  const [currentAdminTab, setCurrentAdminTab] = useState<string>(ADMIN_TAB_KEY.MY)
  const [currentType, setCurrentType] = useState<{ number: string, string: string }>({
    number: JOURNAL_TYPES_API[JOURNAL_TYPES.THEORETICAL],
    string: JOURNAL_TYPES.THEORETICAL
  })

  const [adminPriorityField, setAdminPriorityField] = useState<any>(null)

  const {
    journal: {list: journalListState},
    common: {ctpFilter: ctpFilterState},
    profile: {userProfile: userProfileState}
  }: RootState = useSelector(selectStore)

  const getJournalList = (year: any, type: any) => {
    dispatch(getJournalListAction({
      year: year,
      type: type.number
    }))
  }

  useEffect(() => {
    getJournalList(currentYear, currentType)

    dispatch(getPeriodByGroupActionReset())
  }, [])

  const handleClickTypeTab = (type: any) => {
    let body = {
      number: JOURNAL_TYPES_API[type],
      string: type
    }
    adminForm.resetFields()

    setCurrentType(body)
    setCurrentAdminTab(ADMIN_TAB_KEY.MY)

    getJournalList(currentYear, body)
  }

  const handleClickYearTab = (key: any) => {
    setCurrentYear(key)
    setCurrentAdminTab(ADMIN_TAB_KEY.MY)
    adminForm.resetFields()

    getJournalList(key, currentType)
  }

  const handleClickAdminTab = (key: any) => {
    setCurrentAdminTab(key)
    adminForm.resetFields()

    if (key === ADMIN_TAB_KEY.ALL) {
      dispatch(getCtpFilterAction({
        year: currentYear,
        type: currentType.number,
      }))
      dispatch(getJournalListActionDone([]))
    } else {
      dispatch(getJournalListAction({
        year: currentYear,
        type: currentType.number,
      }))
    }
  }

  const handleClickJournal = (obj: any) => {
    let type
    switch (currentType.string) {
      case JOURNAL_TYPES.THEORETICAL:
        type = ROUTE_PATH.JOURNAL_THEORETICAL
        break
      case JOURNAL_TYPES.FACULTATIVE:
        type = ROUTE_PATH.JOURNAL_FACULTATIVE
        break
      case JOURNAL_TYPES.INDUSTRIAL:
        type = ROUTE_PATH.JOURNAL_INDUSTRIAL
        break
      case JOURNAL_TYPES.INDIVIDUAL:
        type = ROUTE_PATH.JOURNAL_INDIVIDUAL
        break
      default:
        break
    }

    const path = {
      pathname: generatePath(type, {id: obj.id}),
      search: createSearchParams({
        year: currentYear,
        type: currentType.number
      }).toString()
    }

    navigate(path);
  }

  const tabItem = (listItem: any, parent?: any) => {
    console.log('currentType', currentType)
    return (
      <CardItem
        onClick={() => handleClickJournal(listItem)}
        style={{width: '100%'}}
        title={(
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <MyText size={16} color={setThemeColor(theme, DefaultTokenTheme.primary, DefaultTokenTheme.secondary)}
                      weight={700}>
                {listItem.group}
              </MyText>
            </Col>

            {
              parent &&
              <Col span={24}>
                <MyText size={14} color={setThemeColor(theme, DefaultTokenTheme.icon, DefaultTokenTheme.disabled)}
                        weight={700}>
                  {parent.subject}
                </MyText>
              </Col>
            }
          </Row>
        )}
      >
        <Row justify='space-between' gutter={[8, 8]} align='middle'>
          <Col span={18}>
            <Row align='middle' gutter={[16, 8]}>

              <Col span={24}>
                <MyText size={16} color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')} weight={700}>
                  {listItem.module}
                </MyText>
              </Col>

              <Col span={24}>
                <MyText size={14} color={setThemeColor(theme, DefaultTokenTheme.icon, DefaultTokenTheme.subtitleDark)}>
                  {listItem.subject}
                </MyText>
              </Col>

              {
                currentType.string === JOURNAL_TYPES.INDIVIDUAL &&
                <Col span={24}>
                  <Tooltip title={(
                    <Row>
                      {
                        listItem?.students?.map((stud: any, studIndex: any) => (
                          <Col key={`student-${studIndex}`} span={24}>
                            <MyText color='#fff'>
                              {stud}
                            </MyText>
                          </Col>
                        ))
                      }
                    </Row>
                  )}>
                    <Row align='middle' gutter={[8, 8]}>
                      <Col>
                        <Flex justify='center' align='center' style={{
                          width: 24,
                          height: 24,
                          borderRadius: '50%',
                          backgroundColor: setThemeColor(theme, DefaultTokenTheme.secondary, DefaultTokenTheme.secondary)
                        }}>
                          {listItem?.students?.length}
                        </Flex>
                      </Col>

                      <Col>
                        <MyText
                          color={setThemeColor(theme, DefaultTokenTheme.textSubtitle, DefaultTokenTheme.subtitleDark)} weight={500}>
                          Список студентов
                        </MyText>
                      </Col>
                    </Row>
                  </Tooltip>
                </Col>
              }


            </Row>
          </Col>

          <Col>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 44,
              height: 44,
              borderRadius: '50%',
              backgroundColor: setThemeColor(theme, DefaultTokenTheme.secondary, DefaultTokenTheme.pressed),
            }}>
              <ArrowRightOutlined
                style={{color: setThemeColor(theme, DefaultTokenTheme.pressed, DefaultTokenTheme.secondary)}}/>
            </div>
          </Col>
        </Row>
      </CardItem>
    )
  }

  const secondLevelTabContentItem = () => {
    return (
      <StyledList
        // grid={{
        //   gutter: 16,
        //   column: 3,
        // }}
        // style={{padding: 16}}
        locale={{
          emptyText: <Empty description={t('noRecords')}/>
        }}
        dataSource={journalListState.data || []}
        loading={journalListState.loading}
        rowKey={(record: any) => `${record.id}-journalListState`}
        renderItem={(listItem: any) => (
          listItem?.children?.length > 0
            ? (
              listItem?.children?.map((child: any) => (
                <List.Item key={child.id} style={{width: '32%'}}>
                  {tabItem(child, listItem)}
                </List.Item>
              ))
            )
            : (
              <List.Item style={{width: '32%'}}>
                {tabItem(listItem)}
              </List.Item>
            )
        )}
      />
    )
  }

  const handleAdminFormSubmit = (values: any) => {
    dispatch(getJournalListAction({
      year: currentYear,
      type: currentType.number,
      ...values
    }))
  }

  const handleAdminValuesChange = (changedValues: any, values: any) => {
    let currentFieldsLength = Object.values(adminForm.getFieldsValue())?.filter((item: any) => item)?.length

    dispatch(getJournalListAction({
      year: currentYear,
      type: currentType.number,
      ...values
    }))

    if (currentFieldsLength === 1) {
      setAdminPriorityField(Object.keys(changedValues)[0])
    }

  }


  const secondLevelTabsRenderContent = () => {
    if (userProfileState.data) {
      if (userProfileState.data.isCollegeAdmin) {
        return (
          <ThirdLevelTabs items={[
            {
              key: ADMIN_TAB_KEY.MY,
              label: 'Мои',
              children: secondLevelTabContentItem(),
            },
            {
              key: ADMIN_TAB_KEY.ALL,
              label: 'Все',
              children: (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Spin spinning={ctpFilterState.loading}>
                      <Form
                        form={adminForm}
                        labelCol={{
                          span: 24,
                        }}
                        style={{padding: 12}}
                        onFinish={(values: any) => handleAdminFormSubmit(values)}
                        onValuesChange={(changedValues, values) => handleAdminValuesChange(changedValues, values)}
                      >
                        <Row gutter={8} align='bottom'>
                          {
                            ctpFilterState.data?.fields?.map((field: any, fieldIndex: any) => {
                              const isPriorityField = adminPriorityField ? field.name === adminPriorityField : true

                              const res = field.fieldValues?.filter((data: any) => {
                                return journalListState.data?.some((secondLevelData: any) => {
                                  switch (field.name) {
                                    case 'teacher_id':
                                      return secondLevelData.teacher?.some((teacherInner: any) => {
                                        return field.fieldValues?.some((secondLevel: any) => teacherInner.id === data.value)
                                      })
                                    case 'subject_id':
                                      return secondLevelData.subject_id === data.value
                                    case 'group_id':
                                      return secondLevelData.group_id === data.value
                                    default:
                                      return false
                                  }
                                })

                              })

                              return (
                                <Col key={fieldIndex} span={5}>
                                  <Form.Item name={field.name} label={field.label}>
                                    <Select style={{width: '100%'}}
                                            options={isPriorityField ? field.fieldValues : res}/>
                                  </Form.Item>
                                </Col>
                              )
                            })
                          }
                          <Col span={4}>
                            <MyButton title='Сбросить' htmlType='reset' width='100%' height={40}
                                      style={{marginBottom: 12}} onClick={() => {
                              setAdminPriorityField(null)
                              dispatch(getJournalListActionDone([]))
                            }}/>
                          </Col>
                          <Col span={5}>
                            <MyButton title='Применить' htmlType='submit' width='100%' height={40}
                                      style={{marginBottom: 12}}/>
                          </Col>
                        </Row>
                      </Form>
                    </Spin>
                  </Col>

                  <Col span={24}>
                    {secondLevelTabContentItem()}
                  </Col>
                </Row>
              ),
            },
          ]} onTabClick={(key: any) => handleClickAdminTab(key)} activeKey={currentAdminTab}/>
        )
      } else {
        return secondLevelTabContentItem()
      }
    }
  }

  const secondLevelTabsContent = [
    {
      key: JOURNAL_YEAR.PREV,
      label: `${JOURNAL_YEAR.PREV}-${JOURNAL_YEAR.CURRENT}`,
      children: secondLevelTabsRenderContent()
    },
    {
      key: JOURNAL_YEAR.CURRENT,
      label: `${JOURNAL_YEAR.CURRENT}-${JOURNAL_YEAR.NEXT}`,
      children: secondLevelTabsRenderContent()
    }
  ]

  const firstLevelTabs = [
    {
      key: JOURNAL_TYPES.THEORETICAL,
      label: t('journalTheoretical'),
      children: (
        <Row>
          <Col span={24}>
            <SecondLevelTabs items={secondLevelTabsContent} onTabClick={(key) => handleClickYearTab(key)}
                             activeKey={currentYear}/>
          </Col>
        </Row>
      ),
    },
    {
      key: JOURNAL_TYPES.INDIVIDUAL,
      label: t('journalIndividual'),
      children: (
        <Row>
          <Col span={24}>
            <SecondLevelTabs items={secondLevelTabsContent} onTabClick={(key) => handleClickYearTab(key)}
                             activeKey={currentYear}/>
          </Col>
        </Row>
      ),
    },
    {
      key: JOURNAL_TYPES.INDUSTRIAL,
      label: t('journalIndustrial'),
      children: (
        <Row>
          <Col span={24}>
            <SecondLevelTabs items={secondLevelTabsContent} onTabClick={(key) => handleClickYearTab(key)}
                             activeKey={currentYear}/>
          </Col>
        </Row>
      ),
    },
    {
      key: JOURNAL_TYPES.FACULTATIVE,
      label: t('journalFacultative'),
      children: (
        <Row>
          <Col span={24}>
            <SecondLevelTabs items={secondLevelTabsContent} onTabClick={(key) => handleClickYearTab(key)}
                             activeKey={currentYear}/>
          </Col>
        </Row>
      ),
    },
  ]

  return (
    <>
      <MyBreadcrumb title={t('journal')}/>

      <Row justify='center'>
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs items={firstLevelTabs} onTabClick={(key: any) => handleClickTypeTab(key)}
                              activeKey={currentType.string}/>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
