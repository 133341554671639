import {axiosConfig as axios} from "../../../utils/axios";
import {escapeStringForGraphQL} from "../../../utils/escapeStringForGraphQL";
import dayjs from "dayjs";
import {COOKIES_ENUM} from "../../../constants";
import {Cookies} from "react-cookie";
const cookies = new Cookies()

let token = cookies.get(COOKIES_ENUM.JWT)

let url = 'v1/graphql/main'
let url_v1 = 'v1'

let config = {
  headers: {
    "access-key":  `${dayjs().format('DD.MM.YYYY')}-college`,
    "accept": 'application/json',
    "jwt": `${token}`,
  }
}


export const getAllRupsApi = () => axios.post(url, {
  query: `
    {
      newRups{
        id
        name
        type
        rup_type
        status
        profile_id
        year
        profile {
          id
          caption
          code
        }
        profile_direction_id
        profile_direction{
          id
          caption
          code
        }
        specialization_id
        specialization{
          id
          caption
          code
        }
        speciality_id
        speciality{
          id
          caption
          code
        }
        linkGroups{
        id
          group {
            id
            caption
          }
        }
      }
    }
  `
}, config)

export const getDeletedRupsApi = () => axios.post(url, {
  query: `
    {
      newRups(status: "2"){
        id
        name
        type
        rup_type
        status
        year
        profile_id
        profile {
          id
          caption
          code
        }
        profile_direction_id
        profile_direction{
          id
          caption
          code
        }
        specialization_id
        specialization{
          id
          caption
          code
        }
        speciality_id
        speciality{
          id
          caption
          code
        }
        linkGroups{
        id
          group {
            id
            caption
          }
        }
      }
    }
  `
}, config)

export const addNewRupApi = (body: any) => axios.post(url, {
  query: `
    mutation{
      addNewRup(rup_type: ${body.rup_type}, speciality_id: ${body.speciality_id}, name: "${body.name}", year: ${body.year}){
        id
        year
        name
        type
        rup_type
        status
        year
        profile_id
        profile {
          id
          caption
          code
        }
        profile_direction_id
        profile_direction{
          id
          caption
          code
        }
        specialization_id
        specialization{
          id
          caption
          code
        }
        speciality_id
        speciality{
          id
          caption
          code
        }
        linkGroups{
        id
          group {
            id
            caption
          }
        }
      }
    }
  `
}, config)

export const getGroupForRupApi = (body: any) => axios.post(url, {
  query: `
    {
      getGroupForRup(rup_type: ${body.rup_type}, year: ${body.year}, ${body.id ? `rup_id: ${body.id}` : ''}){
        id
        caption
      }
    }
  `
}, config)

export const getAllProfilesApi = () => axios.post(url, {
  query: `
  {
   specialities(level: 1, is_archived: "0" ){
      id
      level
      caption
      code
      is_archived
      qual_types
    }
  }
  `
}, config)

export const getProfileDirectionsSpecializationsSpecialtyApi = () => axios.post(url, {
  query: `
  {
    specialities(is_archived: "0", level: 4 ){
      id
      caption
      code
      is_archived
      qual_types
    }
  }
  `
}, config)

export const getSpecializationsSpecialtyApi = (id: any) => axios.post(url, {
  query: `
  {
    specialities(is_archived: "0", level: 5, parent_id: ${id} ){
      id
      caption
      code
      is_archived
      qual_types
    }
  }
  `
}, config)

export const setGroupForRupApi = (body: any) => axios.post(url, {
  query: `
    mutation{
      addRupGroups(rup_id: ${body.id}, group_ids: [${body.linkGroups}])
    }
  `
}, config)

export const editRupApi = (body: any) => axios.post(url, {
  query: `
    mutation{
      changeRup(
        id: ${body.id}, 
        name: "${body.name}", 
        status: ${body.status}, 
        year:${body.year}, 
        ${body.rup_type ? `rup_type: ${body.rup_type}` : ''}
        ${body.speciality_id ? `speciality_id: ${body.speciality_id}` : ''}
      ){
         id
        name
        year
        type
        rup_type
        status
        isCanDelete
        profile_id
        profile {
          id
          caption
          code
        }
        profile_direction_id
        profile_direction{
          id
          caption
          code
        }
        specialization_id
        specialization{
          id
          caption
          code
        }
        speciality_id
        speciality{
          id
          caption
          code
        }
        linkGroups{
        id
          group {
            id
            caption
          }
        }
      }
    }
  `
}, config)

export const duplicateRupApi = (body: any) => axios.post(url, {
  query: `
    mutation{
      duplicateRup(rup_id: ${body.rup_id}, year:${body.year}, ){
        id
        year
        name
        type
        rup_type
        status
        isCanDelete
        profile_id
        profile {
          id
          caption
          code
        }
        profile_direction_id
        profile_direction{
          id
          caption
          code
        }
        specialization_id
        specialization{
          id
          caption
          code
        }
        speciality_id
        speciality{
          id
          caption
          code
        }
        linkGroups{
        id
          group {
            id
            caption
          }
        }
      }
    }
  `
}, config)

export const deleteRupApi = (rup_id: any) => axios.post(url, {
  query: `
  mutation{
      deleteRup(id: ${rup_id}){
        id
        status
      }
    }
  `
}, config)

export const getRecommendedRupsApi = () => axios.get(`${url_v1}/common/recommendation`, {
  headers: config.headers}).then(({data}) => data);

export const addRupFromRecommendedApi = (body: any) => axios.post(url, {
  query: `
    mutation{
      addRupFromRecommend(profile_id: ${body.profile}, 
      profile_direction_id: ${body.profile_direction}, 
      specialization_id: ${body.specialization}, 
      speciality_id: ${body.speciality}, 
      rup_type: ${body.rup_type}){
        id
        year
        name
        type
        rup_type
        status
        isCanDelete
        profile_id
        profile {
          id
          caption
          code
        }
        profile_direction_id
        profile_direction{
          id
          caption
          code
        }
        specialization_id
        specialization{
          id
          caption
          code
        }
        speciality_id
        speciality{
          id
          caption
          code
        }
        linkGroups{
        id
          group {
            id
            caption
          }
        }
      }
    }
  `
}, config)

export const getQualificationsApi = (rup_id: any) => axios.post(url, {
  query: `
  {
    rupQualifications(rup_id: ${rup_id}){
      id
      name
      isCanDelete
      time_years
      time_months
      qualification{
        id
        caption
        code
      }
    }
  }
  `
}, config)

export const getSpecializationIdApi = (rup_id: any) => axios.post(url,{
  query: `
  {
    newRup(id: ${rup_id}){
      year
      id
      name
      type
      rup_type
      status
      isCanDelete
      profile_id
      profile {
        id
        caption
        code
      }
      profile_direction_id
      profile_direction{
        id
        caption
        code
      }
      specialization_id
      specialization{
        id
        caption
        code
      }
      speciality_id
      speciality{
        id
        caption
        code
      }
      linkGroups{
      id
        group {
          id
          caption
        }
      }
    }
  }  
  `
}, config)

export const addNewQualificationApi = (rup_id: any) => axios.post(url, {
  query: `
    mutation{
      addQual(rup_id: ${rup_id}){
        id
        name
        isCanDelete
        time_years
        time_months
        qualification{
          id
          caption
          code
        }
      }
    }
  `
}, config)

export const changeQualificationApi = (body: any) => axios.post(url, {
  query: `
    mutation{
      changeQual(id: ${body.id}, 
      ${body.key === 'name' ? (`name: "${body.name}", qualification_id: ${body.value}`) : `${body.key}: ${body.value}`}){
        id
        name
        isCanDelete
        time_years
        time_months
        qualification{
          id
          caption
          code
        }
      }
    }
  `
}, config)

export const deleteQualificationApi = (body: any) => axios.post(url, {
  query: `
  mutation{
    deleteQual(id: ${body.id}){
      id
    }
  }
  `
}, config)

export const getRupApi = (rup_id: any) => axios.get(`${url_v1}/common/rup-hours`, {
  params: { rup_id }, headers: config.headers}).then(({data}) => data);

export const restoreStructureApi = (rup_id: any) => axios.post(url, {
  query: `
    mutation{
      restoreStructure(rup_id: ${rup_id})
    }
  `
}, config)

export const getDisciplinesApi = () => axios.post(url, {
  query: `
  {
    disciplines{
      id
      caption
    }
  }
  `
}, config)

export const getSemestersSettingsDataApi = (rup_id: any) => axios.post(url,{
  query: `
  {
    semester(rup_id: ${rup_id}){
      id
      one
      two
      three
      four
      five
      six
      seven
      eight
    }
  }  
  `
}, config)

export const getNewHourRowApi = (id: any) => axios.post(url, { query: `
  mutation{
    addHour(id: ${id}){
      id
      rup_id
    }
  }
` }, config)

export const postDisciplineForRupApi = (parent_id: any) => axios.post(url, {
  query: `
    mutation{
      addDiscipline(id: ${parent_id}){
        id
        rup_id
      }
    }
  `
}, config)

export const addModuleWithoutDisciplineApi = (parent_id: any) => axios.post(url, {
  query: `
    mutation{
      addModuleWithoutDisciplines(id: ${parent_id}){
        id
        rup_id
      }
    }
  `
}, config)

export const deleteHourRowApi = (id: any) => axios.post(url, {
  query: `
  mutation{
    deleteHour(id: ${id}){
      id
      rup_id
      index
    }
  }
  `
}, config)

export const editRowApi = (body: any) => axios.post(url, {
  query: `
  mutation {
    ${body.map((item: any, index: any) => (
    `my${index}: changeHour(id: ${item.id}, 
      ${item.key === 'credit' ? `credits` : `${[item.key]}`}: ${item.key === 'credit' ? `"${item.value}"` : `${typeof item.value === 'string' ? `"${escapeStringForGraphQL(item.value)}"` : item.value}`}){
        id
        ${item.key === 'credit' ? `credits` : `${[item.key]}`}
      }`
  ))}
  }
  `
}, config)

export const editSemestersSettingsApi = (body: any) => axios.post(url,{
  query: `
  mutation{
    changeSemester(rup_id: ${body.rup_id}, one: ${body.one}, two: ${body.two}, three: ${body.three}, four: ${body.four}, five: ${body.five}, six: ${body.six}, seven: ${body.seven}, eight: ${body.eight}){
      id
      one
      two
      three
      four
      five
      six
      seven
      eight
    }
  }  
  `
}, config)

export const postNewRupApi = (body: any) => axios.post(url, {
  query: `
    mutation{
      addRup(rup_type: ${body.rup_type}){
        year
        id
        name
        type
        rup_type
        status
        profile{
          code
          caption
        }
        profile_direction{
          code
          caption
        }
        specialization{
          code
          caption
        }
        speciality{
          code
          caption
        }
      }
    }
  `
}, config)

export const getRupHoursTypesForTranslateApi = () => axios.post(url, {
  query: `
    {
      getRupHoursTypesForTranslate
    }
  `
}, config)

export const rupHoursApi = (id: any) => axios.post(url, {
  query: `
    {rupHours(rup_id: ${id}, row_type: "qualification"){
      id
      row_type
      name
      index
      parent_id
      caption_ru
      caption_kk
      children{
        id
        row_type
        name
        parent_id
        caption_ru
        caption_kk
        
        children{
          id
          row_type
          name
          parent_id
          caption_ru
          caption_kk
          
          children{
            id
            row_type
            name
            parent_id
            caption_ru
            caption_kk
            
            children{
              id
              row_type
              name
              parent_id
              caption_ru
              caption_kk
              
              children{
                id
                row_type
                name
                parent_id
                caption_ru
                caption_kk
              }
            }
          }
        }
      }
    }
    }
  `
}, config)

export const changeRupHoursApi = (body: any) => axios.post(url, {
  query: `
    mutation{
      changeHour(
        id: ${body.id}, 
        ${body.caption_ru ? `caption_ru: "${escapeStringForGraphQL(body.caption_ru)}",` : ''}
        ${body.caption_kk ? `caption_kk: "${escapeStringForGraphQL(body.caption_kk)}",` : ''}
      ){
        id
        caption_ru
        caption_kk
        name
      }
    }
  `
}, config)
