import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Row,
  Table,
  Form,
  Typography,
  TabsProps,
  Tabs,
} from "antd";
import { MyBreadcrumb, FirstLevelTabs } from "../../../components";
import "../../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import { Cookies } from "react-cookie";
import {
  initialDataID,
  initialDataIDBirth,
  initialDataJob,
  initialDataPassport,
  initDataQualification,
  initialDataContact,
  initialDataPD,
  initDataAuth,
  initDataLg,
  initDataAwards,
  initDataStage,
  initialDataGetEdu,
  initDataCategory,
} from "./TableEmployee";
import { PlusOutlined } from "@ant-design/icons";
import { columns } from "./TableExperience";
import { COOKIES_ENUM } from "../../../constants";
import { CreateEmployeeRequest } from "../../InterfacesApi/InterfacesApi";
import { HeadersAuth } from "../../../constants/Auth";
export const EmployeeView = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { Title } = Typography;
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("1");

  const [dataPD, setDataPD] = useState(initialDataPD);
  const [dataContact, setDataContact] = useState(initialDataContact);
  const [dataIDBirth, setDataIDBirth] = useState(initialDataIDBirth);
  const [dataID, setDataId] = useState(initialDataID);
  const [dataPassport, setDataPassport] = useState(initialDataPassport);
  const [dataJob, setDataJob] = useState(initialDataJob);
  const [dataAuth, setDataAuth] = useState(initDataAuth);
  const [dataLg, setDataLg] = useState(initDataLg);
  const [dataAwards, setDataAwards] = useState(initDataAwards);
  const [dataStage, setDataStage] = useState(initDataStage);
  const [dataGetEdu, setDataGetEdu] = useState(initialDataGetEdu);
  const [dataQualification, setDataQualification] = useState(
    initDataQualification
  );
  const [dataCategory, setDataCategory] = useState(initDataCategory);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getEmployeePrivateInfo?id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          const updatedDataPD = [
            {
              content: "ФИО",
              info: `${data.secondname} ${data.firstname} ${data.middlename}`,
            },
            { content: "ИИН", info: data.iin },
            { content: "Дата рождения", info: data.date_birth },
            { content: "Место рождения", info: data.place_of_birth },
            { content: "Гражданство", info: data.citizenship },
            { content: "Пол", info: data.gender },
            { content: "Национальность", info: data.nationality },
            { content: "Язык обучения", info: data.lang },
            { content: "Роль пользователя", info: data.role_ids.join(", ") },
          ];
          setDataPD(updatedDataPD);
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных");
      }
    };

    const fetchEmployeeContact = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getContactInfo?id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          const updatedDataContact = [
            {
              content: "Домашний телефон	",
              info: data.home_phone,
            },
            { content: "Мобильный телефон	", info: data.mobile_phone },
            { content: "Адрес переписки	", info: data.registration_address },
            { content: "Домашний адрес	", info: data.home_address },
            { content: "E-mail	", info: data.email },
          ];
          setDataContact(updatedDataContact);
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных");
      }
    };

    const fetchEmployeeDoc1 = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getEmployeeIdentificationCard?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          const updatedDataID = [
            {
              content: "Номер удостоверения личности",
              info: data.id_card_number,
            },
            { content: "Дата выдачи", info: data.card_date_of_issue },
            { content: "Действительно до", info: data.card_valid_until },
            { content: "Кем выдано", info: data.card_who_issued },
          ];
          setDataId(updatedDataID);
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных");
      }
    };

    const fetchEmployeeDoc2 = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getPersonBirthCertificate?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          const updatedDataIDBirth = [
            {
              content: "Номер свидетельство о рождении	",
              info: data.number_birth_certificate,
            },
            { content: "Дата выдачи	", info: data.birth_date_of_issue },
            {
              content: "Серия свидетельство о рождении",
              info: data.series_birth_certificate,
            },
          ];
          setDataIDBirth(updatedDataIDBirth);
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных");
      }
    };

    const fetchEmployeeDoc3 = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getPersonPassport?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          const updatedPassport = [
            {
              content: "Номер паспорта",
              info: data.passport_number,
            },
            { content: "Дата выдачи	", info: data.passport_date_of_issue },
            { content: "Действителен до	", info: data.passport_valid_until },
            { content: "Кем выдано	", info: data.passport_who_issued },
            { content: "Серия	", info: data.passport_series },
          ];
          setDataPassport(updatedPassport);
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных");
      }
    };

    const fetchEmployeeJobPosition = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/GetEmployeePosition?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          const updatedPositionEmployee = [
            {
              content: "Название",
              info: data.position.job_title,
            },
            { content: "Ставка", info: data.position.working_rate },
          ];
          setDataJob(updatedPositionEmployee);
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных");
      }
    };

    const fetchEmployeeAuth = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/GetAuth?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          const updateAuth = [
            {
              content: "Почта",
              info: data.email,
            },
          ];
          setDataAuth(updateAuth);
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных");
      }
    };

    const fetchEmployeeLang = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getEmployeeLanguages?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          const languages = data.languages[0];
          const updateLang = [
            {
              content: "Язык",
              info: languages.lang,
            },
            {
              content: "Владение языком	",
              info: languages.lang_skills,
            },
          ];
          setDataLg(updateLang);
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных");
      }
    };

    const fetchEmployeeAwards = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getEmployeeAwards?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          const awards = data.awards[0];
          const updateAward = [
            {
              content: "Награда",
              info: awards.award,
            },
            {
              content: "Персональные названия",
              info: awards.personal_name,
            },
            {
              content: "Ученая степень",
              info: awards.academic_degree,
            },
          ];
          setDataAwards(updateAward);
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных");
      }
    };

    /// FIRST
    fetchEmployeeData();
    fetchEmployeeContact();
    fetchEmployeeDoc1();
    fetchEmployeeDoc2();
    fetchEmployeeDoc3();
    fetchEmployeeJobPosition();
    fetchEmployeeAuth();
    fetchEmployeeLang();
    fetchEmployeeAwards();

    //EXPERIENCE

    const fetchEmployeeExperience = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/GetPedagogicalExperience?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          if (Array.isArray(data) && data.length > 0) {
            const experience = data[0];
            const updateStage = [
              {
                content: "Организации",
                info: experience.id,
              },
              {
                content: "Дата начала работы",
                info: experience.start_date,
              },
              {
                content: "Дата окончания работы",
                info: experience.end_date,
              },
              {
                content: "Опыт работы",
                info: experience.work_experience,
              },
              {
                content: "Номер приказа при приеме на работу",
                info: experience.order_number,
              },
              {
                content: "Дата приказа при приеме на работу",
                info: experience.order_date,
              },
            ];
            setDataStage(updateStage);
          } else {
            console.error(
              "Ошибка: Неправильный формат данных или отсутствуют данные"
            );
          }
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных", error);
      }
    };

    const fetchEmployeeEdu = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/GetEducation?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();

          if (data.education && data.education.length > 0) {
            const educationData = data.education[0];

            const updateStage = [
              {
                content: "Образование",
                info: educationData.education,
              },
              {
                content: "Учебное заведение",
                info: educationData.institution_title,
              },
              {
                content: "Факультет",
                info: educationData.faculty_title,
              },
              {
                content: "Специальность",
                info: educationData.speciality_title,
              },
              {
                content: "№ диплома",
                info: educationData.diploma_number,
              },
              {
                content: "Когда окончил",
                info: educationData.graduate_date,
              },
              {
                content: "Педагогическое",
                info: educationData.pedagogical,
              },
            ];
            setDataGetEdu(updateStage);
          } else {
            console.error("Данные об образовании отсутствуют");
          }
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных", error);
      }
    };

    const fetchEmployeeCourses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/GetQualificationCourses?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          if (Array.isArray(data) && data.length > 0) {
            const experience = data[0];
            const updateStage = [
              {
                content: "Название курса",
                info: experience.course_name,
              },
              {
                content: "Дата начало курса",
                info: experience.start_date,
              },
              {
                content: "Дата окончания курса",
                info: experience.end_date,
              },
              {
                content: "Результаты/Итоги",
                info: experience.result,
              },
            ];
            setDataQualification(updateStage);
          } else {
            console.error(
              "Ошибка: Неправильный формат данных или отсутствуют данные"
            );
          }
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных", error);
      }
    };

    const fetchEmployeeCategory = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/GetTeacherCategories?person_id=${id}`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          if (Array.isArray(data) && data.length > 0) {
            const experience = data[0];
            const updateStage = [
              {
                content: "Название	",
                info: experience.category_title,
              },
              {
                content: "№ приказа	",
                info: experience.order_number,
              },
              {
                content: "Дата приказа	",
                info: experience.end_date,
              },
              {
                content: "№ Удостоверения	",
                info: experience.id_number,
              },
              {
                content: "Дата выдачи удостоверения	",
                info: experience.date_of_issue,
              },
              {
                content: "Срок действия удостоверения		",
                info: experience.valid_until,
              },
            ];
            setDataCategory(updateStage);
          } else {
            console.error(
              "Ошибка: Неправильный формат данных или отсутствуют данные"
            );
          }
        } else {
          console.error("Ошибка получения данных");
        }
      } catch (error) {
        console.error("Ошибка получения данных", error);
      }
    };

    fetchEmployeeExperience();
    fetchEmployeeEdu();
    fetchEmployeeCourses();
    fetchEmployeeCategory();
  }, [id]);

  const renderRowClassName = (index) => {
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  const handleEditClick = () => {
    navigate(`/employee/edit/${id}`);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Стаж педагогической работы",
      children: (
        <Content
          style={{
            width: "100%",
            height: "380px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <div
            className="employee_experience_table"
            style={{ padding: "20px" }}
          >
            <Table
              style={{ marginTop: "5px" }}
              columns={columns}
              dataSource={dataStage}
              bordered
              showHeader={false}
              pagination={false}
              rowClassName={renderRowClassName}
            ></Table>
          </div>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/employee")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton" onClick={handleEditClick}>
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "2",
      label: "Полученное образование",
      children: (
        <Content
          style={{
            width: "100%",
            height: "410px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <div
            className="employee_experience_table"
            style={{ padding: "20px" }}
          >
            <Table
              style={{ marginTop: "5px" }}
              columns={columns}
              dataSource={dataGetEdu}
              bordered
              showHeader={false}
              pagination={false}
              rowClassName={renderRowClassName}
            ></Table>
          </div>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/employee")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton" onClick={handleEditClick}>
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "3",
      label: "Курсы повышенной квалификации",
      children: (
        <Content
          style={{
            width: "100%",
            height: "310px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <div
            className="employee_experience_table"
            style={{ padding: "20px" }}
          >
            <Table
              style={{ marginTop: "5px" }}
              columns={columns}
              dataSource={dataQualification}
              bordered
              showHeader={false}
              pagination={false}
              rowClassName={renderRowClassName}
            ></Table>
          </div>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/employee")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton" onClick={handleEditClick}>
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "4",
      label: "Категории",
      children: (
        <Content
          style={{
            width: "100%",
            height: "380px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <div
            className="employee_experience_table"
            style={{ padding: "20px" }}
          >
            <Table
              style={{ marginTop: "5px" }}
              columns={columns}
              dataSource={dataCategory}
              bordered
              showHeader={false}
              pagination={false}
              rowClassName={renderRowClassName}
            ></Table>
          </div>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/employee")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton" onClick={handleEditClick}>
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
  ];

  const firstLevelTabs = [
    {
      key: "1",
      label: "Личные данные",
      children: (
        <Content
          style={{
            width: "100%",
            height: "530px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row
              className="tableEmployee"
              style={{ padding: "10px 20px 10px 20px" }}
              gutter={[20, 20]}
            >
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={dataPD}
                  bordered
                  showHeader={false}
                  pagination={false}
                  rowClassName={renderRowClassName}
                ></Table>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/employee")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton" onClick={handleEditClick}>
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "2",
      label: "Контакты",
      children: (
        <Content
          style={{
            width: "100%",
            height: "400px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={dataContact}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/employee")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton" onClick={handleEditClick}>
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "3",
      label: "Документы",
      children: (
        <Content
          style={{
            width: "100%",
            height: "860px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Title level={3}>Свидетельство о рождении</Title>
              <Table
                style={{ marginTop: "20px" }}
                columns={columns}
                dataSource={dataIDBirth}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>

          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Title level={3}>Удостоверение личности</Title>
              <Table
                style={{ marginTop: "20px" }}
                columns={columns}
                dataSource={dataID}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>
          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Title level={3}>Паспорт</Title>
              <Table
                style={{ marginTop: "20px" }}
                columns={columns}
                dataSource={dataPassport}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/employee")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton" onClick={handleEditClick}>
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "4",
      label: "Образование и опыт работы",
      children: (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Tabs
              tabBarStyle={{ padding: "0 20px 0 20px" }}
              tabPosition="top"
              size="large"
              defaultActiveKey="1"
              items={items}
            />
          </Col>
        </Row>
      ),
    },
    {
      key: "5",
      label: "Награды и Языки",
      children: (
        <Content
          style={{
            width: "100%",
            height: "490px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Title level={3}>Награды</Title>
              <Table
                style={{ marginTop: "20px" }}
                columns={columns}
                dataSource={dataAwards}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>

          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Title level={3}>Языки</Title>
              <Table
                style={{ marginTop: "20px" }}
                columns={columns}
                dataSource={dataLg}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/employee")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton" onClick={handleEditClick}>
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "6",
      label: "Должность",
      children: (
        <Content
          style={{
            width: "100%",
            height: "280px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={dataJob}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/employee")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton" onClick={handleEditClick}>
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "7",
      label: "Авторизационные записи",
      children: (
        <Content
          style={{
            width: "100%",
            height: "240px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={dataAuth}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/employee")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton" onClick={handleEditClick}>
                Редактировать
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Просмотр данных сотрудника" />
      <Row justify="center">
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="1" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>

      <p />
    </>
  );
};
