import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { selectStore } from "../../store/selector";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Modal,
  Pagination,
  Row,
  Spin,
  Table,
  Tabs,
  TabsProps,
  Tooltip,
} from "antd";
import {
  SecondLevelTabs,
  JournalTable,
  MyBreadcrumb,
  MyButton,
  MyText,
  FirstLevelTabs,
} from "../../components";
import { CardItem, StyledJournalTableContainer } from "../Journal/index.styles";
import { getGetPerformance } from "../../store/pages/journal/actions";
import dayjs from "dayjs";
import cl from "classnames";
import { setThemeColor } from "../../utils/setThemeColor";
import { useTheme } from "../../hooks";
import { position } from "polished";
import "../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import {
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { Cookies } from "react-cookie";
import { GroupData } from "../InterfacesApi/InterfacesApi";
import { HeadersAuth } from "../../constants/Auth";

export const Group = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const editIcon = (
    <EditOutlined
      onClick={() => navigate("/group/edit")}
      className="speciality_edit"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
    />
  );
  const deleteIcon = (
    <DeleteOutlined
      className="speciality_delete"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
    />
  );
  const [groupData, setGroupData] = useState<GroupData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [qualificationsData, setQualificationsData] = useState({
    current_page: 1,
    data: [],
    last_page: 1,
    per_page: 10,
    total: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/src1/group/index-study-groups?college_id=1&page=${currentPage}`,
          HeadersAuth
        );
        const apiData: GroupData[] = response.data.data;
        setGroupData(apiData);
        setQualificationsData(response.data);
        setTotalPages(response.data.total_pages);
      } catch (error) {
        console.error("Error fetching group data:", error);
      }
    };

    fetchData();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleDelete = (groupId) => {
    Modal.confirm({
      title: "Удаление записи",
      content: "Вы уверены что хотите удалить группу?",
      okText: "Да",
      cancelText: "Нет",
      onOk: async () => {
        try {
          await axios.delete(
            `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/v1/deleteStudy-groups?groupId=${groupId}`,
            HeadersAuth
          );
          setGroupData((prevGroupData) =>
            prevGroupData.filter((group) => group.id !== groupId)
          );
        } catch (error) {
          console.error("Ошибка удаления группы:", error);
        }
      },
      onCancel: () => {},
    });
  };

  const editIconClickHandler = (
    id,
    start_year,
    department_id,
    adviser_id,
    study_group_info,
    speciality_id,
    ref_study_group_to_qualifications
  ) => {
    console.log("Navigating with state:", {
      id,
      start_year,
      department_id,
      adviser_id,
      study_group_info,
      speciality_id,
      ref_study_group_to_qualifications,
    });
    navigate(`/group/edit/${id}`, {
      state: {
        id,
        start_year,
        department_id,
        adviser_id,
        study_group_info,
        speciality_id,
        ref_study_group_to_qualifications,
      },
    });
  };

  const columns = [
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>ID</span>,
      dataIndex: "id",
      key: "id",
      style: { fontSize: "26px", fontWeight: "bold" },
    },
    {
      title: (
        <span style={{ padding: "10px", color: "#773DBD" }}>Название</span>
      ),
      dataIndex: ["study_group_info", "title"],
      key: "study_group_info.title",
      style: { fontSize: "26px" },
      sorter: true,
    },
    {
      title: <span style={{ padding: "10px", color: "#773DBD" }}>Язык</span>,
      dataIndex: ["study_group_info", "language_iso"],
      key: "study_group_info.language_iso",
      style: { fontSize: "26px" },
      sorter: true,
    },
    {
      title: (
        <span style={{ padding: "10px", color: "#773DBD" }}>Специальность</span>
      ),
      dataIndex: "speciality_title",
      key: "speciality_title",
      style: { fontSize: "26px" },
    },
    {
      title: <span style={{ padding: "10px", color: "#773DBD" }}>Кафедра</span>,
      dataIndex: "department_caption",
      key: "department_caption",
      style: { fontSize: "26px" },
    },
    {
      title: <span style={{ padding: "10px", color: "#773DBD" }}>Куратор</span>,
      dataIndex: "adviser_id",
      key: "adviser_id",
      style: { fontSize: "26px" },
    },
    {
      title: (
        <span style={{ padding: "10px", color: "#773DBD" }}>
          Год зачисления
        </span>
      ),
      dataIndex: "start_year",
      key: "start_year",
      style: { fontSize: "26px" },
      sorter: true,
    },
    {
      title: (
        <span style={{ padding: "10px", color: "#773DBD" }}>
          Кол-во студентов
        </span>
      ),
      dataIndex: "ref_study_group_to_qualifications",
      key: "ref_study_group_to_qualifications",
      render: (qualifications) => qualifications.length,
      style: { fontSize: "26px" },
    },
    {
      title: (
        <span style={{ padding: "10px", color: "#773DBD" }}>Действие</span>
      ),
      dataIndex: "delete",
      key: "delete",
      width: 80,
      render: (_, record) => (
        <span>
          <Tooltip title="Редактировать">
            <EditOutlined
              onClick={() =>
                editIconClickHandler(
                  record.id,
                  record.start_year,
                  record.department_id,
                  record.adviser_id,
                  record.study_group_info,
                  record.speciality_id,
                  record.ref_study_group_to_qualifications
                )
              }
              className="speciality_edit"
              style={{
                display: "inline",
                fontSize: "24px",
                marginLeft: "10px",
              }}
              twoToneColor="red"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined
              className="speciality_delete"
              style={{
                display: "inline",
                fontSize: "24px",
                marginLeft: "10px",
              }}
              twoToneColor="red"
              onClick={() => handleDelete(record.id)}
            />
          </Tooltip>
        </span>
      ),
    },
  ];

  const data = groupData.map((group) => ({
    id: group.id,
    speciality_title: group.speciality_title,
    department_caption: group.department_caption,
    department_id: group.department_id,
    start_year: group.start_year,
    speciality_id: group.speciality_id,
    adviser_id: group.adviser_id,
    study_group_info: group.study_group_info,
    ref_study_group_to_qualifications: group.ref_study_group_to_qualifications,
    delete: [editIcon, deleteIcon],
  }));

  const redirectToSpeciality = () => {
    navigate("/speciality");
  };
  const redirectToInstitutionDiscipline = () => {
    navigate("/institution_discipline");
  };
  const redirectGroup = () => {
    navigate("/group");
  };

  const redirectToKafedra = () => {
    navigate("/institution_department");
  };

  const secondLevelTabs = [
    {
      key: "1",
      label: (
        <div onClick={redirectGroup} style={{ cursor: "pointer" }}>
          Активные
        </div>
      ),
      children: "",
    },
    {
      key: "2",
      label: (
        <div onClick={redirectGroup} style={{ cursor: "pointer" }}>
          Выпущенные
        </div>
      ),
      children: "",
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Content
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              marginTop: "0px",
              borderRadius: "0px 8px 8px 8px",
            }}
          >
            {/* <SecondLevelTabs defaultActiveKey="3" items={secondLevelTabs} /> */}
            <p />
            <Row
              style={{ height: "20" }}
              justify="space-between"
              align="middle"
            >
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0 20px 0 20px",
                }}
              >
                <span style={{ fontWeight: "500", fontSize: "24px" }}>
                  Добавленные группы
                </span>
              </Col>
              <Col
                span={12}
                style={{
                  textAlign: "end",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  padding: "0 20px 0 0",
                }}
              >
                <Button
                  onClick={() => navigate("/group/create")}
                  className="speciality_create_button"
                >
                  <PlusOutlined
                    style={{ display: "inline-block", fontSize: "16px" }}
                  />
                  <span
                    style={{
                      fontWeight: "400",
                      marginTop: "-20px",
                      lineHeight: "0px",
                    }}
                  >
                    Добавить
                  </span>
                </Button>
              </Col>
            </Row>
            <div
              className="speciality_table"
              style={{ fontSize: "20px", padding: "20px" }}
            >
              <Table
                dataSource={data}
                bordered
                columns={columns}
                pagination={{
                  current: qualificationsData.current_page,
                  pageSize: qualificationsData.per_page,
                  total: qualificationsData.total,
                  onChange: handlePageChange,
                }}
              />
            </div>

            <Row>
              <Col
                span={12}
                style={{ paddingBottom: "30px", marginTop: "-55px" }}
              >
                <span
                  style={{
                    marginLeft: "28px",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >{`Показаны ${
                  (qualificationsData.current_page - 1) *
                    qualificationsData.per_page +
                  1
                }-${Math.min(
                  qualificationsData.current_page * qualificationsData.per_page,
                  qualificationsData.total
                )} из ${qualificationsData.total} записи`}</span>
              </Col>
            </Row>
          </Content>
        </Col>
      </Row>

      <p />
    </>
  );
};
