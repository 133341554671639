import { Layout } from "antd";
import { Navigate, useOutlet } from "react-router-dom";
import { useAuth, useLang, useTheme } from "../../hooks";
import React, { useEffect } from "react";
import {
  fetchGetDashboardAction,
  fetchGetNotificationAction,
  fetchUserProfileAction,
  getMenuAction,
} from "../../store/pages/profile/actions";
import { getPeriodAction } from "../../store/common/actions";
import { useDispatch } from "react-redux";
import { StyledLayout } from "./Layout.styles";
import { ROUTE_PATH } from "../../constants";
import { fetchScheduleAction } from "../../store/pages/schedule/actions";

export const DefaultProtectedLayout = () => {
  const outlet = useOutlet();
  const { isAuth } = useAuth();
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const { lang } = useLang();

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchUserProfileAction());
      dispatch(getMenuAction());
      dispatch(getPeriodAction());
      // @ts-ignore
      dispatch(fetchScheduleAction());
      dispatch(fetchGetNotificationAction());
      dispatch(fetchGetDashboardAction());
    }
  }, []);

  if (!isAuth) {
    return <Navigate to={ROUTE_PATH.AUTH} replace={true} />;
  }

  return (
    <StyledLayout className={`theme-${theme}`} lang={lang}>
      <Layout style={{ maxHeight: "100vh", overflow: "hidden" }}>
        <Layout.Content style={{ overflowY: "scroll" }}>
          {outlet}
        </Layout.Content>
      </Layout>
    </StyledLayout>
  );
};
