import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  Upload,
  message,
} from "antd";
import {
  SecondLevelTabs,
  MyBreadcrumb,
  FirstLevelTabs,
} from "../../components";
import "../Speciality/Speciality.scss";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { CurriculumVM, SpecialityVM } from "../InterfacesApi/InterfacesApi";
import { HeadersAuth } from "../../constants/Auth";

export const Rup = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [qualificationsData, setQualificationsData] = useState({
    current_page: 1,
    data: [],
    last_page: 1,
    per_page: 10,
    total: 0,
  });

  const [curriculumsData, setCurriculumsData] = useState<CurriculumVM[]>([]);
  const [deletedCurriculumsData, setDeletedCurriculumsData] = useState<
    CurriculumVM[]
  >([]);
  const [eduBases, setEduBases] = useState<{ id: number; title: string }[]>([]);
  const [getGroups, setGetGroups] = useState<{ id: number; title: string }[]>(
    []
  );
  const [specialities, setSpecialities] = useState<SpecialityVM[]>([]);

  const [createForm] = Form.useForm();

  const handleCreateRup = async (values) => {
    const { title, eduBaseId, specialityId, groupIds, file } = values;

    const formData = new FormData();
    formData.append("title", title);
    formData.append("year", "2024");
    formData.append("study_group_ids", groupIds.join(","));
    formData.append("college_id", "1");

    if (file) {
      formData.append("file_url", file[0].originFileObj);
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/curriculum/v1/createCurriculum`,
        formData,
        HeadersAuth
      );
      message.success("РУП успешно создан!");

      createForm.resetFields();
      handleCancel();
    } catch (error) {
      message.error("Ошибка создания РУП!");
    }
  };

  const fetchData = async () => {
    try {
      const eduBasesResponse = await axios.get(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/curriculum/v1/showEduBase`,
        HeadersAuth
      );
      setEduBases(eduBasesResponse.data);

      const groupsResponse = await axios.get(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/curriculum/v1/indexStudyGroupForAddRup?college_id=1&speciality_id=6&edu_base_id=1`,
        HeadersAuth
      );
      setGetGroups(groupsResponse.data);

      const specialitiesResponse = await axios.get(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/specialitiesIndex`,
        HeadersAuth
      );
      setSpecialities(specialitiesResponse.data);
    } catch (error) {
      console.error("Ошибка получения данных: ", error);
    }
  };

  useEffect(() => {
    const fetchCurriculums = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/curriculum/v1/showCurriculums?college_id=1`,
          HeadersAuth
        );

        if (Array.isArray(response.data.data)) {
          setCurriculumsData(response.data.data);
        } else {
          console.error(
            "Data field in API response is not an array:",
            response.data.data
          );
        }
      } catch (error) {
        console.error("Error fetching curriculums:", error);
      }
    };

    fetchCurriculums();
  }, []);

  useEffect(() => {
    const fetchDeletedCurriculums = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/curriculum/v1/showCurriculumsDelete?college_id=1&year=2024`,
          HeadersAuth
        );
        if (Array.isArray(response.data.data)) {
          setDeletedCurriculumsData(response.data.data);
        } else {
          console.error(
            "Data field in API response is not an array:",
            response.data.data
          );
        }
      } catch (error) {
        console.error("Error fetching deleted curriculums:", error);
      }
    };

    fetchDeletedCurriculums();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleStatusDeleteChange = async (curriculumId: number) => {
    Modal.confirm({
      title: "Изменение статуса удаления",
      content: "Вы уверены, что хотите изменить статус удаления?",
      okText: "Да",
      cancelText: "Нет",
      onOk: async () => {
        try {
          await axios.post(
            `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/curriculum/v1/changeStatusDelete?curriculum_id=${curriculumId}`,
            null,
            HeadersAuth
          );

          setCurriculumsData((prevCurriculumsData) =>
            prevCurriculumsData.map((curriculum) =>
              curriculum.id === curriculumId
                ? { ...curriculum, status_delete: !curriculum.status_delete }
                : curriculum
            )
          );

          message.success("Статус удаления успешно изменен");
        } catch (error) {
          message.error("Ошибка при изменении статуса удаления");
        }
      },
      onCancel: () => {},
    });
  };

  const handleDownloadFile = async (curriculumId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/curriculum/v1/downloadFile?curriculum_id=${curriculumId}`,
        HeadersAuth
      );

      const blob = new Blob([response.data]);
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = `Curriculum_${curriculumId}.pdf`;
      downloadLink.click();
    } catch (error) {
      message.error("Ошибка скачивания файла");
    }
  };

  const dataCurriculum = curriculumsData.map((curriculum, index) => ({
    id: curriculum.id,
    title: curriculum.title,
    year: curriculum.year,
    edu_base: curriculum.edu_base,
    speciality_title: curriculum.speciality_title,
    study_group_title: curriculum.study_groups
      .map((studyGroup) => studyGroup.study_group_info.title)
      .join(", "),
    actions: (
      <>
        <DownloadOutlined
          onClick={() => handleDownloadFile(curriculum.id)}
          style={{
            display: "inline",
            fontSize: "24px",
            marginLeft: "10px",
            cursor: "pointer",
          }}
        />
      </>
    ),
    "#": index + 1 + (currentPage - 1) * qualificationsData.per_page,
  }));

  const dataDelete = deletedCurriculumsData.map((curriculum, index) => ({
    id: curriculum.id,
    title: curriculum.title,
    year: curriculum.year,
    edu_base: curriculum.edu_base,
    speciality_title: curriculum.speciality_title,
    study_group_title: curriculum.study_groups
      .map((studyGroup) => studyGroup.study_group_info.title)
      .join(", "),

    "#": index + 1 + (currentPage - 1) * qualificationsData.per_page,
  }));

  const columns = [
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>#</span>,
      dataIndex: "#",
      key: "#",
      style: { fontSize: "26px", fontWeight: "bold" },
      width: 30,
    },
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>ID</span>,
      dataIndex: "id",
      key: "id",
      style: { fontSize: "26px", fontWeight: "bold" },
      width: 35,
    },
    {
      title: (
        <span style={{ padding: "10px", color: "#773DBD" }}>Название РУПа</span>
      ),
      dataIndex: "title",
      key: "title",
      style: { fontSize: "26px" },
      sorter: true,
    },
    {
      title: (
        <span style={{ padding: "10px", color: "#773DBD" }}>Специальность</span>
      ),
      dataIndex: "speciality_title",
      key: "speciality_title",
      style: { fontSize: "26px" },
      sorter: true,
    },
    {
      title: <span style={{ padding: "10px", color: "#773DBD" }}>Группы</span>,
      dataIndex: "study_group_title",
      key: "study_group_title",
      style: { fontSize: "26px" },
    },
    {
      title: <span style={{ padding: "10px", color: "#773DBD" }}>Базы</span>,
      dataIndex: "edu_base",
      key: "edu_base",
      style: { fontSize: "26px" },
    },
    {
      title: (
        <span style={{ padding: "10px", color: "#773DBD" }}>Действие</span>
      ),
      dataIndex: "delete",
      key: "delete",
      width: 120,
      render: (_, record) => (
        <span>
          <Tooltip title="Download">
            <DownloadOutlined
              onClick={() => handleDownloadFile(record.id)}
              style={{
                display: "inline",
                fontSize: "24px",
                marginLeft: "10px",
              }}
            />
          </Tooltip>
          <Tooltip title="Редактировать">
            <EditOutlined
              onClick={showEditModal}
              className="speciality_edit"
              style={{
                display: "inline",
                fontSize: "24px",
                marginLeft: "10px",
              }}
              twoToneColor="red"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined
              onClick={() => handleStatusDeleteChange(record.id)}
              className="status_delete_icon"
              style={{
                display: "inline",
                fontSize: "24px",
                marginLeft: "10px",
              }}
              twoToneColor="red"
            />
          </Tooltip>
        </span>
      ),
    },
  ];

  const columnsDelete = [
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>#</span>,
      dataIndex: "#",
      key: "#",
      style: { fontSize: "26px", fontWeight: "bold" },
      width: 30,
    },
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>ID</span>,
      dataIndex: "id",
      key: "id",
      style: { fontSize: "26px", fontWeight: "bold" },
      width: 35,
    },
    {
      title: (
        <span style={{ padding: "10px", color: "#773DBD" }}>Название РУПа</span>
      ),
      dataIndex: "title",
      key: "title",
      style: { fontSize: "26px" },
      sorter: true,
    },
    {
      title: (
        <span style={{ padding: "10px", color: "#773DBD" }}>Специальность</span>
      ),
      dataIndex: "speciality_title",
      key: "speciality_title",
      style: { fontSize: "26px" },
      sorter: true,
    },
    {
      title: <span style={{ padding: "10px", color: "#773DBD" }}>Группы</span>,
      dataIndex: "study_group_title",
      key: "study_group_title",
      style: { fontSize: "26px" },
    },
    {
      title: <span style={{ padding: "10px", color: "#773DBD" }}>Базы</span>,
      dataIndex: "edu_base",
      key: "edu_base",
      style: { fontSize: "26px" },
    },
    {
      title: (
        <span style={{ padding: "10px", color: "#773DBD" }}>Действие</span>
      ),
      dataIndex: "delete",
      key: "delete",
      width: 10,
      render: (_, record) => (
        <Tooltip title="Download">
          <DownloadOutlined
            onClick={() => handleDownloadFile(record.id)}
            style={{ fontSize: "24px", marginLeft: "35px" }}
          />
        </Tooltip>
      ),
    },
  ];

  const redirectGroup = () => {
    navigate("/rup");
  };

  const showModal = () => {
    setIsModalOpen(true);
    fetchData();
  };

  const showEditModal = () => {
    setIsModalEditOpen(true);
    fetchData();
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsModalEditOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalEditOpen(false);
  };

  const secondLevelTabs = [
    {
      key: "1",
      label: (
        <div onClick={redirectGroup} style={{ cursor: "pointer" }}>
          Активные РУПы
        </div>
      ),
      children: (
        <>
          <Col
            span={24}
            style={{
              textAlign: "end",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "0 20px 0 0",
            }}
          >
            <Button
              onClick={showModal}
              type="primary"
              className="rup_create_button"
            >
              <span
                style={{
                  fontWeight: "400",
                  marginTop: "-20px",
                  lineHeight: "0px",
                }}
              >
                Создать РУП
              </span>
            </Button>
          </Col>
          <p />
          <Row
            style={{ height: "20", marginTop: "20px" }}
            justify="space-between"
            align="middle"
          ></Row>
          <div
            className="speciality_table"
            style={{ fontSize: "20px", padding: "20px" }}
          >
            <Table
              key={Math.random()}
              dataSource={dataCurriculum}
              bordered
              className="tableRup"
              columns={columns}
              pagination={{
                current: qualificationsData.current_page,
                pageSize: qualificationsData.per_page,
                total: qualificationsData.total,
                onChange: handlePageChange,
              }}
            />
          </div>
          <Row></Row>
          <Modal
            className="modalRup"
            title="Добавление РУПы"
            open={isModalOpen}
            width={600}
            footer={[
              <Button
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "5px",
                }}
                key="back"
                onClick={handleCancel}
              >
                Закрыть
              </Button>,
              <Button
                style={{ color: "white" }}
                className="CreateButton"
                key="submit"
                type="primary"
                onClick={createForm.submit}
              >
                Сохранить
              </Button>,
            ]}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div className="modalHeader">Добавление РУПы</div>
            <p />
            <Form
              form={createForm}
              onFinish={handleCreateRup}
              initialValues={{
                eduBaseId: "",
                specialityId: "",
                groupIds: [],
                file: null,
              }}
            >
              <Row className="ModalInputs">
                <Col span={12}>
                  Наименование РУПа
                  <p />
                  <Form.Item name="title">
                    <Input
                      style={{ width: "90%", padding: "10px", height: "45px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  База классов <p />
                  <Select
                    placeholder="Выберите из списка"
                    style={{ width: "100%", height: "45px" }}
                  >
                    {eduBases.map((eduBase) => (
                      <Select.Option key={eduBase.id} value={eduBase.id}>
                        {eduBase.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col style={{ marginTop: "8px" }} span={24}>
                  <p />
                  Специальность <p />
                  <Select
                    placeholder="Выберите из списка"
                    style={{ width: "100%", height: "45px" }}
                  >
                    {specialities.map((speciality) => (
                      <Select.Option key={speciality.id} value={speciality.id}>
                        {speciality.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col style={{ marginTop: "8px" }} span={24}>
                  <p />
                  Группы
                  <p />
                  <Form.Item name="groupIds">
                    <Select mode="multiple" placeholder="Выберите из списка">
                      {getGroups.map((group) => (
                        <Select.Option key={group.id} value={group.id}>
                          {group.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ marginTop: "8px", width: "100%" }} span={24}>
                  <p />
                  <label
                    htmlFor="fileInput"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      fontWeight: "500",
                    }}
                  >
                    Вложения
                  </label>
                  <p />
                  <Form.Item
                    name="file"
                    valuePropName="fileList"
                    getValueFromEvent={(e) =>
                      Array.isArray(e) ? e : e && e.fileList
                    }
                  >
                    <Upload>
                      <Button style={{ width: "100%", textAlign: "left" }}>
                        <span style={{ lineHeight: "0px" }}>
                          Приложите файл{" "}
                        </span>{" "}
                        <PaperClipOutlined
                          style={{ marginLeft: "390px", fontSize: "20px" }}
                        />
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Divider style={{ marginTop: "50px" }} />
            <p />
          </Modal>

          <Modal
            className="modalRup"
            title="Редактирование РУПы"
            open={isModalEditOpen}
            width={600}
            footer={[
              <Button
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "5px",
                }}
                key="back"
                onClick={handleCancel}
              >
                Закрыть
              </Button>,
              <Button onClick={handleOk} className="CreateButton">
                Сохранить
              </Button>,
            ]}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div className="modalHeader">Редактирование РУПы</div>
            <p />
            <Form>
              <Row className="ModalInputs">
                <Col span={12}>
                  Наименование РУПа
                  <p />
                  <Input
                    style={{ width: "90%", padding: "10px", height: "45px" }}
                  />
                </Col>
                <Col span={12}>
                  База классов <p />
                  <Select
                    placeholder="Выберите из списка"
                    style={{ width: "100%", height: "45px" }}
                  >
                    {eduBases.map((eduBase) => (
                      <Select.Option key={eduBase.id} value={eduBase.id}>
                        {eduBase.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col style={{ marginTop: "8px" }} span={24}>
                  <p />
                  Специальность <p />
                  <Select
                    placeholder="Выберите из списка"
                    style={{ width: "100%", height: "45px" }}
                  >
                    {specialities.map((speciality) => (
                      <Select.Option key={speciality.id} value={speciality.id}>
                        {speciality.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>{" "}
                <Col style={{ marginTop: "8px" }} span={24}>
                  <p />
                  Группы <p />
                  <Select
                    placeholder="Выберите из списка"
                    style={{ width: "100%", height: "45px" }}
                  >
                    {getGroups.map((groups) => (
                      <Select.Option key={groups.id} value={groups.id}>
                        {groups.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>{" "}
                <Col style={{ marginTop: "8px", width: "100%" }} span={24}>
                  <p />
                  <label
                    htmlFor="fileInput"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      fontWeight: "500",
                    }}
                  >
                    Вложения
                  </label>
                  <p />
                  <Upload>
                    <Button style={{ width: "100%", textAlign: "left" }}>
                      <span style={{ lineHeight: "0px" }}>Приложите файл </span>{" "}
                      <PaperClipOutlined
                        style={{ marginLeft: "390px", fontSize: "20px" }}
                      />
                    </Button>
                  </Upload>
                </Col>
              </Row>
            </Form>
            <Divider style={{ marginTop: "50px" }} />
            <p />
          </Modal>
        </>
      ),
    },
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      key: "2",
      label: (
        <div onClick={redirectGroup} style={{ cursor: "pointer" }}>
          Удаленные РУПы
        </div>
      ),
      children: (
        <>
          <Row
            style={{ height: "20", marginTop: "20px" }}
            justify="space-between"
            align="middle"
          >
            <Col
              span={12}
              style={{
                textAlign: "end",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "0 20px 0 0",
              }}
            ></Col>
          </Row>
          <div
            className="speciality_table"
            style={{ fontSize: "20px", padding: "20px" }}
          >
            <Table
              dataSource={dataDelete}
              bordered
              columns={columnsDelete}
              pagination={{
                current: qualificationsData.current_page,
                pageSize: qualificationsData.per_page,
                total: qualificationsData.total,
                onChange: handlePageChange,
              }}
            />
          </div>
          <Row>
            <Col
              span={12}
              style={{ paddingBottom: "30px", marginTop: "-55px" }}
            >
              <span
                style={{
                  marginLeft: "28px",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              ></span>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const firstLevelTabs = [
    {
      key: "1",
      label: "Рабочие учебные планы",
      children: (
        <>
          <SecondLevelTabs defaultActiveKey="1" items={secondLevelTabs} />
        </>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Планирование учебного процесса" />
      <Row justify="center">
        <Col span={22}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="1" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>
      <p />
    </>
  );
};
