import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Select,
  Flex,
  Input,
  DatePicker,
  Checkbox,
  Modal,
} from "antd";
import "../../Speciality/Speciality.scss";
import { MyBreadcrumb, MyInput } from "../../../components";
import "./ScheduleCreate.scss";
import { HeadersAuth } from "../../../constants/Auth";
import {
  ClassroomResponse,
  ClassroomVM,
  Disciplines,
  Group,
  Semester,
  Shift,
  Teacher,
} from "../ScheduleInterface";
interface ShiftTime {
  id: number;
  education_shift_id: number;
  start_time: string;
  end_time: string;
}

export const ScheduleCreate = () => {
  const [isTableShown, setIsTableShown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disciplines, setDisciplines] = useState<Disciplines[]>([]);
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [semesters, setSemesters] = useState<Semester[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [tableData, setTableData] = useState([]);

  const [classrooms, setClassrooms] = useState<ClassroomResponse>({
    current_page: 0,
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 0,
    prev_page_url: null,
    to: 0,
    total: 0,
  });
  const [academicYears, setAcademicYears] = useState<string[]>([]);

  const spanStyle: React.CSSProperties = {
    color: "rgba(107, 108, 126, 1)",
    fontSize: 14,
    fontWeight: 500,
  };

  const fetchAcademicYears = async () => {
    try {
      const years = ["2022", "2023", "2024"];
      setAcademicYears(years);
    } catch (error) {
      console.error("Error fetching academic years:", error);
    }
  };

  const fetchData = async () => {
    try {
      const disciplinesResponse = await fetch(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/indexDisciplineHandbook?college_id=1`,
        HeadersAuth
      );
      const disciplinesData = await disciplinesResponse.json();
      setDisciplines(disciplinesData);

      const teachersResponse = await fetch(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getTeacherWithRolesHandbook?college_id=1`,
        HeadersAuth
      );
      const teachersData = await teachersResponse.json();
      setTeachers(teachersData);

      const groupResponse = await fetch(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/v1/indexStudyGroupHandbook?college_id=1`,
        HeadersAuth
      );
      const groupData = await groupResponse.json();
      setGroups(groupData);

      const semestersResponse = await fetch(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/schedule/v1/showAcademicSemester`,
        HeadersAuth
      );
      const semestersData = await semestersResponse.json();
      setSemesters(semestersData);

      const shiftsResponse = await fetch(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/schedule/v1/getByCollegeEducationShifts?college_id=1`,
        HeadersAuth
      );
      const shiftsData = await shiftsResponse.json();
      setShifts(shiftsData);

      const classroomResponse = await fetch(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/auditfund/v1/getClassrooms?college_id=1`,
        HeadersAuth
      );
      const classroomData = await classroomResponse.json();
      setClassrooms(classroomData);
    } catch (error) {
      console.error("Ошибка получения данных");
    }
  };
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  useEffect(() => {
    fetchData();
    fetchAcademicYears();
  }, []);

  const handleShiftChange = async (id: any) => {
    try {
      const shiftsTimeResponse = await fetch(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/schedule/v1/showEducationShiftsTime?id=${id}`,
        HeadersAuth
      );
      const shiftsDataResp = await shiftsTimeResponse.json();
      console.log("Shifts data response:", shiftsDataResp);

      const newData = shiftsDataResp.shift_times.map((shift) => ({
        key: shift.id,
        time: `${shift.start_time} - ${shift.end_time}`,

        mondayShiftTime: shift,
        tuesdayShiftTime: shift,
        wednesdayShiftTime: shift,
        thursdayShiftTime: shift,
        fridayShiftTime: shift,
        saturdayShiftTime: shift,
        sundayShiftTime: shift,
      }));
      setTableData(newData);
      console.log("New table data:", newData);
    } catch (error) {
      console.error("Ошибка при загрузке времени смены:", error);
    }
  };

  const tableCell = (shiftTime: ShiftTime) => {
    const classroomData = Array.isArray(classrooms.data) ? classrooms.data : [];

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Checkbox onChange={() => setIsCheckboxChecked(!isCheckboxChecked)} />
        <Select
          onChange={() => {}}
          placeholder="Кабинет"
          style={{ height: 24, fontSize: 12, marginTop: 7, minWidth: "80%" }}
          disabled={!isCheckboxChecked}
        >
          {classroomData.map((classroom) => (
            <Select.Option key={classroom.id} value={classroom.id.toString()}>
              {classroom.number}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };

  const flattenGroups = groups.flatMap((innerArray) => innerArray);
  const inputColums = [
    {
      title: "Смена",
      onchange: (value: any) => handleShiftChange(value),
      isSelect: true,
      options: shifts.map((shift) => ({
        value: shift.id.toString(),
        label: shift.title,
      })),
    },

    {
      title: "Учебный год",
      onchange: (e: any) => {},
      isSelect: true,
      options: academicYears.map((year) => ({
        value: year,
        label: year,
      })),
    },
    {
      title: "Семестр",
      onchange: (e: any) => {},
      isSelect: true,
      options: semesters.map((semester) => ({
        value: semester.id.toString(),
        label: semester.title,
      })),
    },
    {
      title: "Преподаватель ",
      onchange: (e: any) => {},
      isSelect: true,
      options: teachers.map((teacher) => ({
        value: teacher.id.toString(),
        label: teacher.name,
      })),
    },
    {
      title: "Группа",
      onchange: (e: any) => {},
      isSelect: true,
      options: flattenGroups.map((group) => ({
        value: group?.id?.toString() || "",
        label: group?.title || "",
      })),
    },
    {
      title: "Предмет",
      onchange: (e: any) => {},
      isSelect: true,
      options: disciplines.map((discipline) => ({
        value: discipline.id.toString(),
        label: discipline.caption,
      })),
    },
  ];

  const columns = [
    {
      title: "Время",
      dataIndex: "time",
      render: (text: string) => (
        <div style={{ fontWeight: "bold" }}>{text}</div>
      ),
    },
    {
      title: "Понедельник",
      render: (record: any) => tableCell(record.mondayShiftTime),
      align: "center" as const,
    },
    {
      title: "Вторник",
      render: (record: any) => tableCell(record.tuesdayShiftTime),
      align: "center" as const,
    },
    {
      render: (record: any) => tableCell(record.wednesdayShiftTime),
      align: "center" as const,
      title: "Среда",
    },
    {
      render: (record: any) => tableCell(record.thursdayShiftTime),
      align: "center" as const,
      title: "Четверг",
    },
    {
      render: (record: any) => tableCell(record.fridayShiftTime),
      align: "center" as const,
      title: "Пятница",
    },
    {
      render: (record: any) => tableCell(record.saturdayShiftTime),
      align: "center" as const,
      title: "Суббота",
    },
    {
      render: (record: any) => tableCell(record.sundayShiftTime),
      align: "center" as const,
      title: "Воскресенье",
    },
  ];

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <MyBreadcrumb title="Составление расписания" isBack={true} />
      <Row justify="center">
        <Col
          span={20}
          style={{ backgroundColor: "white", padding: "32px 22px 22px 22px" }}
        >
          <Row gutter={[22, 22]}>
            {inputColums.map((item, index) => (
              <Col span={8} key={item.title}>
                <span style={spanStyle}>{item.title}</span>
                {item.isSelect ? (
                  <Select
                    onChange={item.onchange}
                    placeholder="Обязательное поле"
                    options={item.options}
                    style={{}}
                    className="schedule_create_input"
                  />
                ) : (
                  <Input
                    className="schedule_create_input"
                    placeholder="Обязательное поле"
                    onChange={item.onchange}
                    required
                  />
                )}
              </Col>
            ))}
          </Row>
          {isTableShown && (
            <>
              <Row
                gutter={[22, 22]}
                style={{ marginTop: 22, marginBottom: 22 }}
              >
                <Col span={8}>
                  <span style={spanStyle}>Начало дисциплины</span>
                  <DatePicker
                    className="schedule_create_input"
                    placeholder="Обязательное поле"
                  />
                </Col>
                <Col span={8}>
                  <span style={spanStyle}>Конец дисциплины</span>
                  <DatePicker
                    className="schedule_create_input"
                    placeholder="Обязательное поле"
                  />
                </Col>
              </Row>
              <div className="schedule_module">
                <Table
                  dataSource={tableData}
                  columns={columns}
                  bordered
                  pagination={false}
                />
              </div>
            </>
          )}
          <Row justify="center">
            <Col>
              <Button
                onClick={() => {
                  if (isTableShown) {
                    return setIsModalOpen(true);
                  }
                  setIsTableShown(true);
                }}
                className="schedule_create_button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 50,
                }}
              >
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                  }}
                >
                  Добавить
                </span>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        open={isModalOpen}
        style={{ paddingTop: 0 }}
        footer={[
          <Button
            onClick={handleOk}
            className="CreateButton"
            style={{ display: "block", margin: "0 auto", alignSelf: "center" }}
          >
            Продолжить
          </Button>,
        ]}
        centered
      >
        <div
          style={{
            textAlign: "center",
            fontSize: 24,
            fontWeight: 700,
          }}
        >
          Уведомление
        </div>
        <p
          style={{
            textAlign: "center",
            fontSize: 18,
            marginTop: 12,
          }}
        >
          Расписание успешно добавлено!
        </p>
      </Modal>
    </>
  );
};
