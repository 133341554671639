export const FORM_SECTION_TYPES = {
  PLANS: 'plans',
  JOURNAL: 'journal',
  PROFILE: 'profile',
  SCHEDULE: 'schedule',

}

export const FORM_TYPES = {
  ADD: 'add',
  VISIT: 'visit',
  UPDATE: 'update',
  DELETE: 'delete',
  IMPORT: 'import',
  COPY: 'copy',
}


export const PLANS_FORM_TYPES = {
  PLAN: 'plan',
  MATERIALS: 'materials',
  TASKS: 'tasks',
  TESTS: 'tests',
  LESSON: 'lesson',
  HOME_WORK: 'home_work'
}

export const JOURNAL_FORM_TYPES = {
  LESSON: 'lesson',
  INDIVIDUAL: 'individual',
  BALL: 'ball',
  THEME: 'theme',

  INDIVIDUAL_LESSON: 'individualLesson',
  LESSON_THEME: 'lessonTheme',
  BALL_VISIT: 'ballVisit',
  INCENTIVE_POINT: 'incentivePoint',
  PASS: 'pass',
  EXAM: 'exam',
  LESSON_PASS_EXAM: 'lessonPassExam',
}

export const PROFILE_FORM_TYPES = {

}

export const SCHEDULE_FORM_TYPES = {

}

export const PLANS_API = {
  [`${FORM_TYPES.ADD}_${PLANS_FORM_TYPES.PLAN}`]: 'plan_add_lesson_v1',
  [`${FORM_TYPES.UPDATE}_${PLANS_FORM_TYPES.PLAN}`]: 'plan_update_lesson_v1',

  [`${FORM_TYPES.ADD}_${PLANS_FORM_TYPES.MATERIALS}`]: 'plan_add_material_v1',
  [`${FORM_TYPES.UPDATE}_${PLANS_FORM_TYPES.MATERIALS}`]: 'plan_update_material_v1',

  [`${FORM_TYPES.ADD}_${PLANS_FORM_TYPES.TASKS}`]: 'plan_add_task_v1',
  [`${FORM_TYPES.UPDATE}_${PLANS_FORM_TYPES.TASKS}`]: 'plan_update_task_v1',

  [`${FORM_TYPES.ADD}_${PLANS_FORM_TYPES.TESTS}`]: 'plan_add_test_v1',
  [`${FORM_TYPES.UPDATE}_${PLANS_FORM_TYPES.TESTS}`]: 'plan_update_test_v1',

  [`${FORM_TYPES.IMPORT}_${PLANS_FORM_TYPES.LESSON}`]: 'plan_import_lesson_v1',
  [`${FORM_TYPES.COPY}_${PLANS_FORM_TYPES.LESSON}`]: 'plan_copy_lesson_v1',
}

export const JOURNAL_API = {
  [`${FORM_TYPES.ADD}_${JOURNAL_FORM_TYPES.LESSON}`]: 'grade_add_lesson_v1',

  [`${FORM_TYPES.ADD}_${JOURNAL_FORM_TYPES.INDIVIDUAL_LESSON}`]: 'grade_add_individualLesson_v1',
}

export const FILE_FORM_API = {
  FILE: 'https://storage.itulek.kz/upload',
  EXCEL: `${process.env.REACT_APP_URL}/edu/import-lesson`
}
