import React, {useEffect} from "react";
import {FloatButton, Layout} from "antd";
import {Navigate, useOutlet} from "react-router-dom";
import {Header} from "../Header/Header";
import {useAuth, useLang, useTheme} from "../../hooks";
import {StyledLayout} from "./Layout.styles";
import {ROUTE_PATH} from "../../constants";
import {useDispatch} from "react-redux";
import {
  fetchGetDashboardAction,
  fetchGetNotificationAction,
  fetchUserProfileAction,
  getMenuAction
} from "../../store/pages/profile/actions";
import {Cookies} from "react-cookie";
import {getPeriodAction} from "../../store/common/actions";
import {fetchScheduleAction} from "../../store/pages/schedule/actions";
import {Footer} from "../Footer/Footer";

const cookies = new Cookies()
export const ProtectedLayout = (): React.ReactElement => {
  const outlet = useOutlet();
  const dispatch = useDispatch();
  const {isAuth, menu} = useAuth()
  const {theme} = useTheme()
  const {lang} = useLang()

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchUserProfileAction())
      dispatch(getMenuAction())
      dispatch(getPeriodAction())
      // @ts-ignore
      dispatch(fetchScheduleAction())
      dispatch(fetchGetNotificationAction())
      dispatch(fetchGetDashboardAction())
    }
  }, [])

  if (!isAuth) {
    return <Navigate to={ROUTE_PATH.AUTH} replace={true}/>
  }

  return (
    <StyledLayout className={`theme-${theme}`} lang={lang}>
      <Layout style={{maxHeight: "100vh", overflow: 'hidden'}}>
        <Header/>
        <Layout.Content style={{overflowY: 'scroll'}}>
          {outlet}
        </Layout.Content>
        <Footer/>
      </Layout>
    </StyledLayout>
  )
}
