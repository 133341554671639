import {axiosConfig as axios} from "../../../utils/axios";

let url = 'rest'
export const apiOneLesson = (data: any) => axios.get(`${url}/edu/ctp/${data}`);

export const apiLesson = (data: any) => axios.get(`${url}/edu/lesson`, {
  params: {
    ctp_id: data.ctp_id,
    start_date: data.start_date,
    end_date: data.end_date
  },
});

export const apiGetOneLesson = (body: any) => axios.get(`${url}/edu/lesson/${body.lesson_id}`);

export const apiDeleteOneLesson = (body: any) => axios.delete(`${url}/edu/lesson/${body.lesson_id}`);

export const apiPostPlanTest = (body: any) => axios.post(`${url}/sdot/question-answer`, body);

export const apiGetPlanTest = (id: any) => axios.get(`${url}/sdot/test/${id}`)
