import styled from 'styled-components';
import {setThemeColor} from "../../../utils/setThemeColor";
import {DefaultTokenTheme} from "../../../assets/themes/defaultTheme";

export const StyledInfoContainer = styled.div`
  box-shadow: 0px 2px 8px 0px ${({theme}) => setThemeColor(theme.current, 'rgba(0, 0, 0, 0.15)', 'rgba(255, 255, 255, 0.15)')};
  border-radius: 4px;
  background: ${({theme}) => setThemeColor(theme.current, '#fff', DefaultTokenTheme.plateDark)};
  margin-bottom: 20px;

  .ant-collapse {
    background-color: transparent;
    overflow: hidden;
    border-color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};

    .ant-collapse-item {
      border-color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};

      &.ant-collapse-item-active {
        .ant-collapse-header {
          background-color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.secondary, DefaultTokenTheme.pressed)};

          .ant-collapse-header-text {
            color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.primary, '#fff')}
          }

          .ant-collapse-expand-icon {
            svg {
              fill: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.primary,'#fff')};
            }
          }
        }
      }

      .ant-collapse-header {

        .ant-collapse-header-text {
          color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.textTitle, DefaultTokenTheme.disabled)}
        }

        .ant-collapse-expand-icon {
          svg {
            fill: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.textTitle, DefaultTokenTheme.disabled)};
          }
        }
      }

      .ant-collapse-content {
        background-color: ${({theme}) => setThemeColor(theme.current, '#fff', DefaultTokenTheme.plateDark)};
        border-color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};
        color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.textTitle, '#fff')}

      }

    }

  }
`
