import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Row,
  Input,
  Cascader,
  Form,
  message,
  Switch,
} from "antd";
import { MyBreadcrumb, FirstLevelTabs } from "../../../components";
import { useTheme } from "../../../hooks";
import "../../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import {
  BuildingDictionaryVM,
  ClassroomTypesDictionaryVM,
} from "../ClassroomInterface";
import { HeadersAuth } from "../../../constants/Auth";

export const ClassroomEdit = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const { id, title, area, number, type_title, type_id, building_id } =
    location.state;
  const { theme } = useTheme();
  const [buildings, setBuildings] = useState<BuildingDictionaryVM[]>([]);
  const [classroomTypes, setClassroomTypes] = useState<
    ClassroomTypesDictionaryVM[]
  >([]);
  const [inputStates, setInputStates] = useState({
    number: false,
    area: false,
    building_id: false,
    title: false,
    type: false,
    type_id: false,
    status: false,
  });

  const [formData, setFormData] = useState({
    number: number || 0,
    area: area || 0,
    building_id: building_id || 0,
    title: title || "",
    type: type_title || "",
    type_id: type_id || 0,
    status: false,
  });

  useEffect(() => {
    const { title, area, number, type_title, type_id, building_id } =
      location.state;

    setFormData({
      number: number || 0,
      area: area || 0,
      building_id: building_id || 0,
      title: title || "",
      type: type_title || "",
      type_id: type_id || 0,
      status: false,
    });
    const fetchBuildings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/auditfund/v1/getBuildingsSelect?college_id=1`,
          HeadersAuth
        );

        setBuildings(response.data);
      } catch (error) {
        console.error("Ошибка получения данных:", error);
      }
    };

    const fetchTypesClassroom = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/auditfund/v1/getClassroomsTypes`,
          HeadersAuth
        );

        setClassroomTypes(response.data);
      } catch (error) {
        console.error("Ошибка получения данных:", error);
      }
    };

    fetchBuildings();
    fetchTypesClassroom();
  }, []);

  const updateClassroom = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/auditfund/v1/updateClassroom?id=${id}`,
        {
          number: formData.number,
          area: formData.area,
          building_id: formData.building_id,
          title: formData.title,
          type_id: formData.type_id,
          status: formData.status,
        },
        HeadersAuth
      );
      message.success("Данные успешно обновлены");
    } catch (error) {
      message.error("Ошибка обновления данных");
    }
  };

  const handleInputChange = (inputName) => (e) => {
    const inputValue = e.target ? e.target.value : e;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: inputValue,
    }));

    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [inputName]: inputValue !== "",
    }));
  };

  const handleSwitchChange = (checked) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: checked,
    }));
  };

  const firstLevelTabs = [
    {
      key: "1",
      label: <div style={{ cursor: "pointer" }}>Аудитории</div>,
      children: (
        <Content
          style={{
            width: "100%",
            height: "430px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />

          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form form={form}>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Номер
                <p />
                <Form.Item
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Обязательное поле",
                    },
                  ]}
                  required
                  validateStatus={inputStates.number ? "success" : ""}
                >
                  <Input
                    value={formData.number}
                    required
                    style={{
                      width: "90%",
                      borderColor: inputStates.number ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("number")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Площадь (м.кв)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.area ? "success" : ""}
                >
                  <Input
                    value={formData.area}
                    style={{
                      width: "90%",
                      borderColor: inputStates.area ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("area")}
                  />
                </Form.Item>
              </Col>

              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Корпус
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.building_id ? "success" : ""}
                >
                  <Cascader
                    style={{
                      width: "90%",
                      ...(inputStates.building_id
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    value={formData.building_id}
                    options={buildings.map((building) => ({
                      value: building.id,
                      label: building.title,
                    }))}
                    onChange={handleInputChange("building_id")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Название
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.title ? "success" : ""}
                >
                  <Input
                    value={formData.title}
                    style={{
                      width: "90%",
                      borderColor: inputStates.title ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("title")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Тип
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.type_id ? "success" : ""}
                >
                  <Cascader
                    style={{
                      width: "90%",
                      ...(inputStates.type_id
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    value={formData.type_id}
                    options={classroomTypes.map((types) => ({
                      value: types.id,
                      label: types.title,
                    }))}
                    onChange={handleInputChange("type_id")}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <p />
                <Switch
                  checked={formData.status}
                  onChange={handleSwitchChange}
                  style={{ marginRight: "10px" }}
                />{" "}
                Статус не использования
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "80px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/classroom")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Отмена
              </Button>
              <Button onClick={updateClassroom} className="CreateButton">
                Сохранить
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title={`Редактирование аудитории: Аудитория № ${id}`} />
      <Row justify="center">
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="1" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>
      <p />
    </>
  );
};
