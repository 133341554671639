import styled from "styled-components";
import {Card} from "antd";

export const PlanItemStyles = styled(Card)`
  .ant-card-head {
    padding: 6px 12px;
    min-height: auto;
  }

  .ant-card-body {
    padding: 8px 12px;
  }
`