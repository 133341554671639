
import React from "react";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {MyPieChartProps} from "./MyPieChart.props";

export const MyPieChart = ({progress = 0}: MyPieChartProps) => {
  const getProgressColor = () => {
    if (progress >= 0 && progress <= 33) {
      return {
        main: '#FA6400',
        sub: '#FFECCE'
      }
    } else if (progress >= 33 && progress <= 66) {
      return {
        main: '#109CF1',
        sub: '#D1E2FE'
      }
    } else {
      return {
        main: '#4EAF51',
        sub: '#E6F3E5'
      }
    }
  }

  const pieConfig = () => {
    return {
      pathColor: getProgressColor().main,
      trailColor: getProgressColor().sub,
      textColor: getProgressColor().main,
    }
  }

  return (
    <CircularProgressbar value={progress ? progress : 0} text={`${progress ? progress : 0}%`}
                         styles={buildStyles(pieConfig())}/>
  )
}
