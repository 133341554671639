import styled from "styled-components";
import {Layout} from "antd";
import {HeaderProps} from "./Header.props";
import {DefaultTokenTheme} from "../../assets/themes/defaultTheme";
import {setThemeColor} from "../../utils/setThemeColor";

export const StyledHeader = styled(Layout.Header)<HeaderProps>`
  padding-inline: 0;
  border-bottom: 1px solid;
  border-color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.borderDark)};
  background: ${({theme}) => setThemeColor(theme.current, "#fff", DefaultTokenTheme.headerDark)};
`
