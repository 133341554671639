import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Row,
  Input,
  Form,
  message,
  Typography,
  TabsProps,
  Tabs,
} from "antd";
import { MyBreadcrumb, FirstLevelTabs } from "../../../components";
import "../../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { Cookies } from "react-cookie";
import {
  SpecialityVM,
  QualificationVM,
} from "../../InterfacesApi/InterfacesApi";
import { HeadersAuth } from "../../../constants/Auth";

export const StudentCreate = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [specialities, setSpecialities] = useState<SpecialityVM[]>([]);
  const [qualifications, setQualifications] = useState<QualificationVM[]>([]);
  const [selectedSpecialityId, setSelectedSpecialityId] = useState<
    number | null
  >(null);
  const [selectedQualificationId, setSelectedQualificationId] = useState<
    number | null
  >(null);
  const { Title } = Typography;

  const [inputStates, setInputStates] = useState({
    nameRu: false,
    nameKz: false,
    typeDiscipline: false,
    teacher: false,
    faculty: false,
  });
  const handleInputChange = (inputName) => (value) => {
    if (inputName === "educationProfile" || inputName === "eduDirection") {
      const selectedSpeciality = specialities.find(
        (spec) => spec.title === value
      );
      setInputStates((prevInputStates) => ({
        ...prevInputStates,
        [inputName]: !!value,
      }));
      setSelectedSpecialityId(
        selectedSpeciality ? selectedSpeciality.id : null
      );
      const selectedQualification = qualifications.find(
        (qual) => qual.title === value
      );
      setSelectedQualificationId(
        selectedQualification ? selectedQualification.id : null
      );
    }
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/specialitiesIndex`,
        HeadersAuth
      )
      .then((response) => {
        setSpecialities(response.data);
      })
      .catch((error) => {
        console.error("Ошибка получения специальностей:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedSpecialityId) {
      axios
        .get(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/qualificationsIndexAdd?speciality_id=${selectedSpecialityId}`,
          HeadersAuth
        )
        .then((response) => {
          setQualifications(response.data);
        })
        .catch((error) => {
          console.error("Ошибка получения квалификации:", error);
        });
    }
  }, [selectedSpecialityId]);

  const handleAddQualification = () => {
    if (selectedQualificationId) {
      const payload = {
        college_id: 1,
        qualification_id: selectedQualificationId,
      };
      axios
        .post(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/college-qualifications?college_id=1&qualification_id=${payload.qualification_id}`,
          payload,
          HeadersAuth
        )
        .then((response) => {
          message.success("Квалификация успешно добавлена");
          navigate("/speciality");
        })
        .catch((error) => {
          message.error("Ошибка при добавлении квалификации");
        });
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Социальные группы",
      children: (
        <Content
          style={{
            width: "100%",
            height: "900px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "10px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Несовершеннолетние дети
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameRu ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameRu ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameRu")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дети-сиропы и без попечения родителей
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Кандасы <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Опекаемые
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дети-инвалиды <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дети из многодетных семей
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дети из семей, имеющие право на получение адресн...
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дети из неполных семей
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Неблагополучная семья, стоящая на учете в ОВД
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col
                span={8}
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  whiteSpace: "normal",
                }}
              >
                Учащиеся, не соблюдающие внешний вид <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Учащиеся, состоящие на учёте в ОВД
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Учащиеся, состоящие на учете колледжа
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Семья, где родители инвалиды
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Семья, где родители инвалиды
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дети, обучающиеся на дому
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дети с ООП
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Проживающие с отчимами
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Проживающие с мачехами
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Склонные к суициду
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Склонные к правонарушению
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Неформальные лидеры (отрицательные)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Неформальные лидеры (положительные)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Добавить</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "2",
      label: "Общежитие",
      children: (
        <Content
          style={{
            width: "100%",
            height: "230px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "10px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Нуждается в общежитии
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameRu ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameRu ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameRu")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Статус
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Комментарии <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Добавить</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "3",
      label: "Питание",
      children: (
        <Content
          style={{
            width: "100%",
            height: "230px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "10px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Нуждается в льготном питании
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameRu ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameRu ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameRu")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Статус
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Комментарии <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Добавить</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
  ];

  const firstLevelTabs = [
    {
      key: "1",
      label: "Личные данные",
      children: (
        <Content
          style={{
            width: "100%",
            height: "720px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Имя
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameRu ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameRu ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameRu")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Фамилия
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Отчество (при наличии)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                ИИН
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дата рождения
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Место рождения
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Гражданство
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Пол
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Национальность
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Язык обучения
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Специальность
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Квалификация
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Курс
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Группа
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Основа обучения
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Срок обучения
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Роль
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Добавить</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    ///////////////////////
    {
      key: "2",
      label: "Контакты",
      children: (
        <Content
          style={{
            width: "100%",
            height: "340px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Домашний телефон
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameRu ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameRu ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameRu")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Мобильный телефон
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Адрес прописки
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Домашний адрес
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                E-mail
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Добавить</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "3",
      label: "Документы",
      children: (
        <Content
          style={{
            width: "100%",
            height: "790px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}>
                <Title level={4}>Свидетельство о рождении</Title>
                <p />
              </Col>

              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Номер свидетельство о рождении
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameRu ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameRu ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameRu")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дата выдачи
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Серия свидетельство о рождении
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>

              <Col span={24} style={{ marginTop: "20px" }}>
                <Title level={4}>Удостоверение личности</Title>
                <p />
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Номер удостоверения личности
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дата выдачи
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Действителен до
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Кем выдано
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>

              <Col span={24} style={{ marginTop: "20px" }}>
                <Title level={4}>Паспорт</Title>
                <p />
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Номер паспорта
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дата выдачи
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Действителен до
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Кем выдано
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Серия
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Добавить</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "4",
      label: "Авторизационные записи",
      children: (
        <Content
          style={{
            width: "100%",
            height: "240px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Логин
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameRu ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameRu ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameRu")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Пароль
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Добавить</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "5",
      label: "Родственники",
      children: (
        <Content
          style={{
            width: "100%",
            height: "590px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}>
                <Title level={4}>Родственники</Title>
                <p />
              </Col>

              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Фамилия
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameRu ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameRu ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameRu")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Имя
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Отчество
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>

              <Col span={24} style={{ marginTop: "20px" }}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Кем приходится
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дата рождения
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                ИИН
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Домашний телефон
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Сотовый телефон
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                E-mail
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Место работы
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Должность
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Добавить</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "6",
      label: "Приказы",
      children: (
        <Content
          style={{
            width: "100%",
            height: "340px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Номер
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameRu ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameRu ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameRu")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Тип
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дата приказа
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Вложения
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Добавить</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "7",
      label: "Данные об обучении",
      children: (
        <Content
          style={{
            width: "100%",
            height: "240px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Группа
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameRu ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameRu ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameRu")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дата зачисления
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дата отчисления
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Добавить</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "8",
      label: "Социальные группы",
      children: (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Tabs
              tabBarStyle={{ padding: "0 20px 0 20px" }}
              tabPosition="top"
              size="large"
              defaultActiveKey="1"
              items={items}
            />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Создание профиля обучающегося" />
      <Row justify="center">
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="1" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>

      <p />
    </>
  );
};
