import styled from "styled-components";
import {Checkbox} from "antd";

export const MyCheckboxStyles = styled(Checkbox)`
  &.size-s{
    font-size: 16px;
    align-items: center;
    
    .ant-checkbox{
      top: 0;
      &-inner{
        width: 16px;
        height: 16px;
        
        &:after{
          width: 6px;
          height: 10px;
        }
      }
    }
  }
  
  &.size-m{
    font-size: 18px;
    align-items: center;
    
    .ant-checkbox{
      top: 0;
      &-inner{
        width: 24px;
        height: 24px;
        
        &:after{
          width: 8px;
          height: 14px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    &.size-s{
      font-size: 14px;
      
      .ant-checkbox{
        &-inner{
          width: 14px;
          height: 14px;

          &:after{
            width: 4px;
            height: 8px;
          }
        }
      }
    }

    &.size-m{
      font-size: 14px;

      .ant-checkbox{
        &-inner{
          width: 24px;
          height: 24px;

          &:after{
            width: 8px;
            height: 14px;
          }
        }
      }
    }
  }
`