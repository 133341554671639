import { useNavigate } from "react-router-dom";
import { Button, Col, Row, Table, Tooltip, message } from "antd";
import "../../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { SecondLevelTabs } from "../../../components";
import { useEffect, useState } from "react";
import { ScheduleSemesterVM, ScheduleShiftVM } from "../ScheduleInterface";
import { HeadersAuth } from "../../../constants/Auth";
import axios from "axios";

export const ScheduleSettings = () => {
  const navigate = useNavigate();
  const [scheduleSemester, setScheduleSemester] = useState<
    ScheduleSemesterVM[]
  >([]);
  const [scheduleShift, setScheduleShift] = useState<ScheduleShiftVM[]>([]);

  useEffect(() => {
    async function fetchScheduleData() {
      const [academicSemesterResponse, shiftsResponse] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/schedule/v1/showAcademicSemester`,
          HeadersAuth
        ),
        axios.get(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/schedule/v1/getByCollegeEducationShifts?college_id=1`,
          HeadersAuth
        ),
      ]);

      if (academicSemesterResponse.status === 200) {
        setScheduleSemester(academicSemesterResponse.data);
        setScheduleShift(shiftsResponse.data);
      }
    }

    fetchScheduleData();
  }, []);

  const deleteItemById = async (id, type) => {
    try {
      let url = "";
      if (type === "shift") {
        url = `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/schedule/v1/deleteEducationShifts?id=${id}`;
      } else if (type === "semester") {
        url = `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/schedule/v1/deleteAcademicSemester?id=${id}`;
      }
      await axios.delete(url, HeadersAuth);
      if (type === "shift") {
        setScheduleShift(scheduleShift.filter((shift) => shift.id !== id));
        message.success("Смена успешно удалена!");
      } else if (type === "semester") {
        setScheduleSemester(
          scheduleSemester.filter((semester) => semester.id !== id)
        );
        message.success("Семестр успешно удален!");
      }
    } catch (error) {
      console.error("Ошибка удаления");
    }
  };

  const redirectToEdit = (id, title, type) => {
    if (type === "shift") {
      navigate(`/schedule/shift/item/${id}`, {
        state: {
          id,
          title,
        },
      });
    } else if (type === "semester") {
      navigate(`/schedule/semester/item/${id}`, {
        state: {
          id,
          title,
        },
      });
    }
  };

  const columnsSettings = (type) => [
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>№</span>,
      dataIndex: "id",
      key: "id",
      style: { fontSize: "26px", fontWeight: "bold" },
      width: 30,
    },
    {
      title: (
        <span style={{ padding: "10px", color: "#773DBD" }}>Название</span>
      ),
      dataIndex: "title",
      key: "title",
      style: { fontSize: "26px" },
    },
    {
      title: (
        <span style={{ padding: "10px", color: "#773DBD" }}>Действие</span>
      ),
      dataIndex: "delete",
      key: "delete",
      width: 100,
      render: (_, record) => (
        <span>
          <Tooltip title="Редактировать">
            <EditOutlined
              className="speciality_edit"
              style={{
                display: "inline",
                fontSize: "24px",
                marginLeft: "20px",
              }}
              twoToneColor="red"
              onClick={() => redirectToEdit(record.id, record.title, type)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined
              className="status_delete_icon"
              style={{
                display: "inline",
                fontSize: "24px",
                marginLeft: "10px",
              }}
              twoToneColor="red"
              onClick={() => deleteItemById(record.id, type)}
            />
          </Tooltip>
        </span>
      ),
    },
  ];

  const secondLevelSettings = [
    {
      key: "1",
      label: <div style={{ cursor: "pointer" }}>Смены</div>,
      children: (
        <Content
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            >
              <span style={{ fontWeight: "500", fontSize: "24px" }}>Смены</span>
            </Col>
            <Col
              span={12}
              style={{
                textAlign: "end",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "0 20px 0 0",
              }}
            >
              <Button
                onClick={() => navigate("/schedule/shift/create")}
                className="speciality_create_button"
              >
                <PlusOutlined
                  style={{ display: "inline-block", fontSize: "16px" }}
                />
                <span
                  style={{
                    fontWeight: "400",
                    marginTop: "-20px",
                    lineHeight: "0px",
                  }}
                >
                  Добавить
                </span>
              </Button>
            </Col>
          </Row>
          <div
            className="speciality_table"
            style={{ fontSize: "20px", padding: "20px" }}
          >
            <Table
              dataSource={scheduleShift}
              bordered
              columns={columnsSettings("shift")}
            />
          </div>
        </Content>
      ),
    },
    {
      key: "2",
      label: <div style={{ cursor: "pointer" }}>Семестры</div>,
      children: (
        <Content
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            >
              <span style={{ fontWeight: "500", fontSize: "24px" }}>
                Семестры
              </span>
            </Col>
            <Col
              span={12}
              style={{
                textAlign: "end",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "0 20px 0 0",
              }}
            >
              <Button
                onClick={() => navigate("/schedule/semester/create")}
                className="speciality_create_button"
              >
                <PlusOutlined
                  style={{ display: "inline-block", fontSize: "16px" }}
                />
                <span
                  style={{
                    fontWeight: "400",
                    marginTop: "-20px",
                    lineHeight: "0px",
                  }}
                >
                  Добавить
                </span>
              </Button>
            </Col>
          </Row>
          <div
            className="speciality_table"
            style={{ fontSize: "20px", padding: "20px" }}
          >
            <Table
              dataSource={scheduleSemester}
              bordered
              columns={columnsSettings("semester")}
            />
          </div>
        </Content>
      ),
    },
  ];

  return (
    <>
      <Col span={24}>
        <Content
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <SecondLevelTabs defaultActiveKey="1" items={secondLevelSettings} />
        </Content>
      </Col>
    </>
  );
};
