import {createAction} from "@reduxjs/toolkit";

import {
  DONE,
  FAIL,
  FETCH, SET, FORM, GET, CTP, PERIOD, EXTRA, FILTER, GROUPS, RESET,
} from "../constants";
import {IFormType, ISendFormType} from "./types";

export const getFormAction = createAction<IFormType>(GET + FORM + FETCH);
export const getFormActionDone = createAction<IFormType>(GET + FORM + FETCH + DONE);
export const getFormActionFail = createAction<IFormType>(GET + FORM + FETCH + FAIL);

export const setFormAction = createAction<ISendFormType>(SET + FORM + FETCH);
export const setFormActionDone = createAction<ISendFormType>(SET + FORM + FETCH + DONE);
export const setFormActionFail = createAction<any>(SET + FORM + FETCH + FAIL);

export const getCtpAction = createAction<any>(GET + CTP + FETCH);
export const getCtpActionDone = createAction<any>(GET + CTP + FETCH + DONE);
export const getCtpActionFail = createAction<undefined>(GET + CTP + FETCH + FAIL);

export const getCtpFilterAction = createAction<any>(GET + CTP + FILTER + FETCH);
export const getCtpFilterActionDone = createAction<any>(GET + CTP + FILTER + FETCH + DONE);
export const getCtpFilterActionFail = createAction<undefined>(GET + CTP + FILTER + FETCH + FAIL);

export const getPeriodAction = createAction<undefined>(GET + PERIOD + FETCH);
export const getPeriodActionDone = createAction<any>(GET + PERIOD + FETCH + DONE);
export const getPeriodActionFail = createAction<undefined>(GET + PERIOD + FETCH + FAIL);

export const getPeriodByGroupActionReset = createAction<undefined>(GET + PERIOD + GROUPS + FETCH + RESET);
export const getPeriodByGroupAction = createAction<undefined>(GET + PERIOD + GROUPS + FETCH);
export const getPeriodByGroupActionDone = createAction<any>(GET + PERIOD + GROUPS + FETCH + DONE);
export const getPeriodByGroupActionFail = createAction<undefined>(GET + PERIOD + GROUPS + FETCH + FAIL);

export const getFormExtraAction = createAction<IFormType>(GET + FORM + EXTRA + FETCH);
export const getFormExtraActionDone = createAction<IFormType>(GET + FORM + EXTRA + FETCH + DONE);
export const getFormExtraActionFail = createAction<IFormType>(GET + FORM + EXTRA + FETCH + FAIL);
