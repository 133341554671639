import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Row,
  Input,
  Cascader,
  Form,
  message,
  Switch,
} from "antd";
import { MyBreadcrumb, FirstLevelTabs } from "../../../components";
import { useTheme } from "../../../hooks";
import "../../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { HeadersAuth } from "../../../constants/Auth";
import { BuildingCreate } from "../BuildingCreate/BuildingCreate";
import {
  BuildingDictionaryVM,
  ClassroomTypesDictionaryVM,
} from "../ClassroomInterface";

export const ClassroomCreate = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [buildings, setBuildings] = useState<BuildingDictionaryVM[]>([]);
  const [classroomTypes, setClassroomTypes] = useState<
    ClassroomTypesDictionaryVM[]
  >([]);

  const [form] = Form.useForm();
  const { id } = useParams();
  const { theme } = useTheme();
  const [inputStates, setInputStates] = useState({
    number: false,
    area: false,
    building_id: false,
    title: false,
    type: false,
    type_id: false,
    status: false,
  });
  const [attemptedSave, setAttemptedSave] = useState(false);

  const handleSave = async () => {
    try {
      await form.validateFields();
      setAttemptedSave(true);
      const response = await axios.post(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/auditfund/v1/createClassrooms`,
        {
          number: formData.number,
          area: formData.area,
          building_id: formData.building_id,
          title: formData.title,
          type_id: formData.type_id,
          status: formData.status,
        },
        HeadersAuth
      );
      console.log(response.data);
      message.success("Аудитория успешно добавлена");
      navigate("/classroom");
    } catch (error: any) {
      if (error.response && error.response.status === 500) {
        message.error("Пожалуйста, заполните все обязательные поля");
      } else {
        message.error("Произошла ошибка при сохранении данных");
      }
    }
  };

  useEffect(() => {
    const fetchBuildings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/auditfund/v1/getBuildingsSelect?college_id=1`,
          HeadersAuth
        );

        setBuildings(response.data);
      } catch (error) {
        console.error("Ошибка получения данных:", error);
      }
    };

    const fetchTypesClassroom = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/auditfund/v1/getClassroomsTypes`,
          HeadersAuth
        );

        setClassroomTypes(response.data);
      } catch (error) {
        console.error("Ошибка получения данных:", error);
      }
    };

    fetchBuildings();
    fetchTypesClassroom();
  }, []);

  const [formData, setFormData] = useState({
    number: 0,
    area: 0,
    building_id: 0,
    title: "",
    type: "",
    type_id: 0,
    status: false,
  });

  const handleInputChange = (inputName) => (e) => {
    let inputValue = e?.target ? e.target.value : e;

    const isValuePresent =
      inputValue !== null && inputValue !== undefined && inputValue !== "";

    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: inputValue,
    }));

    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [inputName]: isValuePresent,
    }));
  };

  const handleSwitchChange = (checked) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: checked,
    }));
  };

  const firstLevelTabs = [
    {
      key: "1",
      label: <div style={{ cursor: "pointer" }}>Аудитории</div>,
      children: (
        <Content
          style={{
            width: "100%",
            height: "440px",
            backgroundColor: "white",
            marginTop: "20px",

            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />

          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form form={form}>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Номер
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.number
                      ? "error"
                      : inputStates.number
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave && !inputStates.number && "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    required
                    style={{
                      width: "90%",
                      borderColor:
                        attemptedSave && !inputStates.number
                          ? "#FF0000"
                          : inputStates.number
                          ? "#8B00FF"
                          : "",
                    }}
                    onChange={handleInputChange("number")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Площадь (м.кв)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.area
                      ? "error"
                      : inputStates.area
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave && !inputStates.area && "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      borderColor: inputStates.area ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("area")}
                  />
                </Form.Item>
              </Col>

              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Корпус
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.building_id
                      ? "error"
                      : inputStates.building_id
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave &&
                    !inputStates.building_id &&
                    "Незаполненное поле"
                  }
                >
                  <Cascader
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      ...(inputStates.building_id
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    options={buildings.map((building) => ({
                      value: building.id,
                      label: building.title,
                    }))}
                    onChange={handleInputChange("building_id")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Название
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.title
                      ? "error"
                      : inputStates.title
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave && !inputStates.title && "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      borderColor: inputStates.title ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("title")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Тип
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.type_id
                      ? "error"
                      : inputStates.type_id
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave &&
                    !inputStates.type_id &&
                    "Незаполненное поле"
                  }
                >
                  <Cascader
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      ...(inputStates.type_id
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    options={classroomTypes.map((types) => ({
                      value: types.id,
                      label: types.title,
                    }))}
                    onChange={handleInputChange("type_id")}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <p />
                <Switch
                  style={{ marginRight: "10px" }}
                  checked={formData.status}
                  onChange={handleSwitchChange}
                />{" "}
                Статус не использования
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "80px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/classroom")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Отмена
              </Button>
              <Button onClick={handleSave} className="CreateButton">
                Сохранить
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "2",
      label: <div style={{ cursor: "pointer" }}>Корпуса</div>,
      children: <BuildingCreate />,
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Добавить аудиторию" />
      <Row justify="center">
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="1" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>
      <p />
    </>
  );
};
