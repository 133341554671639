import {
  FETCH,
  DONE,
  FAIL, GET, PRINT, MODULE, EDIT, DATA, RESET, SET, STUDY, MEDICAL, GROUPS, REMARK, TOTAL_UVR, DISCIPLINES, JOURNAL,
} from "../../constants";

import reducerStatuses from "../../status";
import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {TFetch} from "../../types";

const initialState: TFetch = {
  modules: {
    ...reducerStatuses,
    data: [] as any,
  },
  study: {
    ...reducerStatuses,
    data: [] as any,
  },
  medical: {
    ...reducerStatuses,
    data: [] as any,
  },
  medicalGroups: {
    ...reducerStatuses,
    data: [] as any,
  },
  remark: {
    ...reducerStatuses,
    data: [] as any,
  },
  totalUvr: {
    ...reducerStatuses,
    data: [] as any,
  },
  totalUvrDisciplines: {
    ...reducerStatuses,
    data: [] as any,
  },
  printJournal: {
    ...reducerStatuses,
    data: [] as any,
  }
};

export default createReducer(initialState, builder =>
  builder
    .addCase(PRINT + DATA + RESET, (state) => ({
      ...initialState
    }))


    .addCase(GET + PRINT + MODULE + FETCH, (state) => ({
      ...state,
      modules: {
        ...initialState.modules,
        loading: true
      }
    }))
    .addCase(GET + PRINT + MODULE + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      modules: {
        ...initialState.modules,
        success: true,
        data: payload.map((item: any) => ({...item, isEdit: false}))
      }
    }))
    .addCase(GET + PRINT + MODULE + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      modules: {
        ...initialState.modules,
        failed: true
      }
    }))

    .addCase(PRINT + MODULE + SET + EDIT + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      [payload.reduxState]: {
        ...initialState[payload.reduxState],
        data: state[payload.reduxState].data.map((item: any, index: any) => {
          if (index === payload.index) {
            return {
              ...item,
              ...payload.object
            }
          } else {
            return item
          }
        }),
      }
    }))


    .addCase(PRINT + MODULE + EDIT + FETCH, (state) => ({
      ...state,
      modules: {
        ...initialState.modules,
        loading: true,
        data: state.modules.data
      }
    }))
    .addCase(PRINT + MODULE + EDIT + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      modules: {
        ...initialState.modules,
        success: true,
        data: state.modules.data.map((item: any) => {
          if (item.id === payload.id) {
            return {
              ...item,
              isEdit: false,
              date_start: payload.date_start,
              date_end: payload.date_end,
            }
          } else {
            return item
          }
        })
      }
    }))


    .addCase(GET + PRINT + STUDY + FETCH, (state) => ({
      ...state,
      study: {
        ...initialState.study,
        loading: true
      }
    }))
    .addCase(GET + PRINT + STUDY + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      study: {
        ...initialState.study,
        success: true,
        data: payload.map((item: any) => ({...item, isEdit: false}))
      }
    }))
    .addCase(GET + PRINT + STUDY + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      study: {
        ...initialState.study,
        failed: true
      }
    }))

    .addCase(EDIT + PRINT + STUDY + FETCH, (state) => ({
      ...state,
      study: {
        ...initialState.study,
        loading: true,
        data: state.study.data
      }
    }))
    .addCase(EDIT + PRINT + STUDY + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      study: {
        ...initialState.study,
        success: true,
        data: state.study.data.map((item: any) => ({...item, isEdit: false}))
      }
    }))

    .addCase(GET + PRINT + MEDICAL + GROUPS + FETCH, (state) => ({
      ...state,
      medicalGroups: {
        ...initialState.medicalGroups,
        loading: true
      }
    }))
    .addCase(GET + PRINT + MEDICAL + GROUPS + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      medicalGroups: {
        ...initialState.medicalGroups,
        success: true,
        data: payload.map((item: any) => ({...item, isEdit: false}))
      }
    }))
    .addCase(GET + PRINT + MEDICAL + GROUPS + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      medicalGroups: {
        ...initialState.medicalGroups,
        failed: true
      }
    }))


    .addCase(GET + PRINT + MEDICAL + FETCH, (state) => ({
      ...state,
      medical: {
        ...initialState.medical,
        loading: true
      }
    }))
    .addCase(GET + PRINT + MEDICAL + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      medical: {
        ...initialState.medical,
        success: true,
        data: payload.map((item: any) => ({...item, isEdit: false}))
      }
    }))
    .addCase(GET + PRINT + MEDICAL + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      medical: {
        ...initialState.medical,
        failed: true
      }
    }))

    .addCase(PRINT + MEDICAL + EDIT + FETCH, (state) => ({
      ...state,
      medical: {
        ...initialState.medical,
        loading: true,
        data: state.medical.data
      }
    }))
    .addCase(PRINT + MEDICAL + EDIT + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      medical: {
        ...initialState.medical,
        success: true,
        data: state.medical.data.map((item: any) => {
          if (item.person_id === payload.person_id) {
            return {
              ...item,
              isEdit: false,
              ...payload
            }
          } else {
            return item
          }
        })
      }
    }))

    .addCase(GET + PRINT + REMARK + FETCH, (state) => ({
      ...state,
      remark: {
        ...initialState.remark,
        loading: true
      }
    }))
    .addCase(GET + PRINT + REMARK + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      remark: {
        ...initialState.remark,
        success: true,
        data: payload.map((item: any) => ({...item, isEdit: false}))
      }
    }))
    .addCase(GET + PRINT + REMARK + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      remark: {
        ...initialState.remark,
        failed: true
      }
    }))

    .addCase(SET + PRINT + REMARK + FETCH, (state) => ({
      ...state,
      remark: {
        ...initialState.remark,
        loading: true,
        data: state.remark.data.map((item: any) => ({...item, isEdit: false}))
      }
    }))


    .addCase(GET + PRINT + TOTAL_UVR + FETCH, (state) => ({
      ...state,
      totalUvr: {
        ...initialState.totalUvr,
        loading: true
      }
    }))
    .addCase(GET + PRINT + TOTAL_UVR + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      totalUvr: {
        ...initialState.totalUvr,
        success: true,
        data: payload.map((item: any) => ({...item, isEdit: false}))
      }
    }))
    .addCase(GET + PRINT + TOTAL_UVR + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      totalUvr: {
        ...initialState.totalUvr,
        failed: true
      }
    }))

    .addCase(GET + DISCIPLINES + PRINT + TOTAL_UVR + FETCH, (state) => ({
      ...state,
      totalUvrDisciplines: {
        ...initialState.totalUvrDisciplines,
        loading: true
      }
    }))
    .addCase(GET + DISCIPLINES + PRINT + TOTAL_UVR + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      totalUvrDisciplines: {
        ...initialState.totalUvrDisciplines,
        success: true,
        data: payload
      }
    }))
    .addCase(GET + DISCIPLINES + PRINT + TOTAL_UVR + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      totalUvrDisciplines: {
        ...initialState.totalUvrDisciplines,
        failed: true
      }
    }))

    .addCase(PRINT + JOURNAL + FETCH, (state) => ({
      ...state,
      printJournal: {
        ...initialState.printJournal,
        loading: true
      }
    }))
    .addCase(PRINT + JOURNAL + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      printJournal: {
        ...initialState.printJournal,
        success: true,
        data: payload
      }
    }))
    .addCase(PRINT + JOURNAL + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      printJournal: {
        ...initialState.printJournal,
        failed: true
      }
    }))
)
