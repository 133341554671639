import React, {useEffect} from "react";
import {Col, Row, Checkbox, List, Card} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getQuestionAnswerAction} from "../../../../../../store/pages/plans/actions";
import {RootState} from "../../../../../../store";
import {MyBreadcrumb, MyText} from "../../../../../../components";
import {selectStore} from "../../../../../../store/selector";
import {useTranslation} from "react-i18next";

export const TestView = () => {
  const {t} = useTranslation("common")

  const dispatch = useDispatch()
  const {planId, lessonId, testId} = useParams()

  useEffect(() => {
    dispatch(getQuestionAnswerAction(testId))
  }, [])

  const {plans: {oneTest: testState}}: RootState = useSelector(selectStore)

  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('')

  return (
    <>
      <MyBreadcrumb title={t('back')} isBack/>

      <Row justify='center'>
        <Col span={20}>
          <Card>
            <List
              dataSource={testState.data?.questions}
              loading={testState.loading}
              rowKey={(record: any) => record.id}
              header={<MyText color={'var(--c-primary)'} weight={700} size={24}>{testState.data?.name}</MyText>}
              renderItem={(item: any, index: any) => {
                return (
                  <List.Item>
                    <Row style={{width: '100%'}} gutter={[16, 16]}>
                      <Col span={24}>
                        <MyText weight={500} size={20} color={'var(--c-hover)'}>
                          {index + 1}. {item.name}
                        </MyText>
                      </Col>

                      <Col span={24}>
                        <Row align='middle' gutter={[16, 6]}>
                          {item.answers.map((answer: any, answerIndex: any) => (
                            <Col key={answer.id} span={24}>
                              <Row justify='space-between' align='middle'>
                                <Col>
                                  <Row align='middle' gutter={6}>
                                    <Col>
                                      <MyText weight={500} size={18} color={'var(--c-subtitle)'}>
                                        {alphabet[answerIndex].toUpperCase()})
                                      </MyText>
                                    </Col>

                                    <Col>
                                      <MyText  weight={500} size={18} color={'var(--c-title-text)'}>
                                        {answer.name}
                                      </MyText>
                                    </Col>
                                  </Row>
                                </Col>

                                <Col>
                                  <Checkbox checked={answer.is_correct} value={answer.is_correct}/>
                                </Col>
                              </Row>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </List.Item>
                )
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  )
}