import styled from "styled-components";
import {Tabs} from "antd";
import {DefaultTokenTheme} from "../../../assets/themes/defaultTheme";
import {setThemeColor} from "../../../utils/setThemeColor";

export const FirstLevelTabsStyles = styled(Tabs)`
  
  .ant-tabs-content-holder{
    box-shadow: 0px 2px 8px -1px #1B242C14;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  //странная тема
  //.ant-tabs-nav-wrap:first-child{
  //  position: relative;
  //  left: -1px;
  //}
  
  & > .ant-tabs-nav {
    margin-bottom: 0;

    &:before{
      border-color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};
    }

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-tab {
      background-color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.tableHeader, DefaultTokenTheme.tableHeaderDark)};
      margin: 0;
      padding: 18px 16px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-top: 2px solid ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};
      // border-left: 1px solid ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};
      border-bottom: 1px solid ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};
      
      &:nth-last-child(-n+2){
        border-right: 1px solid ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};
      }

      .ant-tabs-tab-btn {
        color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.disabled, DefaultTokenTheme.disabledDark)};
        font-size: 16px;
        line-height: 20px;
      }

      &.ant-tabs-tab-active {
        background-color: ${({theme}) => setThemeColor(theme.current, '#fff', DefaultTokenTheme.plateDark)};
        border-top: 2px solid ${DefaultTokenTheme.primary};
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-bottom: none !important;
        
        .ant-tabs-tab-btn {
          color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.textTitle, "#fff")};
        }
      }
    }
  }

  & > .ant-tabs-content-holder {
    background-color: ${({theme}) => setThemeColor(theme.current, '#fff', DefaultTokenTheme.plateDark)};
  }
`