import {JournalTableProps} from "./JournalTable.props";
import {Table} from "antd";
import React from "react";

export const JournalTable = ({columns, dataSource, loading = false, ...props}: JournalTableProps) => {
  const bodyHeight = document.body.scrollHeight - 300

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      rowKey={(record: any) => record.id}
      pagination={{
        position: [],
        pageSize: 9999999,
      }}
      scroll={{
        // x: true,
        y: bodyHeight,
      }}
      {...props}
    />
  )
}