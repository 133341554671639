import {
  FETCH,
  DONE,
  FAIL,
  PROFILE,
  AUTH, GET, MENU, NOTIFICATION, SET, DASHBOARD
} from "../../constants";

import reducerStatuses from "../../status";
import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {TFetch} from "../../types";

const initialState: TFetch = {
  userProfile: {
    ...reducerStatuses,
    data: {} as any,
  },
  menu: {
    ...reducerStatuses,
    data: [],
  },
  notification: {
    ...reducerStatuses,
    data: [],
  },
  dashboard: {
    ...reducerStatuses,
    data: {} as any,
  }
};

export default createReducer(initialState, builder =>
  builder
    .addCase(AUTH + FETCH, (state) => ({
      ...state,
      userProfile: {
        ...state.userProfile,
        loading: true
      }
    }))
    .addCase(AUTH + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      userProfile: {
        ...state.userProfile,
        loading: false,
        success: true,
        data: payload
      }
    }))
    .addCase(AUTH + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      userProfile: {
        ...state.userProfile,
        loading: false,
        success: false,
        failed: true
      }
    }))


    .addCase(PROFILE + FETCH, (state) => ({
      ...state,
      userProfile: {
        ...state.userProfile,
        loading: true
      }
    }))
    .addCase(PROFILE + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      userProfile: {
        ...state.userProfile,
        loading: false,
        success: true,
        data: payload
      }
    }))
    .addCase(PROFILE + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      userProfile: {
        ...state.userProfile,
        loading: false,
        success: false,
        failed: true
      }
    }))

    .addCase(GET + MENU + FETCH, (state) => ({
      ...state,
      menu: {
        ...initialState.menu,
        loading: true
      }
    }))
    .addCase(GET + MENU + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      menu: {
        ...initialState.menu,
        success: true,
        data: payload
      }
    }))
    .addCase(GET + MENU + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      menu: {
        ...initialState.menu,
        failed: true
      }
    }))

    .addCase(GET + NOTIFICATION + FETCH, (state) => ({
      ...state,
      notification: {
        ...state.notification,
        loading: true
      }
    }))
    .addCase(GET + NOTIFICATION + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      notification: {
        ...state.notification,
        loading: false,
        success: true,
        data: payload
      }
    }))
    .addCase(GET + NOTIFICATION + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      notification: {
        ...state.notification,
        loading: false,
        success: false,
        failed: true
      }
    }))

    .addCase(SET + NOTIFICATION + FETCH, (state) => ({
      ...state,
      notification: {
        ...state.notification,
        loading: true
      }
    }))
    .addCase(SET + NOTIFICATION + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      notification: {
        ...state.notification,
        loading: false,
        success: true,
        data: []
      }
    }))
    .addCase(SET + NOTIFICATION + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      notification: {
        ...state.notification,
        loading: false,
        success: false,
        failed: true
      }
    }))

    .addCase(GET + DASHBOARD + FETCH, (state) => ({
      ...state,
      dashboard: {
        ...initialState.dashboard,
        loading: true
      }
    }))
    .addCase(GET + DASHBOARD + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      dashboard: {
        ...initialState.dashboard,
        success: true,
        data: payload
      }
    }))
    .addCase(GET + DASHBOARD + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      dashboard: {
        ...initialState.dashboard,
        failed: true
      }
    }))
)
