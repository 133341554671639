import {DefaultTokenTheme} from "./defaultTheme";
import {ThemeConfig} from "antd/es/config-provider/context";

export const LightTheme: ThemeConfig | undefined = {
  token: {
    ...DefaultTokenTheme,
    colorPrimary: DefaultTokenTheme.primary,
  },
  components: {
    Layout: {
      headerHeight: 60,
      padding: 0,
      headerBg: '#fff'
    },
    Button: {
      colorPrimary: DefaultTokenTheme.primary,
      colorPrimaryHover: DefaultTokenTheme.hover,
      colorPrimaryActive: DefaultTokenTheme.pressed,
      controlHeight: 48,
    },
    Input: {
      controlHeight: 48,
      colorBgContainer: 'transparent',
      colorText: DefaultTokenTheme.textTitle,
      colorTextPlaceholder: DefaultTokenTheme.disabled,
      colorBorder: DefaultTokenTheme.line,
      fontSize: 18,
    },
    Select: {
      colorTextPlaceholder: DefaultTokenTheme.textTitle,
      controlHeight: 48,
    },
    Form: {
      marginLG: 12,
      labelColor: '#6B6C7E',
      labelFontSize: 14
    },
    Divider: {
      margin: 0,
      marginLG: 0,
      marginXS: 0,
      colorSplit: DefaultTokenTheme.line
    },
    Table: {
      padding: 6,
      paddingContentVerticalLG: 6,
      borderColor: DefaultTokenTheme.line,
      colorBgContainer: DefaultTokenTheme.backgroundLight,
      headerColor: DefaultTokenTheme.textTitle,
      colorText: DefaultTokenTheme.textTitle,
      colorTextDisabled: DefaultTokenTheme.disabled
    },
    Checkbox: {
      fontSize: 14
    },
    Card: {
      colorBgContainer: 'transparent',
      colorBorderSecondary: DefaultTokenTheme.line
    },
    Tabs: {
      itemColor: DefaultTokenTheme.label,
    },
    Empty: {
      colorBgContainer: DefaultTokenTheme.backgroundLight,
      colorText: DefaultTokenTheme.textTitle
    },
    Badge: {
      colorText: DefaultTokenTheme.textTitle
    }
  },
}
