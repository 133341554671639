import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Col, List, Row, FloatButton, Form, Spin, Card, Flex, Empty} from "antd";
import {createSearchParams, generatePath, useNavigate} from "react-router-dom";
import {JOURNAL_YEAR, PLANS_TYPES, ROUTE_PATH} from "../../constants";
import React, {useEffect, useState} from "react";
import {FormItem, SecondLevelTabs, MyBreadcrumb, MyButton, MyText, FirstLevelTabs, MyPieChart} from "../../components";
import {selectStore} from "../../store/selector";
import {getListAction, getListActionDone} from "../../store/pages/plans/actions";
import {getCtpFilterAction} from "../../store/common/actions";
import {CardItem} from "../Journal/index.styles";
import {DefaultTokenTheme} from "../../assets/themes/defaultTheme";
import {setThemeColor} from "../../utils/setThemeColor";
import {useTheme} from "../../hooks";

export const Plans = () => {
  const {t} = useTranslation("common")
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const {theme} = useTheme()
  const [adminForm] = Form.useForm()

  const [currentYear, setCurrentYear] = useState<string>(JOURNAL_YEAR.CURRENT);
  const [currentType, setCurrentType] = useState<string>(PLANS_TYPES.PRIVATE)

  const {
    plans: {list: plansListState},
    common: {ctpFilter: ctpFilterState},
    profile: {userProfile: userProfileState}
  } = useSelector(selectStore)

  const getPlanList = (year: any, type: any) => {
    if (type === 'all') {
      dispatch(getCtpFilterAction({
        year: year,
      }))
    } else {
      dispatch(getListAction({
        year: year,
        ...(type === PLANS_TYPES.PUBLIC ? {is_shared: 1} : {}),
        ...(type === PLANS_TYPES.SDOT ? {type: 0} : {}),
      }))
    }
  }

  useEffect(() => {
    getPlanList(currentYear, currentType)
  }, [])

  const handleClickTypeTab = (type: any) => {
    adminForm.resetFields()

    setCurrentType(type)
    getPlanList(currentYear, type)

    if (type === 'all') {
      dispatch(getListActionDone([]))
    }
  }

  const handleClickYearTab = (key: any) => {
    adminForm.resetFields()

    setCurrentYear(key)
    getPlanList(key, currentType)
  }

  const handleClickPlan = (obj: any) => {
    const path = {
      pathname: generatePath(ROUTE_PATH.PLANS_VIEW, {planId: obj.id}),
      search: createSearchParams({
        year: currentYear,
        type: currentType
      }).toString()
    }

    navigate(path);
  }

  const tabItem = (listItem: any) => {
    return (
      <CardItem
        onClick={() => handleClickPlan(listItem)}
        title={listItem.group}
      >
        <Row justify='space-between' gutter={[16, 16]} align='middle'>
          <Col span={18}>
            <Row align='middle'>
              {
                listItem.module &&
                  <Col span={24}>
                      <MyText size={14} weight={500} color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                        {listItem.module}
                      </MyText>
                  </Col>
              }

              {
                listItem.subject &&
                  <Col span={24}>
                      <MyText size={12}
                              color={setThemeColor(theme, DefaultTokenTheme.icon, DefaultTokenTheme.subtitleDark)}>
                        {listItem.subject}
                      </MyText>
                  </Col>
              }

            </Row>
          </Col>

          <Col span={6}>
            <MyPieChart progress={listItem.completion_percent}/>
          </Col>
        </Row>
      </CardItem>
    )
  }

  const handleAdminFormSubmit = (values: any) => {
    dispatch(getListAction({
      year: currentYear,
      ...(currentType === PLANS_TYPES.PUBLIC ? {is_shared: 1} : {}),
      ...(currentType === PLANS_TYPES.SDOT ? {type: 0} : {}),
      ...values
    }))
  }

  const secondLevelTabContentItem = () => {
    return (
      <Row gutter={[16, 16]}>
        {
          currentType === 'all' &&
            <Col span={24}>
                <Spin spinning={ctpFilterState.loading}>
                    <Form
                        form={adminForm}
                        labelCol={{
                          span: 24,
                        }}
                        style={{padding: 12}}
                        onFinish={(values: any) => handleAdminFormSubmit(values)}
                    >
                        <Row gutter={8} align='bottom'>
                          {
                            ctpFilterState.data?.fields?.map((field: any, fieldIndex: any) => (
                              <Col key={fieldIndex} span={6}>
                                <FormItem key={fieldIndex} item={field}/>
                              </Col>
                            ))
                          }
                            <Col span={6}>
                                <MyButton title='Применить' htmlType='submit' width='100%'
                                          style={{marginBottom: 12}}/>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Col>
        }
        <Col span={24}>
          <List
            grid={{
              gutter: 16,
              column: 3,
            }}
            style={{padding: 16}}
            locale={{
              emptyText: <Empty description={t('noRecords')}/>
            }}
            dataSource={plansListState.data || []}
            loading={plansListState.loading}
            rowKey={(record: any) => `${record.id}-journalListState`}
            renderItem={(listItem: any) => (
              <List.Item>
                {tabItem(listItem)}
              </List.Item>
            )}
          />
        </Col>
      </Row>
    )
  }

  const secondLevelTabsContent = [
    {
      key: JOURNAL_YEAR.PREV,
      label: `${JOURNAL_YEAR.PREV}-${JOURNAL_YEAR.CURRENT}`,
      children: secondLevelTabContentItem()
    },
    {
      key: JOURNAL_YEAR.CURRENT,
      label: `${JOURNAL_YEAR.CURRENT}-${JOURNAL_YEAR.NEXT}`,
      children: secondLevelTabContentItem()
    }
  ]


  const firstLevelTabs = [
    {
      key: PLANS_TYPES.PRIVATE,
      label: t('private'),
      children: (
        <SecondLevelTabs items={secondLevelTabsContent} onTabClick={(key) => handleClickYearTab(key)}
                         activeKey={currentYear}/>
      )
    },
    {
      key: PLANS_TYPES.PUBLIC,
      label: t('public'),
      children: (
        <SecondLevelTabs items={secondLevelTabsContent} onTabClick={(key) => handleClickYearTab(key)}
                         activeKey={currentYear}/>
      )
    },
    {
      key: PLANS_TYPES.SDOT,
      label: t('sdot'),
      children: (
        <SecondLevelTabs items={secondLevelTabsContent} onTabClick={(key) => handleClickYearTab(key)}
                         activeKey={currentYear}/>
      )
    }
  ]

  return (
    <>
      <MyBreadcrumb title={t('lessonsPlan')}/>
      <FloatButton.BackTop visibilityHeight={10}/>

      <Row justify='center'>
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs items={[...firstLevelTabs,
                ...(userProfileState.data?.isCollegeAdmin ?
                  [{
                    key: 'all',
                    label: 'Все',
                    children: (
                      <SecondLevelTabs items={secondLevelTabsContent} onTabClick={(key) => handleClickYearTab(key)}
                                       activeKey={currentYear}/>
                    )
                  }] : [])
              ]} onTabClick={(key: any) => handleClickTypeTab(key)} activeKey={currentType}/>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
