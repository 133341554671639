import {MyButtonStyles} from "./MyButton.styles";
import {MyButtonProps} from "./MyButton.props";
import cl from "classnames";
import React from "react";
import {useTheme} from "../../../hooks";

export const MyButton = ({
                           title,
                           width,
                           height,
                           background,
                           info = false,
                           warning = false,
                           children,
                           ...props
                         }: MyButtonProps): React.ReactElement => {
  const {theme, themeConfig} = useTheme()

  return (
    <MyButtonStyles {...props} style={{width: width, background: background, height: height, ...props.style}}
                    className={cl({
                      'ant-btn-warning': warning,
                      'ant-btn-info': info,
                    })}>
      {title}
      {children}
    </MyButtonStyles>
  )
}
