import {
  FETCH,
  DONE,
  FAIL,
  RUPS,
  RESET,
  ADD,
  SET,
  RUP,
  GET,
  HELPER,
  DUPLICATE,
  DELETE,
  REVERT,
  RECOMMENDED,
  ONE,
  QUALIFICATIONS,
  SPECIALITIES,
  ID,
  OPTIONS,
  EDIT,
  CURRENT,
  DISCIPLINES,
  SEMESTER,
  SETTINGS,
  TYPES, INFO, MODULE
} from "../../constants";

import reducerStatuses from "../../status";
import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {TFetch} from "../../types";
import {t} from "i18next";

const initialState: TFetch | any = {
  rups: {
    ...reducerStatuses,
    data: [
      {
        key: 1,
        status: 1,
        label: t('rup:tabs.activeRup'),
        data: [] as any,
      },
      {
        key: 2,
        status: 2,
        label: t('rup:tabs.deleteRup'),
        data: [] as any,
      }
    ],
  },

  currentRup: {
    loading: false,
    success: false,
    failed: false,
    data: {} as any
  },

  rupsHelpers: {
    linkGroups: {
      ...reducerStatuses,
      data: [] as any
    },
    profile: {
      ...reducerStatuses,
      data: [] as any
    },
    speciality: {
      ...reducerStatuses,
      data: [] as any
    }
  },

  recommendedRups: {
    loading: false,
    success: false,
    failed: false,
    data: []
  },

  qualifications: {
    loading: false,
    success: false,
    failed: false,
    data: []
  },

  qualificationsOptions: {
    loading: false,
    success: false,
    failed: false,
    data: []
  },

  currentRupTable: {
    loading: false,
    success: false,
    failed: false,
    data: []
  },

  //todo создал клон массива, из за того какого то *** мутирует массив, хотя не должен
  cloneRupTable: {
    loading: false,
    success: false,
    failed: false,
    data: []
  },

  rupsDisciplines: {
    loading: false,
    success: false,
    failed: false,
    data: []
  },

  semesterSettings: {
    loading: false,
    success: false,
    failed: false,
    data: {
      id: null,
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
      seven: null,
      eight: null,
    }
  },

  rupTranslate: {
    loading: false,
    success: false,
    failed: false,
    data: []
  },

  rupTypesTranslate: {
    loading: false,
    success: false,
    failed: false,
    data: []
  }
};

export default createReducer(initialState, builder =>
  builder
    .addCase(RUPS + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rups: {
        ...state.rups,
        loading: true,
      },
    }))
    .addCase(RUPS + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rups: {
        ...initialState.rups,
        success: true,
        data: [
          {
            key: 1,
            status: 1,
            label: t('rup:tabs.activeRup'),
            data: payload.all
          },
          {
            key: 2,
            status: 2,
            label: t('rup:tabs.deleteRup'),
            data: payload.deleted
          }
        ]
      },
    }))
    .addCase(RUPS + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rups: {
        ...initialState.rups,
        failed: true,
      },
    }))

    .addCase(ADD + RUP + SET, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRup: {
        ...state.currentRup,
        success: true,
        data: {
          ...state.currentRup.data,
          ...payload
        }
      }
    }))
    .addCase(RESET + RUP + SET, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRup: {
        ...initialState.currentRup,
        success: true,
      }
    }))

    .addCase(ADD + RUP + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRup: {
        ...state.currentRup,
        loading: true,
      },
      rups: {
        ...state.rups,
        loading: true,
      }
    }))
    .addCase(ADD + RUP + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRup: {
        ...initialState.currentRup,
        success: true,
        data: {
          ...state.currentRup.data,
          id: payload.id,
        }
      },
      rups: {
        ...initialState.rups,
        success: true,
        loading: false,
        data: state.rups.data.map((item: any) => {
          if (item.status === payload.status) {
            return {
              ...item,
              data: [payload, ...item.data].sort((a, b) => a.id > b.id ? -1 : 1),
            }
          } else {
            return item
          }
        })
      }
    }))
    .addCase(ADD + RUP + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRup: {
        ...initialState.currentRup,
        failed: true,
      },
      rups: {
        ...state.rups,
        loading: false,
        failed: true,
      }
    }))

    .addCase(GET + RUP + HELPER + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rupsHelpers: {
        ...state.rupsHelpers,
        [payload.key]: {
          ...state.rupsHelpers[payload.key],
          loading: true,
        }
      }
    }))
    .addCase(GET + RUP + HELPER + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rupsHelpers: {
        ...state.rupsHelpers,
        [payload.key]: {
          ...initialState.rupsHelpers[payload.key],
          success: true,
          data: payload.value,
        }
      }
    }))
    .addCase(GET + RUP + HELPER + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rupsHelpers: {
        ...state.rupsHelpers,
        [payload.key]: {
          ...initialState.rupsHelpers[payload.key],
          failed: true,
        }
      }
    }))

    .addCase(RUPS + EDIT + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rups: {
        ...state.rups,
        loading: true,
      }
    }))
    .addCase(RUPS + EDIT + FETCH + DONE, (state, {payload}: PayloadAction<any>) => {
      let isNewRup = !(state.rups.data[0].data.some((item: any) => item.id === payload.id))
      return {
        ...state,
        rups: {
          ...initialState.rups,
          success: true,
          loading: false,
          data: state.rups.data.map((item: any) => {
            if (item.status === payload.status) {
              if (isNewRup) {
                return {
                  ...item,
                  data: [payload, ...item.data].sort((a, b) => a.id > b.id ? -1 : 1),
                }
              } else {
                return {
                  ...item,
                  data: item.data.map((rup: any) => {
                    if (rup.id === payload.id) {
                      return {
                        ...payload
                      }
                    } else {
                      return rup
                    }
                  })
                }
              }
            } else {
              return item
            }
          })
        }
      }
    })
    .addCase(RUPS + EDIT + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rups: {
        ...state.rups,
        loading: false,
        failed: true,
      }
    }))

    .addCase(DUPLICATE + RUP + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rups: {
        ...state.rups,
        loading: true,
      }
    }))
    .addCase(DUPLICATE + RUP + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rups: {
        ...initialState.rups,
        success: true,
        loading: false,
        data: state.rups.data.map((item: any) => {
          if (item.status === payload.status) {
            return {
              ...item,
              data: [payload, ...item.data].sort((a, b) => a.id > b.id ? -1 : 1),
            }
          } else {
            return item
          }
        })
      }
    }))
    .addCase(DUPLICATE + RUP + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rups: {
        ...state.rups,
        loading: false,
        failed: true,
      }
    }))

    .addCase(RUPS + DELETE + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rups: {
        ...state.rups,
        loading: true,
      }
    }))
    .addCase(RUPS + DELETE + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rups: {
        ...initialState.rups,
        success: true,
        loading: false,
        data: state.rups.data.map((item: any) => {
          if (item.status === payload.status) {
            return {
              ...item,
              data: item.data.filter((rup: any) => rup.id !== payload.id),
            }
          } else {
            return item
          }
        })
      }
    }))
    .addCase(RUPS + DELETE + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rups: {
        ...state.rups,
        loading: false,
        failed: true,
      }
    }))

    .addCase(RUPS + REVERT, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rups: {
        ...initialState.rups,
        success: true,
        loading: false,
        data: state.rups.data.map((item: any) => {
          if (item.status === payload.status) {
            return {
              ...item,
              data: item.data.filter((rup: any) => rup.id !== payload.id),
            }
          } else {
            return item
          }
        })
      }
    }))

    .addCase(RUPS + RECOMMENDED + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      recommendedRups: {
        ...state.recommendedRups,
        loading: true,
      }
    }))
    .addCase(RUPS + RECOMMENDED + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      recommendedRups: {
        ...initialState.recommendedRups,
        success: true,
        data: payload.map((item: any, index: any) => ({...item, index: index + 1}))
      }
    }))
    .addCase(RUPS + RECOMMENDED + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      recommendedRups: {
        ...initialState.recommendedRups,
        failed: true,
      }
    }))

    .addCase(RUPS + ADD + ONE + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      recommendedRups: {
        ...state.recommendedRups,
        loading: true,
      },
      rups: {
        ...state.rups,
        loading: true,
      }
    }))
    .addCase(RUPS + ADD + ONE + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      recommendedRups: {
        ...state.recommendedRups,
        success: true,
        loading: false,
        payload: payload,
        data: state.recommendedRups.data.filter((item: any, index: any) => item.index !== payload.index)
      },
      rups: {
        ...state.rups,
        success: true,
        loading: false,
        data: state.rups.data.map((item: any) => {
          if (item.status === payload.status) {
            return {
              ...item,
              data: [payload, ...item.data].sort((a, b) => a.id > b.id ? -1 : 1),
            }
          } else {
            return item
          }
        })
      }
    }))
    .addCase(RUPS + ADD + ONE + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      recommendedRups: {
        ...state.recommendedRups,
        failed: true,
        loading: false
      }
    }))

    .addCase(RUP + QUALIFICATIONS + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      qualifications: {
        ...initialState.qualifications,
        loading: true,
      }
    }))
    .addCase(RUP + QUALIFICATIONS + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      qualifications: {
        ...initialState.qualifications,
        success: true,
        data: payload.map((item: any) => ({...item, new: false}))
      }
    }))
    .addCase(RUP + QUALIFICATIONS + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      qualifications: {
        ...initialState.qualifications,
        failed: true,
      }
    }))

    .addCase(RUP + SPECIALITIES + ID + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRup: {
        ...initialState.currentRup,
        loading: true,
      }
    }))
    .addCase(RUP + SPECIALITIES + ID + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRup: {
        ...initialState.currentRup,
        success: true,
        data: {
          ...initialState.currentRup.data,
          ...payload
        }
      }
    }))
    .addCase(RUP + SPECIALITIES + ID + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRup: {
        ...initialState.currentRup,
        failed: true,
      }
    }))

    .addCase(RUP + QUALIFICATIONS + OPTIONS + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      qualificationsOptions: {
        ...initialState.qualificationsOptions,
        loading: true,
      }
    }))
    .addCase(RUP + QUALIFICATIONS + OPTIONS + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      qualificationsOptions: {
        ...initialState.qualificationsOptions,
        success: true,
        data: payload
      }
    }))
    .addCase(RUP + QUALIFICATIONS + OPTIONS + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      qualificationsOptions: {
        ...initialState.qualificationsOptions,
        failed: true,
      }
    }))

    .addCase(RUP + QUALIFICATIONS + ADD + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      qualifications: {
        ...state.qualifications,
        loading: true,
      }
    }))
    .addCase(RUP + QUALIFICATIONS + ADD + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      qualifications: {
        ...initialState.qualifications,
        success: true,
        data: [...state.qualifications.data, payload]
      }
    }))
    .addCase(RUP + QUALIFICATIONS + ADD + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      qualifications: {
        ...state.qualifications,
        loading: false,
        failed: true,
      }
    }))

    .addCase(RUP + QUALIFICATIONS + EDIT + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      qualifications: {
        ...state.qualifications,
        loading: false,
        success: true,
        data: state.qualifications.data.map((item: any) => {
          if (item.id === payload.id) {
            return {
              ...item,
              [payload.key]: payload.value
            }
          } else {
            return item
          }
        })
      }
    }))
    // .addCase(RUP + QUALIFICATIONS + EDIT + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
    //   ...state,
    //     qualifications: {
    //       ...state.qualifications,
    //       loading: false,
    //       success: true,
    //       data: state.qualifications.data.map((item: any) => {
    //         if(item.id === payload.id){
    //           return {
    //             ...payload
    //           }
    //         } else {
    //           return item
    //         }
    //       })
    //     }
    // }))
    .addCase(RUP + QUALIFICATIONS + EDIT + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      qualifications: {
        ...state.qualifications,
        loading: false,
        failed: true,
      }
    }))

    .addCase(RUP + QUALIFICATIONS + DELETE + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      qualifications: {
        ...state.qualifications,
        data: state.qualifications.data.filter((item: any) => item.id !== payload.id)
        // loading: true,
      }
    }))
    .addCase(RUP + QUALIFICATIONS + DELETE + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      qualifications: {
        ...state.qualifications,
        loading: false,
        success: true,
        data: state.qualifications.data.filter((item: any) => item.id !== payload.id)
      }
    }))
    .addCase(RUP + QUALIFICATIONS + DELETE + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      qualifications: {
        ...state.qualifications,
        loading: false,
        failed: true,
      }
    }))

    .addCase(RUP + CURRENT + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRup: {
        ...initialState.currentRup,
        loading: true,
      }
    }))
    .addCase(RUP + CURRENT + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRup: {
        ...initialState.currentRup,
        success: true,
        data: {
          ...initialState.currentRup.data,
          ...payload
        }
      }
    }))
    .addCase(RUP + CURRENT + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRup: {
        ...initialState.currentRup,
        failed: true,
      }
    }))

    .addCase(RUP + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRupTable: {
        ...initialState.currentRupTable,
        loading: true,
      },
      cloneRupTable: {
        ...initialState.cloneRupTable,
        loading: true,
      }
    }))
    .addCase(RUP + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRupTable: {
        ...initialState.currentRupTable,
        success: true,
        // data: payload,
        data: payload.map((item: any) => {
          if (parseFloat(item.credit)) {
            return item
          } else {
            return {
              ...item,
              credit: '0'
            }
          }
        })
      },
      cloneRupTable: {
        ...initialState.cloneRupTable,
        success: true,
        // data: payload,
        data: payload.map((item: any) => {
          if (parseFloat(item.credit)) {
            return item
          } else {
            return {
              ...item,
              credit: '0'
            }
          }
        })
      },
    }))
    .addCase(RUP + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRupTable: {
        ...initialState.currentRupTable,
        failed: true,
      },
      cloneRupTable: {
        ...initialState.cloneRupTable,
        failed: true,
      }
    }))

    .addCase(RUP + DISCIPLINES + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rupsDisciplines: {
        ...initialState.rupsDisciplines,
        loading: true,
      }
    }))
    .addCase(RUP + DISCIPLINES + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rupsDisciplines: {
        ...initialState.rupsDisciplines,
        success: true,
        data: payload
      }
    }))
    .addCase(RUP + DISCIPLINES + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rupsDisciplines: {
        ...initialState.rupsDisciplines,
        failed: true,
      }
    }))

    .addCase(GET + SEMESTER + SETTINGS + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      semesterSettings: {
        ...initialState.semesterSettings,
        loading: true,
      }
    }))
    .addCase(GET + SEMESTER + SETTINGS + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      semesterSettings: {
        ...initialState.semesterSettings,
        success: true,
        data: payload,
        // data: {
        //   ...payload,
        //   one: payload.one ? payload.one : 0,
        //   two: payload.two ? payload.two : 0,
        //   three: payload.three ? payload.three : 0,
        //   four: payload.four ? payload.four : 0,
        //   five: payload.five ? payload.five : 0,
        //   six: payload.six ? payload.six : 0,
        //   seven: payload.seven ? payload.seven : 0,
        //   eight: payload.eight ? payload.eight : 0,
        // }
      }
    }))
    .addCase(GET + SEMESTER + SETTINGS + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      semesterSettings: {
        ...initialState.semesterSettings,
        failed: true,
      }
    }))

    .addCase(SEMESTER + SETTINGS + EDIT + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      semesterSettings: {
        ...initialState.semesterSettings,
        loading: true,
        data: state.semesterSettings.data,
      }
    }))
    .addCase(SEMESTER + SETTINGS + EDIT + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      semesterSettings: {
        ...initialState.semesterSettings,
        success: true,
        data: payload,
      }
    }))
    .addCase(SEMESTER + SETTINGS + EDIT + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      semesterSettings: {
        ...state.semesterSettings,
        loading: false,
        failed: true,
      }
    }))

    .addCase(RUP + ONE + ADD + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRupTable: {
        ...state.currentRupTable,
        loading: true,
      },
      cloneRupTable: {
        ...state.cloneRupTable,
        loading: true,
      }
    }))
    .addCase(RUP + ONE + ADD + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRupTable: {
        ...state.currentRupTable,
        loading: false,
        failed: true,
      },
      cloneRupTable: {
        ...state.cloneRupTable,
        loading: false,
        failed: true,
      }
    }))

    .addCase(RUP + ADD + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRupTable: {
        ...state.currentRupTable,
        loading: true,
      },
      cloneRupTable: {
        ...state.cloneRupTable,
        loading: true,
      },
    }))
    .addCase(RUP + ADD + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRupTable: {
        ...state.currentRupTable,
        loading: false,
        failed: true,
      },
      cloneRupTable: {
        ...state.cloneRupTable,
        loading: false,
        failed: true,
      }
    }))

    .addCase(RUP + MODULE + DISCIPLINES + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRupTable: {
        ...state.currentRupTable,
        loading: true,
      },
      cloneRupTable: {
        ...state.cloneRupTable,
        loading: true,
      }
    }))
    .addCase(RUP + MODULE + DISCIPLINES + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRupTable: {
        ...state.currentRupTable,
        loading: false,
        failed: true,
      },
      cloneRupTable: {
        ...state.cloneRupTable,
        loading: false,
        failed: true,
      }
    }))

    .addCase(RUP + DELETE + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRupTable: {
        ...initialState.currentRupTable,
        loading: true,
        data: state.currentRupTable.data
      },
      cloneRupTable: {
        ...initialState.cloneRupTable,
        loading: true,
        data: state.cloneRupTable.data
      }
    }))
    // .addCase(RUP + DELETE + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
    //   ...state,
    //   currentRupTable: {
    //     ...initialState.currentRupTable,
    //     success: true,
    //     data: state.currentRupTable.data.filter(item => item.key !== payload).filter(item => item.parent_id !== payload)
    //   }
    // }))
    .addCase(RUP + DELETE + FETCH+ FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRupTable: {
        ...initialState.currentRupTable,
        failed: true,
        data: state.currentRupTable.data
      },

      cloneRupTable: {
        ...initialState.cloneRupTable,
        failed: true,
        data: state.cloneRupTable.data
      }
    }))

    .addCase(RUP + EDIT + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRupTable: {
        ...initialState.currentRupTable,
        success: true,
        data: state.currentRupTable.data
        // data: state.currentRupTable.data.map(item => {
        //   let payloadElement = payload.find(elem => elem.id === item.key)
        //
        //   if(payloadElement){
        //     return {
        //       ...item,
        //       [payloadElement.key]: payloadElement.value
        //     }
        //   } else {
        //     return item
        //   }
        // })
      },
      cloneRupTable: {
        ...initialState.cloneRupTable,
        success: true,
        data: state.cloneRupTable.data
        // data: state.cloneRupTable.data.map(item => {
        //   let payloadElement = payload.find(elem => elem.id === item.key)
        //
        //   if(payloadElement){
        //     return {
        //       ...item,
        //       [payloadElement.key]: payloadElement.value
        //     }
        //   } else {
        //     return item
        //   }
        // })
      }
    }))
    .addCase(RUP + EDIT + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      currentRupTable: {
        ...initialState.currentRupTable,
        failed: true,
        data: state.currentRupTable.data
      },
      cloneRupTable: {
        ...initialState.cloneRupTable,
        failed: true,
        data: state.cloneRupTable.data
      }
    }))

    .addCase(GET + RUP + TYPES + INFO + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rupTypesTranslate: {
        ...initialState.rupTypesTranslate,
        loading: true,
      }
    }))
    .addCase(GET + RUP + TYPES + INFO + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rupTypesTranslate: {
        ...initialState.rupTypesTranslate,
        success: true,
        data: payload
      }
    }))
    .addCase(GET + RUP + TYPES + INFO + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rupTypesTranslate: {
        ...initialState.rupTypesTranslate,
        failed: true,
      }
    }))

    .addCase(GET + RUP + INFO + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rupTranslate: {
        ...initialState.rupTranslate,
        loading: true
      }
    }))
    .addCase(GET + RUP + INFO + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rupTranslate: {
        ...initialState.rupTranslate,
        success: true,
        data: payload
      }
    }))
    .addCase(GET + RUP + INFO + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      rupTranslate: {
        ...initialState.rupTranslate,
        failed: null
      }
    }))
)