import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Card, Col, Row, Select, Button, Table, Tooltip } from "antd";
import {
  MyBreadcrumb,
  FirstLevelTabs,
  SecondLevelTabs,
} from "../../components";
import "./Schedule.scss";
import { ScheduleData } from "./ScheduleInterface";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { HeadersAuth } from "../../constants/Auth";
import { customNoDataText, daysOfWeek } from "./ScheduleConst";
import { ScheduleList } from "./ScheduleCreate/ScheduleList";
import { ScheduleSettings } from "./ScheduleCreate/ScheduleSettings";

export const Schedule = () => {
  const { t } = useTranslation("common");
  const [scheduleData, setScheduleData] = useState<ScheduleData>({});
  useEffect(() => {
    async function fetchScheduleData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/schedule/v1/getSchedulesByDayAndGroup?groupId=3`,
          HeadersAuth
        );
        if (response.status === 200) {
          setScheduleData(response.data);
        }
      } catch (error) {
        console.error("Не удалось получить данные", error);
      }
    }
    fetchScheduleData();
  }, []);

  const renderTables = () => {
    return daysOfWeek.map((day) => {
      const dayData = scheduleData[day.english]?.schedules || [];
      const dateOfMonth = scheduleData[day.english]?.date_of_month || "";

      return (
        <Col key={day.english} span={8}>
          <Table
            dataSource={dayData}
            columns={[
              {
                title: (
                  <span>
                    {day.russian}, {dateOfMonth}
                  </span>
                ),
                dataIndex: day.english,
                key: day.english,
                colSpan: 5,
                render: (_, record) => (
                  <span>{`${record.start_time} - ${record.end_time}`}</span>
                ),
              },
              {
                dataIndex: "class_type",
                key: "class_type",
                colSpan: 0,
                render: (class_type) => (
                  <span style={{ color: "#773DBD", fontWeight: "600" }}>
                    {class_type?.type_name || ""}
                  </span>
                ),
              },
              {
                title: "Classroom & Discipline",
                key: "classroom_discipline",
                dataIndex: "",
                colSpan: 0,
                render: (_, record) => (
                  <span style={{ color: "#773DBD", fontWeight: "600" }}>
                    {record.classroom_title} - {record.discipline_title}
                  </span>
                ),
              },
              {
                dataIndex: "teacher_name",
                key: "teacher_name",
                colSpan: 0,
              },
            ]}
            pagination={false}
            locale={customNoDataText}
          />
        </Col>
      );
    });
  };

  const secondLevelTabsSchedule = [
    {
      key: "1",
      label: <div style={{ cursor: "pointer" }}>По преподавателям</div>,
      children: (
        <>
          <Content
            style={{
              width: "100%",
              height: "500px",
              backgroundColor: "white",
              marginTop: "0px",
              borderRadius: "0px 8px 8px 8px",
            }}
          >
            <p />
            <Row className="Schedule_header" style={{ paddingRight: "20px" }}>
              <Col span={24}>
                <Select
                  className="Schedule_header_select"
                  placeholder="Преподаватели"
                ></Select>
                <Button
                  className="Schedule_header_button"
                  size="middle"
                  type="primary"
                >
                  Поиск
                </Button>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: "20px",
                position: "absolute",
                padding: "20px 0px 20px 0px",
              }}
              className="schedule_module"
              gutter={[10, 10]}
            >
              {renderTables()}
            </Row>
          </Content>
        </>
      ),
    },
    {
      key: "2",
      label: <div style={{ cursor: "pointer" }}>По группам</div>,
      children: (
        <>
          <Content
            style={{
              width: "100%",
              height: "500px",
              backgroundColor: "white",
              marginTop: "0px",
              borderRadius: "0px 8px 8px 8px",
            }}
          >
            <p />
            <Row className="Schedule_header" style={{ paddingRight: "20px" }}>
              <Col span={24}>
                <Select
                  className="Schedule_header_select"
                  placeholder="Группа"
                ></Select>
                <Button
                  className="Schedule_header_button"
                  size="middle"
                  type="primary"
                >
                  Поиск
                </Button>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: "20px",
                position: "absolute",
                padding: "20px 0px 20px 0px",
              }}
              className="schedule_module"
              gutter={[10, 10]}
            >
              {renderTables()}
            </Row>
          </Content>
        </>
      ),
    },
  ];

  const firstLevelTabs = [
    {
      key: "1",
      label: <div style={{ cursor: "pointer" }}>Расписание</div>,
      children: (
        <SecondLevelTabs defaultActiveKey="2" items={secondLevelTabsSchedule} />
      ),
    },
    {
      key: "2",
      label: <div style={{ cursor: "pointer" }}>Составление расписания</div>,
      children: <ScheduleList />,
    },
    {
      key: "3",
      label: "Настройки",
      children: <ScheduleSettings />,
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Расписание" />
      <Row justify="center">
        <Col span={23}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="1" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>
      <p />
    </>
  );
};

export default Schedule;
