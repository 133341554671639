import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store";
import { selectStore } from "../../../store/selector";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Row,
  Spin,
  Table,
  Tabs,
  TabsProps,
  Tooltip,
  Input,
  Cascader,
  Form,
  Alert,
  Segmented,
  Select,
  message,
} from "antd";
import {
  SecondLevelTabs,
  JournalTable,
  MyBreadcrumb,
  MyButton,
  MyText,
  FirstLevelTabs,
} from "../../../components";
import { setThemeColor } from "../../../utils/setThemeColor";
import { useTheme } from "../../../hooks";
import { position } from "polished";
import "../Speciality.scss";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { COOKIES_ENUM } from "../../../constants";
import { Cookies } from "react-cookie";
import {
  SpecialityVM,
  QualificationVM,
} from "../../InterfacesApi/InterfacesApi";
import { HeadersAuth } from "../../../constants/Auth";

export const SpecialityCreate = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [specialities, setSpecialities] = useState<SpecialityVM[]>([]);
  const [qualifications, setQualifications] = useState<QualificationVM[]>([]);
  const [selectedSpecialityId, setSelectedSpecialityId] = useState<
    number | null
  >(null);
  const [selectedQualificationId, setSelectedQualificationId] = useState<
    number | null
  >(null);

  const handleInputChange = (inputName) => (value) => {
    if (inputName === "educationProfile" || inputName === "eduDirection") {
      const selectedSpeciality = specialities.find(
        (spec) => spec.title === value
      );
      setInputStates((prevInputStates) => ({
        ...prevInputStates,
        [inputName]: !!value,
      }));
      setSelectedSpecialityId(
        selectedSpeciality ? selectedSpeciality.id : null
      );
      const selectedQualification = qualifications.find(
        (qual) => qual.title === value
      );
      setSelectedQualificationId(
        selectedQualification ? selectedQualification.id : null
      );
    }
  };

  const [inputStates, setInputStates] = useState({
    educationProfile: false,
    eduDirection: false,
  });

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/specialitiesIndex`,
        HeadersAuth
      )
      .then((response) => {
        setSpecialities(response.data);
      })
      .catch((error) => {
        console.error("Ошибка получения специальностей:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedSpecialityId) {
      axios
        .get(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/qualificationsIndexAdd?speciality_id=${selectedSpecialityId}`,
          HeadersAuth
        )
        .then((response) => {
          setQualifications(response.data);
        })
        .catch((error) => {
          console.error("Ошибка получения квалификации:", error);
        });
    }
  }, [selectedSpecialityId]);

  const handleAddQualification = () => {
    if (selectedQualificationId) {
      const payload = {
        college_id: 1,
        qualification_id: selectedQualificationId,
      };
      axios
        .post(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/college-qualifications?college_id=1&qualification_id=${payload.qualification_id}`,
          payload,
          HeadersAuth
        )
        .then((response) => {
          message.success("Квалификация успешно добавлена");
          navigate("/speciality");
        })
        .catch((error) => {
          message.error("Ошибка при добавлении квалификации");
        });
    }
  };

  const redirectToKafedra = () => {
    navigate("/institution_department/create");
  };
  const redirectToInstitutionDiscipline = () => {
    navigate("/institution_discipline/create");
  };
  const redirectGroup = () => {
    navigate("/group/create");
  };
  const firstLevelTabs = [
    {
      key: "1",
      label: "Специальности",
      children: (
        <Content
          style={{
            width: "100%",
            height: "400px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row>
              <Col span={12} style={{ paddingLeft: "20px", marginTop: "10px" }}>
                <span style={{ fontWeight: "500", fontSize: "14px" }}>
                  Специальность
                </span>{" "}
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.educationProfile ? "success" : ""}
                >
                  <Select
                    style={{
                      width: "100%",
                      ...(inputStates.educationProfile
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    onChange={handleInputChange("educationProfile")}
                  >
                    {specialities.map((spec) => (
                      <Select.Option key={spec.id} value={spec.title}>
                        {spec.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <p />
            <Row>
              <Col span={12} style={{ paddingLeft: "20px", marginTop: "20px" }}>
                <span style={{ fontWeight: "500", fontSize: "14px" }}>
                  Квалификация
                </span>{" "}
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.eduDirection ? "success" : ""}
                >
                  <Select
                    style={{
                      width: "100%",
                      ...(inputStates.eduDirection
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    onChange={handleInputChange("eduDirection")}
                  >
                    {qualifications.map((qualification) => (
                      <Select.Option
                        key={qualification.id}
                        value={qualification.title}
                      >
                        {qualification.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Divider style={{ marginTop: "67px" }} plain />

              <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => navigate("/speciality")}
                  style={{
                    background: "#F6F1FF",
                    color: "#773DBD",
                    height: "40px",
                    lineHeight: "0px",
                    marginRight: "17px",
                  }}
                >
                  Назад
                </Button>
                <Button
                  className="CreateButton"
                  onClick={handleAddQualification}
                >
                  Добавить
                </Button>
              </Col>
            </Row>
          </Form>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={redirectToInstitutionDiscipline}
          style={{ cursor: "pointer" }}
        >
          Дисциплины
        </div>
      ),
      children: "",
    },
    {
      key: "3",
      label: (
        <div onClick={redirectGroup} style={{ cursor: "pointer" }}>
          Группы
        </div>
      ),
      children: "",
    },
    {
      key: "4",
      label: (
        <div onClick={redirectToKafedra} style={{ cursor: "pointer" }}>
          Кафедры
        </div>
      ),
      children: (
        <Row>
          <Col span={24}>
            <SecondLevelTabs />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Добавить квалификацию" />
      <Row justify="center">
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="1" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
