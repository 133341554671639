import {createAction} from "@reduxjs/toolkit";

import {
  FETCH,
  DONE,
  FAIL,
  LESSON,
  VIEW,
  FILTER,
  SELECT,
  GET,
  PERIOD,
  RESET,
  ADD,
  FORM,
  POST,
  PLAN,
  ONE,
  DELETE,
  INNER,
  LIST,
  EDIT,
  CTP,
  TEST,
  ANSWER,
  DATA,
  CONTENT, COPY, RESULT,
} from "../../constants";
import {IPlanType} from "./types";

export const getOneCtpAction = createAction<any>(GET + CTP + VIEW + FETCH);
export const getOneCtpActionDone = createAction<any>(GET + CTP + VIEW + FETCH + DONE);
export const getOneCtpActionFail = createAction<undefined>(GET + CTP + VIEW + FETCH + FAIL);

export const getListAction = createAction<IPlanType>(GET + LIST + FETCH);
export const getListActionDone = createAction<IPlanType>(GET + LIST + FETCH + DONE);
export const getListActionDoneData = createAction<IPlanType>(GET + LIST + FETCH + DONE + DATA);
export const getListActionFail = createAction<IPlanType>(GET + LIST + FETCH + FAIL);

export const getLessonAction = createAction<any>(LESSON + VIEW + FETCH);
export const getLessonActionDone = createAction<any>(LESSON + VIEW + FETCH + DONE);
export const getLessonActionFail = createAction<undefined>(LESSON + VIEW + FETCH + FAIL);

export const getOneLessonAction = createAction<any>(LESSON + VIEW + ONE + FETCH);
export const getOneLessonActionDone = createAction<any>(LESSON + VIEW + ONE + FETCH + DONE);
export const getOneLessonActionFail = createAction<undefined>(LESSON + VIEW + ONE + FETCH + FAIL);

export const addLessonAction = createAction<undefined>(LESSON + ADD + FETCH);
export const addLessonActionDone = createAction<any>(LESSON + ADD + FETCH + DONE);
export const addLessonActionFail = createAction<undefined>(LESSON + ADD + FETCH + FAIL);

export const editLessonAction = createAction<undefined>(LESSON + EDIT + FETCH);
export const editLessonActionDone = createAction<any>(LESSON + EDIT + FETCH + DONE);
export const editLessonActionFail = createAction<undefined>(LESSON + EDIT + FETCH + FAIL);

export const addOneLessonAction = createAction<undefined>(LESSON + ONE + ADD + FETCH);
export const addOneLessonActionDone = createAction<any>(LESSON + ONE + ADD + FETCH + DONE);
export const addOneLessonActionFail = createAction<undefined>(LESSON + ONE + ADD + FETCH + FAIL);

export const deleteOneLessonAction = createAction<any>(LESSON + DELETE + FETCH);
export const deleteOneLessonActionDone = createAction<any>(LESSON + DELETE + FETCH + DONE);
export const deleteOneLessonActionFail = createAction<undefined>(LESSON + DELETE + FETCH + FAIL);

export const addQuestionAnswerAction = createAction<any>(ADD + TEST + ANSWER + FETCH);
export const addQuestionAnswerActionDone = createAction<undefined>(ADD + TEST + ANSWER + FETCH + DONE);
export const addQuestionAnswerActionFail = createAction<undefined>(ADD + TEST + ANSWER + FETCH + FAIL);

export const getQuestionAnswerAction = createAction<any>(GET + TEST + ANSWER + FETCH);
export const getQuestionAnswerActionDone = createAction<undefined>(GET + TEST + ANSWER + FETCH + DONE);
export const getQuestionAnswerActionFail = createAction<undefined>(GET + TEST + ANSWER + FETCH + FAIL);

export const updateTabInnerContentAction = createAction<any>(EDIT + INNER + CONTENT + FETCH);
export const updateTabInnerContentActionDone = createAction<undefined>(EDIT + INNER + CONTENT + FETCH + DONE);
export const updateTabInnerContentActionFail = createAction<undefined>(EDIT + INNER + CONTENT + FETCH + FAIL);

export const deleteTabInnerContentAction = createAction<any>(DELETE + INNER + CONTENT + FETCH);
export const deleteTabInnerContentActionDone = createAction<undefined>(DELETE + INNER + CONTENT + FETCH + DONE);
export const deleteTabInnerContentActionFail = createAction<undefined>(DELETE + INNER + CONTENT + FETCH + FAIL);

export const copyPlanAction = createAction<any>(COPY + PLAN + FETCH);
export const copyPlanActionDone = createAction<undefined>(COPY + PLAN + FETCH + DONE);
export const copyPlanActionFail = createAction<undefined>(COPY + PLAN + FETCH + FAIL);

export const testResultAction = createAction<any>(TEST + RESULT + FETCH);
export const testResultActionDone = createAction<undefined>(TEST + RESULT + FETCH + DONE);
export const testResultActionFail = createAction<undefined>(TEST + RESULT + FETCH + FAIL);
