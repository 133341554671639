import React, {useCallback, useEffect, useMemo, useState} from "react";
import {MyBreadcrumb} from "../../../../../components";
import {Col, Row, Input, Checkbox, Form, Button, Spin, message, TabPaneProps, Card} from "antd";
import {StyledInfoContainer} from "../../index.styles";
import {DeleteOutlined, MoreOutlined} from "@ant-design/icons";
import {TabsStyles} from "./index.styles";
import {addQuestionAnswerAction, getQuestionAnswerAction} from "../../../../../store/pages/plans/actions";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {selectStore} from "../../../../../store/selector";
import {RootState} from "../../../../../store";
import {useTranslation} from "react-i18next";
import {useTheme} from "../../../../../hooks";
import {setThemeColor} from "../../../../../utils/setThemeColor";
import {DefaultTokenTheme} from "../../../../../assets/themes/defaultTheme";

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

export const Test = () => {
  const {t} = useTranslation("common")
  const {theme} = useTheme()
  const [testForm] = Form.useForm()
  const dispatch = useDispatch()
  const {planId, lessonId, testId} = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()

  const isRefreshed = searchParams.get('refreshed')

  //todo это пиздец юлятьб я настолько преисполнился от этого пиздеца что аж ахуеть не встать
  useEffect(() => {
    dispatch(getQuestionAnswerAction(testId))

    if (isRefreshed) {
      console.log(searchParams.get('refreshed'))

    } else {
      setSearchParams((prev: any) => ([...prev.entries(), ['refreshed', 'true']]))

      const time = setTimeout(() => {
        window.location.reload()
      }, 20)

      return () => {
        clearTimeout(time)
      }
    }
  }, [])

  const FORM_ITEM_NAME = {
    ID: 'id',
    TEST_ID: 'test_id',
    NAME: 'name',
    ANSWERS: 'answers',
    QUESTIONS: 'questions',
    IS_CORRECT: 'is_correct',
  }

  let initialState = {
    [FORM_ITEM_NAME.ID]: null,
    [FORM_ITEM_NAME.NAME]: t('question'),
    [FORM_ITEM_NAME.ANSWERS]: [{
      [FORM_ITEM_NAME.ID]: null,
      [FORM_ITEM_NAME.NAME]: null,
      [FORM_ITEM_NAME.IS_CORRECT]: false
    }]
  }

  const {plans: {oneTest: testState}}: RootState = useSelector(selectStore)

  const [tabsItem, setTabsItems] = useState<any>([]);
  const [activeKey, setActiveKey] = useState<any>();

  const formItemRender = useCallback((item: any, key: any) => {
    return (
      <Row gutter={[16, 16]} style={{padding: 16}}>
        <Col span={24}>
          <Form.Item name={[key, FORM_ITEM_NAME.ID]} initialValue={item[FORM_ITEM_NAME.ID]} hidden>
            <Input hidden/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item labelCol={{span: 4}} labelAlign='left' wrapperCol={{span: 20}} name={[key, FORM_ITEM_NAME.NAME]}
                     label={t('enterQuestion')}
                     rules={[{
                       validator: async (field, name) => {
                         if (!name) {
                           return Promise.reject(new Error(t('enterQuestion')));
                         }
                       }
                     }]} initialValue={item[FORM_ITEM_NAME.NAME]}>
            <Input placeholder={t('question')}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item labelCol={{span: 4}} labelAlign='left' wrapperCol={{span: 20}} label={t('answers')}
                     name={[key, FORM_ITEM_NAME.ANSWERS]} initialValue={item[FORM_ITEM_NAME.ANSWERS]} rules={[

            ({getFieldValue}) => ({
              validator: async (answer, answers) => {
                let checkIsCorrect = answers?.filter((item: any) => item[FORM_ITEM_NAME.IS_CORRECT] === true)?.length === 0
                let checkText = answers?.filter((item: any) => item[FORM_ITEM_NAME.NAME])?.length !== answers?.length
                let checkIsAnswers = !answers || answers?.length < 1

                if (checkIsAnswers) {
                  return Promise.reject(new Error(t('testErrorEmptyAnswers')))
                }

                if (checkIsCorrect) {
                  return Promise.reject(new Error(t('testErrorEmptyRightAnswer')))
                }

                if (checkText) {
                  return Promise.reject(new Error(t('testErrorEmptyAnswerText')))
                }
              },
            })

          ]}>
            <Form.List name={[key, 'answers']}>
              {
                (subFields, subOpt) => {
                  return (
                    <Row gutter={[16, 16]}>
                      {subFields.map((subField) => {
                        return (
                          <Col key={subField.key} span={24}>
                            <Row justify='space-between' align='middle'>
                              <Col span={20}>
                                <Form.Item noStyle name={[subField.name, FORM_ITEM_NAME.NAME]} required={true}
                                           key={subField.key + 'input'}>
                                  <Input placeholder={t('answerVariant')}/>
                                </Form.Item>
                              </Col>
                              <Col>
                                <Form.Item noStyle name={[subField.name, FORM_ITEM_NAME.IS_CORRECT]}
                                           label={subField.name}
                                           valuePropName="checked" initialValue={false} key={subField.key + 'checkbox'}
                                           required={true}>
                                  <Checkbox/>
                                </Form.Item>
                              </Col>

                              <Col>
                                <Button type='text' icon={<DeleteOutlined style={{color: DefaultTokenTheme.error}}/>} onClick={() => {
                                  subOpt.remove(subField.name);
                                }}/>
                              </Col>
                            </Row>
                          </Col>
                        )
                      })}
                      {
                        subFields.length <= 4 &&
                          <Col span={24}>
                              <Button type="dashed" onClick={() => {
                                subOpt.add()
                              }} block>
                                {t('addAnswerVariant')}
                              </Button>
                          </Col>
                      }
                    </Row>
                  )
                }
              }
            </Form.List>
          </Form.Item>
        </Col>
      </Row>
    )
  }, [theme])

  useEffect(() => {
    if (testState?.data?.questions) {
      let arr = testState.data?.questions?.length > 0 ? testState.data?.questions : [initialState]

      const test: TabPaneProps[] = arr.map((item: any, index: any) => {
        return {
          key: index,
          label: item[FORM_ITEM_NAME.NAME],
          children: formItemRender(item, index),
          forceRender: true
        }
      })

      setTabsItems(test)
    }
  }, [testState?.data])


  const onChangeTab = (newActiveKey: number) => {
    setActiveKey(newActiveKey);
  }


  const addTab = () => {
    const newActiveKey = tabsItem.length
    const newPanes = [...tabsItem];

    newPanes.push({
      label: t('question'),
      children: formItemRender(initialState, newActiveKey),
      key: newActiveKey,
    });

    setTabsItems(newPanes);
    setActiveKey(newActiveKey);
  }

  const removeTab = (targetKey: TargetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;

    tabsItem.forEach((item: any, i: any) => {
      if (item?.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes: any = tabsItem.filter((item: any) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setTabsItems(newPanes);
    setActiveKey(newActiveKey);
  }

  const onEditTab = (targetKey: TargetKey, action: 'add' | 'remove') => {
    if (action === 'add') {
      addTab()
    } else {
      removeTab(targetKey)
    }
  }

  const onFinish = (values: any) => {
    console.log('values', values)
    testForm.validateFields().then(() => {
      dispatch(addQuestionAnswerAction(values))
    })
  }

  const onValuesChange = (changedValues: any, allValues: any) => {
    let currentKey: any = Object.keys(changedValues)[0]
    let currentValue: any = Object.values(changedValues)[0]

    if (currentValue.name) {
      setTabsItems((prevState: any) => {
        return prevState.map((item: any) => item.key === currentKey ? {
          ...item,
          label: currentValue[FORM_ITEM_NAME.NAME]
        } : item)
      })
    }

  }

  const formRender = () => {
    if (tabsItem.length > 0) {
      return (
        <Form
          autoComplete="off"
          form={testForm}
          name="form"
          onFinish={onFinish}
          onValuesChange={(changedValues, allValues) => onValuesChange(changedValues, allValues)}
          onFinishFailed={({values, errorFields, outOfDate}) => {
            let findError = errorFields.map((item: any) => item[FORM_ITEM_NAME.NAME][0])
            let findUnique = findError.filter((value, index, array) => array.indexOf(value) === index)
            let findByError = tabsItem.filter((item: any) => findUnique.includes(item?.key))
            message.error(`${t('errorOnTab')} ${findByError.map((item: any) => item.label).join(', ')}`)
          }}
        >

          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Form.Item name={FORM_ITEM_NAME.TEST_ID} initialValue={testId} hidden>
                <Input hidden/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.List name={FORM_ITEM_NAME.QUESTIONS}>
                {
                  (fields, operation, meta) => {
                    return (
                      <TabsStyles
                        type="editable-card"
                        onChange={(activeKey) => onChangeTab(parseInt(activeKey))}
                        activeKey={activeKey}
                        onEdit={(targetKey, action) => onEditTab(targetKey, action)}
                        items={tabsItem}
                        moreIcon={<MoreOutlined style={{
                          width: 20,
                          height: 20,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}/>}
                      />
                    )
                  }
                }
              </Form.List>
            </Col>
            <Col span={24}>
              <Row justify='center'>
                <Col>
                  <Form.Item>
                    <Button htmlType='submit'>{t('save')}</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )
    }
  }

  return (
    <>
      <MyBreadcrumb title={t('back')} isBack link={`/plans/${planId}/${lessonId}/`}/>

      <Row justify='center'>
        <Col span={20}>
          <Card loading={testState.loading} bodyStyle={{backgroundColor: setThemeColor(theme, '#fff', DefaultTokenTheme.plateDark)}}>
            {formRender()}
          </Card>
        </Col>
      </Row>
    </>
  )
}
