import {createAction} from "@reduxjs/toolkit";

import {
  FETCH,
  DONE,
  FAIL,
  PROFILE,
  AUTH, GET, MENU, NOTIFICATION, SET, DASHBOARD
} from "../../constants";

export const getMenuAction = createAction<undefined>(GET + MENU + FETCH);
export const getMenuActionDone = createAction<any[]>(GET + MENU + FETCH + DONE);
export const getMenuActionFail = createAction<undefined>(GET + MENU + FETCH + FAIL);

export const fetchAuth = createAction<any>(AUTH + FETCH)
export const fetchAuthDone = createAction<any>(AUTH + FETCH + DONE)
export const fetchAuthFail = createAction<undefined>(AUTH + FETCH + FAIL)

export const fetchUserProfileAction = createAction<undefined>(PROFILE + FETCH);
export const fetchUserProfileActionDone = createAction<any>(PROFILE + FETCH + DONE);
export const fetchUserProfileActionFail = createAction<undefined>(PROFILE + FETCH + FAIL);

export const fetchGetNotificationAction = createAction<undefined>(GET + NOTIFICATION + FETCH);
export const fetchGetNotificationActionDone = createAction<any>(GET + NOTIFICATION + FETCH + DONE);
export const fetchGetNotificationActionFail = createAction<undefined>(GET + NOTIFICATION + FETCH + FAIL);

export const fetchSetNotificationAction = createAction<undefined>(SET + NOTIFICATION + FETCH);
export const fetchSetNotificationActionDone = createAction<any>(SET + NOTIFICATION + FETCH + DONE);
export const fetchSetNotificationActionFail = createAction<undefined>(SET + NOTIFICATION + FETCH + FAIL);

export const fetchGetDashboardAction = createAction<undefined>(GET + DASHBOARD + FETCH);
export const fetchGetDashboardActionDone = createAction<any>(GET + DASHBOARD + FETCH + DONE);
export const fetchGetDashboardActionFail = createAction<undefined>(GET + DASHBOARD + FETCH + FAIL);
