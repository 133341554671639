import React, { createContext, useContext, useEffect, useState } from "react";
import { IAuth } from "../types";
import { fetchAuth } from "../store/pages/profile/actions";
import { useDispatch, useSelector } from "react-redux";
import { Cookies } from "react-cookie";
import { COOKIES_ENUM } from "../constants";
import { getFromUrl } from "../utils/getFromUrl";
import { selectStore } from "../store/selector";
import { RootState } from "../store";
import { message } from "antd";

const cookies = new Cookies();

const initialState: IAuth = {
  isAuth: false,
  role: "",
  handleLogin: (values) => new Promise(() => {}),
  handleLogout: () => new Promise(() => {}),
  menu: [],
};

/**
 * @description set token cookie
 */
export const setTokenCookie = (accesstoken: string | null) => {
  cookies.set(COOKIES_ENUM.ACCESSTOKEN, accesstoken, { path: "/", domain: "" });
};

const AuthContext = createContext<IAuth>(initialState);
export const AuthProvider = ({ children }: any) => {
  const dispatch = useDispatch();

  const {
    profile: { menu: menuState, userProfile: userProfileState },
  }: RootState = useSelector(selectStore);

  const [isAuth, setIsAuth] = useState<boolean>(
    !!cookies.get(COOKIES_ENUM.ACCESSTOKEN)
  );
  const [menu, setMenu] = useState<any[]>(menuState.data);
  const [role, setRole] = useState<string>("");

  useEffect(() => {
    if (menuState.success) {
      setMenu(menuState.data);
    }
  }, [menuState.success]);

  useEffect(() => {
    if (userProfileState.success) {
      setRole(userProfileState.data?.person_type);
    }
  }, [userProfileState.success]);

  useEffect(() => {
    const cookiesHandler = (options: any) => {
      if (options.name === COOKIES_ENUM.ACCESSTOKEN) {
        if (options.value !== undefined) {
          setIsAuth(true);
        } else {
          setIsAuth(false);
        }
      }
    };

    cookies.addChangeListener((options) => {
      cookiesHandler(options);
    });

    return () => {
      cookies.removeChangeListener((options) => {
        cookiesHandler(options);
      });
    };
  }, []);

  useEffect(() => {
    if (getFromUrl(COOKIES_ENUM.ACCESSTOKEN)) {
      handleSetJwt(getFromUrl(COOKIES_ENUM.ACCESSTOKEN));
    }
  }, []);

  const handleLogin = async (values: any) => {
    dispatch(fetchAuth(values));
  };

  const handleSetJwt = (accesstoken: string | null) => {
    setTokenCookie(accesstoken);
    setIsAuth(true);
  };

  const handleLogout = async () => {
    setIsAuth(false);
    await cookies.remove(COOKIES_ENUM.ACCESSTOKEN, {
      path: "/",
      domain: "", // здесь надо будет вставлять домен фронта для выхода из аккаунта
    });
    window.location.replace(`/auth`);
  };

  return (
    <AuthContext.Provider
      value={{ isAuth, handleLogin, handleLogout, menu, role }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
