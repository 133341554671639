import { message } from "antd";
import { setTokenCookie } from "../../../hooks/useAuth";
import {axiosConfig as axios} from "../../../utils/axios";
import { HeadersAuth } from "../../../constants/Auth";

let url = 'api'
export const apiAuth = (body) => {
  return axios.post(`${url}/person/v1/person_unit/login`, body)
    .then(({ data }) => {
      setTokenCookie(data.accesstoken);
      window.location.replace('/');
      return data;
    })
    .catch((error) => {
      console.error('Ошибка авторизации!', error);
      throw error;
    });
};

export const apiGetUserProfile = () => axios.get(`${url}/person/v1/get_info`)
export const apiGetMenuUser = () => axios.get(`${process.env.REACT_APP_FULLSTACK_URL}/api/common/v1/nav_bar/get_prepared_items`, HeadersAuth)

// export const apiGetNotification = () => axios.get(`${url}/notification/unread`)

// export const apiSetNotification = () => axios.get(`${url}/notification/unread/read`)

// export const apiGetDashboard = () => axios.get(`${url}/dashboard`)

export const apiSetAuth = (body: any) => axios.options(`auth`, {
  headers: body,
})
