import {call, put, select, takeEvery, takeLatest} from "redux-saga/effects";
import {
  apiDeleteOneLesson,
  apiGetOneLesson, apiGetPlanTest,
  apiLesson, apiOneLesson, apiPostPlanTest,
} from "./api";
import {
  addLessonAction,
  addLessonActionDone,
  addLessonActionFail,
  addOneLessonAction,
  addOneLessonActionDone,
  addOneLessonActionFail,
  addQuestionAnswerAction,
  addQuestionAnswerActionDone,
  addQuestionAnswerActionFail,
  copyPlanAction,
  copyPlanActionDone,
  copyPlanActionFail,
  deleteOneLessonAction,
  deleteOneLessonActionDone,
  deleteOneLessonActionFail,
  deleteTabInnerContentAction,
  deleteTabInnerContentActionDone,
  deleteTabInnerContentActionFail,
  editLessonAction,
  editLessonActionDone,
  editLessonActionFail,
  getLessonAction,
  getLessonActionDone,
  getLessonActionFail,
  getListAction,
  getListActionDone,
  getListActionDoneData,
  getListActionFail,
  getOneCtpAction,
  getOneCtpActionDone,
  getOneCtpActionFail,
  getOneLessonAction,
  getOneLessonActionDone,
  getOneLessonActionFail,
  getQuestionAnswerAction,
  getQuestionAnswerActionDone,
  getQuestionAnswerActionFail, testResultAction, testResultActionDone, testResultActionFail,
  updateTabInnerContentAction,
  updateTabInnerContentActionDone,
} from "./actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {apiGetCtp} from "../../common/api";
import {message} from "antd";
import {RootState} from "../../index";
import {selectStore} from "../../selector";
import {IPlanType} from "./types";
import {getPeriodByGroupAction, getPeriodByGroupActionReset} from "../../common/actions";

function* getOneCtpFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiOneLesson, payload);
    if (data.data) {
      yield put(getOneCtpActionDone(data.data));
      // @ts-ignore
      yield put(getPeriodByGroupAction({group_id: data.data?.group_id}))
    }
  } catch (e) {
    console.warn(e);
    yield put(getOneCtpActionFail());
  }
}

function* getListFlow({ payload }: PayloadAction<IPlanType>): any {
  const {plans: {list: dataState}}: RootState = yield select(selectStore)

  try {
    const data = yield call(apiGetCtp, payload);
    if (data.data) {
      yield put(getListActionDone(data.data));
    }
  } catch (e) {
    console.warn(e);
    yield put(getListActionFail(payload));
  }

}

function* getLessonFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiLesson, payload);
    if (data.data) {
      yield put(getLessonActionDone(data.data));
    }
  } catch (e) {
    console.warn(e);
    yield put(getLessonActionFail());
  }
}

function* getOneLessonFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiGetOneLesson, payload);
    if (data.data) {
      yield put(getOneLessonActionDone(data.data));
    }
  } catch (e) {
    console.warn(e);
    yield put(getOneLessonActionFail());
  }
}

function* addLessonFlow({payload}: PayloadAction<any>): any {
  const isDone = payload.success
  const isFail = payload.failed


  if (isDone) {
    message.success(`Урок "${payload.data.theme}" успешно добавлен!`)
    yield put(addLessonActionDone(payload));
  }

  if (isFail) {
    yield put(addLessonActionFail(payload));
  }
}

function* deleteOneLessonFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiDeleteOneLesson, payload);
    if (data.data) {
      yield put(deleteOneLessonActionDone(data.data));
    }
  } catch (e) {
    console.warn(e);
    yield put(deleteOneLessonActionFail());
  }
}

function* addOneLessonFlow({payload}: PayloadAction<any>): any {
  const isDone = payload.success
  const isFail = payload.failed

  if (isDone) {
    yield put(addOneLessonActionDone(payload));
    message.success(`${payload.data.name} успешно добавлен!`)
  }

  if (isFail) {
    yield put(addOneLessonActionFail(payload));
  }
}

function* editOneLessonFlow({payload}: PayloadAction<any>):any{
  const isDone = payload.success
  const isFail = payload.failed

  if(isDone){
    yield put(editLessonActionDone(payload))
  }

  if(isFail){
    yield put(editLessonActionFail(payload))
  }
}

function* addQuestionAnswerFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiPostPlanTest, payload);
    if (data.data) {
      yield put(addQuestionAnswerActionDone(data.data));
      message.success('Сохранено!')
    }
  } catch (e) {
    console.warn(e);
    yield put(addQuestionAnswerActionFail());
  }
}

function* getQuestionAnswerFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiGetPlanTest, payload);
    if (data.data) {
      yield put(getQuestionAnswerActionDone(data.data));
    }
  } catch (e) {
    console.warn(e);
    yield put(getQuestionAnswerActionFail());
  }
}

function* copyPlanFlow({payload}: PayloadAction<any>): any {
  const isDone = payload.success
  const isFail = payload.failed

  if (isDone) {
    message.success('Планы скопировались успешно!')
    yield put(copyPlanActionDone(payload));
  }

  if (isFail) {
    yield put(copyPlanActionFail(payload));
  }
}

function* updateTabInnerContentFlow({payload}: PayloadAction<any>): any {
  const isDone = payload.success
  if(isDone){
    // @ts-ignore
    yield put(updateTabInnerContentActionDone(payload));
  }
}

function* deleteTabInnerContentFlow({payload}: PayloadAction<any>): any {
  const isDone = payload.success
  const isFail = payload.failed

  if(isDone){
    // @ts-ignore
    yield put(deleteTabInnerContentActionDone(payload));
  }

  if(isFail){
    // @ts-ignore
    yield put(deleteTabInnerContentActionFail(payload));
  }
}

function* testResultFlow({payload}: PayloadAction<any>): any {
  const isDone = payload.success
  const isFail = payload.failed

  if(isDone){
    // @ts-ignore
    yield put(testResultActionDone(payload));
  }

  if(isFail){
    // @ts-ignore
    yield put(testResultActionFail(payload));
  }
}


export default function* plansSaga() {
  yield takeLatest(getOneCtpAction, getOneCtpFlow);
  yield takeEvery(getListAction, getListFlow);
  yield takeLatest(getLessonAction, getLessonFlow);
  yield takeLatest(getOneLessonAction, getOneLessonFlow);
  yield takeLatest(addLessonAction, addLessonFlow);
  yield takeLatest(editLessonAction, editOneLessonFlow);
  yield takeLatest(deleteOneLessonAction, deleteOneLessonFlow);
  yield takeLatest(addOneLessonAction, addOneLessonFlow);
  yield takeLatest(addQuestionAnswerAction, addQuestionAnswerFlow);
  yield takeLatest(getQuestionAnswerAction, getQuestionAnswerFlow);
  yield takeLatest(copyPlanAction, copyPlanFlow);
  yield takeLatest(updateTabInnerContentAction, updateTabInnerContentFlow);
  yield takeLatest(deleteTabInnerContentAction, deleteTabInnerContentFlow);
  yield takeLatest(testResultAction, testResultFlow);
}

