import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Empty,
  Row,
  Input,
  Form,
  message,
  Switch,
} from "antd";
import { useTheme } from "../../../hooks";
import "../../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { HeadersAuth } from "../../../constants/Auth";

export const BuildingCreate = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const { theme } = useTheme();

  const [inputStates, setInputStates] = useState({
    college_id: false,
    id: false,
    title: false,
    foundation_year: false,
    territory_area: false,
    building_area: false,
    owned_area: false,
    leased_area: false,
    educational_capacity: false,
    dining_capacity: false,
    gym_area: false,
    hall_area: false,
    dormitory_area: false,
    pcs_count: false,
    pcs_internet_access: false,
    interactive_sets: false,
  });
  const [attemptedSave, setAttemptedSave] = useState(false);

  const [formData, setFormData] = useState({
    college_id: 0,
    id: 0,
    title: "",
    foundation_year: 0,
    territory_area: 0,
    building_area: 0,
    owned_area: 0,
    leased_area: 0,
    educational_capacity: 0,
    dining_capacity: 0,
    gym_area: 0,
    hall_area: 0,
    dormitory_area: 0,
    pcs_count: 0,
    pcs_internet_access: 0,
    interactive_sets: 0,
  });

  const handleSave = async () => {
    try {
      await form.validateFields();
      setAttemptedSave(true);
      const response = await axios.post(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/auditfund/v1/createBuilding`,
        {
          college_id: 1,
          id: formData.id,
          title: formData.title,
          foundation_year: formData.foundation_year,
          territory_area: formData.territory_area,
          building_area: formData.building_area,
          owned_area: formData.owned_area,
          leased_area: formData.leased_area,
          educational_capacity: formData.educational_capacity,
          dining_capacity: formData.dining_capacity,
          gym_area: formData.gym_area,
          hall_area: formData.hall_area,
          dormitory_area: formData.dormitory_area,
          pcs_count: formData.pcs_count,
          pcs_internet_access: formData.pcs_internet_access,
          interactive_sets: formData.interactive_sets,
        },
        HeadersAuth
      );
      console.log(response.data);
      message.success("Аудитория успешно добавлена");
      navigate("/classroom");
    } catch (error: any) {
      if (error.response && error.response.status === 500) {
        message.error("Пожалуйста, заполните все обязательные поля");
      } else {
        message.error("Произошла ошибка при сохранении данных");
      }
    }
  };

  const handleInputChange = (inputName) => (e) => {
    let inputValue = e?.target ? e.target.value : e;

    const isValuePresent =
      inputValue !== null && inputValue !== undefined && inputValue !== "";

    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: inputValue,
    }));

    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [inputName]: isValuePresent,
    }));
  };

  return (
    <>
      <Col span={24}>
        <Content
          style={{
            width: "100%",
            height: "780px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />

          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form form={form}>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Название на русском
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.title
                      ? "error"
                      : inputStates.title
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave && !inputStates.title && "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    required
                    style={{
                      width: "90%",
                      borderColor: inputStates.title ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("title")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Название на казахском
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.title
                      ? "error"
                      : inputStates.title
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave && !inputStates.title && "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      borderColor: inputStates.title ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("title")}
                  />
                </Form.Item>
              </Col>

              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Год основания
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.foundation_year
                      ? "error"
                      : inputStates.foundation_year
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave &&
                    !inputStates.foundation_year &&
                    "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    required
                    style={{
                      width: "90%",
                      borderColor: inputStates.foundation_year
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("foundation_year")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Площадь территории (га)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.territory_area
                      ? "error"
                      : inputStates.territory_area
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave &&
                    !inputStates.territory_area &&
                    "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      borderColor: inputStates.territory_area
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("territory_area")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Площадь зданий (м.кв)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.building_area
                      ? "error"
                      : inputStates.building_area
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave &&
                    !inputStates.building_area &&
                    "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      borderColor: inputStates.building_area
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("building_area")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Из них собственная (м.кв)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.owned_area
                      ? "error"
                      : inputStates.owned_area
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave &&
                    !inputStates.owned_area &&
                    "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      borderColor: inputStates.owned_area
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("owned_area")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Арендованная (м.кв)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.leased_area
                      ? "error"
                      : inputStates.leased_area
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave &&
                    !inputStates.leased_area &&
                    "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      borderColor: inputStates.leased_area
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("leased_area")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Проектная мощнасть заведения (уч.мест)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.educational_capacity
                      ? "error"
                      : inputStates.educational_capacity
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave &&
                    !inputStates.educational_capacity &&
                    "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      borderColor: inputStates.educational_capacity
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("educational_capacity")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Проектная мощность столовой (посадочных мест)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.dining_capacity
                      ? "error"
                      : inputStates.dining_capacity
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave &&
                    !inputStates.dining_capacity &&
                    "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      borderColor: inputStates.dining_capacity
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("dining_capacity")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Площадь спортзала (м.кв)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.gym_area
                      ? "error"
                      : inputStates.gym_area
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave &&
                    !inputStates.gym_area &&
                    "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      borderColor: inputStates.gym_area ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("gym_area")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Площадь актового зала (м.кв)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.hall_area
                      ? "error"
                      : inputStates.hall_area
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave &&
                    !inputStates.hall_area &&
                    "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      borderColor: inputStates.hall_area
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("hall_area")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Площадь общежитий (м.кв)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.dormitory_area
                      ? "error"
                      : inputStates.dormitory_area
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave &&
                    !inputStates.dormitory_area &&
                    "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      borderColor: inputStates.dormitory_area
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("dormitory_area")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Количество ПК, используемый в уч.процессе (единиц)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.pcs_count
                      ? "error"
                      : inputStates.pcs_count
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave &&
                    !inputStates.pcs_count &&
                    "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      borderColor: inputStates.pcs_count
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("pcs_count")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Из них имеют доступ к сети интернет (единиц)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.pcs_internet_access
                      ? "error"
                      : inputStates.pcs_internet_access
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave &&
                    !inputStates.pcs_internet_access &&
                    "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      borderColor: inputStates.pcs_internet_access
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("pcs_internet_access")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Кол-во комплектов интерактивного оборудования
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    attemptedSave && !inputStates.interactive_sets
                      ? "error"
                      : inputStates.interactive_sets
                      ? "success"
                      : ""
                  }
                  help={
                    attemptedSave &&
                    !inputStates.interactive_sets &&
                    "Незаполненное поле"
                  }
                >
                  <Input
                    placeholder="Обязательное поле"
                    style={{
                      width: "90%",
                      borderColor: inputStates.interactive_sets
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("interactive_sets")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "80px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/classroom")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Отмена
              </Button>
              <Button onClick={handleSave} className="CreateButton">
                Сохранить
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      </Col>
    </>
  );
};
