import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Row,
  Input,
  Form,
  Select,
  message,
} from "antd";
import {
  SecondLevelTabs,
  JournalTable,
  MyBreadcrumb,
  FirstLevelTabs,
} from "../../../components";
import { useTheme } from "../../../hooks";
import { position } from "polished";
import "../../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import {
  DisciplinesTypeVM,
  FacultyTypeVM,
} from "../../InterfacesApi/InterfacesApi";
import axios from "axios";
import { HeadersAuth } from "../../../constants/Auth";

export const InstitutionDisciplineCreate = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [disciplines, setDisciplines] = useState<DisciplinesTypeVM[]>([]);
  const [faculty, setFaculty] = useState<FacultyTypeVM[]>([]);

  const [formData, setFormData] = useState({
    nameRu: "",
    nameKz: "",
    teacher: "",
    speciality: "",
    typeDiscipline: "",
    faculty: "",
  });

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/discipline-types`,
        HeadersAuth
      )
      .then((response) => {
        setDisciplines(response.data);
      })
      .catch((error) => {
        console.error("Ошибка обновлений дисциплин:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/show-departments?search`,
        HeadersAuth
      )
      .then((response) => {
        setFaculty(response.data);
      })
      .catch((error) => {
        console.error("Ошибка обновлений кафедр:", error);
      });
  }, []);

  const [inputStates, setInputStates] = useState({
    nameRu: false,
    nameKz: false,
    typeDiscipline: false,
    teacher: false,
    faculty: false,
  });

  const handleCreateDiscipline = () => {
    const requestData = {
      caption: formData.nameRu,
      college_id: 1,
      department_id: formData.faculty,
      discipline_type_id: formData.typeDiscipline,
    };

    axios
      .post(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/create-discipline-college`,
        requestData,
        HeadersAuth
      )
      .then((response) => {
        message.success("Дисциплина успешно добавлена");
        navigate("/speciality");
      })
      .catch((error) => {
        message.error("Ошибка при добавлении дисциплины");
      });
  };

  const handleInputChange = (inputName) => (e) => {
    const inputValue = e.target ? e.target.value : e;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: inputValue,
    }));

    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [inputName]: inputValue !== "",
    }));
  };

  const redirectToKafedra = () => {
    navigate("/institution_department/create");
  };

  const redirectGroup = () => {
    navigate("/group/create");
  };
  const redirectToSpeciality = () => {
    navigate("/speciality/create");
  };

  const disciplineOptions = disciplines.map((discipline) => (
    <Select.Option key={discipline.id} value={discipline.id}>
      {discipline.title}
    </Select.Option>
  ));

  const facultyOptions = faculty.map((faculties) => (
    <Select.Option key={faculties.id} value={faculties.id}>
      {faculties.caption}
    </Select.Option>
  ));
  const firstLevelTabs = [
    {
      key: "1",
      label: (
        <div onClick={redirectToSpeciality} style={{ cursor: "pointer" }}>
          Специальности
        </div>
      ),
      chilren: "",
    },
    {
      key: "2",
      label: "Дисциплины",
      children: (
        <Content
          style={{
            width: "100%",
            height: "400px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Название на русском
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameRu ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameRu ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameRu")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Название на казахском
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дисциплина
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.typeDiscipline ? "success" : ""}
                >
                  <Select
                    value={formData.typeDiscipline}
                    style={{
                      width: "90%",
                      ...(inputStates.typeDiscipline
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    onChange={handleInputChange("typeDiscipline")}
                  >
                    {disciplineOptions}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ padding: "10px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Преподаватели
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.teacher ? "success" : ""}
                >
                  <Select
                    value={formData.teacher}
                    style={{
                      width: "90%",
                      ...(inputStates.teacher
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    onChange={handleInputChange("teacher")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Кафедра
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.faculty ? "success" : ""}
                >
                  <Select
                    style={{
                      width: "90%",
                      ...(inputStates.faculty
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    onChange={handleInputChange("faculty")}
                    value={formData.faculty}
                  >
                    {facultyOptions}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "80px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/speciality")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton" onClick={handleCreateDiscipline}>
                Добавить
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "3",
      label: (
        <div onClick={redirectGroup} style={{ cursor: "pointer" }}>
          Группы
        </div>
      ),
      children: "",
    },
    {
      key: "4",
      label: (
        <div onClick={redirectToKafedra} style={{ cursor: "pointer" }}>
          Кафедры
        </div>
      ),
      children: (
        <Row>
          <Col span={24}>
            <SecondLevelTabs />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Добавить дисциплину" />
      <Row justify="center">
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="2" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
