import { JOURNAL_TYPES } from "./journal";

export const ROUTE_PATH: any = {
  // common
  ERROR_PAGE: "/404",
  AUTH: "/auth",
  RESTORE: "/restore",
  MAIN: "/",

  // journal
  JOURNAL: "/journal",
  JOURNAL_THEORETICAL: `/journal/${JOURNAL_TYPES.THEORETICAL}/:id`,
  JOURNAL_INDUSTRIAL: `/journal/${JOURNAL_TYPES.INDUSTRIAL}/:id`,
  JOURNAL_INDIVIDUAL: `/journal/${JOURNAL_TYPES.INDIVIDUAL}/:id`,
  JOURNAL_FACULTATIVE: `/journal/${JOURNAL_TYPES.FACULTATIVE}/:id`,

  // Организация учебного вопроса
  SPECIALITY: "/speciality",
  SPECIALITY_CREATE: "/speciality/create",
  SPECIALITY_EDIT: `/speciality/edit/:id`,

  INSTITUTION_DISCIPLINE: "/institution_discipline",
  INSTITUTION_DISCIPLINE_CREATE: "/institution_discipline/create",
  INSTITUTION_DISCIPLINE_EDIT: "/institution_discipline/edit/:id",

  GROUP: "/group",
  GROUP_CREATE: "/group/create",
  GROUP_EDIT: "/group/edit/:id",

  INSTITUTION_DEPARTMENT: "/institution_department",
  INSTITUTION_DEPARTMENT_CREATE: "/institution_department/create",
  INSTITUTION_DEPARTMENT_EDIT: "/institution_department/edit/:id",

  // rup
  RUP: "/rup",

  // employee (кадровый учет)

  EMPLOYEE: "/employee",
  EMPLOYEE_CREATE: "/employee/create",
  EMPLOYEE_ID: "/employee/view/:id",
  EMPLOYEE_EDIT: "/employee/edit/:id",

  // employee (картотека обуч)

  STUDENT: "/students",
  STUDENT_CREATE: "/students/create",
  STUDENT_ID: "/students/view",
  STUDENT_EDIT: "/students/edit",

  // Schedule
  SCHEDULE_TABLE: "/schedule",
  SCHEDULE_CREATE: "/schedule/create",
  SCHEDULE_SHIFT_CREATE: "/schedule/shift/create",
  SCHEDULE_SHIFT_EDIT: "/schedule/shift/item/:id",
  SCHEDULE_SEMESTER_CREATE: "/schedule/semester/create",
  SCHEDULE_SEMESTER_EDIT: "/schedule/semester/item/:id",

  // plans.ts
  PLANS: "/plans",
  PLANS_VIEW: "/plans/:planId",
  LESSON_VIEW: "/plans/:planId/:lessonId",
  LESSON_TEST: "/plans/:planId/:lessonId/tests/:testId",
  LESSON_TEST_VIEW: "/plans/:planId/:lessonId/tests/:testId/view",

  STUDENTS_SCHEDULE: "/student-schedule",
  STUDENTS_SCHEDULE_PLAN: "/student-schedule/:lessonId",
  STUDENTS_SCHEDULE_PLAN_TEST: "/student-schedule/:lessonId/test/:testId",

  // classroom (Аудитории)
  CLASSROOM: "/classroom",
  CLASSROOM_CREATE: "/classroom/create",
  CLASSROOM_EDIT: "/classroom/edit/item/:id",
  BUILDING: "/building",
  BUILDING_CREATE: "/building/create",
  BUILDING_EDIT: "/building/edit/item/:id",

  //record
  RECORD: "/record",

  PRINT_JOURNAL: "/print-journal",
};
