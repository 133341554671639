import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Table,
  Tooltip,
} from "antd";
import {
  SecondLevelTabs,
  JournalTable,
  MyBreadcrumb,
  MyButton,
  MyText,
  FirstLevelTabs,
} from "../../components";
import { useTheme } from "../../hooks";
import { position } from "polished";
import "../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import {
  DeleteFilled,
  DeleteOutlined,
  DeleteTwoTone,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { Cookies } from "react-cookie";
import { HeadersAuth } from "../../constants/Auth";

export const Institution_Discipline = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useTheme();

  const editIcon = (
    <EditOutlined
      onClick={() => navigate("/institution_discipline/edit")}
      className="speciality_edit"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
    />
  );
  const deleteIcon = (
    <DeleteOutlined
      className="speciality_delete"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
    />
  );

  const [qualificationsData, setQualificationsData] = useState({
    current_page: 1,
    data: [],
    last_page: 1,
    per_page: 20,
    total: 0,
  });

  const fetchQualificationsData = async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/index-discipline?college_id=1&page=${page}`,
        HeadersAuth
      );

      setQualificationsData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/delete-disciplines?id=${id}`,
        HeadersAuth
      );
      fetchQualificationsData(qualificationsData.current_page);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  useEffect(() => {
    fetchQualificationsData(1);
  }, []);

  const handlePageChange = (page) => {
    fetchQualificationsData(page);
  };

  const editIconClickHandler = (
    id,
    caption,
    department_id,
    discipline_type_id
  ) => {
    console.log("Navigating with state:", {
      caption,
      department_id,
      discipline_type_id,
    });
    navigate(`/institution_discipline/edit/${id}`, {
      state: {
        caption,
        department_id,
        discipline_type_id,
      },
    });
  };

  const columns = [
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>#</span>,
      dataIndex: "id",
      key: "id",
      style: { fontSize: "26px", fontWeight: "bold" },
      width: 50,
    },
    {
      title: (
        <span style={{ color: "#773DBD", padding: "10px" }}>Название</span>
      ),
      dataIndex: "caption",
      key: "caption",
      style: { fontSize: "26px" },
      sorter: true,
    },
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>Тип</span>,
      dataIndex: "discipline_type_title",
      key: "discipline_type_title",
      style: { fontSize: "26px" },
      sorter: true,
    },
    {
      title: <span style={{ padding: "2px", color: "#773DBD" }}>Действие</span>,
      dataIndex: "delete",
      key: "delete",
      width: 90,
      render: (_, record) => (
        <span>
          <Tooltip title="Редактировать">
            <EditOutlined
              onClick={() =>
                editIconClickHandler(
                  record.id,
                  record.caption,
                  record.department_id,
                  record.discipline_type_id
                )
              }
              className="speciality_edit"
              style={{
                display: "inline",
                fontSize: "24px",
                marginLeft: "10px",
              }}
              twoToneColor="red"
            />
          </Tooltip>
          <Tooltip title="Удалить">
            <Popconfirm
              title="Вы уверены, что хотите удалить?"
              onConfirm={() => handleDelete(record.id)}
              okText="Да"
              cancelText="Нет"
            >
              {deleteIcon}
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ];

  const redirectToSpeciality = () => {
    navigate("/speciality");
  };
  const redirectGroup = () => {
    navigate("/group");
  };
  const redirectToKafedra = () => {
    navigate("/institution_department");
  };
  const firstLevelTabs = [
    {
      key: "1",
      label: (
        <div onClick={redirectToSpeciality} style={{ cursor: "pointer" }}>
          Специальности
        </div>
      ),
      children: "",
    },
    {
      key: "2",
      label: "Дисциплины",
      children: (
        <Content
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            >
              <span style={{ fontWeight: "500", fontSize: "24px" }}>
                Добавленные дисциплины
              </span>
            </Col>
            <Col
              span={12}
              style={{
                textAlign: "end",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "0 20px 0 0",
              }}
            >
              <Button
                onClick={() => navigate("/institution_discipline/create")}
                className="speciality_create_button"
              >
                <PlusOutlined
                  style={{ display: "inline-block", fontSize: "16px" }}
                />
                <span
                  style={{
                    fontWeight: "400",
                    marginTop: "-20px",
                    lineHeight: "0px",
                  }}
                >
                  Добавить
                </span>
              </Button>
            </Col>
          </Row>
          <div
            className="speciality_table"
            style={{ fontSize: "20px", padding: "20px" }}
          >
            <Table
              dataSource={qualificationsData.data.map((item: any) => {
                const selectedDepartmentIndex =
                  item.selectedDepartmentIndex || 0; // Assuming 0 as the default index
                const selectedDepartment =
                  item.departments[selectedDepartmentIndex];

                return {
                  key: item.id,
                  id: item.id,
                  caption: item.caption,
                  discipline_type_title: item.discipline_type.title,
                  discipline_type_id: item.discipline_type_id,
                  speciality_code: item.speciality_code,
                  department_id: selectedDepartment
                    ? selectedDepartment.id
                    : null,
                  delete: [editIcon, deleteIcon],
                };
              })}
              bordered
              columns={columns}
              pagination={{
                current: qualificationsData.current_page,
                pageSize: qualificationsData.per_page,
                total: qualificationsData.total,
                onChange: handlePageChange,
              }}
            />
          </div>
          <Row>
            <Col
              span={12}
              style={{ paddingBottom: "30px", marginTop: "-55px" }}
            >
              <span
                style={{
                  marginLeft: "28px",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >{`Показаны ${
                (qualificationsData.current_page - 1) *
                  qualificationsData.per_page +
                1
              }-${Math.min(
                qualificationsData.current_page * qualificationsData.per_page,
                qualificationsData.total
              )} из ${qualificationsData.total} записи`}</span>
            </Col>{" "}
          </Row>
        </Content>
      ),
    },
    {
      key: "3",
      label: (
        <div onClick={redirectGroup} style={{ cursor: "pointer" }}>
          Группы
        </div>
      ),
      children: "",
    },
    {
      key: "4",
      label: (
        <div onClick={redirectToKafedra} style={{ cursor: "pointer" }}>
          Кафедры
        </div>
      ),
      children: (
        <Row>
          <Col span={24}>
            <SecondLevelTabs />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Content
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              marginTop: "20px",
              borderRadius: "0px 8px 8px 8px",
            }}
          >
            <p />
            <Row
              style={{ height: "20" }}
              justify="space-between"
              align="middle"
            >
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0 20px 0 20px",
                }}
              >
                <span style={{ fontWeight: "500", fontSize: "24px" }}>
                  Добавленные дисциплины
                </span>
              </Col>
              <Col
                span={12}
                style={{
                  textAlign: "end",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  padding: "0 20px 0 0",
                }}
              >
                <Button
                  onClick={() => navigate("/institution_discipline/create")}
                  className="speciality_create_button"
                >
                  <PlusOutlined
                    style={{ display: "inline-block", fontSize: "16px" }}
                  />
                  <span
                    style={{
                      fontWeight: "400",
                      marginTop: "-20px",
                      lineHeight: "0px",
                    }}
                  >
                    Добавить
                  </span>
                </Button>
              </Col>
            </Row>
            <div
              className="speciality_table"
              style={{ fontSize: "20px", padding: "20px" }}
            >
              <Table
                dataSource={qualificationsData.data.map((item: any) => {
                  const selectedDepartmentIndex =
                    item.selectedDepartmentIndex || 0; // Assuming 0 as the default index
                  const selectedDepartment =
                    item.departments[selectedDepartmentIndex];

                  return {
                    key: item.id,
                    id: item.id,
                    caption: item.caption,
                    discipline_type_title: item.discipline_type.title,
                    discipline_type_id: item.discipline_type_id,
                    speciality_code: item.speciality_code,
                    department_id: selectedDepartment
                      ? selectedDepartment.id
                      : null,
                    delete: [editIcon, deleteIcon],
                  };
                })}
                bordered
                columns={columns}
                pagination={{
                  current: qualificationsData.current_page,
                  pageSize: qualificationsData.per_page,
                  total: qualificationsData.total,
                  onChange: handlePageChange,
                }}
              />
            </div>
            <Row>
              <Col
                span={12}
                style={{ paddingBottom: "30px", marginTop: "-55px" }}
              >
                <span
                  style={{
                    marginLeft: "28px",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >{`Показаны ${
                  (qualificationsData.current_page - 1) *
                    qualificationsData.per_page +
                  1
                }-${Math.min(
                  qualificationsData.current_page * qualificationsData.per_page,
                  qualificationsData.total
                )} из ${qualificationsData.total} записи`}</span>
              </Col>{" "}
            </Row>
          </Content>
        </Col>
      </Row>
    </>
  );
};
