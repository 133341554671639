import React from "react";
import {MyTextStyles} from "./MyText.styles";
import {MyTextProps} from "./MyText.props";
import {theme as configTheme} from "antd";
import {GlobalToken} from "antd/es/theme/interface";
import {MyAliasToken} from "../../../assets/themes/defaultTheme";

const {useToken} = configTheme

export const MyText = ({
                         children,
                         color = '#000',
                         align = 'left',
                         lineHeight = '100%',
                         size = 16,
                         weight = 400,
                         ...props
                       }: MyTextProps) => {
  // @ts-ignore
  const {token}:{token: MyAliasToken} = useToken();

  return (
    <MyTextStyles {
                    ...{
                      ...props,
                      style: {
                        color: color,
                        lineHeight: lineHeight,
                        textAlign: align,
                        fontSize: size,
                        fontWeight: weight,
                        ...props.style
                      },
                    }
                  }>
      {children}
    </MyTextStyles>
  )
}