

  export const columns = [
    {
      dataIndex: "content",
      key: "content",
      sorter: false,
      width: 500,
    },
    {
      dataIndex: "info",
      key: "info",
      sorter: false,
      width: 500,
    },
  ];