import {axiosConfig as axios} from "../../../utils/axios";

let url = 'rest'
export const apiGetJournalTable = (body: any) =>
  axios.get(`${url}/edu/grade-journal`, {
    params: {
      ctp_id: body.ctp_id,
      start_date: body.start_date,
      end_date: body.end_date,
    }
  });

export const apiJournalMark = (body: any) => axios({
  method: body.api_method,
  url: `${url}/edu/mark${body.id ? `/${body.id}` : ''}`,
  data: body
})

export const apiPerformance = (body: any) => axios.get(`${url}/edu/performance`, {
  params: {
    // ctp_id: body.ctp_id,
    start_date: body.start_date,
    end_date: body.end_date,
  }
});
