import Icon from "@ant-design/icons";

const Svg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 15.3337C13.8409 15.3337 15.3333 13.8413 15.3333 12.0003C15.3333 10.1594 13.8409 8.66699 12 8.66699C10.159 8.66699 8.66663 10.1594 8.66663 12.0003C8.66663 13.8413 10.159 15.3337 12 15.3337Z" stroke="white" strokeOpacity="0.45" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 4.66699V6.00033" stroke="white" strokeOpacity="0.45" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 18V19.3333" stroke="white" strokeOpacity="0.45" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.81335 6.81348L7.76002 7.76014" stroke="white" strokeOpacity="0.45" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.24 16.2402L17.1867 17.1869" stroke="white" strokeOpacity="0.45" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.66663 12H5.99996" stroke="white" strokeOpacity="0.45" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18 12H19.3333" stroke="white" strokeOpacity="0.45" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.81335 17.1869L7.76002 16.2402" stroke="white" strokeOpacity="0.45" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.24 7.76014L17.1867 6.81348" stroke="white" strokeOpacity="0.45" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
export const MyIcon = (props: any) => <Icon component={Svg} {...props} />;