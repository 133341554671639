export enum COMPONENT_TYPES_ENUM {
  TYPE_HIDE = 9,
  TYPE_TEXT = 10,
  TYPE_NUMBER = 11,
  TYPE_PASSWORD = 12,
  TYPE_TEL = 13,
  TYPE_EMAIL = 14,
  TYPE_URL = 15,
  TYPE_DATE = 20,
  TYPE_DATETIME_LOCAL = 21,
  TYPE_TIME = 22,
  TYPE_WEEK = 23,
  TYPE_MONTH = 24,
  TYPE_DATE_AT = 25,

  TYPE_TEXTAREA = 30,
  TYPE_CHECKBOX = 40,
  TYPE_RADIO = 50,
  TYPE_MARKS = 51,
  TYPE_SELECT = 60,
  TYPE_DATALIST = 61,
  TYPE_SELECT2 = 62,
  TYPE_FILE = 70,
  TYPE_IMAGE = 71,
  TYPE_COLOR = 80,
  TYPE_RANGE = 90,
}
