import React, {useCallback, useMemo, useState} from "react";
import {FormItemProps} from "./FormItem.props";
import {COMPONENT_TYPES_ENUM, COOKIES_ENUM, FILE_FORM_API, LANG} from "../../../../../constants";
import {
  Checkbox,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  InputNumber,
  List, message,
  Radio,
  Select,
  Slider,
  Typography, Upload
} from "antd";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import 'dayjs/locale/ru.js';
import locale from 'antd/locale/ru_RU';

import isBetween from "dayjs/plugin/isBetween"
import {MyButton} from "../../../MyButton/MyButton";
import axios from "axios";
import {getFromUrl} from "../../../../../utils/getFromUrl";
import {Cookies} from "react-cookie";

dayjs.extend(isBetween)
const cookies = new Cookies()

export const FormItem = ({item, object}: FormItemProps): React.ReactElement => {
  const {t} = useTranslation("common")

  const FILE_TYPE = {
    PDF: 'application/pdf',
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    XLS: 'application/vnd.ms-excel',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  }

  const renderType = useMemo(() => {
    switch (item?.type) {
      case COMPONENT_TYPES_ENUM.TYPE_TEXT:
        return (
          <Input placeholder={item?.placeholder ? item?.placeholder : ''}/>
        );
      case COMPONENT_TYPES_ENUM.TYPE_NUMBER:
        return (
          <InputNumber max={100} placeholder={item?.placeholder ? item?.placeholder : ''}/>
        );
      case COMPONENT_TYPES_ENUM.TYPE_PASSWORD:
        return (
          <Input placeholder={item?.placeholder ? item?.placeholder : ''} type={"password"}/>
        );
      case COMPONENT_TYPES_ENUM.TYPE_TEL:
        return (
          <Input placeholder={item?.placeholder ? item?.placeholder : ''} type={"tel"}/>
        );
      case COMPONENT_TYPES_ENUM.TYPE_EMAIL:
        return (
          <Input placeholder={item?.placeholder ? item?.placeholder : ''} type={"email"}/>
        );
      case COMPONENT_TYPES_ENUM.TYPE_URL:
        return (
          <Input placeholder={item?.placeholder ? item?.placeholder : ''} type={"url"}></Input>
        );
      case COMPONENT_TYPES_ENUM.TYPE_DATE:
        return (
          <DatePicker
            style={{width: '100%'}}
            format={"YYYY-MM-DD"}
            placeholder={item?.placeholder ? item?.placeholder : ''}
            disabledDate={(currentDate: any) => (object?.start_date || object?.end_date) ? !dayjs(currentDate).isBetween(object?.start_date, object?.end_date, 'days', '[]') : false}
          />
        )
      case COMPONENT_TYPES_ENUM.TYPE_DATE_AT:
        return (
          <DatePicker
            style={{width: '100%'}}
            format={"YYYY-MM-DD"}
            placeholder={item?.placeholder ? item?.placeholder : ''}
            disabledDate={(currentDate) => (object?.start_date || object?.end_date)
              ? !dayjs(currentDate).isBetween(object?.start_date, object?.end_date, 'days', '[]')
              : false
            }
          />
        )
      case COMPONENT_TYPES_ENUM.TYPE_DATETIME_LOCAL:
        return (
          <Input placeholder={item?.placeholder ? item?.placeholder : ''} type={"datetime-local"}/>
        );
      case COMPONENT_TYPES_ENUM.TYPE_TIME:
        return (
          <Input placeholder={item?.placeholder ? item?.placeholder : ''} type={"time"}/>
        );
      case COMPONENT_TYPES_ENUM.TYPE_WEEK:
        return (
          <Input placeholder={item?.placeholder ? item?.placeholder : ''} type={"week"}/>
        );
      case COMPONENT_TYPES_ENUM.TYPE_MONTH:
        return (
          <Input placeholder={item?.placeholder ? item?.placeholder : ''} type={"month"}/>
        );
      case COMPONENT_TYPES_ENUM.TYPE_TEXTAREA:
        return (
          <Input.TextArea placeholder={item?.placeholder ? item?.placeholder : ''} rows={4}/>
        );
      case COMPONENT_TYPES_ENUM.TYPE_CHECKBOX:
        return (
          <Checkbox>{item?.label}</Checkbox>
        );
      case COMPONENT_TYPES_ENUM.TYPE_RADIO:
        return (
          <Radio.Group>
            {item?.fieldValues?.map((item: any, index: any) => (
              <Radio.Button value={item.value} key={"TYPE_RADIO" + index}>
                {item.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        );
      case COMPONENT_TYPES_ENUM.TYPE_SELECT:
        return (
          <Select placeholder={item?.placeholder ? item?.placeholder : ''}>
            {item?.fieldValues?.map((item: any, index: any) => (
              <Select.Option value={item.value} key={"TYPE_SELECT" + index}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        );
      case COMPONENT_TYPES_ENUM.TYPE_DATALIST:
        return (
          <List>
            {item?.fieldValues?.map((item: any, index: any) => (
              <List.Item key={"TYPE_DATALIST" + index}>
                <Typography.Text>{item}</Typography.Text>
              </List.Item>
            ))}
          </List>
        );
      case COMPONENT_TYPES_ENUM.TYPE_SELECT2:
        return (
          <Select placeholder={item?.placeholder ? item?.placeholder : ''}>
            {item?.fieldValues?.map((item: any, index: any) => (
              <Select.Option value={item.value} key={"TYPE_SELECT2" + index}>
                {item.label}
              </Select.Option>
            ))}
          </Select>

        );
      case COMPONENT_TYPES_ENUM.TYPE_COLOR: {
        return (
          <Input placeholder={item?.placeholder ? item?.placeholder : ''} type={"color"}/>
        );
      }
      case COMPONENT_TYPES_ENUM.TYPE_FILE: {
        //todo: сделать разделение на проверку типа файла
        const formatCheck = (file: any, fileList: any) => {
          switch (file.type) {
            case FILE_TYPE.DOC:
            case FILE_TYPE.DOCX:
            case FILE_TYPE.PDF:
            case FILE_TYPE.XLS:
            case FILE_TYPE.XLSX:
              return true

            default:
              message.error(`${file.name} is not a DOC, DOCX, PDF, XLS, XLSX  file`);
              return Upload.LIST_IGNORE
          }
        }
        const importHandler = async (option: any) => {
          const {onSuccess, onError, file, onProgress} = option;

          let token = cookies.get(COOKIES_ENUM.JWT)
          let lang = cookies.get(COOKIES_ENUM.LANG) || getFromUrl(COOKIES_ENUM.LANG) || LANG.RU

          let formData = new FormData();

          // let url: string = (file.type === FILE_TYPE.DOC || file.type === FILE_TYPE.DOCX || file.type === FILE_TYPE.PDF) ? FILE_FORM_API.FILE : FILE_FORM_API.EXCEL

          let url = FILE_FORM_API.FILE

          formData.append("yModel", 'Upload');
          formData.append("Upload[file]", file);
          formData.append("Upload[name]", file.name);

          const config = {
            headers: {
              "Authorization": `Bearer ${token}`,
              "Accept-Language": lang
            },
            onUploadProgress: (e: any) => {
              const percent = Math.floor((e.loaded / e.total) * 100)
              onProgress({percent: percent})
            }
          }

          try {
            const res = await axios.post(url, formData, config).then((data) => {
              onSuccess(data);

              return data
            })

            /**
             * Мутирую присланный ответ. Склеиваю date и time в date, т.к приходит раздельно date и time
             */

            // const mutatedThemes = res.data.map((item: any) => ({...item, date: `${item.date} ${item.time}`}))


            // setImportThemeState(mutatedThemes)
          } catch (e) {
            onError({e});
          }
        }

        return (
          <Upload
            beforeUpload={(file, fileList) => formatCheck(file, fileList)}
            customRequest={(option) => importHandler(option)}
            multiple={true}
          >
            <MyButton title={"Выберите файл"}/>
          </Upload>
        );
      }
      case COMPONENT_TYPES_ENUM.TYPE_RANGE: {
        return (
          <Slider range/>
        );
      }
      case COMPONENT_TYPES_ENUM.TYPE_MARKS: {
        return (
          <Radio.Group>
            {item?.fieldValues?.map((button: any, idx: any) => (
              <Radio.Button value={button.value} key={"radio-marks" + idx}>
                {button.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        );
      }
      default: {
        return false;
      }
    }
  }, [item])

  const conditionalValues = useMemo(() => {
    switch (item.type) {
      case COMPONENT_TYPES_ENUM.TYPE_CHECKBOX:
        return {
          valuePropName: 'checked',
          wrapperCol: {
            offset: 8
          }
        }
      case COMPONENT_TYPES_ENUM.TYPE_RADIO:
        return {
          wrapperCol: {
            offset: 8
          }
        }
      case COMPONENT_TYPES_ENUM.TYPE_FILE:
        return {
          valuePropName: 'file',
          wrapperCol: {
            offset: 8
          },
          getValueFromEvent: (args: any) => {
            return args.fileList?.map((item: any) => item?.response?.data?.url)
          }
        }

      default:
        return {
          label: item?.label
        }
    }
  }, [item])

  return (
    <ConfigProvider locale={locale}>
      {renderType
        ?
        <Form.Item
          name={item?.name}
          rules={[
            {required: item?.is_required, message: `${t('setRequiredFields')}`},
          ]}
          {...conditionalValues}
        >
          {renderType}
        </Form.Item>
        :
        <Form.Item style={{display: "none"}}> </Form.Item>}
    </ConfigProvider>
  )
}