import {combineReducers} from "redux";

import common from "./common/reducer";
import journal from "./pages/journal/reducer";
import profile from "./pages/profile/reducer";
import plans from "./pages/plans/reducer";
import schedule from "./pages/schedule/reducer";
import printJournal from "./pages/printJournal/reducer";
import rup from "./pages/rup/reducer";

export default combineReducers({
  common,
  journal,
  profile,
  plans,
  schedule,
  printJournal,
  rup,
})
