import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import {
  Button,
  Col,
  Divider,
  Row,
  Input,
  Form,
  message,
  TimePicker,
  Modal,
} from "antd";
import { MyBreadcrumb } from "../../../../components";
import "../../../Speciality/Speciality.scss";
import "./ScheduleShift.scss";
import { Content } from "antd/es/layout/layout";
import dayjs from "dayjs";
import axios from "axios";
import { HeadersAuth } from "../../../../constants/Auth";
import { useNavigate } from "react-router-dom";
import { Lesson } from "../../ScheduleInterface";

export const ScheduleShiftCreate = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [attemptedSave, setAttemptedSave] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const navigate = useNavigate();
  const [inputStates, setInputStates] = useState({
    title: false,
    titleKZ: false,
    startTime: false,
    endTime: false,
  });

  const handleAddLesson = () => {
    const newLesson: Lesson = { startTime: null, endTime: null };
    setLessons([...lessons, newLesson]);
  };

  const handleLessonTimeChange =
    (index: number, field: "startTime" | "endTime") =>
    (time: dayjs.Dayjs | null, timeString: string | string[]) => {
      if (time) {
        const updatedLessons = [...lessons];
        updatedLessons[index][field] =
          typeof timeString === "string" ? timeString : timeString[0];
        setLessons(updatedLessons);

        const isStartTimeFilled = updatedLessons.every(
          (lesson) => lesson.startTime !== null
        );
        const isEndTimeFilled = updatedLessons.every(
          (lesson) => lesson.endTime !== null
        );
        setInputStates((prevInputStates) => ({
          ...prevInputStates,
          startTime: isStartTimeFilled,
          endTime: isEndTimeFilled,
        }));
      }
    };

  const handleRemoveLesson = (index: number) => {
    const updatedLessons = lessons.filter((_, i) => i !== index);
    setLessons(updatedLessons);
  };
  const handleInputChange = (inputName) => (e) => {
    const inputValue = e?.target ? e.target.value : e;

    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [inputName]:
        inputValue !== "" && inputValue !== undefined && inputValue !== null,
    }));
  };

  const handleSave = async () => {
    try {
      await form.validateFields();

      const isAllLessonsFilled = lessons.every(
        (lesson) => lesson.startTime !== null && lesson.endTime !== null
      );

      if (!isAllLessonsFilled) {
        throw new Error(
          "Пожалуйста, заполните все временные поля для каждого урока"
        );
      }

      const timesString =
        "[" +
        lessons
          .map(
            (lesson) =>
              `{"start_time":"${lesson.startTime}","end_time":"${lesson.endTime}"}`
          )
          .join(",") +
        "]";

      const formData = {
        title: form.getFieldValue("title"),
        college_id: 1,
        times: timesString,
      };

      await axios.post(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/schedule/v1/createEducationShifts`,
        formData,
        HeadersAuth
      );

      setAttemptedSave(true);
      setModalVisible(true);
    } catch (error) {
      message.error("Ошибка при добавлении смены");
    }
  };

  const handleContinue = () => {
    setModalVisible(false);
    navigate("/schedule");
  };

  return (
    <>
      <MyBreadcrumb title="Добавление смены" />
      <Row justify="center" style={{ paddingBottom: "20px" }}>
        <Col span={20}>
          <Row>
            <Col span={24}>
              <Content
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "white",
                  marginTop: "20px",

                  borderRadius: "0px 8px 8px 8px",
                }}
              >
                <p />
                <Row
                  style={{ height: "20" }}
                  justify="space-between"
                  align="middle"
                >
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0 20px 0 20px",
                    }}
                  ></Col>
                </Row>
                <Form form={form}>
                  <Row style={{ padding: "20px 0px 0 20px" }}>
                    <Col span={24}></Col>
                    <Col
                      span={24}
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      Название на русском
                      <p />
                      <Form.Item
                        name="title"
                        hasFeedback
                        validateStatus={
                          attemptedSave && !inputStates.title
                            ? "error"
                            : inputStates.title
                            ? "success"
                            : ""
                        }
                        help={
                          attemptedSave &&
                          !inputStates.title &&
                          "Незаполненное поле"
                        }
                      >
                        <Input
                          onChange={handleInputChange("title")}
                          style={{
                            width: "98%",
                            borderColor: inputStates.title
                              ? "#8B00FF"
                              : undefined,
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        marginTop: "20px",
                      }}
                    >
                      Название на казахском
                      <p />
                      <Form.Item
                        hasFeedback
                        validateStatus={
                          attemptedSave && !inputStates.titleKZ
                            ? "error"
                            : inputStates.titleKZ
                            ? "success"
                            : ""
                        }
                        help={
                          attemptedSave &&
                          !inputStates.titleKZ &&
                          "Незаполненное поле"
                        }
                      >
                        <Input
                          onChange={handleInputChange("titleKZ")}
                          style={{
                            width: "98%",
                            borderColor: inputStates.titleKZ
                              ? "#8B00FF"
                              : undefined,
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Button className="AddLesson" onClick={handleAddLesson}>
                        Добавить урок
                      </Button>
                    </Col>
                    {lessons.map((_, index) => (
                      <React.Fragment key={index}>
                        <Col
                          span={8}
                          style={{
                            fontWeight: "500",
                            fontSize: "14px",
                            marginTop: "40px",
                          }}
                        >
                          Время начала урока
                          <p />
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              attemptedSave && !inputStates.startTime
                                ? "error"
                                : inputStates.startTime
                                ? "success"
                                : ""
                            }
                            help={
                              attemptedSave &&
                              !inputStates.startTime &&
                              "Незаполненное поле"
                            }
                          >
                            <TimePicker
                              showNow={false}
                              placeholder="Обязательное поле"
                              style={{
                                height: 40,
                                width: "90%",
                              }}
                              onChange={(time, timeString) =>
                                handleLessonTimeChange(index, "startTime")(
                                  time as dayjs.Dayjs,
                                  timeString
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          span={8}
                          style={{
                            fontWeight: "500",
                            fontSize: "14px",
                            marginTop: "40px",
                          }}
                        >
                          Время конца урока
                          <p />
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              attemptedSave && !inputStates.endTime
                                ? "error"
                                : inputStates.endTime
                                ? "success"
                                : ""
                            }
                            help={
                              attemptedSave &&
                              !inputStates.endTime &&
                              "Незаполненное поле"
                            }
                          >
                            <TimePicker
                              showNow={false}
                              placeholder="Обязательное поле"
                              style={{
                                height: 40,
                                width: "90%",
                              }}
                              onChange={(time, timeString) =>
                                handleLessonTimeChange(index, "endTime")(
                                  time as dayjs.Dayjs,
                                  timeString
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Button
                            className="DeleteTime"
                            onClick={() => handleRemoveLesson(index)}
                          >
                            Удалить
                          </Button>
                        </Col>
                      </React.Fragment>
                    ))}
                  </Row>
                </Form>
                <Row>
                  <Divider style={{ marginTop: "40px" }} plain />
                  <Col
                    span={24}
                    style={{ textAlign: "center", marginTop: "20px" }}
                  >
                    <Button onClick={handleSave} className="CreateButtonLesson">
                      Добавить
                    </Button>
                  </Col>
                </Row>
                <Row></Row>
              </Content>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        title={
          <span
            style={{
              marginLeft: "160px",
              fontSize: "20px",
            }}
          >
            Уведомление
          </span>
        }
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        centered
        style={{
          flexDirection: "column",
          justifyContent: "center",
        }}
        footer={[
          <Button
            key="continue"
            className="CreateButtonLesson"
            onClick={handleContinue}
            style={{
              height: "35px",
              marginRight: "auto",
              marginLeft: "auto",
              lineHeight: "0",
              justifyContent: "center",
            }}
          >
            Продолжить
          </Button>,
        ]}
      >
        <p style={{ textAlign: "center" }}>Смена успешно добавлена!</p>
        <Divider style={{ marginTop: "20px" }} plain />
      </Modal>

      <p />
    </>
  );
};
