import {axiosConfig as axios} from "../../../utils/axios";

let url = 'rest'

export const apiModules = (body: any) => axios.get(`${url}/edu/theoretic-journal-print`, {
  params: {
    course: body.course,
    group: body.group,
    academic_year: body.year
  },
});

export const apiModuleEdit = (body: any) => axios.put(`${url}/edu/theoretic-journal-print/save-module-dates`, {
  module_id: body.id,
  date_start: body.date_start,
  date_end: body.date_end
});

export const apiStudy = (body: any) => axios.get(`${url}/edu/theoretic-journal-print/students-info`, {
  params: {
    course: body.course,
    group: body.group,
    academic_year: body.year,
    side: body.side,
  },
});

export const apiStudyEdit = (body: any) => axios.patch(`${url}/edu/theoretic-journal-print/save-students-info`, body);

export const apiMedical = (body: any) => axios.get(`${url}/edu/theoretic-journal-print/medical-results`, {
  params: {
    course: body.course,
    group: body.group,
    academic_year: body.year,
    side: body.side,
  },
});

export const apiMedicalGroup = (body: any) => axios.get(`${url}/edu/theoretic-journal-print/medical-groups`, {
  params: {
    course: body.course,
    group: body.group,
    academic_year: body.year,
    side: body.side,
  },
});

export const apiMedicalEdit = (body: any) => axios.put(`${url}/edu/theoretic-journal-print/save-medical-result`, {
  person_id: body.person_id,
  medical_examination_date: body.medical_data.date,
  medical_note: body.medical_data.note,
  medical_group: body.medical_data.group.caption
});

export const apiRemark = (body: any) => axios.get(`${url}/edu/theoretic-journal-print/remarks`, {
  params: {
    course: body.course,
    group: body.group,
    academic_year: body.year,
  },
});

export const apiSetRemark = (body: any) => axios.post(`${url}/edu/theoretic-journal-print/create-remark`, {
  group_id: body.group_id,
  course: body.course,
  remark_date: body.remark_date,
  remark_text: body.remark_text,
});

export const apiEditRemark = (body: any) => axios.patch(`${url}/edu/theoretic-journal-print/update-remark`, {
  remark_id: body.remark_id,
  remark_date: body.remark_date,
  remark_text: body.remark_text,
});

export const apiDeleteRemark = (id: any) => axios.delete(`${url}/edu/theoretic-journal-print/delete-remark/${id}`);

export const apiTotalUvr = (body: any) => axios.get(`${url}/edu/theoretic-journal-print/education-work-result`, {
  params: {
    course: body.course,
    group: body.group,
    academic_year: body.year,
    subject_id: body.subject_id,
    side: body.side,
  },
});

export const apiDisciplinesTotalUvr = (body: any) => axios.get(`${url}/edu/theoretic-journal-print/subjects-list`, {
  params: {
    course: body.course,
    group: body.group,
    academic_year: body.year,
  },
});

export const apiPrintJournal = (body: any) => axios.get(`${url}/edu/theoretic-journal-print/print`, {
  params: {
    course: body.course,
    group: body.group,
    academic_year: body.year,
  },
});

