import {call, put, select, takeLatest} from "redux-saga/effects";
import {
  deletePrintRemark,
  deletePrintRemarkDone,
  deletePrintRemarkFail,
  editPrintRemark,
  editPrintRemarkDone,
  editPrintRemarkFail, editPrintStudy, editPrintStudyDone, editPrintStudyFail,
  getDisciplinesPrintTotalUvr, getDisciplinesPrintTotalUvrDone,
  getDisciplinesPrintTotalUvrFail,
  getPrintMedical,
  getPrintMedicalDone,
  getPrintMedicalFail,
  getPrintMedicalGroup,
  getPrintMedicalGroupDone,
  getPrintMedicalGroupFail,
  getPrintModule,
  getPrintModuleDone,
  getPrintModuleFail,
  getPrintRemark,
  getPrintRemarkDone,
  getPrintRemarkFail,
  getPrintStudy,
  getPrintStudyDone,
  getPrintStudyFail,
  getPrintTotalUvr,
  getPrintTotalUvrDone,
  getPrintTotalUvrFail,
  printJournal, printJournalDone, printJournalFail,
  printMedicalEdit,
  printMedicalEditDone,
  printMedicalEditFail,
  printModuleEdit,
  printModuleEditDone,
  printModuleEditFail,
  setPrintRemark,
  setPrintRemarkDone,
  setPrintRemarkFail,
} from "./actions";
import {
  apiDeleteRemark, apiDisciplinesTotalUvr,
  apiEditRemark,
  apiMedical,
  apiMedicalEdit,
  apiMedicalGroup,
  apiModuleEdit,
  apiModules, apiPrintJournal,
  apiRemark,
  apiSetRemark,
  apiStudy, apiStudyEdit, apiTotalUvr
} from "./api";

import {PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../index";
import {selectStore} from "../../selector";

function* getPrintModuleFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiModules, payload)
    if (data) {
      yield put(getPrintModuleDone(data.data))
    }
  } catch (e) {
    yield put(getPrintModuleFail())
  }
}

function* editPrintModuleFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiModuleEdit, payload)
    if (data.status === 200) {
      yield put(printModuleEditDone(payload))
    }
  } catch (e) {
    yield put(printModuleEditFail())
  }
}

function* getPrintStudyFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiStudy, payload)
    if (data) {
      yield put(getPrintStudyDone(data.data))
    }
  } catch (e) {
    yield put(getPrintStudyFail())
  }
}

function* editPrintStudyFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiStudyEdit, payload)
    if (data) {
      yield put(editPrintStudyDone(data.data))
    }
  } catch (e) {
    yield put(editPrintStudyFail())
  }
}

function* getPrintMedicalFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiMedical, payload)
    if (data) {
      yield put(getPrintMedicalDone(data.data))
    }
  } catch (e) {
    yield put(getPrintMedicalFail())
  }
}

function* getPrintMedicalGroupsFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiMedicalGroup, payload)
    if (data) {
      yield put(getPrintMedicalGroupDone(data.data))
    }
  } catch (e) {
    yield put(getPrintMedicalGroupFail())
  }
}

function* editPrintMedicalFlow({payload}: PayloadAction<any>): any {
  console.log('payload', payload)
  try {
    const data = yield call(apiMedicalEdit, payload)

    if (data.status === 200) {
      yield put(printMedicalEditDone(data.data))
    }
  } catch (e) {
    yield put(printMedicalEditFail())
  }
}

function* getPrintRemarkFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiRemark, payload)

    if (data.data) {
      yield put(getPrintRemarkDone(data.data))
    }
  } catch (e) {
    yield put(getPrintRemarkFail())
  }
}

function* setPrintRemarkFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiSetRemark, payload)

    if (data.data) {
      yield put(setPrintRemarkDone(data.data))

      // @ts-ignore
      yield call(getPrintRemarkFlow, {payload: payload})
    }
  } catch (e) {
    yield put(setPrintRemarkFail())
  }
}

function* editPrintRemarkFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiEditRemark, payload)

    if (data.data) {
      yield put(editPrintRemarkDone(data.data))
    }
  } catch (e) {
    yield put(editPrintRemarkFail())
  }
}

function* deletePrintRemarkFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiDeleteRemark, payload)

    if (data.data) {
      yield put(deletePrintRemarkDone(data.data))
    }
  } catch (e) {
    yield put(deletePrintRemarkFail())
  }
}

function* getPrintTotalUvrFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiTotalUvr, payload)

    if (data.data) {
      yield put(getPrintTotalUvrDone(data.data))
    }
  } catch (e) {
    yield put(getPrintTotalUvrFail());
  }
}

function* getDisciplinesPrintTotalUvrFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiDisciplinesTotalUvr, payload)

    if (data.data) {
      yield put(getDisciplinesPrintTotalUvrDone(data.data))
    }
  } catch (e) {
    yield put(getDisciplinesPrintTotalUvrFail())
  }
}


function* printJournalFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiPrintJournal, payload)

    if (data.data) {
      yield put(printJournalDone(data.data))
    }
  } catch (e) {
    yield put(printJournalFail())
  }
}


export default function* profileSaga() {
  yield takeLatest(getPrintModule, getPrintModuleFlow);
  yield takeLatest(printModuleEdit, editPrintModuleFlow);
  yield takeLatest(getPrintStudy, getPrintStudyFlow);
  yield takeLatest(editPrintStudy, editPrintStudyFlow);
  yield takeLatest(getPrintMedical, getPrintMedicalFlow);
  yield takeLatest(getPrintMedicalGroup, getPrintMedicalGroupsFlow);
  yield takeLatest(printMedicalEdit, editPrintMedicalFlow);
  yield takeLatest(getPrintRemark, getPrintRemarkFlow);
  yield takeLatest(setPrintRemark, setPrintRemarkFlow);
  yield takeLatest(editPrintRemark, editPrintRemarkFlow);
  yield takeLatest(deletePrintRemark, deletePrintRemarkFlow);
  yield takeLatest(getPrintTotalUvr, getPrintTotalUvrFlow);
  yield takeLatest(getDisciplinesPrintTotalUvr, getDisciplinesPrintTotalUvrFlow);
  yield takeLatest(printJournal, printJournalFlow);

}
