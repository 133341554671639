import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Tooltip,
  Popconfirm,
  message,
} from "antd";
import { useTheme } from "../../hooks";
import "../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { HeadersAuth } from "../../constants/Auth";
import { BuildingVM } from "./ClassroomInterface";

export const Building = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { theme } = useTheme();
  const editIcon = (
    id,
    title,
    foundation_year,
    territory_area,
    building_area,
    owned_area,
    leased_area,
    educational_capacity,
    dining_capacity,
    gym_area,
    hall_area,
    dormitory_area,
    pcs_count,
    pcs_internet_access,
    interactive_sets
  ) => (
    <EditOutlined
      onClick={() =>
        redirectToInstitutionDepartmentCreate(
          id,
          title,
          foundation_year,
          territory_area,
          building_area,
          owned_area,
          leased_area,
          educational_capacity,
          dining_capacity,
          gym_area,
          hall_area,
          dormitory_area,
          pcs_count,
          pcs_internet_access,
          interactive_sets
        )
      }
      className="speciality_edit"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
    />
  );
  const deleteIcon = (
    <DeleteOutlined
      className="speciality_delete"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
    />
  );

  const fetchBuildingApi = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/auditfund/v1/getBuildings?college_id=1`,
        HeadersAuth
      );

      setBuildingData(response.data);
    } catch (error) {
      console.error("Ошибка получения данных:", error);
    }
  };

  const [buildingData, setBuildingData] = useState<BuildingVM>({
    current_page: 1,
    data: [],
    last_page: 1,
    per_page: 20,
    total: 0,
  });

  useEffect(() => {
    fetchBuildingApi();
  }, []);

  const redirectToInstitutionDepartmentCreate = (
    id,
    title,
    foundation_year,
    territory_area,
    building_area,
    owned_area,
    leased_area,
    educational_capacity,
    dining_capacity,
    gym_area,
    hall_area,
    dormitory_area,
    pcs_count,
    pcs_internet_access,
    interactive_sets
  ) => {
    navigate(`/building/edit/item/${id}`, {
      state: {
        id,
        title,
        foundation_year,
        territory_area,
        building_area,
        owned_area,
        leased_area,
        educational_capacity,
        dining_capacity,
        gym_area,
        hall_area,
        dormitory_area,
        pcs_count,
        pcs_internet_access,
        interactive_sets,
      },
    });
  };

  const columns = [
    {
      title: <span style={{ padding: "10px", color: "#773DBD" }}>#</span>,
      dataIndex: "id",
      key: "id",
      style: { fontSize: "26px", fontWeight: "bold", color: "#773DBD" },
      sorter: false,
      width: 50,
    },
    {
      title: <span style={{ color: "#773DBD", padding: "5px" }}>Название</span>,
      dataIndex: "title",
      key: "title",
      style: { fontSize: "26px", color: "#773DBD" },
      sorter: false,
    },
    {
      title: <span style={{ padding: "2px", color: "#773DBD" }}>Действие</span>,
      dataIndex: "delete",
      key: "delete",
      width: 90,
      render: (_, record) => (
        <span>
          <Tooltip title="Редактировать">
            {editIcon(
              record.id,
              record.title,
              record.foundation_year,
              record.territory_area,
              record.building_area,
              record.owned_area,
              record.leased_area,
              record.educational_capacity,
              record.dining_capacity,
              record.gym_area,
              record.hall_area,
              record.dormitory_area,
              record.pcs_count,
              record.pcs_internet_access,
              record.interactive_sets
            )}
          </Tooltip>
          <Tooltip title="Удалить">
            <Popconfirm
              title="Вы уверены, что хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => handleDelete(record.id)}
            >
              {deleteIcon}
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/auditfund/v1/deleteBuilding?id=${id}`,
        HeadersAuth
      );

      setBuildingData((prevData) => {
        const newData = prevData.data.filter((item) => item.id !== id);
        return {
          ...prevData,
          data: newData,
        };
      });

      message.success("Аудитория была удалена!");
    } catch (error) {
      message.error("Ошибка удаления аудитории");
    }
  };

  const data = buildingData.data.map((item) => ({
    id: item.id,
    title: item.title,
    foundation_year: item.foundation_year,
    territory_area: item.territory_area,
    building_area: item.building_area,
    owned_area: item.owned_area,
    leased_area: item.leased_area,
    educational_capacity: item.educational_capacity,
    dining_capacity: item.dining_capacity,
    gym_area: item.gym_area,
    hall_area: item.hall_area,
    dormitory_area: item.dormitory_area,
    pcs_count: item.pcs_count,
    pcs_internet_access: item.pcs_internet_access,
    interactive_sets: item.interactive_sets,
  }));

  return (
    <>
      <Col span={24}>
        <Content
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            >
              <span style={{ fontWeight: "500", fontSize: "24px" }}>
                <span style={{ fontWeight: "500", fontSize: "24px" }}>
                  {t("Корпуса")} ({buildingData.data.length})
                </span>
              </span>
            </Col>
            <Col
              span={12}
              style={{
                textAlign: "end",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "0 20px 0 0",
              }}
            >
              <Button
                onClick={() => navigate("/classroom/create")}
                className="speciality_create_button"
              >
                <PlusOutlined
                  style={{ display: "inline-block", fontSize: "16px" }}
                />
                <span
                  style={{
                    fontWeight: "400",
                    marginTop: "-20px",
                    lineHeight: "0px",
                  }}
                >
                  Добавить
                </span>
              </Button>
            </Col>
          </Row>
          <div
            className="speciality_table"
            style={{ fontSize: "20px", padding: "20px" }}
          >
            <Table dataSource={data} bordered columns={columns} />
          </div>
          <Row>
            <Col
              span={12}
              style={{ paddingBottom: "30px", marginTop: "-55px" }}
            >
              <span
                style={{
                  marginLeft: "28px",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                Показаны 1-{Math.min(data.length, 20)} из {data.length} записи
              </span>
            </Col>
          </Row>
        </Content>
      </Col>
    </>
  );
};
