import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Select, Form, Button, Row, Col, Divider, Input, message } from "antd";
import axios from "axios";
import { Cookies } from "react-cookie";
import { COOKIES_ENUM } from "../../../constants";
import { Content } from "antd/es/layout/layout";
import {
  FirstLevelTabs,
  MyBreadcrumb,
  SecondLevelTabs,
} from "../../../components";
import {
  QualificationVM,
  SpecialityVM,
} from "../../InterfacesApi/InterfacesApi";
import { HeadersAuth } from "../../../constants/Auth";

export const SpecialityEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [inputStates, setInputStates] = useState({
    educationProfile: false,
    eduDirection: false,
  });
  const [specialities, setSpecialities] = useState<SpecialityVM[]>([]);
  const [qualifications, setQualifications] = useState<QualificationVM[]>([]);
  const [selectedSpeciality, setSelectedSpeciality] =
    useState<SpecialityVM | null>(null);
  const [selectedQualification, setSelectedQualification] =
    useState<QualificationVM | null>(null);
  useEffect(() => {
    axios
      .get<SpecialityVM[]>(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/specialitiesIndex`,
        HeadersAuth
      )
      .then((response) => {
        const specialitiesData: SpecialityVM[] = response.data;
        setSpecialities(specialitiesData);
        const initialSpeciality = specialitiesData.find(
          (spec) => spec.id === Number(id)
        );
        setSelectedSpeciality(initialSpeciality || null);
        setInputStates((prevInputStates) => ({
          ...prevInputStates,
          educationProfile: !!initialSpeciality,
        }));
      })
      .catch((error) => {
        console.error("Ошибка получения специальностей:", error);
      });
  }, [id]);

  const prevSelectedSpeciality = useRef(selectedSpeciality);

  useEffect(() => {
    if (
      selectedSpeciality &&
      selectedSpeciality !== prevSelectedSpeciality.current
    ) {
      axios
        .get<QualificationVM[]>(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/qualificationsIndexAdd?speciality_id=${selectedSpeciality.id}`,
          HeadersAuth
        )
        .then((response) => {
          const qualificationsData: QualificationVM[] = response.data;
          setQualifications(qualificationsData);
          const initialQualification = qualificationsData.find(
            (qual) => qual.id === selectedQualification?.id
          );
          setSelectedQualification(initialQualification || null);
          setInputStates((prevInputStates) => ({
            ...prevInputStates,
            eduDirection: !!initialQualification,
          }));
        })
        .catch((error) => {
          console.error("Ошибка получение данных:", error);
        });
      prevSelectedSpeciality.current = selectedSpeciality;
    }
  }, [selectedSpeciality, selectedQualification, prevSelectedSpeciality]);

  const handleUpdateSpeciality = () => {
    if (selectedSpeciality && selectedQualification) {
      const params = {
        id: selectedSpeciality.id,
        college_id: 1,
        qualification_id: selectedQualification.id,
      };

      axios
        .patch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/qualificationsUpdate`,
          params,
          HeadersAuth
        )
        .then(() => {
          message.success("Специальность успешно обновлены");
        })
        .catch((error) => {
          console.error("Ошибка обновления данных:", error);
          message.error("Ошибка обновления данных");
        });
    } else {
      message.error("Пожалуйста выберите повторное направление");
    }
  };

  const handleInputChange = (inputName: string) => (value: string) => {
    if (inputName === "educationProfile" || inputName === "eduDirection") {
      const selectedValue = value || null;
      if (inputName === "educationProfile") {
        const selectedSpeciality = specialities.find(
          (spec) => spec.title === selectedValue
        );
        setSelectedSpeciality(selectedSpeciality || null);
        setSelectedQualification(null);
      } else {
        const selectedQualification = qualifications.find(
          (qual) => qual.title === selectedValue
        );
        setSelectedQualification(selectedQualification || null);
      }
      setInputStates((prevInputStates) => ({
        ...prevInputStates,
        [inputName]: !!selectedValue,
      }));
    }
  };

  const redirectToKafedra = () => {
    navigate("/institution_department/edit");
  };
  const redirectToInstitutionDiscipline = () => {
    navigate("/institution_discipline/edit");
  };
  const redirectGroup = () => {
    navigate("/group/edit");
  };
  const firstLevelTabs = [
    {
      key: "1",
      label: "Специальности",
      children: (
        <Content
          style={{
            width: "100%",
            height: "400px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row>
              <Col span={12} style={{ paddingLeft: "20px", marginTop: "10px" }}>
                <span style={{ fontWeight: "500", fontSize: "14px" }}>
                  Профиль образования
                </span>{" "}
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.educationProfile ? "success" : ""}
                >
                  <Select
                    value={
                      selectedSpeciality ? selectedSpeciality.title : undefined
                    }
                    onChange={handleInputChange("educationProfile")}
                    onSelect={handleInputChange("educationProfile")}
                    style={{
                      width: "100%",
                      ...(inputStates.educationProfile
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                  >
                    {specialities.map((spec) => (
                      <Select.Option key={spec.id} value={spec.title}>
                        {spec.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <p />
            <Row>
              <Col span={12} style={{ paddingLeft: "20px", marginTop: "20px" }}>
                <span style={{ fontWeight: "500", fontSize: "14px" }}>
                  Направление профиля
                </span>{" "}
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.eduDirection ? "success" : ""}
                >
                  <Select
                    value={
                      selectedQualification
                        ? selectedQualification.title
                        : undefined
                    }
                    onChange={handleInputChange("eduDirection")}
                    style={{
                      width: "100%",
                      ...(inputStates.eduDirection
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                  >
                    {qualifications.map((qualification) => (
                      <Select.Option
                        key={qualification.id}
                        value={qualification.title}
                      >
                        {qualification.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Divider style={{ marginTop: "67px" }} plain />
              <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  onClick={() => navigate("/speciality")}
                  style={{
                    background: "#F6F1FF",
                    color: "#773DBD",
                    height: "40px",
                    lineHeight: "0px",
                    marginRight: "17px",
                  }}
                >
                  Назад
                </Button>
                <Button
                  className="CreateButton"
                  onClick={handleUpdateSpeciality}
                >
                  Изменить
                </Button>
              </Col>
            </Row>
          </Form>
          <Row></Row>
        </Content>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Редактирование специальности" />
      <Row justify="center">
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="1" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
