import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useAuth, useLang, useTheme } from "../../hooks";
import {
  MyBackdrop,
  MyButton,
  MyImage,
  MySelect,
  MyText,
} from "../../components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../constants";
import { THEME as THEMES_CONST, LANG } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { apiGetMenuUser } from "../../store/pages/profile/api";
import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import { StyledHeader } from "./Header.styles";
import { HeaderProps } from "./Header.props";
import {
  DarkModeIcon,
  LightModeIcon,
  NotificationIcon,
  QuitIcon,
} from "../../assets";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { LogoIcon, ProfileIcon } from "../../assets";
import { selectStore } from "../../store/selector";
import { RootState } from "../../store";
import { setThemeColor } from "../../utils/setThemeColor";
import { DefaultTokenTheme } from "../../assets/themes/defaultTheme";
import dayjs from "dayjs";
import { fetchSetNotificationAction } from "../../store/pages/profile/actions";

export const Header = (props: HeaderProps): React.ReactElement => {
  const navigate = useNavigate();
  const { themeConfig, theme, handleToggleTheme } = useTheme();
  const dispatch = useDispatch();
  const { handleLogout, menu } = useAuth();
  const { handleChangeLang, lang } = useLang();

  const initialPopoverShowState = {
    menu: false,
    profile: false,
    notification: false,
    lang: false,
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [popoverShow, setPopoverShow] = useState(initialPopoverShowState);

  const {
    profile: { userProfile: profileDataState, notification: notificationState },
  }: RootState = useSelector(selectStore);

  const location = useLocation();
  const childrenLocation = `/${location.pathname.split("/")[1]}` || "/";

  useEffect(() => {
    setPopoverShow(initialPopoverShowState);
  }, [location]);

  const handleLogoutButton = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleModalOkButton = useCallback(() => {
    handleLogout();
  }, []);

  const handleModalCancelButton = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleNavigate = useCallback((route: any) => {
    navigate(route);
  }, []);

  const handleChangeLangSelect = useCallback((value: any) => {
    handleChangeLang(value);
  }, []);

  const handleClickMenu = useCallback((item: any) => {
    if (item.open === "self") {
      navigate(item.url);
    } else {
      window.location.replace(
        `${process.env.REACT_APP_FULLSTACK_URL}${item.url}`
      );
    }
  }, []);

  const langArray = [
    {
      value: LANG.RU,
      label: "RU",
    },
    {
      value: LANG.KZ,
      label: "KZ",
    },
  ];

  const handleClickProfile = () => {
    window.location.replace(
      `${process.env.REACT_APP_FULLSTACK_URL}/employee-profile/update`
    );
  };

  return (
    <>
      <MyBackdrop
        isShow={
          popoverShow.menu ||
          popoverShow.profile ||
          popoverShow.notification ||
          popoverShow.lang
        }
        setIsShow={() => setPopoverShow(initialPopoverShowState)}
      />
      <StyledHeader {...props}>
        <Row justify="center">
          <Col span={20}>
            <Row justify="space-between" align="middle">
              <Col>
                <Link to={ROUTE_PATH.MAIN} style={{ display: "flex" }}>
                  <LogoIcon />
                </Link>
              </Col>
              <Col>
                <Row align="middle" gutter={32}>
                  <Col>
                    <Row align="middle" justify="space-between">
                      <Col>
                        <Popover
                          placement="bottom"
                          trigger="click"
                          onOpenChange={(open) =>
                            setPopoverShow((prevState) => ({
                              ...prevState,
                              menu: open,
                            }))
                          }
                          content={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <MyButton
                                type="text"
                                style={{ textAlign: "left", fontSize: 16 }}
                                onClick={() =>
                                  handleNavigate(ROUTE_PATH.SPECIALITY)
                                }
                              >
                                Учебный процесс
                              </MyButton>
                              <MyButton
                                type="text"
                                style={{ textAlign: "left", fontSize: 16 }}
                                onClick={() => handleNavigate(ROUTE_PATH.RUP)}
                              >
                                Учебный план
                              </MyButton>
                              <MyButton
                                type="text"
                                style={{ textAlign: "left", fontSize: 16 }}
                                onClick={() =>
                                  handleNavigate(ROUTE_PATH.SCHEDULE_TABLE)
                                }
                              >
                                Составление расписания
                              </MyButton>
                              <MyButton
                                type="text"
                                style={{ textAlign: "left", fontSize: 16 }}
                                onClick={() =>
                                  handleNavigate(ROUTE_PATH.SCHEDULE_TABLE)
                                }
                              >
                                Расписание
                              </MyButton>
                            </div>
                          }
                        >
                          <MyButton
                            style={{
                              fontSize: 16,
                              color: "rgb(74, 81, 84)",
                              height: 40,
                            }}
                            type="text"
                            size="middle"
                          >
                            Планирование
                            <MyText
                              color={setThemeColor(
                                theme,
                                DefaultTokenTheme.textSubtitle,
                                DefaultTokenTheme.disabled
                              )}
                            ></MyText>
                          </MyButton>
                        </Popover>
                        <Popover
                          placement="bottom"
                          trigger="click"
                          onOpenChange={(open) =>
                            setPopoverShow((prevState) => ({
                              ...prevState,
                              menu: open,
                            }))
                          }
                          content={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <MyButton
                                type="text"
                                style={{ textAlign: "left", fontSize: 16 }}
                                onClick={() =>
                                  handleNavigate(ROUTE_PATH.EMPLOYEE)
                                }
                              >
                                Кадровый учет
                              </MyButton>
                              <MyButton
                                type="text"
                                style={{ textAlign: "left", fontSize: 16 }}
                                onClick={() =>
                                  handleNavigate(ROUTE_PATH.STUDENT)
                                }
                              >
                                Картотека обучающихся
                              </MyButton>
                              <MyButton
                                type="text"
                                style={{ textAlign: "left", fontSize: 16 }}
                                onClick={() =>
                                  handleNavigate(ROUTE_PATH.CLASSROOM)
                                }
                              >
                                Аудиторный фонд
                              </MyButton>
                              <MyButton
                                type="text"
                                style={{ textAlign: "left", fontSize: 16 }}
                                onClick={() =>
                                  handleNavigate(ROUTE_PATH.SETTINGS)
                                }
                              >
                                Настройки
                              </MyButton>
                            </div>
                          }
                        >
                          <MyButton
                            style={{
                              fontSize: 16,
                              color: "rgb(74, 81, 84)",
                              height: 40,
                            }}
                            type="text"
                            size="middle"
                          >
                            Управление
                            <MyText
                              color={setThemeColor(
                                theme,
                                DefaultTokenTheme.textSubtitle,
                                DefaultTokenTheme.disabled
                              )}
                            ></MyText>
                          </MyButton>
                        </Popover>
                      </Col>
                    </Row>
                  </Col>

                  <Col>
                    <Row align="middle" gutter={32}>
                      <Col>
                        <Popover
                          placement="bottomLeft"
                          trigger="click"
                          content={
                            <Row style={{ maxWidth: 300 }} gutter={[16, 8]}>
                              <Col span={24}>
                                <Row>
                                  {notificationState.data.length > 0 ? (
                                    notificationState.data
                                      .slice(0, 5)
                                      .map((item: any) => (
                                        <Col span={24} key={item.id}>
                                          <Row>
                                            <Col span={24}>
                                              <MyText
                                                color="var(--c-icon)"
                                                size={12}
                                              >
                                                {dayjs(item.created_at).format(
                                                  "DD.MM.YYYY HH:mm"
                                                )}
                                              </MyText>
                                            </Col>
                                            <Col span={24}>
                                              <MyText size={12}>
                                                {item.caption_current}
                                              </MyText>
                                            </Col>
                                          </Row>
                                        </Col>
                                      ))
                                  ) : (
                                    <Col span={24}>
                                      <MyText
                                        size={16}
                                        color="rgba(140, 140, 140, 1)"
                                        style={{ display: "block" }}
                                        align="center"
                                      >
                                        Уведомлений нету
                                      </MyText>
                                    </Col>
                                  )}
                                </Row>
                              </Col>

                              {notificationState.data.length > 0 && (
                                <>
                                  <Col span={24}>
                                    <hr
                                      style={{
                                        height: 1,
                                        backgroundColor: "var(--c-line)",
                                      }}
                                    />
                                  </Col>

                                  <Col span={24}>
                                    <MyButton
                                      width="100%"
                                      type="text"
                                      title="Отметить как прочитанное"
                                      onClick={() =>
                                        dispatch(fetchSetNotificationAction)
                                      }
                                    />
                                  </Col>
                                </>
                              )}
                            </Row>
                          }
                          onOpenChange={(open) =>
                            setPopoverShow((prevState) => ({
                              ...prevState,
                              notification: open,
                            }))
                          }
                        >
                          <Row>
                            <Badge
                              count={notificationState.data.length}
                              offset={[4, -2]}
                              color={DefaultTokenTheme.primary}
                              styles={{ indicator: { boxShadow: "none" } }}
                            >
                              <NotificationIcon style={{ cursor: "pointer" }} />
                            </Badge>
                          </Row>
                        </Popover>
                      </Col>

                      <Col>
                        <Popover
                          placement="bottomLeft"
                          trigger="click"
                          content={
                            <Row style={{ width: 160 }}>
                              <Col span={24}>
                                <Typography.Text
                                  strong
                                  style={{ fontSize: 14 }}
                                >
                                  {`${profileDataState.data?.lastname} ${profileDataState.data?.firstname} ${profileDataState.data?.middlename}`}
                                </Typography.Text>
                              </Col>

                              {/*<Col span={24}>*/}
                              {/*  <Typography.Text type='secondary' style={{fontSize: 12}}>*/}
                              {/*    Админ*/}
                              {/*  </Typography.Text>*/}
                              {/*</Col>*/}

                              <Col span={24}>
                                <Divider style={{ margin: "12px 0" }} />
                              </Col>

                              <Col span={24}>
                                <MyButton
                                  type="text"
                                  title="Профиль"
                                  onClick={() => handleClickProfile()}
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 6,
                                    padding: 0,
                                  }}
                                  icon={
                                    <UserOutlined style={{ fontSize: 20 }} />
                                  }
                                />
                              </Col>

                              {/*<Col span={24}>*/}
                              {/*  <MyButton type='text' title='Кураторство' onClick={() => handleNavigate(ROUTE_PATH.CURATING)}*/}
                              {/*            style={{width: '100%', display: 'flex', alignItems: 'center', gap: 6, padding: 0}}*/}
                              {/*            icon={<UserOutlined style={{fontSize: 20}}/>}/>*/}

                              {/*</Col>*/}

                              <Col span={24}>
                                <MyButton
                                  type="text"
                                  title="Выйти"
                                  onClick={handleLogoutButton}
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 6,
                                    padding: 0,
                                  }}
                                  icon={<QuitIcon style={{ fontSize: 20 }} />}
                                />
                              </Col>
                            </Row>
                          }
                          onOpenChange={(open) =>
                            setPopoverShow((prevState) => ({
                              ...prevState,
                              profile: open,
                            }))
                          }
                        >
                          <Row style={{ cursor: "pointer" }}>
                            <ProfileIcon />
                          </Row>
                        </Popover>
                      </Col>

                      <Col>
                        <Popover
                          placement="bottom"
                          trigger="click"
                          content={
                            <Row gutter={[16, 8]}>
                              {langArray.map((item) => (
                                <Col
                                  key={item.value}
                                  span={24}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleChangeLangSelect(item.value)
                                  }
                                >
                                  <MyText>{item.label}</MyText>
                                </Col>
                              ))}
                            </Row>
                          }
                          onOpenChange={(open) =>
                            setPopoverShow((prevState) => ({
                              ...prevState,
                              lang: open,
                            }))
                          }
                        >
                          <MyText
                            style={{ cursor: "pointer" }}
                            color={setThemeColor(
                              theme,
                              DefaultTokenTheme.textSubtitle,
                              DefaultTokenTheme.disabled
                            )}
                          >
                            {langArray.find((item) => item.value === lang)
                              ?.label || langArray[0].label}
                          </MyText>
                        </Popover>
                      </Col>

                      <Col>
                        <Row>
                          {theme === THEMES_CONST.LIGHT ? (
                            <DarkModeIcon onClick={handleToggleTheme} />
                          ) : (
                            <LightModeIcon onClick={handleToggleTheme} />
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledHeader>

      <Modal
        title="Выход из личного кабинета"
        width={708}
        centered
        open={isModalOpen}
        onOk={handleModalOkButton}
        onCancel={handleModalCancelButton}
        okText="Выйти"
        cancelText="Отмена"
        className="modal-restore"
        closeIcon={<CloseOutlined style={{ color: "#fff" }} />}
        okButtonProps={{
          danger: true,
          ghost: true,
        }}
      >
        <Row>
          <Col span={24}>
            <MyText
              color={themeConfig?.token?.colorText}
              align="center"
              size={16}
            >
              Вы уверены, что хотите выйти из личного кабинета?
            </MyText>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
