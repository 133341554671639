import {call, put, takeLatest, select, takeEvery} from "redux-saga/effects";

import {apiSetForm, apiGetCtp, apiGetForm, apiGetCtpFilter} from "./api";

import {
  getCtpAction,
  getCtpActionDone,
  getCtpActionFail,
  getPeriodAction,
  getPeriodActionDone,
  getPeriodActionFail,
  setFormActionDone,
  setFormActionFail,
  setFormAction,
  getFormAction,
  getFormActionDone,
  getFormActionFail,
  getFormExtraAction,
  getFormExtraActionFail,
  getFormExtraActionDone,
  getCtpFilterActionDone,
  getCtpFilterActionFail,
  getCtpFilterAction, getPeriodByGroupActionDone, getPeriodByGroupActionFail, getPeriodByGroupAction

} from "./actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {IFormType, ISendFormType} from "./types";

import {selectStore} from "../selector";
import {RootState} from "../index";
import {axiosConfig as axios} from "../../utils/axios";
import reducerStatuses, {IStatus} from "../status";

function* getFormFlow({payload}: PayloadAction<IFormType>): any {
  const {common: {form: dataState}}: RootState = yield select(selectStore)

  const currentData = dataState[payload.section_type][payload.section_inner_type][payload.form_type]

  if (currentData.success) {
    yield put(getFormActionDone({
      ...payload,
      data: currentData.data
    }));
  } else {
    try {
      const data = yield call(apiGetForm, payload.api);
      if (data.data) {
        let body = {
          ...payload, data: data.data
        }
        yield put(getFormActionDone(body));

        if (payload.extra) {
          // @ts-ignore
          yield call(getFormExtraFlow, {payload: body})
        }
      }
    } catch (e) {
      console.warn(e);
      yield put(getFormActionFail(payload));
    }
  }
}

function* setFormFlow({payload}: PayloadAction<ISendFormType>): any {

  let initialState: IStatus & { data: any } = {
    ...reducerStatuses,
    data: null as any,
  }

  if (payload.action) {
    yield put(payload.action({ ...initialState, loading: true, data: payload.fields}));
  }

  try {
    const data = yield call(apiSetForm, payload);
    if (data) {
      yield put(setFormActionDone(data.data));

      if (payload.action) {
        yield put(payload.action({
          ...initialState,
          success: true,
          data: {...payload.fields, ...data.data},
        }));
      }
    }
  } catch (e) {
    console.error(e);

    if (payload.action) {
      yield put(payload.action({...initialState, failed: true, data: payload.fields}));
    }

    yield put(setFormActionFail(payload));
  }
}

function* getCtpFlow({payload}: PayloadAction<any>): any {
  const {common: {ctp: dataState}}: RootState = yield select(selectStore)
  // if (dataState.success) {
  //   yield put(getCtpActionDone(dataState.data));
  // } else {
    try {
      const data = yield call(apiGetCtp, payload);
      if (data.data) {
        yield put(getCtpActionDone(data.data));
      }
    } catch (e) {
      console.error(e);
      yield put(getCtpActionFail());
    }
  // }
}

function* getCtpFilterFlow({payload}: PayloadAction<any>): any {
  const {common: {ctp: dataState}}: RootState = yield select(selectStore)
  try {
    const data = yield call(apiGetCtpFilter, payload);
    if (data.data) {
      yield put(getCtpFilterActionDone(data.data));
    }
  } catch (e) {
    console.error(e);
    yield put(getCtpFilterActionFail());
  }
}

// function* getPeriodFlow({payload}: PayloadAction<any>): any {
//   const {common: {period: dataState}}: RootState = yield select(selectStore)
//   if (dataState.success) {
//     yield put(getPeriodActionDone(dataState.data));
//   } else {
//     try {
//       const data = yield call(apiGetPeriod, payload);
//       if (data.data) {
//         yield put(getPeriodActionDone(data.data));
//       }
//     } catch (e) {
//       console.error(e);
//       yield put(getPeriodActionFail());
//     }
//   }
// }

// export function* getPeriodByGroupFlow({payload}: PayloadAction<any>): any {
//   try {
//     const data = yield call(apiGetPeriod, payload);
//     if (data.data) {
//       yield put(getPeriodByGroupActionDone(data.data));
//     }
//   } catch (e) {
//     console.error(e);
//     yield put(getPeriodByGroupActionFail());
//   }
// }

/**
  description: Страшная хуйня, которая нужна для прокидывания source_url для копирование планов
 **/
function* getFormExtraFlow({payload}: PayloadAction<any>): any {
  const {common: {form: dataState}}: RootState = yield select(selectStore)
  const currentData = dataState[payload.section_type][payload.section_inner_type][payload.form_type]

  let url = 'rest'

  const checkExtra = currentData.data[0].fields.find((item: any) => item[payload.extra.field_name])

  yield put(getFormExtraAction(payload))

  try {
    const data = yield call(() => axios.get(`${url}${checkExtra[payload.extra.field_name]}${payload.extra.extra_value}`))

    if (data.data) {

      let bodyData = payload.data.map((item: any) => ({
        ...item,
        fields: item.fields.map((fieldItem: any) => fieldItem[payload.extra.field_name] ? ({
          ...fieldItem,
          fieldValues: data.data.map((dataSourceItem: any) => ({
            label: `${dataSourceItem.module ? `${dataSourceItem.module} -` : null} ${dataSourceItem.subject ? `${dataSourceItem.subject} -` : null} ${dataSourceItem.group ? `${dataSourceItem.group}` : null}`,
            value: dataSourceItem.id
          }))
        }) : fieldItem)
      }))

      let body = {
        ...payload, data: bodyData
      }

      yield put(getFormExtraActionDone(body));
    }
  } catch (e) {
    console.error(e);
    yield put(getFormExtraActionFail(payload));
  }
}




export default function* journalSaga() {
  yield takeEvery(getFormAction, getFormFlow);
  yield takeLatest(setFormAction, setFormFlow);
  yield takeLatest(getCtpAction, getCtpFlow);
  yield takeLatest(getCtpFilterAction, getCtpFilterFlow);
  //  
  // yield takeLatest(getPeriodByGroupAction, getPeriodByGroupFlow);
}
