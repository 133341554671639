import {call, put, takeLatest, select, takeEvery} from "redux-saga/effects";

import {
  apiGetJournalTable, apiPerformance
} from "./api";
import {
  getGetPerformance,
  getGetPerformanceDone, getGetPerformanceFail,
  getJournalListAction,
  getJournalListActionDone,
  getJournalListActionDoneData,
  getJournalListActionFail,
  getJournalTableAction,
  getJournalTableActionDone,
  getJournalTableActionFail, journalIndividualColumnUpdateAction, journalIndividualColumnUpdateActionDone,
  journalIndividualColumnUpdateActionFail,
  journalIndividualMarkAddAction, journalIndividualMarkAddActionDone, journalIndividualMarkAddActionFail,
  journalIndividualMarkEditAction,
  journalIndividualMarkEditActionDone, journalIndividualMarkEditActionFail,
  journalMarkAction,
  journalMarkActionDone,
  journalMarkActionFail,
  postJournalLessonAction, postJournalLessonActionFail,
} from "./actions";
import {PayloadAction} from "@reduxjs/toolkit";

import {apiGetCtp} from "../../common/api";
import {IJournalTable, IJournalType} from "./types";
import {RootState} from "../../index";
import {selectStore} from "../../selector";
import {message} from "antd";

function* getJournalListFlow({ payload }: PayloadAction<IJournalType>): any {
  // const {journal: {list: dataState}}: RootState = yield select(selectStore)
  //
  // let myData = dataState[payload?.journal_type]?.find(item => item?.year === payload?.body?.year)?.data
  //
  // if(myData?.success){
  //   yield put(getJournalListActionDoneData({
  //     ...payload,
  //     data: myData.data
  //   }))
  // } else {
    try {
      const data = yield call(apiGetCtp, payload);
      if (data.data) {
        // let body = {
        //   ...payload,
        //   data: data.data,
        // }

        yield put(getJournalListActionDone(data.data));
      }
    } catch (e) {
      console.warn(e);
      yield put(getJournalListActionFail(payload));
    }
  // }

}

function* getJournalTableFlow({payload}: PayloadAction<IJournalTable>): any {
  try {
    const data = yield call(apiGetJournalTable, payload);
    if (data.data) {
      yield put(getJournalTableActionDone(data.data));
    }
  } catch (e) {
    console.error(e);
    yield put(getJournalTableActionFail(payload));
  }
}

function* postJournalLessonFlow({payload}: PayloadAction<any>): any {
  const isDone = payload.success
  const isFail = payload.failed

  if(isDone){
    // @ts-ignore
    yield call(getJournalTableFlow, {payload: payload.data})
  }

  if(isFail){
    yield put(postJournalLessonActionFail(payload))
  }
}

function* journalMarkFlow({payload}: PayloadAction<any>): any {
  const isDone = payload.success
  const isFail = payload.failed

  if(isDone){
    // @ts-ignore
    yield call(getJournalTableFlow, {payload: payload.data})
    message.success('Оценка успешно сохранена!')
    //   yield put(journalMarkActionDone(payload))
  }


  if(isFail){
    yield put(journalMarkActionFail(payload))
  }
}

function* journalIndividualMarkEditFlow({payload}: PayloadAction<any>): any {
  const isDone = payload.success
  const isFail = payload.failed

  if(isDone){
    yield put(journalIndividualMarkEditActionDone(payload))
  }

  if(isFail){
    yield put(journalIndividualMarkEditActionFail(payload))
  }
}

function* journalIndividualMarkAddFlow({payload}: PayloadAction<any>): any {
  const isDone = payload.success
  const isFail = payload.failed

  if(isDone){
    yield put(journalIndividualMarkAddActionDone(payload))
  }

  if(isFail){
    yield put(journalIndividualMarkAddActionFail(payload))
  }
}

function* journalIndividualColumnUpdateFlow({payload}: PayloadAction<any>): any {
  const isDone = payload.success
  const isFail = payload.failed

  if(isDone){
    yield put(journalIndividualColumnUpdateActionDone(payload))
  }

  if(isFail){
    yield put(journalIndividualColumnUpdateActionFail(payload))
  }
}

function* getPerformanceFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiPerformance, payload);
    if (data.data) {
      yield put(getGetPerformanceDone(data.data));
    }
  } catch (e) {
    yield put(getGetPerformanceFail());
  }
}

export default function* journalSaga() {
  yield takeEvery(getJournalListAction, getJournalListFlow);
  yield takeEvery(getJournalTableAction, getJournalTableFlow);

  yield takeLatest(postJournalLessonAction, postJournalLessonFlow);
  yield takeLatest(journalMarkAction, journalMarkFlow);
  yield takeLatest(journalIndividualMarkEditAction, journalIndividualMarkEditFlow);
  yield takeLatest(journalIndividualMarkAddAction, journalIndividualMarkAddFlow);
  yield takeLatest(journalIndividualColumnUpdateAction, journalIndividualColumnUpdateFlow);
  yield takeLatest(getGetPerformance, getPerformanceFlow);
}