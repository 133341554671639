import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {generatePath, Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {getOneLessonAction} from "../../../store/pages/plans/actions";
import {RootState} from "../../../store";
import {selectStore} from "../../../store/selector";
import {MyBreadcrumb, MyText, SecondLevelTabs} from "../../../components";
import {Col, Empty, List, Row, Spin, TabsProps} from "antd";
import {StyledInfoContainer} from "../../Plans/[planId]/index.styles";
import {PLANS_FORM_TYPES} from "../../../constants";
import {setThemeColor} from "../../../utils/setThemeColor";
import {DefaultTokenTheme} from "../../../assets/themes/defaultTheme";
import {useTheme} from "../../../hooks";

export const ScheduleStudentPlan = () => {
  const {t} = useTranslation("common")
  const {lessonId} = useParams()
  const {theme} = useTheme()

  const dispatch = useDispatch()
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const {plans: {oneLesson: oneLessonState}}: RootState = useSelector(selectStore)

  const [currentTab, setCurrentTab] = useState(PLANS_FORM_TYPES.MATERIALS)

  const handleTabChange = useCallback((key: any) => {
    setCurrentTab(key)
  }, [])

  useEffect(() => {
    dispatch(getOneLessonAction({lesson_id: lessonId}))
  }, []);

  const materialItemsArray = (item: any) => {
    return [
      {
        label: t('label'),
        value: item.name,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.name}
          </MyText>
        )
      },
      {
        label: t('public'),
        value: item.is_shared,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.is_shared ? t('yes') : t('no')}
          </MyText>
        )
      },
      {
        label: t('videoUrlAddress'),
        value: item.video_url,
        component: (
          <MyText color={DefaultTokenTheme.link}>
            {item.video_url}
          </MyText>
        )
      },
      {
        label: t('source'),
        value: item.source,
        component: (
          <MyText color={DefaultTokenTheme.link}>
            {item.source}
          </MyText>
        )
      },
      {
        label: t('annotation'),
        value: item.content,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.content}
          </MyText>
        )
      },
      {
        label: t('urlAddress'),
        value: item.link_url,
        component: (
          <MyText color={DefaultTokenTheme.link}>
            {item.link_url}
          </MyText>
        )
      },
      {
        label: t('textLink'),
        value: item.link_text,
        component: (
          <MyText color={DefaultTokenTheme.link}>
            {item.link_text}
          </MyText>
        )
      },
      {
        label: t('broadcastsStartTime'),
        value: item.live_time,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.live_time}
          </MyText>
        )
      },
      {
        label: t('broadcastsStartDate'),
        value: item.live_date,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.live_date}
          </MyText>
        )
      },
      {
        label: t('welcomeText'),
        value: item.welcome,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.welcome}
          </MyText>
        )
      },
      {
        label: t('pdf'),
        value: item.presentation,
        component: (
          item.presentation?.map((file: any, index: any) => (
            <Link to={file} key={index} style={{color:DefaultTokenTheme.link}}>
              {file.split('/')[file.split('/').length - 1]}
            </Link>
          )))
      },
      {
        label: t('zoomUrlAddress'),
        value: item.url,
        component: (
          <MyText color={DefaultTokenTheme.link}>
            {item.url}
          </MyText>
        )
      },
      {
        label: t('file'),
        value: item.file,
        component: (
          Array.isArray(item.file) ? item.file?.map((file: any, index: any) => (
              <Link to={file} key={index} style={{color: DefaultTokenTheme.link}}>
                {file?.split('/')[file?.split('/').length - 1]}
              </Link>
            ))
            : <Link to={item.file} style={{color: DefaultTokenTheme.link}}>
              {item.file}
            </Link>
        )
      },
    ]
  }

  const tasksItemsArray = (item: any) => {
    return [
      {
        label: t('label'),
        value: item.name,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.name}
          </MyText>
        )
      },
      {
        label: t('public'),
        value: item.is_shared,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.is_shared ? t('yes') : t('no')}
          </MyText>
        )
      },
      {
        label: t('content'),
        value: item.content,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.content}
          </MyText>
        )
      },
      {
        label: t('file'),
        value: item.files,
        component: (
          Array.isArray(item.files) ? item.files?.map((file: any, index: any) => (
              <Link to={file} key={index} style={{color: DefaultTokenTheme.link}}>
                {file?.split('/')[file?.split('/').length - 1]}
              </Link>
            ))
            : <Link to={item.files} style={{color: DefaultTokenTheme.link}}>
              {item.files}
            </Link>
        )
      },
    ]
  }

  const testsItemsArray = (item: any) => {
    return [
      {
        label: t('label'),
        value: item.name,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.name}
          </MyText>
        )
      },
      {
        label: t('public'),
        value: item.is_shared,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.is_shared ? t('yes') : t('no')}
          </MyText>
        )
      },
      {
        label: t('description'),
        value: item.content,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.content}
          </MyText>
        )
      },
      {
        label: t('questionCount'),
        value: item.qcount,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.qcount}
          </MyText>
        )
      },
      {
        label: t('timeToCompleteOnMinute'),
        value: item.time,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.time}
          </MyText>
        )
      },
    ]
  }

  const materialContent = useCallback((item: any) => {
    return materialItemsArray(item)?.filter((inner: any) => inner.value)?.map((inner: any, index: any) => (
      <Col key={index} span={24}>
        <Row align='middle' gutter={6}>
          <Col span={7}>
            <MyText color={setThemeColor(theme, DefaultTokenTheme.textSubtitle, DefaultTokenTheme.subtitleDark)} weight={500}>
              {inner.label}:
            </MyText>
          </Col>

          <Col>
            {inner.component}
          </Col>
        </Row>
      </Col>
    ))
  }, [])

  const tasksContent = useCallback((item: any) => {
    return tasksItemsArray(item)?.filter((inner: any) => inner.value)?.map((inner: any, index: any) => (
      <Col key={index} span={24}>
        <Row align='middle' gutter={6}>
          <Col span={7}>
            <MyText color={setThemeColor(theme, DefaultTokenTheme.textSubtitle, DefaultTokenTheme.subtitleDark)} weight={500}>
              {inner.label}:
            </MyText>
          </Col>

          <Col>
            {inner.component}
          </Col>
        </Row>
      </Col>
    ))
  }, [])

  const testsContent = useCallback((item: any) => {
    return testsItemsArray(item)?.filter((inner: any) => inner.value)?.map((inner: any, index: any) => (
      <Col key={index} span={24}>
        <Row align='middle' gutter={[6, 16]}>
          <Col span={7}>
            <MyText color={setThemeColor(theme, DefaultTokenTheme.textSubtitle, DefaultTokenTheme.subtitleDark)} weight={500}>
              {inner.label}:
            </MyText>
          </Col>

          <Col>
            {inner.component}
          </Col>
        </Row>
      </Col>
    ))
  }, [])

  const homeWorkContent = useCallback((item: any) => {
    return null
  }, [])

  const innerRender = useMemo(() => {
    const main = oneLessonState.data ? oneLessonState.data[currentTab] : []
    return (
      <List
        dataSource={main}
        rowKey={(record: any) => record.id}
        style={{width: '100%'}}
        loading={oneLessonState.loading}
        locale={{
          emptyText: <Empty description={t('noRecords')}/>
        }}
        renderItem={(item: any) => {
          switch (currentTab) {
            case PLANS_FORM_TYPES.MATERIALS:
              return (
                <List.Item style={{padding: 16}}>
                  <Row style={{width: '100%'}} gutter={[8, 16]}>
                    {materialContent(item)}
                  </Row>
                </List.Item>
              )
            case PLANS_FORM_TYPES.TASKS:
              return (
                <List.Item style={{padding: 16}}>
                  <Row style={{width: '100%'}} gutter={[8, 16]}>
                    {tasksContent(item)}
                  </Row>
                </List.Item>
              )
            case PLANS_FORM_TYPES.TESTS:
              return (
                <List.Item style={{padding: 16}}>
                  <Row style={{width: '100%'}} gutter={[8, 16]}>
                    {testsContent(item)}

                    {/*{*/}
                    {/*  item.questions?.length > 0 &&*/}
                    {/*    <Col span={24}>*/}
                    {/*        <MyButton onClick={() => handleNavigate(ROUTE_PATH.STUDENTS_SCHEDULE_PLAN_TEST, item.id)}>*/}
                    {/*            Пройти тест*/}
                    {/*        </MyButton>*/}
                    {/*    </Col>*/}
                    {/*}*/}

                  </Row>
                </List.Item>
              )
            case PLANS_FORM_TYPES.HOME_WORK:
              return (
                <List.Item style={{padding: 16}}>
                  <Row style={{width: '100%'}} gutter={[8, 16]}>
                    {homeWorkContent(item)}
                  </Row>
                </List.Item>
              )
            default:
              return false
          }
        }}
      />
    )
  }, [oneLessonState, currentTab])

  const tabItems: TabsProps["items"] = [
    {
      key: PLANS_FORM_TYPES.MATERIALS,
      label: t('materials'),
      children: innerRender
    },
    {
      key: PLANS_FORM_TYPES.TASKS,
      label: t('tasks'),
      children: innerRender
    },
    {
      key: PLANS_FORM_TYPES.TESTS,
      label: t('tests'),
      children: innerRender
    },
    {
      key: PLANS_FORM_TYPES.HOME_WORK,
      label: t('homeWorks'),
      children: innerRender
    },
  ]

  const handleNavigate = useCallback((url: any, testId: any) => {
    const path = {
      // @ts-ignore
      pathname: generatePath(url, {lessonId: lessonId, testId: testId}),
    }
    navigate(path)
  }, [])

  return (
    <>
      <MyBreadcrumb title={t('back')} isBack/>

      <Row justify='center'>
        <Col span={20}>
          <StyledInfoContainer>
            <Spin spinning={oneLessonState.loading}>
              <Row style={{borderBottom: `1px solid ${setThemeColor(theme, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)}`, padding: 24}}>
                <Col>
                  <h1 style={{color: setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}}>{oneLessonState.data?.header}</h1>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{padding: 24}}>
                {
                  oneLessonState.data?.view?.filter((item: any) => item.template !== 'accordion')?.map((item: any) => (
                    item.value &&
                    <Col span={12} key={item.label}>
                        <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                          {item.label} : {item.value}
                        </MyText>
                    </Col>
                  ))
                }
              </Row>
            </Spin>
          </StyledInfoContainer>

          <StyledInfoContainer>
            <SecondLevelTabs activeKey={currentTab} items={tabItems} onChange={handleTabChange}/>
          </StyledInfoContainer>
        </Col>
      </Row>

    </>
  )
}
