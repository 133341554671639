export const JOURNAL_TYPES = {
  THEORETICAL: 'theoretical',
  INDIVIDUAL: 'individual',
  INDUSTRIAL: 'industrial',
  FACULTATIVE: 'facultative'
}


export const JOURNAL_TYPES_API: any = {
  [JOURNAL_TYPES.THEORETICAL]: 1,
  [JOURNAL_TYPES.INDIVIDUAL]: 3,
  [JOURNAL_TYPES.INDUSTRIAL]: 4,
  [JOURNAL_TYPES.FACULTATIVE]: 5
}

export const JOURNAL_QUERY_PARAMS = {
  YEAR: 'year',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  TYPE: 'type',
}

//todo: мб сделать генератор годов, которому будешь скармливать год и он от него будет генерить другие года, ввида
// function generateYears(2022) {
//   const currentYear = new Date().getFullYear();
//   const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index);
//   const yearsObject = years.reduce((acc, year, index) => {
//     acc[index] = year;
//     return acc;
//   }, {});
//   return yearsObject;
// }

export const JOURNAL_YEAR = {
  PREV: '2022',
  CURRENT: '2023',
  NEXT: '2024',
}