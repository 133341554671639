import styled from "styled-components";
import {Tabs} from "antd";
import {setThemeColor} from "../../../../../utils/setThemeColor";
import {DefaultTokenTheme} from "../../../../../assets/themes/defaultTheme";

export const TabsStyles = styled(Tabs)`
  .ant-tabs-nav {
    &:before {
      border-bottom-color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};
    }

    .ant-tabs-nav-list {
      & > .ant-tabs-tab {
        border: 1px solid ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};

        &.ant-tabs-tab-active {
          background-color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.primary, DefaultTokenTheme.primary)};

          .ant-tabs-tab-btn {
            color: ${({theme}) => setThemeColor(theme.current, '#fff', '#fff')};
          }
        }
      }

      .ant-tabs-tab-remove {
        svg {
          fill: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.textTitle, '#fff')};
        }
      }

      .ant-tabs-nav-add {
        border: 1px solid ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};

        svg {
          fill: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.textTitle, '#fff')};
        }

      }
    }
  }

  .ant-tabs-content-holder {

  }


  //.ant-tabs-tab{
  //  .ant-tabs-tab-btn{
  //    width: 80px;
  //    overflow: hidden;
  //    text-overflow: ellipsis;
  //    white-space: nowrap;
  //  }
  //}

`
