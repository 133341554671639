export const dataPD = [
    {
      content: "ФИО",
      info: "(Не задано)",
    },
    {
      content: "ИИН",
      info: "(Не задано)",
    },
    {
      content: "Дата рождения",
      info: "(Не задано)",
    },
    {
      content: "Место рождения",
      info: "(Не задано)",
    },
    {
      content: "Гражданство",
      info: "(Не задано)",
    },
    {
      content: "Пол",
      info: "(Не задано)",
    },
    {
      content: "Национальность",
      info: "(Не задано)",
    },
    {
      content: "Язык обучения",
      info: "(Не задано)",
    },
    {
      content: "Специальность",
      info: "(Не задано)",
    },
    {
        content: "Квалификация",
        info: "(Не задано)",
      },
      {
        content: "Курс",
        info: "(Не задано)",
      },
      {
        content: "Группа",
        info: "(Не задано)",
      },
      {
        content: "Основа обучения",
        info: "(Не задано)",
      },
      {
        content: "Срок обучения",
        info: "(Не задано)",
      },
  ];


  export const dataContact = [
    {
      content: "Домашний телефон",
      info: "(Не задано)",
    },
    {
      content: "Мобильный телефон",
      info: "(Не задано)",
    },
    {
      content: "Адрес переписки",
      info: "(Не задано)",
    },
    {
      content: "Домашний адрес",
      info: "(Не задано)",
    },

    {
        content: "E-mail",
        info: "(Не задано)",
      },
  ];

  export const dataJob = [
    {
      content: "Название",
      info: "(Не задано)",
    },
    {
      content: "Ставка",
      info: "(Не задано)",
    },
    {
        content: "Изменить должность",
        info: "(Не задано)",
      },
  ];

  export const dataAuth = [
    {
      content: "Логин",
      info: "(Не задано)",
    },
    {
      content: "Пароль",
      info: "(Не задано)",
    },
   
  ];



  export const dataAwards = [
    {
      content: "Награда",
      info: "(Не задано)",
    },
    {
      content: "Персональные названия",
      info: "(Не задано)",
    },
    {
      content: "Ученая степень",
      info: "(Не задано)",
    },
   
  ];


  export const dataLg = [
    {
      content: "Язык",
      info: "(Не задано)",
    },
    {
      content: "Владение языком",
      info: "(Не задано)",
    },
  ];

  export const dataIDBirth = [
    {
      content: "Номер свидетельство о рождении",
      info: "(Не задано)",
    },
    {
      content: "Дата выдачи",
      info: "(Не задано)",
    },
    {
      content: "Серия свидетельство о рождении",
      info: "(Не задано)",
    },
   
  ];

  export const dataID = [
    {
      content: "Номер удостоверения личности",
      info: "(Не задано)",
    },
    {
      content: "Дата выдачи",
      info: "(Не задано)",
    },
    {
      content: "Действительно до",
      info: "(Не задано)",
    },
    {
      content: "Кем выдано",
      info: "(Не задано)",
    },
   
  ];
  

  export const dataPassport = [
    {
      content: "Номер паспорта ",
      info: "(Не задано)",
    },
    {
      content: "Дата выдачи",
      info: "(Не задано)",
    },
    {
      content: "Действителен до ",
      info: "(Не задано)",
    },
    {
      content: "Кем выдано",
      info: "(Не задано)",
    },
    {
      content: "Серия",
      info: "(Не задано)",
    },
   
  ];


  export const dataAbEdu = [
    {
      content: "Группа",
      info: "(Не задано)",
    },
    {
      content: "Дата зачисления",
      info: "(Не задано)",
    },
    {
        content: "Дата отчисления",
        info: "(Не задано)",
      },
  ];




  export const dataSocGroup = [
    {
      content: "Несовершеннолетние дети",
      info: "(Не задано)",
    },
    {
      content: "Дети-сиропы и дети, оставшиеся без попечения родителей ",
      info: "(Не задано)",
    },
    {
        content: "Кандасы ",
        info: "(Не задано)",
      },

    {
      content: "Опекаемые",
      info: "(Не задано)",
    },
    {
      content: "Дети-инвалиды",
      info: "(Не задано)",
    },
    {
      content: "Дети из многодетных семей	",
      info: "(Не задано)",
    },
    {
      content: "Дети из семей, имеющие право на получение адресной социальной помощи, а также дети из семей, не получающие государственную адресную социальную помощь, в которых среднедушевой доход ниже величины прожиточного минимума",
      info: "(Не задано)",
    },
    {
      content: "Дети из неполных семей",
      info: "(Не задано)",
    },
    {
      content: "Неблагополучная семья, стоящая на учете в ОВД",
      info: "(Не задано)",
    },
    {
      content: "Учащиеся, не соблюдающие требования к внешнему виду",
      info: "(Не задано)",
    },
    {
      content: "Учащиеся, состоящие на учёте в ОВД",
      info: "(Не задано)",
    },
    {
      content: "Учащиеся, состоящие на учете колледжа",
      info: "(Не задано)",
    },
    {
        content: "Семья, где родители инвалиды",
        info: "(Не задано)",
      },
      {
        content: "Дети, обучающиеся на дому",
        info: "(Не задано)",
      },
      {
        content: "Дети с ООП",
        info: "(Не задано)",
      },
      {
        content: "Проживающие с отчимами",
        info: "(Не задано)",
      },
      {
        content: "Проживающие с мачехами",
        info: "(Не задано)",
      },
      {
        content: "Склонные к суициду",
        info: "(Не задано)",
      },
      {
        content: "Склонные к правонарушению",
        info: "(Не задано)",
      },
{
      content: "Неформальные лидеры (отрицательные)",
      info: "(Не задано)",
    },
    {
      content: "Неформальные лидеры (положительные)",
      info: "(Не задано)",
    },
  ];


  export const dataDorm = [
    {
      content: "Нуждается в общежитии",
      info: "(Не задано)",
    },
    {
      content: "Статус ",
      info: "(Не задано)",
    },
    {
      content: "Комментарии",
      info: "(Не задано)",
    },

    
  ];

  export const dataMeal = [
    {
      content: "Нуждается в льготном питании",
      info: "(Не задано)",
    },
    {
      content: "Статус ",
      info: "(Не задано)",
    },
    {
      content: "Комментарии",
      info: "(Не задано)",
    },

    
  ];