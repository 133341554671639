import {Col, Form, Modal, Popconfirm, Row, Spin} from "antd";
import {FormItem} from "./components/FormItem/FormItem";
import {MyButton} from "../MyButton/MyButton";
import React, {useCallback, useEffect, useState} from "react";
import {MyModalFormProps} from "./MyModalForm.props";

export const MyModalForm = ({
                              title,
                              visible,
                              onCancel,
                              onFinish,
                              onDelete = () => {},
                              state,
                              period,
                              form,
                              formName,
                              modalClassName = 'modal-restore',
                              extraFieldsArr = [],
                              onValuesChange = (changedFields, allFields) => {}
                            }: MyModalFormProps) => {

  let submitLabel = state?.data[0]?.submit_label
  let prependItem = state?.data[0]?.prepend
  let isDelete = state?.data[0]?.is_delete_action
  let fieldsArr = state?.data[0]?.fields || []

  const [isSubmitButtonDisable, setIsSubmitButtonDisable] = useState<boolean>(false)

  const resetFieldHandler = useCallback(() => {
    onCancel(form)
    form.resetFields()
  }, [form])

  const onValuesChangeHandler = (changedFields: any, allFields: any) => {
    onValuesChange(changedFields, allFields)

    checkIsDisableButton(allFields)
  }

  const checkIsDisableButton = (values: any = {}) => {
    const array = [...fieldsArr, ...extraFieldsArr].filter(field => field.type !== 9).filter(field => field.is_required).map(field => ({
      name: field.name,
      isValidate: !!values[field.name]
    }))

    let res = !(array.every(field => field.isValidate))

    setIsSubmitButtonDisable(res)
  }

  useEffect(() => {
    checkIsDisableButton(form.getFieldsValue())
  }, [state])

  return (
    <Modal
      title={title}
      open={visible}
      centered
      destroyOnClose
      footer={null}
      width={600}
      className={modalClassName}
      onCancel={resetFieldHandler}
      forceRender
    >
      <Spin spinning={state?.loading}>
        <Form
          name={formName}
          form={form}
          onFinish={(values) => {
            onFinish(values)
            resetFieldHandler()
          }}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
            flex: 1,
          }}
          labelAlign='left'
          scrollToFirstError
          layout="horizontal"
          labelWrap
          colon={false}
          onValuesChange={(changedFields, allFields) => onValuesChangeHandler(changedFields, allFields)}
        >

          {prependItem ? <div dangerouslySetInnerHTML={{__html: prependItem}}/> : null}

          {
            fieldsArr?.map((item: any, index: any) => (
              <FormItem
                key={index} item={item} object={{
                ...state?.data[0],
                start_date: period?.start_date,
                end_date: period?.end_date,
              }}/>
            ))
          }

          {
            extraFieldsArr?.map((item: any, index: any) => (
              <FormItem
                key={index} item={item} object={{
                ...state?.data[0],
                start_date: period?.start_date,
                end_date: period?.end_date,
              }}/>
            ))
          }

          <Row gutter={16} justify='center' style={{marginTop: 24}}>
            <Col>
              <Form.Item>
                <MyButton title={submitLabel ? submitLabel : "Сохранить"} htmlType="submit" disabled={isSubmitButtonDisable}/>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item>
                <MyButton title='Отменить' htmlType="reset" onClick={resetFieldHandler}/>
              </Form.Item>
            </Col>

            {
              isDelete &&

              <Col>
                <Form.Item>
                  <Popconfirm
                    title="Удалить"
                    description="Вы уверены что хотите удалить?"
                    onConfirm={onDelete}
                    onCancel={resetFieldHandler}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <MyButton title='Удалить' danger/>
                  </Popconfirm>
                </Form.Item>
              </Col>
            }
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}