import {
  FETCH,
  DONE,
  FAIL,
  LESSON,
  VIEW, ONE, ADD, GET, LIST, EDIT, CTP, TEST, ANSWER, JOURNAL, DATA, INNER, CONTENT, DELETE, RESULT
} from "../../constants";

import reducerStatuses from "../../status";
import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {JOURNAL_YEAR, PLANS_TYPES} from "../../../constants";
import {IJournalType} from "../journal/types";
import {IPlanType} from "./types";

const initialState = {
  oneCtp: {
    ...reducerStatuses,
    data: null as any
  },
  list: {
    ...reducerStatuses,
    data: null as any,
  },
  lessons: {
    ...reducerStatuses,
    data: null as any,
  },
  oneLesson: {
    ...reducerStatuses,
    data: null as any,
  },
  oneTest: {
    ...reducerStatuses,
    data: null as any,
  },
  testResult: {
    ...reducerStatuses,
    data: null as any,
  }
};

export default createReducer(initialState, builder =>
  builder
    .addCase(GET + CTP + VIEW + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      oneCtp: {
        ...initialState.oneCtp,
        loading: true,
      },
    }))
    .addCase(GET + CTP + VIEW + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      oneCtp: {
        ...initialState.oneCtp,
        success: true,
        data: payload,
      },
    }))
    .addCase(GET + CTP + VIEW + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      oneCtp: {
        ...initialState.oneCtp,
        failed: true,
      },
    }))

    .addCase(GET + LIST + FETCH, (state, {payload}: PayloadAction<IPlanType>) => ({
      ...state,
      list: {
        ...initialState.list,
        loading: true
      },
    }))
    .addCase(GET + LIST + FETCH + DONE, (state, {payload}: PayloadAction<IPlanType>) => ({
      ...state,
      list: {
        ...initialState.list,
        success: true,
        data: payload
      },
    }))
    .addCase(GET + LIST + FETCH + FAIL, (state, {payload}: PayloadAction<IPlanType>) => ({
      ...state,
      list: {
        ...initialState.list,
        failed: true,
      },
    }))



    .addCase(LESSON + VIEW + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      lessons: {
        ...state.lessons,
        loading: true,
        success: false,
      },
    }))
    .addCase(LESSON + VIEW + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      lessons: {
        ...state.lessons,
        success: true,
        loading: false,
        data: payload,
      },
    }))
    .addCase(LESSON + VIEW + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      lessons: {
        ...state.lessons,
        loading: false,
        failed: true,
      },
    }))


    .addCase(LESSON + VIEW + ONE + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      oneLesson: {
        ...initialState.oneLesson,
        loading: true
      },
    }))
    .addCase(LESSON + VIEW + ONE + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      oneLesson: {
        ...initialState.oneLesson,
        success: true,
        data: payload,
      },
    }))
    .addCase(LESSON + VIEW + ONE + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      oneLesson: {
        ...initialState.oneLesson,
        failed: true,
      },
    }))


    .addCase(LESSON + ADD + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      lessons: {
        ...state.lessons,
        loading: true,
        success: false,
      },
    }))
    .addCase(LESSON + ADD + FETCH + DONE, (state, {payload}: PayloadAction<any>) => {
      let newItem = {
        id: payload.data.id,
        date: `${payload.data.date} 00:00:00`,
        title: `${dayjs(payload.data.date).format('DD.MM')} - ${payload.data.theme}`,
        filter: null,
        isEdit: true,
        isDelete: true
      }

      return {
        ...state,
        lessons: {
          ...state.lessons,
          loading: false,
          success: true,
          data: [
            ...state.lessons.data, newItem
          ]
        }
      }
    })



    .addCase(LESSON + ONE + ADD + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      oneLesson: {
        ...state.oneLesson,
        loading: true,
        success: false,
      },
    }))
    .addCase(LESSON + ONE + ADD + FETCH + DONE, (state, {payload}: PayloadAction<any>) => {
      let newItem = {
        ...payload.data,
        created_at: dayjs(),
        updated_at: dayjs(),
      }

      return {
        ...state,
        oneLesson: {
          ...state.oneLesson,
          loading: false,
          success: true,
          data: {
            ...state.oneLesson.data,
            [payload.data.currentTab]: [...state.oneLesson.data[payload.data.currentTab], newItem]
          }
        }
      }
    })
    .addCase(LESSON + EDIT + FETCH, (state, {payload}: PayloadAction<any>) => {

      return {
        ...state,
        oneCtp: {
          ...state.oneCtp,
          loading: true,
          success: false,
          data: {
            ...state.oneCtp.data,
          }
        },
        oneLesson: {
          ...state.oneLesson,
          loading: true,
          success: false,
          data: {
            ...state.oneLesson.data,
          }
        }
      }
    })
    .addCase(LESSON + EDIT + FETCH + DONE, (state, {payload}: PayloadAction<any>) => {

      return {
        ...state,
        oneCtp: {
          ...state.oneCtp,
          loading: false,
          success: true,
          data: payload.data
        },
        oneLesson: {
          ...state.oneLesson,
          loading: false,
          success: true,
          data: payload.data
        }
      }
    })


    .addCase(GET + TEST + ANSWER + FETCH, (state, {payload}: PayloadAction<any>) => {

      return {
        ...state,
        oneTest: {
          ...state.oneTest,
          loading: true,
          success: false,
          data: initialState.oneTest.data
        }
      }
    })
    .addCase(GET + TEST + ANSWER + FETCH + DONE, (state, {payload}: PayloadAction<any>) => {

      return {
        ...state,
        oneTest: {
          ...state.oneTest,
          loading: false,
          success: true,
          data: payload
        }
      }
    })

    .addCase(ADD + TEST + ANSWER + FETCH, (state, {payload}: PayloadAction<any>) => {

      return {
        ...state,
        oneTest: {
          ...state.oneTest,
          loading: true,
          success: false,
          data: state.oneTest.data
        }
      }
    })
    .addCase(ADD + TEST + ANSWER + FETCH + DONE, (state, {payload}: PayloadAction<any>) => {

      return {
        ...state,
        oneTest: {
          ...state.oneTest,
          loading: false,
          success: true,
          data: payload
        }
      }
    })

    .addCase(EDIT + INNER + CONTENT + FETCH, (state, {payload}: PayloadAction<any>) => {

      return {
        ...state,
        oneLesson: {
          ...state.oneLesson,
          loading: true,
          success: false,
          data: {
            ...state.oneLesson.data,
          }
        }
      }
    })
    .addCase(EDIT + INNER + CONTENT + FETCH + DONE, (state, {payload}: PayloadAction<any>) => {

      return {
        ...state,
        oneLesson: {
          ...state.oneLesson,
          loading: false,
          success: true,
          data: {
            ...state.oneLesson.data,
            [payload.data.currentTab]: state.oneLesson.data[payload.data.currentTab].map((item: any) => {
              if(item.id == payload.data.id){
                return {
                  ...payload.data,
                  updated_at: dayjs()
                }
              } else {
                return item
              }
            })
          }
        }
      }
    })

    .addCase(DELETE + INNER + CONTENT + FETCH, (state, {payload}: PayloadAction<any>) => {

      return {
        ...state,
        oneLesson: {
          ...state.oneLesson,
          loading: true,
          success: false,
          data: {
            ...state.oneLesson.data,
          }
        }
      }
    })
    .addCase(DELETE + INNER + CONTENT + FETCH + DONE, (state, {payload}: PayloadAction<any>) => {

      return {
        ...state,
        oneLesson: {
          ...state.oneLesson,
          loading: false,
          success: true,
          data: {
            ...state.oneLesson.data,
            [payload.data.currentTab]: state.oneLesson.data[payload.data.currentTab].filter((item: any) => item.id !== payload.data.id)
          }
        }
      }
    })

    .addCase(TEST + RESULT + FETCH, (state, {payload}: PayloadAction<any>) => {

      return {
        ...state,
        testResult: {
          ...initialState.testResult,
          loading: true,
        }
      }
    })
    .addCase(TEST + RESULT + FETCH + DONE, (state, {payload}: PayloadAction<any>) => {

      return {
        ...state,
        testResult: {
          ...initialState.testResult,
          success: true,
          data: payload.data
        }
      }
    })
)
