import {FirstLevelTabs, MyBreadcrumb, MyButton, MyText, MyTextarea, SecondLevelTabs} from "../../components";
import {Card, Col, DatePicker, Form, Input, Modal, Radio, Row, Select, Table} from "antd";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {JOURNAL_TYPES, JOURNAL_TYPES_API, JOURNAL_YEAR} from "../../constants";
import {RootState} from "../../store";
import {selectStore} from "../../store/selector";
import {getJournalListAction} from "../../store/pages/journal/actions";
import {CheckOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {
  deletePrintRemark, editPrintRemark,
  editPrintStudy,
  getDisciplinesPrintTotalUvr,
  getPrintMedical, getPrintMedicalGroup,
  getPrintModule, getPrintRemark, getPrintStudy, getPrintTotalUvr, printJournal, printMedicalEdit,
  printModuleEdit,
  printSetEdit,
  printStoreReset, setPrintRemark
} from "../../store/pages/printJournal/actions";
import dayjs from "dayjs";
import locale from 'antd/es/date-picker/locale/ru_RU';
import {useTheme} from "../../hooks";
import {setThemeColor} from "../../utils/setThemeColor";
import {DefaultTokenTheme} from "../../assets/themes/defaultTheme";

const FORM_NAME = {
  TYPE_NUMBER: 'type_number',
  TYPE_STRING: 'type_string',
  YEAR: 'year',
  COURSE: 'course',
  GROUP: 'group'
}

const PRINT_TYPES = {
  MODULE: 'modules',
  STUDY: 'study',
  STUDENT_INDUSTRIAL: 'STUDENT_INDUSTRIAL',
  MEDICAL: 'medical',
  TOTAL_UVR: 'TOTAL_UVR',
  TOTAL_HALF: 'TOTAL_HALF',
  TOTAL_PO: 'TOTAL_PO',
  TOTAL_PP: 'TOTAL_PP',
  REMARK: 'remark',
  PRINT: 'PRINT',
}

const CHECKBOX_TYPES = {
  TITLE: 'title_page',
  CONTENT: 'content',
  MODULE: 'modules_info',
  GROUP: 'group_info',
  MEDICAL: 'medical_info',
  EDUCATION_WORK: 'educational_work_info',
  REMARKS: 'remarks_info',
  ATTENDANCE: 'attendance_performance_info',
}

export const PrintJournal = () => {
  const {t} = useTranslation("common")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {theme} = useTheme()

  const [filterForm] = Form.useForm()
  const [addRemarkForm] = Form.useForm()

  let currentFilterInitialState = {
    [FORM_NAME.TYPE_NUMBER]: JOURNAL_TYPES_API[JOURNAL_TYPES.THEORETICAL],
    [FORM_NAME.TYPE_STRING]: JOURNAL_TYPES.THEORETICAL,
    [FORM_NAME.YEAR]: JOURNAL_YEAR.PREV,
    [FORM_NAME.COURSE]: null,
    [FORM_NAME.GROUP]: null
  }

  let sideInitialState = [
    {
      key: 'left',
      value: 'Левая'
    },
    {
      key: 'right',
      value: 'Правая'
    }
  ]

  const years = [
    {
      label: `${JOURNAL_YEAR.PREV}-${JOURNAL_YEAR.CURRENT}`,
      year: JOURNAL_YEAR.PREV,
    },
    {
      label: `${JOURNAL_YEAR.CURRENT}-${JOURNAL_YEAR.NEXT}`,
      year: JOURNAL_YEAR.CURRENT,
    }
  ]

  const [currentFilterState, setCurrentFilterState] = useState(currentFilterInitialState)

  const [totalUvr, setTotalUvr] = useState({
    side: sideInitialState[0],
    subject_id: null
  })

  const [currentSide, setCurrentSide] = useState<any>(sideInitialState[0])

  const {
    journal: {list: journalListState},
    printJournal: {
      modules: modulesState,
      study: studyState,
      medical: medicalState,
      medicalGroups: medicalGroupsState,
      remark: remarkState,
      totalUvr: totalUvrState,
      totalUvrDisciplines: totalUvrDisciplinesState,
      printJournal: printJournalState,
    }
  }: RootState = useSelector(selectStore)

  const getJournalList = useCallback((year: any, type: any) => {
    dispatch(getJournalListAction({
      year: year,
      type: JOURNAL_TYPES_API[type]
    }))
    setCurrentFilterState(prevState => ({
      ...prevState,
      [FORM_NAME.TYPE_STRING]: type,
      [FORM_NAME.TYPE_NUMBER]: JOURNAL_TYPES_API[type],
      [FORM_NAME.YEAR]: year
    }))
  }, [])

  useEffect(() => {
    getJournalList(currentFilterInitialState[FORM_NAME.YEAR], currentFilterState[FORM_NAME.TYPE_STRING])

    filterForm.setFields([{name: FORM_NAME.YEAR, value: currentFilterState[FORM_NAME.YEAR]}])
  }, [])

  const handleEditState = useCallback((reduxState: any, index: any, object: any) => {
    let body = {reduxState, index, object}
    dispatch(printSetEdit(body))
  }, [])

  const handleSaveState = (reduxState: any, index: any, object: any) => {
    let body = {}
    switch (reduxState) {
      case PRINT_TYPES.MODULE:
        dispatch(printModuleEdit(modulesState.data[index]))
        break
      case PRINT_TYPES.STUDY:
        body = {
          ...studyState.data[index],
          side: currentSide.key
        }
        dispatch(editPrintStudy(body))
        break
      case PRINT_TYPES.MEDICAL:
        dispatch(printMedicalEdit(medicalState.data[index]))
        break

      case PRINT_TYPES.REMARK:
        dispatch(editPrintRemark(remarkState.data[index]))
        break
      default:
        return false
    }
  }

  const handleAddRemark = useCallback(() => {
    const handleClose = () => {
      modal.destroy()
      addRemarkForm.resetFields()
    }

    const handleOk = () => {
      const remark_date = dayjs(addRemarkForm.getFieldsValue().remark_date).format('YYYY-MM-DD hh:mm:ss')
      const remark_text = addRemarkForm.getFieldsValue().remark_text
      const group_id = filterForm.getFieldsValue().group
      const course = filterForm.getFieldsValue().course

      dispatch(setPrintRemark({
        group_id: group_id,
        course: course,
        remark_date: remark_date,
        remark_text: remark_text,
        ...filterForm.getFieldsValue()
      }))

      modal.destroy()
      addRemarkForm.resetFields()
    }

    const modal = Modal.confirm({
      title: 'Добавить замечание',
      content: (
        <Form form={addRemarkForm}>
          <Form.Item label='Дата' name='remark_date' labelCol={{
            span: 24
          }}>
            <DatePicker/>
          </Form.Item>

          <Form.Item label='Текст замечания или предложения' name='remark_text' labelCol={{
            span: 24
          }}>
            <MyTextarea/>
          </Form.Item>
        </Form>
      ),
      footer: (
        <Row justify='center' gutter={16}>
          <Col>
            <MyButton title='Отмена' onClick={(close) => handleClose()}/>
          </Col>
          <Col>
            <MyButton title='Сохранить' type='primary' onClick={handleOk}/>
          </Col>
        </Row>
      )
    })

    modal.update((prevConfig) => ({
      ...prevConfig
    }))

  }, [])

  const handleClickPrintJournal = useCallback(() => {
    dispatch(printJournal(filterForm.getFieldsValue()))
  }, [])


  const handleSelectTotalUvrDiscipline = useCallback((item: any) => {
    setTotalUvr(prevState => ({
      ...prevState,
      subject_id: item.value
    }))

    let body = {
      ...filterForm.getFieldsValue(),
      subject_id: item.value,
      side: totalUvr.side.key
    }

    dispatch(getPrintTotalUvr(body))
  }, [totalUvr])

  const moduleColumn = [
    {
      title: '№ п/п',
      key: '№ п/п',
      dataIndex: 'index',
      render: (item: any, record: any, index: any) => {
        return index + 1
      },
    },
    {
      title: 'Наименование модуля',
      key: 'Наименование модуля',
      dataIndex: 'caption',
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Количество часов по учебному плану',
      key: 'Количество часов по учебному плану',
      dataIndex: 'sum_hours',
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Дата начала',
      key: 'Дата начала',
      dataIndex: 'date_start',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return (
          <Row>
            {record.isEdit ? <DatePicker  {...item ? {defaultValue: dayjs(item)} : {}}
                                          onChange={(date: any) => handleEditState(PRINT_TYPES.MODULE, index, {date_start: dayjs(date).format('YYYY-MM-DD HH:mm:ss')})}
                                          format='DD.MM.YYYY' showToday={false} locale={locale}/> :
              <MyText>{item ? dayjs(item).format('DD.MM.YYYY') : ''}</MyText>}
          </Row>
        )
      },
    },
    {
      title: 'Дата завершения',
      key: 'Дата завершения',
      dataIndex: 'date_end',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return (
          <Row>
            {record.isEdit ? <DatePicker  {...item ? {defaultValue: dayjs(item, 'DD.MM.YYYY')} : {}}
                                          onChange={(date: any) => handleEditState(PRINT_TYPES.MODULE, index, {date_end: dayjs(date).format('YYYY-MM-DD HH:mm:ss')})}
                                          format='DD.MM.YYYY' showToday={false} locale={locale}/> :
              <MyText>{item ? dayjs(item).format('DD.MM.YYYY') : ''}</MyText>}
          </Row>
        )
      },
    },
    {
      title: 'Действие',
      key: 'Действие',
      dataIndex: 'action',
      width: 120,
      render: (item: any, record: any, index: any) => {
        return (
          <Row gutter={6} style={{width: '100%'}} justify='center'>
            <Col>
              {
                record.isEdit ?
                  <MyButton icon={<CheckOutlined/>} type='text' width={40} height={40} style={{padding: 0}}
                            onClick={() => handleSaveState(PRINT_TYPES.MODULE, index, {isEdit: false})}/>
                  : <MyButton icon={<EditOutlined/>} type='text' width={40} height={40} style={{padding: 0}}
                              onClick={() => handleEditState(PRINT_TYPES.MODULE, index, {isEdit: true})}/>

              }
            </Col>
            {/*<Col>*/}
            {/*  <MyButton icon={<DeleteOutlined/>} type='text' width={40} height={40} style={{padding: 0}}/>*/}
            {/*</Col>*/}
          </Row>
        )
      },
    }
  ]

  const studyLeftColumn = [
    {
      title: '№ п/п',
      key: '№ п/п',
      dataIndex: 'index',
      render: (item: any, record: any, index: any) => {
        return index + 1
      },
    },
    {
      title: 'ФИО обучающегося',
      key: 'ФИО обучающегося',
      dataIndex: 'full_name',
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Число, месяц, год рождения',
      key: 'Число, месяц, год рождения',
      dataIndex: 'birth_date',
      render: (item: any, record: any, index: any) => {
        return (
          <Row>
            {record.isEdit ? <DatePicker  {...item ? {defaultValue: dayjs(item, 'DD.MM.YYYY')} : {}}
                                          onChange={(date: any) => handleEditState(PRINT_TYPES.STUDY, index, {birth_date: dayjs(date).format('YYYY-MM-DD HH:mm:ss')})}
                                          format='DD.MM.YYYY' showToday={false} locale={locale}/> :
              <MyText>{item ? item : ''}</MyText>}
          </Row>
        )
      },
    },
    {
      title: 'Номер по поименной книге',
      key: 'Номер по поименной книге',
      dataIndex: 'name_book',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return (
          <Row>
            {record.isEdit ? <Input {...item ? {defaultValue: item} : {}}
                                    onChange={(e) => handleEditState(PRINT_TYPES.STUDY, index, {
                                      name_book: e.target.value
                                    })}/> :
              <MyText>{item ? item : ''}</MyText>}
          </Row>
        )
      },
    },
    {
      title: 'Дата и № приказа о зачислении',
      key: 'Дата и № приказа о зачислении',
      dataIndex: 'order_date',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return (
          <Row>
            {record.isEdit ? <DatePicker  {...item ? {defaultValue: dayjs(item, 'DD.MM.YYYY')} : {}}
                                          onChange={(date: any) => handleEditState(PRINT_TYPES.STUDY, index, {order_date: dayjs(date).format('YYYY-MM-DD HH:mm:ss')})}
                                          format='DD.MM.YYYY' showToday={false} locale={locale}/> :
              <MyText>{item ? item : ''}</MyText>}
          </Row>
        )
      },
    },
    {
      title: 'Действие',
      key: 'Действие',
      dataIndex: 'action',
      width: 120,
      render: (item: any, record: any, index: any) => {
        return (
          <Row gutter={6} style={{width: '100%'}} justify='center'>
            <Col>
              {
                record.isEdit ?
                  <MyButton icon={<CheckOutlined/>} type='text' width={40} height={40} style={{padding: 0}}
                            onClick={() => handleSaveState(PRINT_TYPES.STUDY, index, {isEdit: false})}/>
                  : <MyButton icon={<EditOutlined/>} type='text' width={40} height={40} style={{padding: 0}}
                              onClick={() => handleEditState(PRINT_TYPES.STUDY, index, {isEdit: true})}/>

              }
            </Col>
            {/*<Col>*/}
            {/*  <MyButton icon={<DeleteOutlined/>} type='text' width={40} height={40} style={{padding: 0}}/>*/}
            {/*</Col>*/}
          </Row>
        )
      },
    }
  ]

  const studyRightColumn = [
    {
      title: '№ п/п',
      key: '№ п/п',
      dataIndex: 'index',
      render: (item: any, record: any, index: any) => {
        return index + 1
      },
    },
    {
      title: 'ФИО обучающегося',
      key: 'ФИО обучающегося',
      dataIndex: 'caption',
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Домашний адрес обучающегося',
      key: 'Домашний адрес обучающегося',
      dataIndex: 'sum_hours',
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Телефон',
      key: 'Телефон',
      dataIndex: 'date_start',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Движение контингента',
      key: 'Движение контингента',
      dataIndex: 'date_end',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Дополнительные сведения',
      key: 'Дополнительные сведения',
      dataIndex: 'date_end',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Действие',
      key: 'Действие',
      dataIndex: 'action',
      width: 120,
      render: (item: any, record: any, index: any) => {
        return (
          <Row gutter={6} style={{width: '100%'}} justify='center'>
            <Col>
              {
                record.isEdit ?
                  <MyButton icon={<CheckOutlined/>} type='text' width={40} height={40} style={{padding: 0}}
                            onClick={() => handleSaveState(PRINT_TYPES.STUDY, index, {isEdit: false})}/>
                  : <MyButton icon={<EditOutlined/>} type='text' width={40} height={40} style={{padding: 0}}
                              onClick={() => handleEditState(PRINT_TYPES.STUDY, index, {isEdit: true})}/>

              }
            </Col>
            {/*<Col>*/}
            {/*  <MyButton icon={<DeleteOutlined/>} type='text' width={40} height={40} style={{padding: 0}}/>*/}
            {/*</Col>*/}
          </Row>
        )
      },
    }
  ]

  const medicalColumn = [
    {
      title: '№ п/п',
      key: '№ п/п',
      dataIndex: 'index',
      render: (item: any, record: any, index: any) => {
        return index + 1
      },
    },
    {
      title: 'ФИО обучающегося',
      key: 'ФИО обучающегося',
      dataIndex: 'fio',
      render: (item: any, record: any, index: any) => {
        return record.full_name
      },
    },
    {
      title: 'Дата осмотра',
      key: 'Дата осмотра',
      dataIndex: 'date',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return (
          <Row>
            {record.isEdit ?
              <DatePicker  {...record.medical_data.date ? {defaultValue: dayjs(record.medical_data.date, 'DD.MM.YYYY')} : {}}
                           onChange={(date: any) => handleEditState(PRINT_TYPES.MEDICAL, index, {
                             medical_data: {
                               ...record.medical_data,
                               date: dayjs(date).format('YYYY-MM-DD HH:mm:ss')
                             }
                           })}
                           format='DD.MM.YYYY' showToday={false} locale={locale}/> :
              <MyText>{record.medical_data.date ? dayjs(record.medical_data.date).format('DD.MM.YYYY') : ''}</MyText>}
          </Row>
        )
      },
    },
    {
      title: 'Медгруппа (основная, подготовительная, специальная)',
      key: 'Медгруппа (основная, подготовительная, специальная)',
      dataIndex: 'group',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return (
          <Row>
            {record.isEdit ?
              <Select
                style={{width: '100%'}} {...record.medical_data.group?.name ? {defaultValue: record.medical_data.group?.name} : {}}
                options={medicalGroupsState.data.map((item: any) => ({
                  key: item.group_type,
                  label: item.caption,
                  value: item.group_type,
                  item: {
                    name: item.caption,
                    caption: item.group_type
                  }
                }))} onChange={(value, option) => handleEditState(PRINT_TYPES.MEDICAL, index, {
                medical_data: {
                  ...record.medical_data,
                  // @ts-ignore
                  group: option?.item
                }
              })}/> :
              <MyText>{record?.medical_data?.group?.caption ? record?.medical_data?.group?.caption : ''}</MyText>}
          </Row>
        )
      },
    },
    {
      title: 'Примечание',
      key: 'Примечание',
      dataIndex: 'note',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return (
          <Row>
            {record.isEdit ? <Input {...record.medical_data.note ? {defaultValue: record.medical_data.note} : {}}
                                    onChange={(e) => handleEditState(PRINT_TYPES.MEDICAL, index, {
                                      medical_data: {
                                        ...record.medical_data,
                                        note: e.target.value
                                      }
                                    })}/> :
              <MyText>{record?.medical_data?.note ? record?.medical_data?.note : ''}</MyText>}
          </Row>
        )
      },
    },
    {
      title: 'Действие',
      key: 'Действие',
      dataIndex: 'action',
      width: 120,
      render: (item: any, record: any, index: any) => {
        return (
          <Row gutter={6} style={{width: '100%'}} justify='center'>
            <Col>
              {
                record.isEdit ?
                  <MyButton icon={<CheckOutlined/>} type='text' width={40} height={40} style={{padding: 0}}
                            onClick={() => handleSaveState(PRINT_TYPES.MEDICAL, index, {isEdit: false})}/>
                  : <MyButton icon={<EditOutlined/>} type='text' width={40} height={40} style={{padding: 0}}
                              onClick={() => handleEditState(PRINT_TYPES.MEDICAL, index, {isEdit: true})}/>

              }
            </Col>
            {/*<Col>*/}
            {/*  <MyButton icon={<DeleteOutlined/>} type='text' width={40} height={40} style={{padding: 0}}/>*/}
            {/*</Col>*/}
          </Row>
        )
      },
    }
  ]

  const remarkColumn = [
    {
      title: '№ п/п',
      key: '№ п/п',
      dataIndex: 'remark_id',
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Дата',
      key: 'Дата',
      dataIndex: 'remark_date',
      render: (item: any, record: any, index: any) => {
        return (
          <Row>
            {record.isEdit ? <DatePicker  {...item ? {defaultValue: dayjs(item, 'YYYY-MM-DD HH:mm:ss')} : {}}
                                          onChange={(date: any) => handleEditState(PRINT_TYPES.REMARK, index, {remark_date: dayjs(date).format('YYYY-MM-DD HH:mm:ss')})}
                                          format='DD.MM.YYYY' showToday={false} locale={locale}/> :
              <MyText>{item ? dayjs(item).format('DD.MM.YYYY') : ''}</MyText>}
          </Row>
        )
      },
    },
    {
      title: 'Содержание замечаний и предложений',
      key: 'Содержание замечаний и предложений',
      dataIndex: 'remark_text',
      render: (item: any, record: any, index: any) => {
        return (
          <Row>
            {record.isEdit ? <Input {...item ? {defaultValue: item} : {}}
                                    onChange={(e) => handleEditState(PRINT_TYPES.REMARK, index, {
                                      remark_text: e.target.value
                                    })}/> :
              <MyText>{item ? item : ''}</MyText>}
          </Row>
        )
      },
    },
    {
      title: 'Фамилия и инициалы проверившего журнал, должность и подпись',
      key: 'Фамилия и инициалы проверившего журнал, должность и подпись',
      dataIndex: 'fio',
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Действие',
      key: 'Действие',
      dataIndex: 'action',
      width: 120,
      render: (item: any, record: any, index: any) => {
        return (
          <Row gutter={6} style={{width: '100%'}} justify='center'>
            <Col>
              {
                record.isEdit ?
                  <MyButton icon={<CheckOutlined/>} type='text' width={40} height={40} style={{padding: 0}}
                            onClick={() => handleSaveState(PRINT_TYPES.REMARK, index, {isEdit: false})}/>
                  : <MyButton icon={<EditOutlined/>} type='text' width={40} height={40} style={{padding: 0}}
                              onClick={() => handleEditState(PRINT_TYPES.REMARK, index, {isEdit: true})}/>

              }
            </Col>
            <Col>
              <MyButton icon={<DeleteOutlined/>} type='text' width={40} height={40} style={{padding: 0}}
                        onClick={() => dispatch(deletePrintRemark(record.remark_id))}/>
            </Col>
          </Row>
        )
      },
    }
  ]

  const totalUvrLeftColumn = [
    {
      title: '№ п/п',
      key: '№ п/п',
      dataIndex: 'index',
      render: (item: any, record: any, index: any) => {
        return index + 1
      },
    },
    {
      title: 'ФИО обучающегося',
      key: 'ФИО обучающегося',
      dataIndex: 'student_full_name',
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Число, месяц, год рождения',
      key: 'Число, месяц, год рождения',
      dataIndex: '',
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: '1-е полугодие (семестр)',
      key: '1-е полугодие (семестр)',
      dataIndex: 'first_semester',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: '2-е полугодие (семестр)',
      key: '2-е полугодие (семестр)',
      dataIndex: 'second_semester',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Рейтинг допуска к промежуточной и/или итоговой аттестации',
      key: 'Рейтинг допуска к промежуточной и/или итоговой аттестации',
      dataIndex: '',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Зачет',
      key: 'Зачет',
      dataIndex: '',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Экзамен',
      key: 'Экзамен',
      dataIndex: '',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Итоговая оценка',
      key: 'Итоговая оценка',
      dataIndex: '',
      width: 150,
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Действие',
      key: 'Действие',
      dataIndex: 'action',
      width: 120,
      render: (item: any, record: any, index: any) => {
        return (
          <Row gutter={6} style={{width: '100%'}} justify='center'>
            <Col>
              {
                record.isEdit ?
                  <MyButton icon={<CheckOutlined/>} type='text' width={40} height={40} style={{padding: 0}}
                            onClick={() => handleSaveState(PRINT_TYPES.TOTAL_UVR, index, {isEdit: false})}/>
                  : <MyButton icon={<EditOutlined/>} type='text' width={40} height={40} style={{padding: 0}}
                              onClick={() => handleEditState(PRINT_TYPES.TOTAL_UVR, index, {isEdit: true})}/>

              }
            </Col>
            {/*<Col>*/}
            {/*  <MyButton icon={<DeleteOutlined/>} type='text' width={40} height={40} style={{padding: 0}}/>*/}
            {/*</Col>*/}
          </Row>
        )
      },
    }
  ]

  const totalUvrRightColumn = [
    {
      title: '№ п/п',
      key: '№ п/п',
      dataIndex: 'index',
      render: (item: any, record: any, index: any) => {
        return index + 1
      },
    },
    {
      title: 'ФИО обучающегося',
      key: 'ФИО обучающегося',
      dataIndex: 'full_name',
      render: (item: any, record: any, index: any) => {
        return item
      },
    },
    {
      title: 'Пропущено часов',
      children: [
        {
          title: 'За полугодие (семестр)',
        },
        {
          title: 'В 1 полугодии (за 1-й семестр)',
          children: [
            {
              title: 'Всего',
            },
            {
              title: 'По не уважительной причине'
            }
          ]
        },
        {
          title: 'В 1 полугодии (за 2-й семестр)',
          children: [
            {
              title: 'Всего',
            },
            {
              title: 'По не уважительной причине'
            }
          ]
        },
        {
          title: 'За год',
          children: [
            {
              title: 'Всего',
            },
            {
              title: 'По не уважительной причине'
            }
          ]
        }
      ]
    },
    {
      title: 'Действие',
      key: 'Действие',
      dataIndex: 'action',
      width: 120,
      render: (item: any, record: any, index: any) => {
        return (
          <Row gutter={6} style={{width: '100%'}} justify='center'>
            <Col>
              {
                record.isEdit ?
                  <MyButton icon={<CheckOutlined/>} type='text' width={40} height={40} style={{padding: 0}}
                            onClick={() => handleSaveState(PRINT_TYPES.STUDY, index, {isEdit: false})}/>
                  : <MyButton icon={<EditOutlined/>} type='text' width={40} height={40} style={{padding: 0}}
                              onClick={() => handleEditState(PRINT_TYPES.STUDY, index, {isEdit: true})}/>

              }
            </Col>
            {/*<Col>*/}
            {/*  <MyButton icon={<DeleteOutlined/>} type='text' width={40} height={40} style={{padding: 0}}/>*/}
            {/*</Col>*/}
          </Row>
        )
      },
    }
  ]


  const onFinishFilter = useCallback((values: any) => {
    dispatch(getPrintModule(values))
    dispatch(getPrintStudy({...values, side: currentSide.key}))
    dispatch(getPrintMedical(values))
    dispatch(getPrintMedicalGroup(values))
    dispatch(getPrintRemark(values))
    dispatch(getDisciplinesPrintTotalUvr(values))
  }, [currentSide])

  const onValuesChangeFilter = useCallback((changedFields: any, allFields: any) => {
    const key = Object.keys(changedFields)[0]
    const value = Object.values(changedFields)[0]
    switch (key) {
      case FORM_NAME.YEAR:
        getJournalList(value, currentFilterState[FORM_NAME.TYPE_STRING])
        break

      case FORM_NAME.COURSE:
        filterForm.resetFields([FORM_NAME.GROUP])
        setCurrentFilterState(prevState => ({
          ...prevState,
          [key]: value
        }))
        break

      case FORM_NAME.GROUP:
        setCurrentFilterState(prevState => ({
          ...prevState,
          [key]: value
        }))
        break

      default:
        return false
    }
  }, [])

  const filterRender = useMemo(() => {
    const filteredGroup = journalListState.data?.filter((item: any) => item.group_type === 1)?.filter((v: any, i: any, a: any) => a.findIndex((t: any) => (t.group_id === v.group_id)) === i)?.filter((item: any) => item.course?.includes(currentFilterState[FORM_NAME.COURSE]))

    return (
      <Form form={filterForm}
            layout="vertical"
            onFinish={onFinishFilter}
            onValuesChange={(changedFields, allFields) => onValuesChangeFilter(changedFields, allFields)}>
        <Row align='bottom' gutter={[16, 16]} justify='space-between'>
          <Col span={6}>
            <Form.Item label={t('studyYear')} name={[FORM_NAME.YEAR]}>
              <Select>
                {
                  years.map(item => (
                    <Select.Option key={item.year} value={item.year}>
                      {item.label}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('course')} name={[FORM_NAME.COURSE]}>
              <Select>
                {
                  [1, 2, 3, 4].map(item => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('group')} name={[FORM_NAME.GROUP]}>
              <Select>
                {
                  filteredGroup?.map((item: any) => (
                    <Select.Option key={item.id} value={item.group_id}>
                      {item.group}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item>
              <MyButton title={t('throw')} htmlType='reset' width='100%'/>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item>
              <MyButton title={t('set')} htmlType='submit' width='100%'
                        disabled={!currentFilterState[FORM_NAME.GROUP]}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }, [currentFilterState, journalListState])

  const moduleRender = useMemo(() => {
    return (
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <MyText size={24} weight={700} color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            Сведения о реализуемых модулях на учебный год
          </MyText>
        </Col>

        <Col span={24}>
          <Table columns={moduleColumn} dataSource={modulesState.data} loading={modulesState.loading}
                 rowKey={(record) => record.id} pagination={false}/>
        </Col>
      </Row>
    )
  }, [modulesState])

  const studentJournalRender = useMemo(() => {
    return (
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row justify='space-between' align='middle'>
            <Col>
              <MyText size={24} weight={700} color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                Сведения об обучающихся группы
              </MyText>
            </Col>

            <Col>
              <Radio.Group defaultValue={currentSide.key} size="large" onChange={(e) => {
                let res = sideInitialState.find(item => item.key === e.target.value)
                setCurrentSide(res)
                let filter = filterForm.getFieldsValue()
                if (filter.group) {
                  dispatch(getPrintStudy({...filterForm.getFieldsValue(), side: res?.key}))
                }
              }}>
                {
                  sideInitialState.map(item => (
                    <Radio.Button value={item.key} key={item.key}>{item.value}</Radio.Button>
                  ))
                }
              </Radio.Group>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Table columns={currentSide.key === 'left' ? studyLeftColumn : studyRightColumn} dataSource={studyState.data}
                 loading={studyState.loading}
                 rowKey={(record) => record.person_id} pagination={false}/>
        </Col>
      </Row>
    )
  }, [currentSide, filterForm, studyState])

  const medicalRender = useMemo(() => {
    return (
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <MyText size={24} weight={700} color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            Результаты медицинского осмотра обучающихся
          </MyText>
        </Col>

        <Col span={24}>
          <Table columns={medicalColumn} dataSource={medicalState.data} loading={medicalState.loading}
                 rowKey={(record) => record.person_id} pagination={false}/>
        </Col>
      </Row>
    )
  }, [medicalState, filterForm])

  const totalUvrRender = useMemo(() => {
    return (
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row justify='space-between' align='middle'>
            <Col>
              <MyText size={24} weight={700} color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                Итоги учебно-воспитательной работы
              </MyText>
            </Col>

            <Col>
              <Select placeholder='Выберите дисциплину из списка'
                      options={totalUvrDisciplinesState.data?.map((item: any, index: any) => ({
                        key: `discipline-${item.id}-${index}`,
                        label: item.caption,
                        value: item.id,
                        item: item
                      }))} onChange={(value, option) => handleSelectTotalUvrDiscipline(option)}/>
            </Col>

            <Col>
              <Radio.Group defaultValue={totalUvr.side.key} size="large" onChange={(e) => {
                let res = sideInitialState.find(item => item.key === e.target.value)
                // @ts-ignore
                setTotalUvr(prevState => ({
                  ...prevState,
                  side: res
                }))
                let filter = filterForm.getFieldsValue()
                if (filter.group && totalUvr.subject_id) {
                  dispatch(getPrintTotalUvr({
                    ...filterForm.getFieldsValue(),
                    side: res?.key,
                    subject_id: totalUvr.subject_id
                  }))
                }
              }}>
                {
                  sideInitialState.map(item => (
                    <Radio.Button value={item.key} key={item.key}>{item.value}</Radio.Button>
                  ))
                }
              </Radio.Group>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Table columns={totalUvr?.side?.key === 'left' ? totalUvrLeftColumn : totalUvrRightColumn}
                 dataSource={totalUvrState.data}
                 loading={totalUvrState.loading}
                 rowKey={record => record.student_id} pagination={false}/>
        </Col>
      </Row>
    )
  }, [totalUvr, filterForm, totalUvrDisciplinesState, totalUvrState])

  const remarkRender = useMemo(() => {
    return (
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row justify='space-between'>
            <Col>
              <MyText size={24} weight={700} color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                Замечания и предложения по ведению журнала
              </MyText>
            </Col>
            <Col>
              <MyButton type='primary' ghost title='Добавить замечание' icon={<PlusOutlined/>}
                        onClick={handleAddRemark} disabled={!currentFilterState[FORM_NAME.GROUP]}/>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Table columns={remarkColumn} dataSource={remarkState.data} loading={remarkState.loading}
                 rowKey={record => record.remark_id} pagination={false}/>
        </Col>
      </Row>
    )
  }, [currentFilterState, remarkState])

  const printRender = useMemo(() => {
    return (
      <Row>
        <Col>
          <MyButton title='Распечатать' type='primary' ghost onClick={handleClickPrintJournal}
                    disabled={!currentFilterState[FORM_NAME.YEAR] || !currentFilterState[FORM_NAME.GROUP] || !currentFilterState[FORM_NAME.COURSE]}/>
        </Col>
      </Row>
    )
  }, [currentFilterState])

  const totalHalfRender = useMemo(() => {
    return (
      <h1>qwe</h1>
    )
  }, [])

  const totalPoRender = useMemo(() => {
    return (
      <h1>qwe</h1>
    )
  }, [])

  const totalPpRender = useMemo(() => {
    return (
      <h1>qwe</h1>
    )
  }, [])

  const innerJournalItems = [
    {
      key: PRINT_TYPES.MODULE,
      label: t('printModule'),
      children: (
        <Card>
          {moduleRender}
        </Card>
      )
    },
    {
      key: PRINT_TYPES.STUDY,
      label: t('printStudent'),
      children: (
        <Card>
          {studentJournalRender}
        </Card>
      )
    },
    {
      key: PRINT_TYPES.MEDICAL,
      label: t('printMedical'),
      children: (
        <Card>
          {medicalRender}
        </Card>
      )
    },
    {
      key: PRINT_TYPES.TOTAL_UVR,
      label: t('printTotalUvr'),
      children: (
        <Card>
          {totalUvrRender}
        </Card>
      )
    },
    {
      key: PRINT_TYPES.REMARK,
      label: t('printRemark'),
      children: (
        <Card>
          {remarkRender}
        </Card>
      )
    },
    {
      key: PRINT_TYPES.PRINT,
      label: t('printJournal'),
      children: (
        <Card>
          {printRender}
        </Card>
      )
    },
  ]

  const innerIndustrialItems = [
    // {
    //   key: PRINT_TYPES.STUDENT_INDUSTRIAL,
    //   label: t('printStudent'),
    //   children: (
    //     <Card>
    //       {studentIndustrialRender}
    //     </Card>
    //   )
    // },
    {
      key: PRINT_TYPES.TOTAL_HALF,
      label: t('printTotalHalf'),
      children: (
        <Card>
          {totalHalfRender}
        </Card>
      )
    },
    {
      key: PRINT_TYPES.TOTAL_PO,
      label: t('printTotalPo'),
      children: (
        <Card>
          {totalPoRender}
        </Card>
      )
    },
    {
      key: PRINT_TYPES.TOTAL_PP,
      label: t('printTotalPp'),
      children: (
        <Card>
          {totalPpRender}
        </Card>
      )
    },
    {
      key: PRINT_TYPES.REMARK,
      label: t('printRemark'),
      children: (
        <Card>
          {remarkRender}
        </Card>
      )
    },
  ]

  const outerItems = [
    {
      key: JOURNAL_TYPES.THEORETICAL,
      label: t('journalTheoretical'),
      children: (
        <Row>
          <Col span={24}>
            <Card>
              {filterRender}
            </Card>
          </Col>
          <Col span={24}>
            <SecondLevelTabs items={innerJournalItems} defaultActiveKey={PRINT_TYPES.MODULE}
                             onTabClick={(key: any) => handleClickPrintTab(key)}/>
          </Col>
        </Row>
      ),
    },
    {
      key: JOURNAL_TYPES.INDIVIDUAL,
      label: t('journalIndividual'),
      children: (
        <Row>
          <Col span={24}>
            <Card>
              {filterRender}
            </Card>
          </Col>
          <Col span={24}>
            <SecondLevelTabs items={innerJournalItems} defaultActiveKey={PRINT_TYPES.MODULE}
                             onTabClick={(key: any) => handleClickPrintTab(key)}/>
          </Col>
        </Row>
      ),
    },
    {
      key: JOURNAL_TYPES.INDUSTRIAL,
      label: t('journalIndustrial'),
      children: (
        <Row>
          <Col span={24}>
            <Card>
              {filterRender}
            </Card>
          </Col>
          <Col span={24}>
            <SecondLevelTabs items={innerIndustrialItems} defaultActiveKey={PRINT_TYPES.STUDENT_INDUSTRIAL}
                             onTabClick={(key: any) => handleClickPrintTab(key)}/>
          </Col>
        </Row>
      ),
    },
  ]

  const handleClickPrintTab = useCallback((key: any) => {
    console.log(key)
  }, [])

  const handleClickTypeTab = useCallback((type: any) => {
    getJournalList(currentFilterState[FORM_NAME.YEAR], type)

    filterForm.resetFields([FORM_NAME.GROUP])
    dispatch(printStoreReset())
  }, [currentFilterState])

  return (
    <>
      <MyBreadcrumb title={t('printJournal')}/>

      <Row justify='center'>
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs items={outerItems}
                              onTabClick={(key: any) => handleClickTypeTab(key)}
                              activeKey={currentFilterState[FORM_NAME.TYPE_STRING]}/>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
