import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {getQuestionAnswerAction, testResultAction} from "../../../../store/pages/plans/actions";
import {RootState} from "../../../../store";
import {selectStore} from "../../../../store/selector";
import {MyBreadcrumb, MyButton, MyText} from "../../../../components";
import {Button, Card, Checkbox, Col, Form, Input, message, Radio, Row, TabPaneProps} from "antd";
import {TabsStyles} from "../../../Plans/[planId]/[lessonId]/tests/index.styles";
import {setFormAction} from "../../../../store/common/actions";

const FORM_ITEM_NAME = {
  ID: 'id',
  TEST_ID: 'test_id',
  NAME: 'name',
  ANSWERS: 'answers',
  QUESTIONS: 'questions',
  IS_CORRECT: 'is_correct',
  INPUT_TYPE: 'input_type',
}

const ACTION_NAME = {
  BEGIN: 'begin',
  REPEAT: 'repeat',
  ANSWER: 'answer',
  FINISH: 'finish'
}

export const ScheduleStudentTest = () => {
  const {t} = useTranslation("common")

  const [testForm] = Form.useForm()
  const dispatch = useDispatch()
  const {testId, lessonId} = useParams()

  useEffect(() => {
    dispatch(getQuestionAnswerAction(testId))
  }, [])

  const {plans: {oneTest: testState, testResult: testActionState}}: RootState = useSelector(selectStore)

  const [tabsItem, setTabsItems] = useState<any>([]);
  const [activeKey, setActiveKey] = useState<any>(0);

  const formItemRender = useCallback((item: any, key: any) => {
    return (
      <Row gutter={[16, 16]} style={{padding: 16}}>
        <Col span={24}>
          <Form.Item name={[key, FORM_ITEM_NAME.ID]} initialValue={item[FORM_ITEM_NAME.ID]} hidden>
            <Input hidden/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <MyText weight={500} size={24}>
            {item[FORM_ITEM_NAME.NAME]}
          </MyText>
        </Col>
        <Col span={24}>
          <Form.Item name={[key, FORM_ITEM_NAME.ANSWERS]}>
            {
              item[FORM_ITEM_NAME.INPUT_TYPE] === 'radio' ?
                (<Radio.Group>
                  <Row gutter={[16, 16]}>
                    {
                      item[FORM_ITEM_NAME.ANSWERS].map((item: any) => (
                        <Col key={item.id} span={24}>
                          <Radio value={item.id}>{item.name}</Radio>
                        </Col>
                      ))
                    }
                  </Row>
                </Radio.Group>)
                :
                (<Checkbox.Group>
                  <Row gutter={[16, 16]}>
                    {
                      item[FORM_ITEM_NAME.ANSWERS].map((item: any) => (
                        <Col key={item.id} span={24}>
                          <Checkbox value={item.id}>{item.name}</Checkbox>
                        </Col>
                      ))
                    }
                  </Row>
                </Checkbox.Group>)
            }
          </Form.Item>
        </Col>
      </Row>
    )
  }, [])

  useEffect(() => {
    if (testState?.data?.questions) {
      let arr = testState.data?.questions?.length > 0 ? testState.data?.questions : []

      const test: TabPaneProps[] = arr.map((item: any, index: any) => {
        return {
          key: index,
          label: item[FORM_ITEM_NAME.NAME],
          children: formItemRender(item, index),
          forceRender: true,
          disabled: !!testState.data?.is_repeatable
        }
      })

      if (testState.data?.is_repeatable) {
        dispatch(setFormAction({
          method: "post",
          path: `sdot/test-process`,
          fields: {
            act: ACTION_NAME.BEGIN,
            lesson_id: lessonId,
            th: testState.data?.hash
          },
          action: testResultAction
        }))
      }

      setTabsItems(test)
    }
  }, [testState?.data])

  const onChangeTab = useCallback((newActiveKey: number) => {
    setActiveKey(newActiveKey);
  }, [])

  const onValuesChange = useCallback((changedValues: any, allValues: any) => {
    let currentKey: any = Object.keys(changedValues)[0]
    let currentValue: any = Object.values(changedValues)[0]


  }, [tabsItem])

  const handleClickFinish = useCallback(() => {
    dispatch(setFormAction({
      method: "post",
      path: `sdot/test-process`,
      fields: {
        act: ACTION_NAME.FINISH,
        lesson_id: lessonId,
        th: testState.data?.hash,
        rh: testActionState.data?.rh
      },
    }))
  }, [testState.data, testActionState.data])

  const handleClickSave = useCallback(() => {
    const answer = Array.isArray(testForm.getFieldsValue()[activeKey]?.answers) ? testForm.getFieldsValue()[activeKey]?.answers : [testForm.getFieldsValue()[activeKey]?.answers]

    let fields = {
      act: ACTION_NAME.ANSWER,
      th: testActionState.data?.th,
      rh: testActionState.data?.rh,
      lesson_id: lessonId,
      question: {
        id: testForm.getFieldsValue()[activeKey]?.id,
        answers: answer.map((item: any) => ({id: item}))
      }
    }

    dispatch(setFormAction({
      method: "post",
      path: `sdot/test-process`,
      fields: fields,
    }))

    message.success(`На вопрос ${tabsItem[activeKey].label} ответ принят!`)

    setActiveKey((prevState: number) => prevState + 1)
  }, [testState, testActionState, activeKey, testForm])

  const formRender = useMemo(() => {
    if (tabsItem.length > 0) {
      return (
        <Form
          autoComplete="off"
          form={testForm}
          name="form"
          onValuesChange={(changedValues, allValues) => onValuesChange(changedValues, allValues)}
        >

          <Row gutter={[0, 16]}>
            <Col span={24}>
              <TabsStyles
                onChange={(activeKey) => onChangeTab(parseInt(activeKey))}
                activeKey={activeKey}
                items={tabsItem}
                tabBarExtraContent={(
                  testState.data?.is_repeatable &&
                  <MyButton danger onClick={() => handleClickFinish()}>
                    Закончить тестирование
                  </MyButton>
                )}
              />
            </Col>
            {
              (testState.data?.is_repeatable) && (tabsItem[tabsItem.length - 1]?.key !== tabsItem[activeKey]?.key) &&
              <Col span={24}>
                <Row justify='center'>
                  <Col>
                    <Form.Item>
                      <Button onClick={() => handleClickSave()}>Отправить ответ</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            }

          </Row>
        </Form>
      )
    }
  }, [tabsItem, activeKey, testActionState.data])

  return (
    <>
      <MyBreadcrumb title={t('back')} isBack/>

      <Row justify='center'>
        <Col span={20}>
          <Card loading={testState.loading || testActionState.loading}>
            {formRender}
          </Card>
        </Col>
      </Row>
    </>
  )
}