import styled from "styled-components";
import {Tabs} from "antd";
import {DefaultTokenTheme} from "../../../assets/themes/defaultTheme";
import {setThemeColor} from "../../../utils/setThemeColor";

export const SecondLevelTabsStyles = styled(Tabs)`
  & > .ant-tabs-nav {
    background-color: ${({theme}) => setThemeColor(theme.current, '#fff', DefaultTokenTheme.plateDark)};
    margin-bottom: 0;

    &:before {
      border-color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};
    }

    .ant-tabs-tab {
      font-size: 16px;
      line-height: 24px;
      padding: 16px 0;

      &:first-child {
        margin-left: 24px;
      }
    }
  }

  & > .ant-tabs-content-holder {
    background-color: ${({theme}) => setThemeColor(theme.current, '#fff', DefaultTokenTheme.plateDark)};
  }
`
