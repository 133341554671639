import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {
  FETCH,
  DONE,
  FAIL,
  GET,
  JOURNAL,
  TABLE,
  MARK,
  ADD,
  POST,
  EDIT,
  SET,
  DATA, LIST, CURRENT, ITEM, LESSON, INDIVIDUAL, COLUMN, PERFORMANCE
} from "../../constants";

import reducerStatuses from "../../status";
import {IJournalType} from "./types";
import dayjs from "dayjs";

const initialState = {
  list: {...reducerStatuses, data: null as any},
  table: {...reducerStatuses, data: {columns: [] as any, data: [] as any}},

  performance:  {...reducerStatuses, data: {columns: [] as any, data: [] as any}},
};

export default createReducer(initialState, builder =>
  builder
    .addCase(GET + JOURNAL + LIST + FETCH, (state, {payload}: PayloadAction<IJournalType>) => ({
      ...state,
      list: {
        ...initialState.list,
        loading: true
      },
    }))
    .addCase(GET + JOURNAL + LIST + FETCH + DONE, (state, {payload}: PayloadAction<IJournalType>) => ({
      ...state,
      list: {
        ...initialState.list,
        success: true,
        data: payload
      },
    }))
    .addCase(GET + JOURNAL + LIST + FETCH + FAIL, (state, {payload}: PayloadAction<IJournalType>) => ({
      ...state,
      list: {
        ...initialState.list,
        failed: true,
      },
    }))

    .addCase(POST + JOURNAL + LESSON + ADD, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      table: {
        ...state.table,
        loading: true
      },
    }))

    .addCase(POST + JOURNAL + LESSON + ADD + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      table: {
        ...state.table,
        loading: false,
        failed: true
      },
    }))

    .addCase(GET + JOURNAL + TABLE + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      table: {
        ...state.table,
        loading: true
      },
    }))
    .addCase(GET + JOURNAL + TABLE + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      table: {
        ...state.table,
        success: true,
        loading: false,
        data: payload
      },
    }))
    .addCase(GET + JOURNAL + TABLE + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      table: {
        ...state.table,
        loading: false,
        success: false,
        failed: true,
      },
    }))

    .addCase(JOURNAL + MARK + EDIT, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      table: {
        ...state.table,
        loading: true
      },
      // table: {
      //   ...state.table,
      //   data: {
      //     ...state.table.data,
      //     data: state.table.data.data.map((dataItem: any) => {
      //       if (dataItem.id === payload.data.student_id) {
      //         return {
      //           ...dataItem,
      //           [payload.data.tableLessonId]: {
      //             ...dataItem[payload.data.tableLessonId],
      //             isLoading: true,
      //           }
      //         }
      //       }
      //       return dataItem
      //     })
      //   },
      // }
    }))
    // .addCase(JOURNAL + MARK + EDIT + DONE, (state, {payload}: PayloadAction<any>) => ({
    //   ...state,
    //   table: {
    //     ...state.table,
    //     data: {
    //       ...state.table.data,
    //       data: state.table.data.data.map((dataItem: any) => {
    //         if (dataItem.id === payload.data.student_id) {
    //           let arrayRes = payload.data.updatedArr?.map((updatedItem: any) => {
    //             return {
    //               [updatedItem.dataIndex]: {
    //                 ...dataItem[updatedItem.dataIndex],
    //                 marks: [{
    //                   ...dataItem[updatedItem.dataIndex].marks[0],
    //                   mark: updatedItem.mark,
    //                   isSuccess: true
    //                 }]
    //               }
    //             }
    //           })
    //
    //           const resultObj = arrayRes?.reduce((a: any, v: any) => {
    //             return v
    //           }, {})
    //
    //           return {
    //             ...dataItem,
    //             [payload.data.tableLessonId]: {
    //               ...dataItem[payload.data.tableLessonId],
    //               isLoading: false,
    //               marks: [{
    //                 key: payload.data.id,
    //                 ...payload.data
    //               }]
    //             },
    //             ...(resultObj ? resultObj : {})
    //           }
    //         }
    //         return dataItem
    //       })
    //     },
    //   }
    // }))
    .addCase(JOURNAL + MARK + EDIT + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      table: {
        ...state.table,
        success: false,
        loading: false,
        failed: true
      }
    }))

    .addCase(JOURNAL + INDIVIDUAL + MARK + EDIT, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      table: {
        ...initialState.table,
        data: {
          ...state.table.data,
          data: state.table.data.data.map((dataItem: any) => {
            if (dataItem.id === payload.data.student_id) {
              return {
                ...dataItem,
                [payload.data.tableLessonId]: {
                  ...dataItem[payload.data.tableLessonId],
                  marks: dataItem[payload.data.tableLessonId]?.marks?.map((mark: any) => {
                    if (mark.key === payload.data.id) {
                      return {
                        ...mark,
                        isLoading: true
                      }
                    } else {
                      return mark
                    }
                  })
                }
              }
            } else {
              return dataItem
            }
          })
        },
      }
    }))
    .addCase(JOURNAL + INDIVIDUAL + MARK + EDIT + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      table: {
        ...state.table,
        data: {
          ...state.table.data,
          data: state.table.data.data.map((dataItem: any) => {
            if (dataItem.id === payload.data.student_id) {
              return {
                ...dataItem,
                [payload.data.tableLessonId]: {
                  ...dataItem[payload.data.tableLessonId],
                  marks: dataItem[payload.data.tableLessonId]?.marks?.map((mark: any) => {
                    if (mark.key === payload.data.id) {
                      return {
                        ...mark,
                        isLoading: false,
                        ...payload.data
                      }
                    } else {
                      return mark
                    }
                  })
                }
              }
            } else {
              return dataItem
            }

          })
        },
      }
    }))


    .addCase(JOURNAL + INDIVIDUAL + MARK + ADD, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      table: {
        ...initialState.table,
        loading: true,
        data: state.table.data
      }
    }))
    .addCase(JOURNAL + INDIVIDUAL + MARK + ADD + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      table: {
        ...initialState.table,
        success: true,
        data: {
          ...state.table.data,
          data: state.table.data.data.map((dataItem: any) => {
            if (dataItem.id === payload.data.student_id) {
              return {
                ...dataItem,
                [payload.data.tableLessonId]: {
                  ...dataItem[payload.data.tableLessonId],
                  //todo form исправить
                  marks: [...dataItem[payload.data.tableLessonId].marks, {
                    ...payload.data,
                    key: payload.data.id,
                    form: 'grade_update_individual_v1'
                  }].sort((a, b) => (dayjs(a.date_at).isAfter(dayjs(b.date_at)) ? 1 : -1))
                }
              }
            }
            return dataItem
          })
        },
      }
    }))

    .addCase(JOURNAL + INDIVIDUAL + COLUMN + EDIT, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      table: {
        ...initialState.table,
        data: {
          ...state.table.data,
          columns: state.table.data.columns.map((dataItem: any) => {
            if (dataItem.lesson_id === payload.data.tableLessonId) {
              return {
                ...dataItem,
                isLoading: true,
              }
            } else {
              return dataItem
            }
          })
        },
      }
    }))
    .addCase(JOURNAL + INDIVIDUAL + COLUMN + EDIT + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      table: {
        ...initialState.table,
        data: {
          ...state.table.data,
          columns: state.table.data.columns.map((dataItem: any) => {
            if (dataItem.lesson_id === payload.data.tableLessonId) {
              return {
                ...dataItem,
                isLoading: false,
                date_at: payload.data.date,
                theme: payload.data.theme,
              }
            } else {
              return dataItem
            }
          })
        },
      }
    }))


    .addCase(GET + PERFORMANCE + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      performance: {
        ...state.performance,
        loading: true,
        success: false,
      },
    }))
    .addCase(GET + PERFORMANCE + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      performance: {
        ...initialState.performance,
        success: true,
        data: payload,
      },
    }))
    .addCase(GET + PERFORMANCE + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      performance: {
        ...initialState.performance,
        failed: true,
      },
    }))
)


