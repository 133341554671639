import Icon from "@ant-design/icons";

const Svg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M21 17H3C4.49117 17 5.7 15.7464 5.7 14.2V9.53333C5.7 5.92507 8.52061 3 12 3C15.4794 3 18.3 5.92507 18.3 9.53333V14.2C18.3 15.7464 19.5088 17 21 17ZM14 20C13.5864 20.6168 12.8244 20.9965 12 20.9965C11.1756 20.9965 10.4136 20.6168 10 20H14Z" stroke="#626C70" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
export const MyIcon = (props: any) => <Icon component={Svg} {...props} />;
