import styled from "styled-components";
import {Row} from "antd";
import {setThemeColor} from "../../utils/setThemeColor";
import {DefaultTokenTheme} from "../../assets/themes/defaultTheme";

export const MainPage = styled(Row)`
  .react-calendar {
    border: none;
    background: ${({theme}) => setThemeColor(theme.current, '#fff', DefaultTokenTheme.plateDark)};
    color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.textTitle, '#fff')};

    .react-calendar__navigation {
      .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
        display: none;
      }

      .react-calendar__navigation__arrow {
        position: relative;
      }
    }

    .react-calendar__viewContainer {
      .react-calendar__tile--now {
        background: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.primary, DefaultTokenTheme.primary)};
        color: #fff;
      }
    }
  }
`