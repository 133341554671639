import Icon from "@ant-design/icons";

const Svg = () => {
  return (
    <svg width="115" height="28" viewBox="0 0 115 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2269_5352)">
        <path d="M25.0451 5.81192L13.4818 10.4557C12.9315 10.677 12.3167 10.677 11.7663 10.4557L0.203761 5.81192C0.106432 5.77271 0 5.84483 0 5.94986V15.7276C0 15.8452 0.0714214 15.951 0.180654 15.9944L5.16685 17.997C5.60308 18.172 5.88807 18.595 5.88807 19.0648V27.3714C5.88807 27.5303 6.0169 27.6592 6.17585 27.6592H9.90517C10.0641 27.6592 10.193 27.5303 10.193 27.3714V16.4061C10.193 15.0638 11.2811 13.975 12.6241 13.975C13.9671 13.975 15.0552 15.0631 15.0552 16.4061V27.3714C15.0552 27.5303 15.1841 27.6592 15.343 27.6592H19.0723C19.2313 27.6592 19.3601 27.5303 19.3601 27.3714V19.0648C19.3601 18.595 19.6458 18.172 20.082 17.997L25.0682 15.9944C25.1775 15.9503 25.2489 15.8452 25.2489 15.7276V5.94986C25.2489 5.84483 25.1424 5.77271 25.0451 5.81192ZM6.9951 2.56294L9.3275 3.51593C9.49205 3.58315 9.57537 3.7645 9.52356 3.93466C9.40802 4.31907 9.36181 4.7322 9.40102 5.16212C9.53966 6.67738 10.9779 8.03999 12.498 8.09811C14.3438 8.16813 15.8619 6.69278 15.8619 4.86313C15.8619 4.53894 15.8135 4.22664 15.7246 3.93185C15.6735 3.7624 15.7575 3.58315 15.9214 3.51593L18.2545 2.56294C18.3889 2.50833 18.3889 2.31717 18.2545 2.26255L12.8026 0.0351856C12.6885 -0.0117285 12.5611 -0.0117285 12.4469 0.0351856L6.9951 2.26255C6.86066 2.31717 6.86066 2.50833 6.9951 2.56294Z" fill="#773DBD"/>
        <path d="M85.527 18.3832C85.9934 16.0178 87.6405 14.5241 90.0961 14.5241C92.6445 14.5241 94.323 16.1427 94.6339 18.3832H85.5277H85.527ZM90.0961 11.7545C85.7138 11.7545 82.1081 15.1153 82.1081 19.8776C82.1081 24.6392 85.7131 28 90.0961 28C93.7946 28 96.3437 25.9458 97.5245 22.9899H94.1989C93.3597 24.4522 92.0541 25.2304 90.0961 25.2304C87.5471 25.2304 85.8379 23.6125 85.465 21.0286H97.8668C98.7367 15.7065 94.7893 11.7538 90.0961 11.7538V11.7545ZM69.0427 20.9043C69.0427 23.3319 67.5511 24.9819 65.2508 24.9819C62.9513 24.9819 61.4903 23.3326 61.4903 20.9043V12.0965H58.2581V20.9043C58.2581 25.0748 60.9619 28 65.2508 28C69.5711 28 72.2749 25.0748 72.2749 20.9043V12.0965H69.0427V20.9043ZM107.103 19.6284L114.127 12.0965H110.243L103.995 18.9437V5.25H100.763V27.658H103.995V20.5623L110.554 27.658H114.625L107.103 19.6284ZM76.0082 27.658H79.2404V5.25H76.0082V27.658ZM38.1276 27.658H41.3597V12.0965H38.1276V27.658ZM43.5979 9.04702H49.099V27.658H52.518V9.04702H58.019V5.8726H43.5979V9.04702ZM39.7133 5.43287C38.5597 5.43287 37.625 6.36886 37.625 7.52332C37.625 8.67779 38.5597 9.61448 39.7133 9.61448C40.8662 9.61448 41.801 8.67848 41.801 7.52332C41.801 6.36886 40.8662 5.43287 39.7133 5.43287Z" fill="#773DBD"/>
      </g>
      <defs>
        <clipPath id="clip0_2269_5352">
          <rect width="114.745" height="28" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  )
}
export const MyIcon = (props: any) => <Icon component={Svg} {...props} />;
