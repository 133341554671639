import styled from "styled-components";
import {Button} from "antd";
import {darken, cssVar} from 'polished'

export const MyButtonStyles = styled(Button)`
  letter-spacing: 0.5px;
  padding: 0 24px;
  font-size: 18px;

  &.ant-btn-primary {
    &.ant-btn-background-ghost {
      &:hover {

      }
    }
  }

`
