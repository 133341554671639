import styled from "styled-components";
import {DefaultTokenTheme} from "../../../../assets/themes/defaultTheme";
import {setThemeColor} from "../../../../utils/setThemeColor";

export const ScheduleItemStyles = styled.div`
  width: 100%;
  border: 1px solid ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};
  border-radius: 8px;
  padding: 12px;
  
  &.current{
    border-color: ${DefaultTokenTheme.primary};
    border-left: 6px solid ${DefaultTokenTheme.primary};
  }
`