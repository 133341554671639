export const initialDataPD = [
  {
    content: "ФИО",
    info: "(Не задано)",
  },
  {
    content: "ИИН",
    info: "(Не задано)",
  },
  {
    content: "Дата рождения",
    info: "(Не задано)",
  },
  {
    content: "Место рождения",
    info: "(Не задано)",
  },
  {
    content: "Гражданство",
    info: "(Не задано)",
  },
  {
    content: "Пол",
    info: "(Не задано)",
  },
  {
    content: "Национальность",
    info: "(Не задано)",
  },
  {
    content: "Языки обучения",
    info: "(Не задано)",
  },
  {
    content: "Роль пользователя",
    info: "(Не задано)",
  },
];


  export const initialDataContact = [
    {
      content: "Домашний телефон",
      info: "(Не задано)",
    },
    {
      content: "Мобильный телефон",
      info: "(Не задано)",
    },
    {
      content: "Адрес переписки",
      info: "(Не задано)",
    },
    {
      content: "Домашний адрес",
      info: "(Не задано)",
    },

    {
        content: "E-mail",
        info: "(Не задано)",
      },
  ];

  export const initialDataJob = [
    {
      content: "Название",
      info: "(Не задано)",
    },
    {
      content: "Ставка",
      info: "(Не задано)",
    },
    {
        content: "Изменить должность",
        info: "(Не задано)",
      },
  ];

  export const initDataAuth = [
    {
      content: "Логин",
      info: "(Не задано)",
    },
    {
      content: "Пароль",
      info: "(Не задано)",
    },
   
  ];



  export const initDataAwards = [
    {
      content: "Награда",
      info: "(Не задано)",
    },
    {
      content: "Персональные названия",
      info: "(Не задано)",
    },
    {
      content: "Ученая степень",
      info: "(Не задано)",
    },
   
  ];


  export const initDataLg = [
    {
      content: "Язык",
      info: "(Не задано)",
    },
    {
      content: "Владение языком",
      info: "(Не задано)",
    },
  ];

  export const initialDataIDBirth = [
    {
      content: "Номер свидетельство о рождении",
      info: "(Не задано)",
    },
    {
      content: "Дата выдачи",
      info: "(Не задано)",
    },
    {
      content: "Серия свидетельство о рождении",
      info: "(Не задано)",
    },
   
  ];

  export const initialDataID = [
    {
      content: "Номер удостоверения личности",
      info: "(Не задано)",
    },
    {
      content: "Дата выдачи",
      info: "(Не задано)",
    },
    {
      content: "Действительно до",
      info: "(Не задано)",
    },
    {
      content: "Кем выдано",
      info: "(Не задано)",
    },
   
  ];
  

  export const initialDataPassport = [
    {
      content: "Номер паспорта ",
      info: "(Не задано)",
    },
    {
      content: "Дата выдачи",
      info: "(Не задано)",
    },
    {
      content: "Действителен до ",
      info: "(Не задано)",
    },
    {
      content: "Кем выдано",
      info: "(Не задано)",
    },
    {
      content: "Серия",
      info: "(Не задано)",
    },
   
  ];
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

  export const initDataStage = [
    {
      content: "Организации",
      info: "(Не задано)",
    },
    {
      content: "Дата начала работы",
      info: "(Не задано)",
    },{
      content: "Дата окончания работы",
      info: "(Не задано)",
    },
    {
      content: "Опыт работы",
      info: "(Не задано)",
    },{
      content: "Номер приказа при приеме на работу ",
      info: "(Не задано)",
    },
    {
      content: "Дата приказа при приеме на работу",
      info: "(Не задано)",
    },
  ]


  export const initialDataGetEdu = [
    {
      content: "Образование",
      info: "(Не задано)",
    },
    {
      content: "Учебное заведение",
      info: "(Не задано)",
    },{
      content: "Факультет",
      info: "(Не задано)",
    },
    {
      content: "Специальность",
      info: "(Не задано)",
    },{
      content: "№ диплома ",
      info: "(Не задано)",
    },
    {
      content: "Когда окончил ",
      info: "(Не задано)",
    },
    {
      content: "Педагогическое ",
      info: "(Не задано)",
    },
  ]


  export const initDataQualification = [
    {
      content: "Название курса ",
      info: "(Не задано)",
    },
    {
      content: "Дата начало курса ",
      info: "(Не задано)",
    },{
      content: "Дата окончания курса ",
      info: "(Не задано)",
    },
    {
      content: "Результаты/Итоги",
      info: "(Не задано)",
    },
  ]


  export const initDataCategory = [
    {
      content: "Название ",
      info: "(Не задано)",
    },
    {
      content: "№ приказа ",
      info: "(Не задано)",
    },{
      content: "Дата приказа  ",
      info: "(Не задано)",
    },
    {
      content: "№ Удостоверения ",
      info: "(Не задано)",
    },
    {
      content: "Дата выдачи удостоверения ",
      info: "(Не задано)",
    },
    {
      content: "Срок действия удостоверения",
      info: "(Не задано)",
    },
    
  ]