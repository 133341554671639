export const initialStates = {
    // Personal data
    firstname: false,
    middlename: false,
    secondname: false,
    lang: false,
    email: false,
    date_birth: false,
    gender: false,
    iin: false,
    place_of_birth: false,
    citizenship: false,
    nationality: false,
    role_ids: false,
    // // //
    nameRu: false,
    nameKz: false,
    // Contacts
    home_address: false,
    mobile_phone: false,
    registration_address: false,
    home_phone: false,
    // ID
    id_card_number: false,
    card_valid_until: false,
    card_who_issued: false,
    card_date_of_issue: false,
    // ID Birth
    birth_date_of_issue: false,
    number_birth_certificate: false,
    series_birth_certificate: false,
    // Passport
    passport_valid_until: false,
    passport_who_issued: false,
    passport_date_of_issue: false,
    passport_series: false,
    passport_number: false,


    // Awards
    award: false,
    personal_name: false,
    academic_degree: false,
    // Languages
    lang_name: false,
    lang_skills: false,

    // Job position
    job_title: false,
    working_rate: false,

     // Auth
     email_auth: false,
     password_auth: false,


////////////////////////////////////////////////
     // Stage
    start_date_stage: false,
    end_date_stage: false,
    work_experience: false,
    order_number: false,
    order_date: false,


    // Getting edu

    education: false,
    institution_title: false,
    faculty_title: false,
    speciality_title: false,
    diploma_number: false,
    graduate_date: false,
    pedagogical: false,

    // Courses
    course_name: false,
    start_date_course: false,
    end_date_course: false,
    result: false,


    // Category
    id_org: false,
    category_title: false,
    order_number_category: false,
    id_number: false,
    date_of_issue_category: false,
    valid_until_category: false,

  };