import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Row,
  Input,
  Cascader,
  Form,
  Select,
  InputNumber,
  message,
} from "antd";
import {
  SecondLevelTabs,
  MyBreadcrumb,
  FirstLevelTabs,
} from "../../../components";
import { useTheme } from "../../../hooks";
import { position } from "polished";
import "../../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import { Cookies } from "react-cookie";
import {
  FacultyTypeVM,
  QualificationVM,
  SpecialityVM,
} from "../../InterfacesApi/InterfacesApi";
import axios from "axios";
import { HeadersAuth } from "../../../constants/Auth";

export const GroupCreate = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [specialities, setSpecialities] = useState<SpecialityVM[]>([]);
  const [qualifications, setQualifications] = useState<QualificationVM[]>([]);
  const [faculty, setFaculty] = useState<FacultyTypeVM[]>([]);
  const [selectedSpecialityId, setSelectedSpecialityId] = useState<
    number | null
  >(null);
  const [selectedQualificationId, setSelectedQualificationId] = useState<
    number | null
  >(null);
  const [selectedFacultyId, setSelectedFacultyId] = useState<number | null>(
    null
  );

  const [inputStates, setInputStates] = useState({
    title: "",
    nameKz: "",
    lang: "",
    year: "",
    speciality: false,
    base: false,
    degree: false,
    due: false,
    mainbase: false,
    department: false,
    faculty: false,
    advisor: false,
    qualification: false,
  });

  const [formData, setFormData] = useState({
    nameRu: "",
    nameKz: "",
    speciality: undefined,
    lang: "",
    year: "",
    base: undefined,
    degree: undefined,
    due: undefined,
    mainbase: undefined,
    department: undefined,
    faculty: undefined,
    advisor: undefined,
    qualification: undefined,
  });
  const handleAddDepartment = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/v1/createStudy-groups`,
        {
          title: formData.nameRu,
          college_id: 1,
          speciality_id: selectedSpecialityId,
          adviser_id: 1,
          language_iso: formData.lang,
          qualification_ids: selectedQualificationId,
          edu_base_id: Number(formData.base),
          start_year: formData.year,
          department_id: formData.faculty,
        },
        HeadersAuth
      );

      message.success("Группа успешно добавлена");
      navigate("/speciality");
    } catch (error) {
      message.error("Ошибка при добавлении группы");
    }
  };

  const handleInputChange = (inputName) => (value) => {
    const inputValue = value.target ? value.target.value : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: inputValue,
    }));

    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [inputName]: inputValue !== "",
    }));

    if (inputName === "speciality") {
      const selectedSpeciality = specialities.find(
        (spec) => spec.title === value
      );
      setSelectedSpecialityId(
        selectedSpeciality ? selectedSpeciality.id : null
      );
    }
    if (inputName === "qualification") {
      if (Array.isArray(value) && value.length > 0) {
        const selectedQualifications = value.map((qualificationTitle) => {
          const selectedQualification = qualifications.find(
            (qual) => qual.title === qualificationTitle
          );
          return selectedQualification ? selectedQualification.id : null;
        });
        const joinedQualificationIds = selectedQualifications
          .filter((id) => id !== null)
          .join(",");
        const numericQualificationIds = joinedQualificationIds
          ? parseInt(joinedQualificationIds, 10)
          : null;
        setSelectedQualificationId(numericQualificationIds);
      } else {
        setSelectedQualificationId(null);
      }
    }
    if (inputName === "faculty") {
      const selectedFaculty = faculty.find((fac) => fac.caption === value);
      setSelectedFacultyId(selectedFaculty ? selectedFaculty.id : null);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/specialitiesIndex`,
          HeadersAuth
        );
        if (response.ok) {
          const data = await response.json();
          setSpecialities(data);
        } else {
          console.error("Ошибка получение данных");
        }
      } catch (error) {
        console.error("Ошибка получение данных ", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedSpecialityId) {
      axios
        .get(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/qualificationsIndexAdd?speciality_id=${selectedSpecialityId}`,
          HeadersAuth
        )
        .then((response) => {
          setQualifications(response.data);
        })
        .catch((error) => {
          console.error("Ошибка получение данных ", error);
        });
    }
  }, [selectedSpecialityId]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/show-departments?search`,
        HeadersAuth
      )
      .then((response) => {
        setFaculty(response.data);
      })
      .catch((error) => {
        console.error("Ошибка получение данных ", error);
      });
  }, []);

  const redirectToKafedra = () => {
    navigate("/institution_department/create");
  };
  const redirectToInstitutionDiscipline = () => {
    navigate("/institution_discipline/create");
  };
  const redirectGroup = () => {
    navigate("/group/create");
  };
  const redirectToSpeciality = () => {
    navigate("/speciality/create");
  };

  const firstLevelTabs = [
    {
      key: "1",
      label: (
        <div onClick={redirectToSpeciality} style={{ cursor: "pointer" }}>
          Специальности
        </div>
      ),
      chilren: "",
    },
    {
      key: "2",
      label: (
        <div
          onClick={redirectToInstitutionDiscipline}
          style={{ cursor: "pointer" }}
        >
          Дисциплины
        </div>
      ),
      children: "",
    },
    {
      key: "3",
      label: (
        <div onClick={redirectGroup} style={{ cursor: "pointer" }}>
          Группы
        </div>
      ),
      children: (
        <Content
          style={{
            width: "100%",
            height: "720px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Название на русском
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.title ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.title ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("title")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Название на казахском
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Язык
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.lang ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.lang ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("lang")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Год зачисления
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.year ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.year ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("year")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Специальность
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.speciality ? "success" : ""}
                >
                  <Select
                    style={{
                      width: "90%",
                      ...(inputStates.speciality
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    onChange={handleInputChange("speciality")}
                  >
                    {specialities.map((spec) => (
                      <Select.Option key={spec.id} value={spec.title}>
                        {spec.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                На базе
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.base ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.base ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("base")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Курс
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.degree ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.degree ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("degree")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Срок обучения <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.due ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.due ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("due")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Основа обучения
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.mainbase ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.mainbase ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("mainbase")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Отделение
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.department ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.department
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("department")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Кафедра
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.faculty ? "success" : ""}
                >
                  <Select
                    style={{
                      width: "90%",
                      ...(inputStates.faculty
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    onChange={handleInputChange("faculty")}
                  >
                    {faculty.map((faculties) => (
                      <Select.Option key={faculties.id} value={faculties.id}>
                        {faculties.caption}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Куратор
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.advisor ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.advisor ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("advisor")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Квалификации
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.qualification ? "success" : ""}
                >
                  <Select
                    style={{
                      width: "90%",
                      ...(inputStates.qualification
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    onChange={handleInputChange("qualification")}
                  >
                    {qualifications.map((qualification) => (
                      <Select.Option
                        key={qualification.id}
                        value={qualification.title}
                      >
                        {qualification.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Row>
            <Divider style={{ marginTop: "80px" }} plain />

            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/speciality")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton" onClick={handleAddDepartment}>
                Добавить
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "4",
      label: (
        <div onClick={redirectToKafedra} style={{ cursor: "pointer" }}>
          Кафедры
        </div>
      ),
      children: (
        <Row>
          <Col span={24}>
            <SecondLevelTabs />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Добавить группы" />
      <Row justify="center">
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="3" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>
      <p />
    </>
  );
};
