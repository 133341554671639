import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Row,
  Input,
  Form,
  Select,
  message,
} from "antd";
import {
  SecondLevelTabs,
  JournalTable,
  MyBreadcrumb,
  MyButton,
  MyText,
  FirstLevelTabs,
} from "../../../components";
import { useTheme } from "../../../hooks";
import "../../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import { DeleteOutlined } from "@ant-design/icons";
import { Cookies } from "react-cookie";
import {
  FacultyTypeVM,
  QualificationVM,
  SpecialityVM,
} from "../../InterfacesApi/InterfacesApi";
import { COOKIES_ENUM } from "../../../constants";
import axios from "axios";
import { HeadersAuth } from "../../../constants/Auth";

export const GroupEdit = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useTheme();
  const [specialities, setSpecialities] = useState<SpecialityVM[]>([]);
  const [qualifications, setQualifications] = useState<QualificationVM[]>([]);
  const [faculty, setFaculty] = useState<FacultyTypeVM[]>([]);
  const [selectedSpecialityId, setSelectedSpecialityId] = useState<
    number | null
  >(null);
  const [selectedQualificationIds, setSelectedQualificationIds] = useState<
    number[]
  >([]);
  const [selectedFacultyId, setSelectedFacultyId] = useState<number | null>(
    null
  );

  const handleInputChange = (inputName) => (value) => {
    const inputValue = value.target ? value.target.value : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: inputValue,
    }));

    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [inputName]: inputValue !== "",
    }));

    if (inputName === "speciality") {
      const selectedSpeciality = specialities.find(
        (spec) => spec.title === value
      );
      setSelectedSpecialityId(
        selectedSpeciality ? selectedSpeciality.id : null
      );
      setSelectedQualificationIds([]);
    }
    if (inputName === "qualification") {
      if (Array.isArray(value) && value.length > 0) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          qualification: value,
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          qualification: [],
        }));
      }
    }
    if (inputName === "faculty") {
      const selectedFaculty = faculty.find((fac) => fac.caption === value);
      setSelectedFacultyId(selectedFaculty ? selectedFaculty.id : null);
    }
  };

  const [formData, setFormData] = useState({
    nameRu: location.state ? location.state.study_group_info.title : "",
    nameKz: "",
    speciality: location.state ? location.state.speciality_id : null,
    lang: location.state ? location.state.study_group_info.language_iso : "",
    year: location.state ? location.state.start_year : "",
    base: location.state ? location.state.study_group_info.language_iso : "",
    faculty: location.state ? location.state.department_id : "",
    advisor: location.state ? location.state.adviser_id : "",
    qualification: location.state
      ? location.state.ref_study_group_to_qualifications.map(
          (qual) => qual.qualification_title
        )
      : [],
    degree: undefined,
    due: undefined,
    mainbase: undefined,
  });

  useEffect(() => {
    const fetchData = async () => {
      const cookies = new Cookies();

      const accessToken = cookies.get(COOKIES_ENUM.ACCESSTOKEN);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/specialitiesIndex`,
          {
            method: "GET",
            headers: {
              Auth: `${accessToken}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setSpecialities(data);
        } else {
          console.error("Ошибка получение данных");
        }
      } catch (error) {
        console.error("Ошибка получение данных ", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (formData.speciality !== null) {
      axios
        .get(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/qualificationsIndexAdd?speciality_id=${formData.speciality}`,
          HeadersAuth
        )
        .then((response) => {
          setQualifications(response.data);
          setSelectedQualificationIds((prevQualificationIds) =>
            prevQualificationIds.filter((id) =>
              response.data.some((qual) => qual.id === id)
            )
          );
        })
        .catch((error) => {
          console.error("Ошибка получение данных ", error);
        });
    }
  }, [formData.speciality]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/show-departments?search`,
        HeadersAuth
      )
      .then((response) => {
        setFaculty(response.data);
      })
      .catch((error) => {
        console.error("Ошибка получение данных ", error);
      });
  }, []);

  const [inputStates, setInputStates] = useState({
    nameRu: "",
    nameKz: "",
    lang: "",
    year: "",
    speciality: false,
    base: false,
    degree: false,
    due: false,
    mainbase: false,
    department: false,
    faculty: false,
    advisor: false,
    qualification: false,
  });

  const updateGroup = async () => {
    const url = `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/v1/updateStudy-groups`;

    const payload = {
      groupId: id,
      start_year: formData.year,
      college_id: 1,
      adviser_id: formData.advisor,
      department_id: formData.faculty,
      speciality_id: formData.speciality,
      title: formData.nameRu,
      language_iso: formData.lang,
      qualification_ids: selectedQualificationIds.join(","),
    };

    try {
      const response = await axios.put(url, payload, HeadersAuth);

      if (response.status === 200) {
        message.success("Группа успешно изменена");
        navigate("/speciality");
      } else {
        message.error("Ошибка при изменении группы");
      }
    } catch (error) {
      message.error("Ошибка при изменении группы");
    }
  };

  const redirectGroup = () => {
    navigate("/group/edit");
  };

  const firstLevelTabs = [
    {
      key: "3",
      label: (
        <div onClick={redirectGroup} style={{ cursor: "pointer" }}>
          Группы
        </div>
      ),
      children: (
        <Content
          style={{
            width: "100%",
            height: "720px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Название на русском
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameRu ? "success" : ""}
                >
                  <Input
                    value={formData.nameRu}
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameRu ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameRu")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Название на казахском
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nameKz ? "success" : ""}
                >
                  <Input
                    value={formData.nameKz}
                    style={{
                      width: "90%",
                      borderColor: inputStates.nameKz ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("nameKz")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Язык
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.lang ? "success" : ""}
                >
                  <Input
                    value={formData.lang}
                    style={{
                      width: "90%",
                      borderColor: inputStates.lang ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("lang")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Год зачисления
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.year ? "success" : ""}
                >
                  <Input
                    value={formData.year}
                    style={{
                      width: "90%",
                      borderColor: inputStates.year ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("year")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Специальность
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.speciality ? "success" : ""}
                >
                  <Select
                    value={formData.speciality}
                    style={{
                      width: "90%",
                      ...(inputStates.speciality
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    onChange={handleInputChange("speciality")}
                  >
                    {specialities.map((spec) => (
                      <Select.Option key={spec.id} value={spec.id}>
                        {spec.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                На базе
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.base ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.base ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("base")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Курс
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.degree ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.degree ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("degree")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Срок обучения <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.due ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.due ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("due")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Основа обучения
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.mainbase ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.mainbase ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("mainbase")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Отделение
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.department ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.department
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("department")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Кафедра
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.faculty ? "success" : ""}
                >
                  <Select
                    style={{
                      width: "90%",
                      ...(inputStates.faculty
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    onChange={handleInputChange("faculty")}
                    value={formData.faculty}
                  >
                    {faculty.map((faculties) => (
                      <Select.Option key={faculties.id} value={faculties.id}>
                        {faculties.caption}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Куратор
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.advisor ? "success" : ""}
                >
                  <Input
                    value={formData.advisor}
                    style={{
                      width: "90%",
                      borderColor: inputStates.advisor ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("advisor")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Квалификации
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.qualification ? "success" : ""}
                >
                  <Select
                    mode="multiple"
                    style={{
                      width: "90%",
                      ...(inputStates.qualification
                        ? { border: "1px solid #8B00FF", borderRadius: "7px" }
                        : {}),
                    }}
                    onChange={handleInputChange("qualification")}
                  >
                    {qualifications.map((qualification) => (
                      <Select.Option
                        key={qualification.id}
                        value={qualification.id}
                      >
                        {qualification.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Row>
            <Divider style={{ marginTop: "80px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/speciality")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton" onClick={updateGroup}>
                Изменить
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Редактирование группы" />
      <Row justify="center">
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="3" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>
      <p />
    </>
  );
};
