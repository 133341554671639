import { Outlet } from "react-router-dom";
import React, { useState } from "react";
import { Spin } from "antd";

export const RootLayout = (): React.ReactElement => {
  const [isLoad, setIsLoad] = useState(false);

  // document.addEventListener('readystatechange', () => {
  // console.log("document.readyState addEventListener", document.readyState)
  //     setIsLoad(false)
  //   }
  // )

  return (
    <Spin spinning={isLoad} wrapperClassName="global-spin">
      <Outlet />
    </Spin>
  );
};
