import styled from "styled-components";
import {DefaultTokenTheme} from "../../../assets/themes/defaultTheme";
import {setThemeColor} from "../../../utils/setThemeColor";

export const MyBreadcrumbStyles = styled.div`
  background: ${({theme}) => setThemeColor(theme.current, '#fff', DefaultTokenTheme.headerDark)};
  height: 64px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;
`
