import {createAction} from "@reduxjs/toolkit";

import {
  FETCH,
  DONE,
  FAIL,
  GET, PRINT, MODULE, EDIT, RESET, DATA, SET, STUDY, MEDICAL, GROUPS, REMARK, DELETE, TOTAL_UVR, DISCIPLINES, JOURNAL
} from "../../constants";

export const printStoreReset = createAction<undefined>(PRINT + DATA + RESET);


export const getPrintModule = createAction<undefined>(GET + PRINT + MODULE + FETCH);
export const getPrintModuleDone = createAction<any>(GET + PRINT + MODULE + FETCH + DONE);
export const getPrintModuleFail = createAction<undefined>(GET + PRINT + MODULE + FETCH + FAIL);

export const printSetEdit = createAction<any>(PRINT + MODULE + SET + EDIT + FETCH);

export const printModuleEdit = createAction<any>(PRINT + MODULE + EDIT + FETCH);
export const printModuleEditDone = createAction<any>(PRINT + MODULE + EDIT + FETCH + DONE);
export const printModuleEditFail = createAction<undefined>(PRINT + MODULE + EDIT + FETCH + FAIL);

export const getPrintStudy = createAction<undefined>(GET + PRINT + STUDY + FETCH);
export const getPrintStudyDone = createAction<any>(GET + PRINT + STUDY + FETCH + DONE);
export const getPrintStudyFail = createAction<undefined>(GET + PRINT + STUDY + FETCH + FAIL);

export const editPrintStudy = createAction<any>(EDIT + PRINT + STUDY + FETCH);
export const editPrintStudyDone = createAction<any>(EDIT + PRINT + STUDY + FETCH + DONE);
export const editPrintStudyFail = createAction<undefined>(EDIT + PRINT + STUDY + FETCH + FAIL);

export const getPrintMedical = createAction<undefined>(GET + PRINT + MEDICAL + FETCH);
export const getPrintMedicalDone = createAction<any>(GET + PRINT + MEDICAL + FETCH + DONE);
export const getPrintMedicalFail = createAction<undefined>(GET + PRINT + MEDICAL + FETCH + FAIL);

export const getPrintMedicalGroup = createAction<undefined>(GET + PRINT + MEDICAL + GROUPS + FETCH);
export const getPrintMedicalGroupDone = createAction<any>(GET + PRINT + MEDICAL + GROUPS + FETCH + DONE);
export const getPrintMedicalGroupFail = createAction<undefined>(GET + PRINT + MEDICAL + GROUPS + FETCH + FAIL);

export const printMedicalEdit = createAction<any>(PRINT + MEDICAL + EDIT + FETCH);
export const printMedicalEditDone = createAction<any>(PRINT + MEDICAL + EDIT + FETCH + DONE);
export const printMedicalEditFail = createAction<undefined>(PRINT + MEDICAL + EDIT + FETCH + FAIL);

export const getPrintRemark = createAction<undefined>(GET + PRINT + REMARK + FETCH);
export const getPrintRemarkDone = createAction<any>(GET + PRINT + REMARK + FETCH + DONE);
export const getPrintRemarkFail = createAction<undefined>(GET + PRINT + REMARK + FETCH + FAIL);

export const setPrintRemark = createAction<any>(SET + PRINT + REMARK + FETCH);
export const setPrintRemarkDone = createAction<any>(SET + PRINT + REMARK + FETCH + DONE);
export const setPrintRemarkFail = createAction<undefined>(SET + PRINT + REMARK + FETCH + FAIL);

export const editPrintRemark = createAction<undefined>(EDIT + PRINT + REMARK + FETCH);
export const editPrintRemarkDone = createAction<any>(EDIT + PRINT + REMARK + FETCH + DONE);
export const editPrintRemarkFail = createAction<undefined>(EDIT + PRINT + REMARK + FETCH + FAIL);

export const deletePrintRemark = createAction<undefined>(DELETE + PRINT + REMARK + FETCH);
export const deletePrintRemarkDone = createAction<any>(DELETE + PRINT + REMARK + FETCH + DONE);
export const deletePrintRemarkFail = createAction<undefined>(DELETE + PRINT + REMARK + FETCH + FAIL);

export const getPrintTotalUvr = createAction<undefined>(GET + PRINT + TOTAL_UVR + FETCH);
export const getPrintTotalUvrDone = createAction<any>(GET + PRINT + TOTAL_UVR + FETCH + DONE);
export const getPrintTotalUvrFail = createAction<undefined>(GET + PRINT + TOTAL_UVR + FETCH + FAIL);

export const getDisciplinesPrintTotalUvr = createAction<undefined>(GET + DISCIPLINES + PRINT + TOTAL_UVR + FETCH);
export const getDisciplinesPrintTotalUvrDone = createAction<any>(GET + DISCIPLINES + PRINT + TOTAL_UVR + FETCH + DONE);
export const getDisciplinesPrintTotalUvrFail = createAction<undefined>(GET + DISCIPLINES + PRINT + TOTAL_UVR + FETCH + FAIL);

export const printJournal = createAction<any>( PRINT + JOURNAL + FETCH);
export const printJournalDone = createAction<any>( PRINT + JOURNAL + FETCH + DONE);
export const printJournalFail = createAction<undefined>( PRINT + JOURNAL + FETCH + FAIL);
