import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Row,
  Input,
  Form,
  message,
  DatePicker,
  Select,
} from "antd";
import { MyBreadcrumb, FirstLevelTabs } from "../../../components";
import "../../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { Cookies } from "react-cookie";
import {
  CreateEmployeeRequest,
  EmployeeVM,
} from "../../InterfacesApi/InterfacesApi";
import { HeadersAuth } from "../../../constants/Auth";

export const EmployeeCreate = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [gender, setGender] = useState("");

  const [formData, setFormData] = useState<CreateEmployeeRequest>({
    firstname: "",
    middlename: "",
    secondname: "",
    lang: "",
    email: "",
    date_birth: "",
    gender: "",
    iin: "",
    place_of_birth: "",
    citizenship: "",
    nationality: "",
    college_id: 1,
    role_ids: [],
  });

  const [inputStates, setInputStates] = useState({
    firstname: false,
    middlename: false,
    secondname: false,
    lang: false,
    email: false,
    date_birth: false,
    gender: false,
    iin: false,
    place_of_birth: false,
    citizenship: false,
    nationality: false,
    role_ids: false,
  });
  const handleInputChange = (inputName) => (e) => {
    let inputValue = e.target ? e.target.value : e;

    if (inputName === "date_birth" && inputValue) {
      const date = new Date(inputValue);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      inputValue = `${day < 10 ? "0" + day : day}.${
        month < 10 ? "0" + month : month
      }.${year}`;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: inputValue,
    }));

    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [inputName]: inputValue !== "",
    }));
  };

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
  });

  const handleSubmit = async () => {
    setRequestStatus({ ...requestStatus, loading: true });

    try {
      await axios.post<EmployeeVM>(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/CreateEmployee`,
        formData,
        HeadersAuth
      );

      setRequestStatus({ loading: false, success: true });
      message.success("Сотрудник успешно добавлен!");

      navigate("/employee");
    } catch (error) {
      setRequestStatus({
        loading: false,
        success: false,
      });
      message.error("Ошибка добавления сотрудника");
    }
  };

  const firstLevelTabs = [
    {
      key: "1",
      label: "Личные данные",
      children: (
        <Content
          style={{
            width: "100%",
            height: "530px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Имя
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.firstname ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.firstname
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={(e) =>
                      handleInputChange("firstname")(e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Фамилия
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.middlename ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.middlename
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={(e) =>
                      handleInputChange("middlename")(e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Отчество (при наличии)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.secondname ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.secondname
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={(e) =>
                      handleInputChange("secondname")(e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                ИИН
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.iin ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.iin ? "#8B00FF" : undefined,
                    }}
                    onChange={(e) => handleInputChange("iin")(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Email
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.email ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.email ? "#8B00FF" : undefined,
                    }}
                    onChange={(e) => handleInputChange("email")(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Дата рождения
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.date_birth ? "success" : ""}
                >
                  <DatePicker
                    format="DD.MM.YYYY"
                    style={{
                      width: "90%",
                      height: "45px",
                      borderColor: inputStates.date_birth
                        ? "#8B00FF"
                        : undefined,
                    }}
                    placeholder="Выберите дату"
                    onChange={(dateString) =>
                      handleInputChange("date_birth")(dateString)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Место рождения
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.place_of_birth ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.place_of_birth
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={(e) =>
                      handleInputChange("place_of_birth")(e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Гражданство
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.citizenship ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.citizenship
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={(e) =>
                      handleInputChange("citizenship")(e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Пол
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.gender ? "success" : ""}
                >
                  <Select
                    style={{
                      width: "90%",
                      borderColor: inputStates.gender ? "#8B00FF" : undefined,
                    }}
                    onChange={(value) => {
                      setGender(value);
                      handleInputChange("gender")(value);
                    }}
                    value={gender}
                  >
                    <Select.Option value="M">М</Select.Option>
                    <Select.Option value="Ж">Ж</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Национальность
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.nationality ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.nationality
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={(e) =>
                      handleInputChange("nationality")(e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Язык обучения
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.lang ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.lang ? "#8B00FF" : undefined,
                    }}
                    onChange={(e) => handleInputChange("lang")(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Роль
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.role_ids ? "success" : ""}
                >
                  <Input
                    style={{
                      width: "90%",
                      borderColor: inputStates.role_ids ? "#8B00FF" : undefined,
                    }}
                    onChange={(e) =>
                      handleInputChange("role_ids")(e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/employee")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Отмена
              </Button>
              <Button className="CreateButton" onClick={handleSubmit}>
                Добавить
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Добавление сотрудника" />
      <Row justify="center">
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="1" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>

      <p />
    </>
  );
};
