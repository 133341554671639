import {MyBreadcrumb, MyButton, MyModalForm, MyText, SecondLevelTabs} from "../../../components";
import {useTranslation} from "react-i18next";
import {
  Checkbox,
  Col, Form,
  List,
  Row,
  Spin,
  Switch,
  Tabs,
} from "antd";
import {createSearchParams, generatePath, Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  addLessonAction, copyPlanAction, editLessonAction,
  getLessonAction, getOneCtpAction
} from "../../../store/pages/plans/actions";
import {
  FORM_SECTION_TYPES,
  FORM_TYPES,
  JOURNAL_QUERY_PARAMS,
  PLANS_API,
  PLANS_FORM_TYPES,
  ROUTE_PATH
} from "../../../constants";
import {StyledInfoContainer} from "./index.styles";
import dayjs from "dayjs";
import {selectStore} from "../../../store/selector";
import {RootState} from "../../../store";
import {getFormAction, setFormAction} from "../../../store/common/actions";
import {DefaultTokenTheme} from "../../../assets/themes/defaultTheme";
import {setThemeColor} from "../../../utils/setThemeColor";
import {useTheme} from "../../../hooks";

interface IInitialState {
  visible: boolean,
  item?: any
}

export const PlansView = () => {
  const {t} = useTranslation("common")
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {theme} = useTheme()

  const {planId} = useParams()

  let typeFromSearchParam = searchParams.get("type");
  let isSharedFromSearchParam = searchParams.get("is_shared");

  const [formAddPlan] = Form.useForm()
  const [formAddLesson] = Form.useForm()
  const [formCopyLesson] = Form.useForm()


  const FORM_NAME = {
    PLANS_PLAN_ADD: 'PLANS_PLAN_ADD',
    PLANS_LESSON_ADD: 'PLANS_LESSON_ADD',
    PLANS_LESSON_COPY: 'PLANS_LESSON_COPY',
  }

  const initialState: IInitialState = {
    visible: false,
    item: {}
  }

  const modalInitialState = {
    [FORM_NAME.PLANS_PLAN_ADD]: initialState,
    [FORM_NAME.PLANS_LESSON_ADD]: initialState,
    [FORM_NAME.PLANS_LESSON_COPY]: initialState,
  }

  const {
    common: {period: periodState, periodByGroup: periodByGroupState, form: formState},
    plans: {lessons: lessonState, oneCtp: oneCtpState}
  }: RootState = useSelector(selectStore)


  const [formModalData, setFormModalData] = useState(modalInitialState)

  const [currentPeriod, setCurrentPeriod] = useState<any>({})
  const [checkboxList, setCheckboxList] = useState<any[]>([])


  useEffect(() => {
    if (periodByGroupState.success) {
      if (periodByGroupState.data?.length > 0) {
        getLessonsList(periodByGroupState.data?.[0])
      } else {
        if (periodState.data?.length > 0) {
          getLessonsList(periodState.data?.[0])
        }
      }
    }
  }, [periodByGroupState.success])

  useEffect(() => {
    if (planId) {
      dispatch(getOneCtpAction(planId))
    }
  }, [planId]);

  const getLessonsList = useCallback((period: any) => {
    dispatch(getLessonAction({
      ctp_id: planId,
      start_date: period?.start_date,
      end_date: period?.end_date
    }))
    setCurrentPeriod(period)
  }, [])

  const handleClickLesson = useCallback((id: any) => {
    // @ts-ignore
    const path = {
      // @ts-ignore
      pathname: generatePath(ROUTE_PATH.LESSON_VIEW, {lessonId: id, planId: planId}),
      search: createSearchParams({
        [JOURNAL_QUERY_PARAMS.START_DATE]: currentPeriod[JOURNAL_QUERY_PARAMS.START_DATE],
        [JOURNAL_QUERY_PARAMS.END_DATE]: currentPeriod[JOURNAL_QUERY_PARAMS.END_DATE],
        ...(isSharedFromSearchParam ? {is_shared: isSharedFromSearchParam} : {})
      }).toString()
    }

    navigate(path);
  }, [currentPeriod])

  const handleImportFromExcel = useCallback(() => {
    dispatch(getFormAction({
      api: PLANS_API[`${FORM_TYPES.IMPORT}_${PLANS_FORM_TYPES.LESSON}`],
      section_type: FORM_SECTION_TYPES.PLANS,
      section_inner_type: PLANS_FORM_TYPES.LESSON,
      form_type: FORM_TYPES.ADD
    }))

    setFormModalData(prevState => ({
      ...prevState,
      [FORM_NAME.PLANS_LESSON_ADD]: {...prevState[FORM_NAME.PLANS_LESSON_ADD], visible: true}
    }))

  }, [])

  const handleCopyLesson = useCallback(() => {
    dispatch(getFormAction({
      api: PLANS_API[`${FORM_TYPES.COPY}_${PLANS_FORM_TYPES.LESSON}`],
      section_type: FORM_SECTION_TYPES.PLANS,
      section_inner_type: PLANS_FORM_TYPES.LESSON,
      form_type: FORM_TYPES.COPY,

      extra: {
        field_name: 'source_url',
        extra_value: planId
      }
    }))

    setFormModalData(prevState => ({
      ...prevState,
      [FORM_NAME.PLANS_LESSON_COPY]: {...prevState[FORM_NAME.PLANS_LESSON_COPY], visible: true}
    }))
  }, [])

  const handleAddLesson = useCallback(() => {
    dispatch(getFormAction({
      api: PLANS_API[`${FORM_TYPES.ADD}_${PLANS_FORM_TYPES.PLAN}`],
      section_type: FORM_SECTION_TYPES.PLANS,
      section_inner_type: PLANS_FORM_TYPES.PLAN,
      form_type: FORM_TYPES.ADD
    }))

    setFormModalData(prevState => ({
      ...prevState,
      [FORM_NAME.PLANS_PLAN_ADD]: {...prevState[FORM_NAME.PLANS_PLAN_ADD], visible: true}
    }))
  }, [])

  const handleFormModalClose = useCallback(() => {
    setFormModalData(modalInitialState)
  }, [])

  const handleModalPlanFormSave = useCallback((values: any) => {
    const main: any = formState[FORM_SECTION_TYPES.PLANS][PLANS_FORM_TYPES.LESSON][FORM_TYPES.ADD].data[0]

    let body = {
      ...values,
      date_at: dayjs(values.date_at).format("YYYY-MM-DD"),
      ctp_id: planId,
      file: values.file[0]
    }

    dispatch(setFormAction({
      method: main?.method,
      path: main?.action,
      fields: body,
      action: addLessonAction
    }))

    setFormModalData(modalInitialState)
  }, [formState])

  const handleModalFormSave = useCallback((values: any) => {
    const main: any = formState[FORM_SECTION_TYPES.PLANS][PLANS_FORM_TYPES.PLAN][FORM_TYPES.ADD].data[0]

    let body = {
      ...values,
      date_at: dayjs(values.date_at).format("YYYY-MM-DD"),
      ctp_id: planId
    }

    dispatch(setFormAction({
      method: main?.method,
      path: main?.action,
      fields: body,
      action: addLessonAction
    }))

    setFormModalData(modalInitialState)
  }, [formState])

  const handleModalCopyLessonFormSave = useCallback((values: any) => {
    const main: any = formState[FORM_SECTION_TYPES.PLANS][PLANS_FORM_TYPES.LESSON][FORM_TYPES.COPY].data[0]

    let body = {
      ...values,
      lesson_id: checkboxList,
      ...(values.with_material ? {with_material: true} : {with_material: false}),
      ...(values.with_task ? {with_task: true} : {with_task: false}),
      ...(values.with_test ? {with_test: true} : {with_test: false}),
    }

    dispatch(setFormAction({
      method: main?.method,
      path: main?.action,
      fields: body,
      action: copyPlanAction
    }))

    setFormModalData(modalInitialState)
  }, [formState, checkboxList])

  const handleChangeSwitch = useCallback((values: any) => {
    dispatch(setFormAction({
      method: "PUT",
      path: `edu/ctp/${planId}`,
      fields: {is_shared: values},
      action: editLessonAction
    }))
  }, [])

  const handleChangeCheckbox = useCallback((values: any) => {
    setCheckboxList(values)
  }, [])

  const tabItems = () => {
    let period = periodByGroupState.data?.length > 0 ? periodByGroupState.data : periodState.data

    return period?.map(item => ({
      key: item.id,
      label: item.name,
      children: (
        <div style={{padding: 24}}>
          <Checkbox.Group style={{width: '100%'}} onChange={handleChangeCheckbox}>
            <List rowKey={(record: any) => record.id} style={{width: '100%'}}
                  loading={lessonState.loading} dataSource={lessonState.data || []} renderItem={(item: any) => (
              <List.Item style={{
                justifyContent: 'normal',
                gap: 10
              }}>
                <Checkbox value={item.id}/>
                <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}
                        onClick={() => handleClickLesson(item.id)}
                        style={{cursor: 'pointer', flex: 1}}>{item.title}</MyText>
              </List.Item>
            )}/>
          </Checkbox.Group>
        </div>
      )
    }))
  }

  const handleTabChange = (key: string) => {
    let period = periodByGroupState.data?.length > 0 ? periodByGroupState.data : periodState.data
    let currentPeriod = period?.find(item => item.id === key)
    getLessonsList(currentPeriod)
  }

  return (
    <>
      <MyBreadcrumb title={t('back')} isBack extra={(
        <MyButton disabled={typeFromSearchParam === '0' || isSharedFromSearchParam === '1'}
                  onClick={() => handleAddLesson()} ghost height={40} type="primary" htmlType="submit"
                  title='Создать план занятия'/>
      )}/>

      <Row justify='center'>
        <Col span={20}>
          <StyledInfoContainer>
            {/*//border-color*/}
            <Spin spinning={oneCtpState.loading}>
              <Row style={{
                borderBottom: `1px solid ${setThemeColor(theme, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)}`,
                padding: 24
              }}>
                <Col>
                  <h1 style={{color: setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}}>{t('lessonsPlan')}</h1>
                </Col>
              </Row>
              <Row justify="space-between" style={{padding: 24}}>
                <Col>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <MyText
                        color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                        {t('group')}: {oneCtpState.data?.group}
                      </MyText>
                    </Col>
                    <Col span={24}>
                      <MyText
                        color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                        {t('module')}: {oneCtpState.data?.module}
                      </MyText>
                    </Col>
                    <Col span={24}>
                      <MyText
                        color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                        {t('discipline')}: {oneCtpState.data?.subject}
                      </MyText>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Row justify='end' style={{flexDirection: 'column'}}>
                    <MyButton disabled={typeFromSearchParam === '0' || isSharedFromSearchParam === '1'}
                              onClick={() => handleImportFromExcel()}
                              ghost
                              height={40}
                              type="primary"
                              title={t('exportFromExcel')}/>
                    <Col>
                      <Row align="middle" style={{marginTop: 16}}>
                        <MyText
                          color={setThemeColor(theme, DefaultTokenTheme.textSubtitle, DefaultTokenTheme.subtitleDark)}
                          size={15} style={{marginRight: 6}}>
                          {t('makePlanPublic')}:
                        </MyText>
                        <Switch checked={oneCtpState.data?.is_shared} onChange={handleChangeSwitch}/>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Spin>
          </StyledInfoContainer>


          <StyledInfoContainer>
            <SecondLevelTabs defaultActiveKey="1" items={tabItems()} onChange={handleTabChange}
                             tabBarExtraContent={(
                               <MyButton info ghost type='primary' title={t('copy')} disabled={checkboxList.length <= 0}
                                         onClick={handleCopyLesson} height={40} style={{marginRight: 10}}/>
                             )}/>
          </StyledInfoContainer>
        </Col>
      </Row>


      <MyModalForm
        formName={FORM_NAME.PLANS_PLAN_ADD}
        form={formAddPlan}
        title={formState[FORM_SECTION_TYPES.PLANS][PLANS_FORM_TYPES.PLAN][FORM_TYPES.ADD].data[0]?.title}
        visible={formModalData[FORM_NAME.PLANS_PLAN_ADD].visible}
        state={formState[FORM_SECTION_TYPES.PLANS][PLANS_FORM_TYPES.PLAN][FORM_TYPES.ADD]}
        onCancel={handleFormModalClose}
        onFinish={handleModalFormSave}
        period={currentPeriod}
      />

      <MyModalForm
        formName={FORM_NAME.PLANS_LESSON_ADD}
        form={formAddLesson}
        title={formState[FORM_SECTION_TYPES.PLANS][PLANS_FORM_TYPES.LESSON][FORM_TYPES.ADD].data[0]?.title}
        visible={formModalData[FORM_NAME.PLANS_LESSON_ADD].visible}
        state={formState[FORM_SECTION_TYPES.PLANS][PLANS_FORM_TYPES.LESSON][FORM_TYPES.ADD]}
        onCancel={handleFormModalClose}
        onFinish={handleModalPlanFormSave}
        period={currentPeriod}
      />

      <MyModalForm
        formName={FORM_NAME.PLANS_LESSON_COPY}
        form={formCopyLesson}
        title={formState[FORM_SECTION_TYPES.PLANS][PLANS_FORM_TYPES.LESSON][FORM_TYPES.COPY].data[0]?.title}
        visible={formModalData[FORM_NAME.PLANS_LESSON_COPY].visible}
        state={formState[FORM_SECTION_TYPES.PLANS][PLANS_FORM_TYPES.LESSON][FORM_TYPES.COPY]}
        onCancel={handleFormModalClose}
        onFinish={handleModalCopyLessonFormSave}
        period={currentPeriod}
      />
    </>
  );
};

