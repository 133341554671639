export const FETCH = "_FETCH";
export const DONE = "_DONE";
export const FAIL = "_FAIL";
export const RESET = "_RESET";
export const ADD = "_ADD";
export const ONE = "_ONE";
export const DELETE = "_DELETE";
export const EDIT = "_EDIT";
export const SET = "_SET";
export const GET = "_GET";
export const LESSON = "LESSON";
export const PLAN = "PLAN";
export const COPY = "COPY";
export const LESSON_ID = "LESSON_ID";
export const PERIOD = "PERIOD";
export const POST = "POST";
export const VIEW = "VIEW";
export const LIST = "LIST";
export const SELECT = "SELECT";
export const DATA = "DATA";
export const AUTH = "AUTH";
export const JOURNAL = "JOURNAL";
export const TABLE = "TABLE";
export const FILTER = "FILTER";
export const CTP = "CTP";
export const MENU = "MENU";
export const TEST = "TEST";
export const RESULT = "RESULT";
export const ANSWER = "ANSWER";
export const FORM = "FORM";
export const EXTRA = "EXTRA";
export const MARK = "MARK";
export const INDIVIDUAL = "INDIVIDUAL";

export const CURRENT = 'CURRENT';
export const ITEM = 'ITEM';
export const INNER = 'INNER';
export const CONTENT = 'CONTENT';
export const OUTER = 'OUTER';
export const COLUMN = "COLUMN";
export const PRINT = "PRINT";
export const STUDY = "STUDY";
export const MEDICAL = "MEDICAL";
export const MODULE = "MODULE";
export const REMARK = "REMARK";
export const TOTAL_UVR = "TOTAL_UVR";

//auth
export const PROFILE = "PROFILE";
export const NOTIFICATION = "NOTIFICATION";
export const VISIT = "VISIT";

// schedule
export const SCHEDULE = "SCHEDULE";


export const HELPER = "HELPER";
export const RUPS = "RUPS";
export const RUP = "RUP";
export const DUPLICATE = "DUPLICATE";
export const REVERT = "REVERT";
export const RECOMMENDED = "RECOMMENDED";
export const QUALIFICATIONS = "QUALIFICATIONS";
export const SPECIALITIES = "SPECIALITIES";
export const DISCIPLINES = "DISCIPLINES";
export const ID = "ID";
export const OPTIONS = "OPTIONS";
export const SEMESTER = "SEMESTER";
export const SETTINGS = "SETTINGS";
export const TYPES = "TYPES";
export const INFO = "INFO";
export const STRUCTURE = "STRUCTURE";
export const RESTORE = "RESTORE";
export const GROUPS = "GROUPS";
export const PERFORMANCE = "PERFORMANCE";
export const DASHBOARD = "DASHBOARD";

