import React from "react";
import { Badge, Card, Col, Divider, Flex, Row } from "antd";
import { MainPage } from "./index.styles";
import {
  MyImage,
  MyPieChart,
  MyText,
  PlanItem,
  ScheduleItem,
} from "../../components";
import { useTheme } from "../../hooks";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { selectStore } from "../../store/selector";
import Calendar from "react-calendar";
import cl from "classnames";
import Building from "../../assets/icons/page/main/building-icon.svg";
import Projects from "../../assets/icons/page/main/projects-icon.svg";
import Meeting from "../../assets/icons/page/main/meeting-icon.svg";
import GroupIc from "../../assets/icons/page/main/group-icon.svg";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from "recharts";
import { setThemeColor } from "../../utils/setThemeColor";
import { DefaultTokenTheme } from "../../assets/themes/defaultTheme";
import collegeIcon from "../../assets/icons/page/main/college-icon.svg";

const CONTENT_TYPE = {
  CALENDAR: "calendar",
  BAR_CHART: "bar-chart",
  BAR_CHART_ARRAY: "bar-chart-array",
  BAR_CHART_OBJECT: "bar-chart-object",
  PIE_CHART: "pie-chart",
  PIE_CHART_ARRAY: "pie-chart-array",
  PIE_CHART_OBJECT: "pie-chart-object",
  TIMETABLE: "timetable",
  TABLE_OCCUPANCY: "table-occupancy",
};

export const Main = () => {
  const { themeConfig, theme } = useTheme();
  const navigate = useNavigate();
  const data = [
    { name: "График", value: 100 },
    { name: "График", value: 200 },
    { name: "График", value: 300 },
    { name: "График", value: 400 },
  ];

  const gender = [
    { name: "Парни", value: 100 },
    { name: "Девушки", value: 200 },
  ];
  const dataPadding = [
    { day: "Пн", value: 65 },
    { day: "Вт", value: 59 },
    { day: "Ср", value: 80 },
    { day: "Чт", value: 81 },
    { day: "Пт", value: 56 },
    { day: "Сб", value: 55 },
    { day: "Вс", value: 40 },
  ];

  const {
    profile: { dashboard: dashboardState },
  }: RootState = useSelector(selectStore);

  const colors = [
    "#1890FF",
    "#13C2C2",
    "#52C41A",
    "#FADB14",
    "#FF4D4F",
    "#FF85C0",
    "#FA8C16",
    "#722ED1",
  ];

  const rightContent = (item: any) => {
    switch (item?.content?.type) {
      case CONTENT_TYPE.CALENDAR:
        return (
          // @ts-ignore
          <Calendar className={cl("calendar")} />
        );

      case CONTENT_TYPE.BAR_CHART:
        let valuesBarChart = item?.content?.values?.map(
          (val: any, valIndex: any) => ({
            name: valIndex,
            value: val,
          })
        );
        return (
          <ResponsiveContainer width={"100%"} height={200}>
            <BarChart width={500} height={200} data={valuesBarChart}>
              <Tooltip />
              <Bar
                dataKey="value"
                fill="#0E5FD9"
                background={{ fill: "#F0F6FF" }}
              />
            </BarChart>
          </ResponsiveContainer>
        );

      case CONTENT_TYPE.PIE_CHART:
        let valuesPieChart = item?.content?.values?.map(
          (val: any, valIndex: any) => ({
            name: valIndex,
            value: val,
          })
        );

        return (
          <Row gutter={[16, 16]} align="middle">
            <Col span={18}></Col>

            <Col span={6}>
              <Row>
                <Col span={24}>
                  <Badge
                    style={{
                      color: setThemeColor(
                        theme,
                        DefaultTokenTheme.textTitle,
                        "#fff"
                      ),
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        );
    }
  };

  const mainContent = (item: any) => {
    switch (item?.type) {
      case CONTENT_TYPE.BAR_CHART_OBJECT:
        let valuesBarChart = item?.content?.values?.map(
          (inner: any, innerIndex: any) => ({
            name: innerIndex,
            value: inner?.[1],
          })
        );

        return (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <ResponsiveContainer height={300}>
                <BarChart
                  barSize={16}
                  barGap={40}
                  layout="vertical"
                  data={valuesBarChart}
                >
                  <CartesianGrid
                    horizontalCoordinatesGenerator={(props: any) => [
                      props?.offset?.top + 1,
                      props?.height - 5,
                    ]}
                  />
                  <XAxis
                    interval="preserveEnd"
                    color="#ffffff"
                    type="number"
                    orientation="top"
                    tickSize={0}
                    tickMargin={12}
                  />
                  <YAxis color="#ffffff" type="category" hide />
                  <Bar layout="vertical" dataKey="value" radius={[0, 8, 8, 0]}>
                    {valuesBarChart?.map((item: any, index: any) => (
                      <Cell key={`cell-${item?.name}`} fill={colors[index]} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Col>

            <Col span={12}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Flex
                    style={{
                      border: `1px solid ${setThemeColor(
                        theme,
                        DefaultTokenTheme.line,
                        DefaultTokenTheme.lineDark
                      )}`,
                      borderRadius: 4,
                      paddingLeft: 16,
                      paddingTop: 6,
                      paddingBottom: 6,
                    }}
                    align="center"
                  >
                    <MyText
                      size={16}
                      weight={700}
                      color={setThemeColor(
                        theme,
                        DefaultTokenTheme.pressed,
                        DefaultTokenTheme.primary
                      )}
                      style={{ marginRight: 4 }}
                    >
                      {item?.content?.value}
                    </MyText>
                    <MyText
                      color={setThemeColor(
                        theme,
                        DefaultTokenTheme.textTitle,
                        "#fff"
                      )}
                    >
                      {item?.content?.label}
                    </MyText>
                  </Flex>
                </Col>

                <Col span={24}>
                  <Row gutter={[16, 16]}>
                    {item?.content?.values?.map(
                      (inner: any, innerIndex: any) => {
                        return (
                          <Col
                            span={24}
                            key={`${innerIndex}-${CONTENT_TYPE.BAR_CHART}`}
                          >
                            <Row justify="space-between">
                              <Col span={16}>
                                <Badge
                                  color={colors[innerIndex]}
                                  text={inner?.[0]}
                                  style={{
                                    color: setThemeColor(
                                      theme,
                                      DefaultTokenTheme.textTitle,
                                      "#fff"
                                    ),
                                  }}
                                />
                              </Col>
                              {inner?.[1] && (
                                <Col>
                                  <MyText
                                    style={{
                                      color: setThemeColor(
                                        theme,
                                        DefaultTokenTheme.textTitle,
                                        "#fff"
                                      ),
                                    }}
                                  >
                                    {inner[1]}
                                  </MyText>
                                </Col>
                              )}
                              {inner?.[2] && (
                                <Col>
                                  <MyText
                                    style={{
                                      color: setThemeColor(
                                        theme,
                                        DefaultTokenTheme.textTitle,
                                        "#fff"
                                      ),
                                    }}
                                  >
                                    {inner[2]}
                                  </MyText>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        );
                      }
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        );

      case CONTENT_TYPE.TIMETABLE:
        return (
          <Row gutter={[16, 16]}>
            {item.items.map((inner: any, innerIndex: any) => (
              <Col span={12} key={`TIMETABLE-${innerIndex}`}>
                <ScheduleItem item={inner} />
              </Col>
            ))}
          </Row>
        );

      case CONTENT_TYPE.TABLE_OCCUPANCY:
        return (
          <Row gutter={[16, 16]}>
            {item.items?.map((inner: any, innerIndex: any) => (
              <Col span={12} key={`TABLE_OCCUPANCY-${innerIndex}`}>
                <PlanItem item={inner} />
              </Col>
            ))}
          </Row>
        );

      case CONTENT_TYPE.PIE_CHART_ARRAY:
        return (
          <Row gutter={[16, 16]} align="middle">
            {item?.items?.map((inner: any, innerIndex: any) => {
              let valuesPieChart = inner?.values?.map(
                (pie: any, peiIndex: any) => ({
                  name: peiIndex,
                  value: pie?.[1],
                })
              );
              return (
                <Col span={12} key={inner.label}>
                  <Row gutter={[16, 16]} align="middle">
                    <Col span={12}></Col>

                    <Col span={12}>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Flex
                            style={{
                              border: `1px solid ${setThemeColor(
                                theme,
                                DefaultTokenTheme.line,
                                DefaultTokenTheme.lineDark
                              )}`,
                              borderRadius: 4,
                              paddingLeft: 16,
                              paddingTop: 6,
                              paddingBottom: 6,
                            }}
                            align="center"
                          >
                            <MyText
                              size={16}
                              weight={700}
                              color={setThemeColor(
                                theme,
                                DefaultTokenTheme.pressed,
                                DefaultTokenTheme.primary
                              )}
                              style={{ marginRight: 4 }}
                            >
                              {inner.value}
                            </MyText>
                            <MyText
                              color={setThemeColor(
                                theme,
                                DefaultTokenTheme.textTitle,
                                "#fff"
                              )}
                            >
                              {inner.label}
                            </MyText>
                          </Flex>
                        </Col>

                        <Col span={24}>
                          <Row gutter={[16, 16]}>
                            {inner?.values?.map((pie: any, pieIndex: any) => (
                              <Col span={24} key={`pieIndex-${pieIndex}`}>
                                <Row justify="space-between">
                                  <Col span={16}>
                                    <Badge
                                      color={colors[pieIndex]}
                                      text={pie?.[0]}
                                      style={{
                                        color: setThemeColor(
                                          theme,
                                          DefaultTokenTheme.textTitle,
                                          "#fff"
                                        ),
                                      }}
                                    />
                                  </Col>
                                  {pie?.[1] && (
                                    <Col>
                                      <MyText
                                        style={{
                                          color: setThemeColor(
                                            theme,
                                            DefaultTokenTheme.textTitle,
                                            "#fff"
                                          ),
                                        }}
                                      >
                                        {pie?.[1]}
                                      </MyText>
                                    </Col>
                                  )}
                                </Row>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        );
    }
  };

  const bottomContent = (item: any) => {
    switch (item?.type) {
      case CONTENT_TYPE.PIE_CHART_OBJECT:
        return (
          <Row>
            <Col span={12}>
              <MyText
                size={16}
                weight={400}
                color={themeConfig.token.textSubtitle}
              >
                {item?.content?.result}
              </MyText>
            </Col>

            <Col span={12}>
              <div>
                <MyPieChart progress={item?.content?.percent} />
              </div>
            </Col>
          </Row>
        );
    }
  };

  return (
    <MainPage justify="center">
      <Col span={20} style={{ paddingTop: 16, paddingBottom: 16 }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card
              style={{ borderRadius: 8, overflow: "hidden" }}
              bodyStyle={{
                backgroundColor: setThemeColor(
                  theme,
                  "#fff",
                  DefaultTokenTheme.plateDark
                ),
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Row gutter={[16, 16]} align="middle" justify="space-between">
                    <Col span={14}>
                      <Row align="middle" gutter={[16, 16]}>
                        <Col>
                          <Flex
                            style={{
                              width: 72,
                              height: 72,
                              borderRadius: "50%",
                              overflow: "hidden",
                              backgroundColor: setThemeColor(
                                theme,
                                DefaultTokenTheme.backgroundLight,
                                DefaultTokenTheme.backgroundDark
                              ),
                              padding: 10,
                              border: `1px solid ${setThemeColor(
                                theme,
                                DefaultTokenTheme.line,
                                DefaultTokenTheme.lineDark
                              )}`,
                            }}
                            justify="center"
                            align="center"
                          >
                            <MyImage
                              src={
                                dashboardState.data?.top?.header?.img
                                  ? dashboardState.data?.top?.header?.img
                                  : collegeIcon
                              }
                            />
                          </Flex>
                        </Col>
                        <span style={{ fontSize: "20px" }}>
                          <span style={{ marginLeft: 7 }}>
                            {" "}
                            Павлодарский Медицинский Высший Колледж - Павлодар
                          </span>
                          <Col span={24}>
                            <MyText
                              size={14}
                              weight={400}
                              color={setThemeColor(
                                theme,
                                DefaultTokenTheme.textSubtitle,
                                DefaultTokenTheme.subtitleDark
                              )}
                            >
                              Наименование учебного заведения
                            </MyText>
                          </Col>
                        </span>

                        <Col span={20}>
                          <Row>
                            <Col span={24}>
                              <MyText
                                size={20}
                                weight={400}
                                color={setThemeColor(
                                  theme,
                                  DefaultTokenTheme.textTitle,
                                  "#fff"
                                )}
                              >
                                {dashboardState.data?.top?.header?.title}
                              </MyText>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={3}>
                      <Row
                        style={{
                          borderLeft: `1px solid ${setThemeColor(
                            theme,
                            DefaultTokenTheme.line,
                            DefaultTokenTheme.lineDark
                          )}`,
                          paddingLeft: 20,
                        }}
                      >
                        <Col span={24}>
                          <MyText
                            size={24}
                            weight={500}
                            color={setThemeColor(
                              theme,
                              DefaultTokenTheme.textTitle,
                              "#fff"
                            )}
                          ></MyText>
                        </Col>
                        <Col span={24}>
                          <MyText
                            size={14}
                            weight={400}
                            color={setThemeColor(
                              theme,
                              DefaultTokenTheme.textSubtitle,
                              DefaultTokenTheme.subtitleDark
                            )}
                          ></MyText>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row
                        style={{
                          borderLeft: `1px solid ${setThemeColor(
                            theme,
                            DefaultTokenTheme.line,
                            DefaultTokenTheme.lineDark
                          )}`,
                          paddingLeft: 20,
                        }}
                      >
                        <Col
                          style={{
                            justifyContent: "end",
                            alignItems: "end",
                            textAlign: "end",
                          }}
                          span={24}
                        >
                          <MyText
                            size={24}
                            weight={500}
                            color={setThemeColor(
                              theme,
                              DefaultTokenTheme.textTitle,
                              "#fff"
                            )}
                          >
                            1970
                          </MyText>
                        </Col>
                        <Col
                          style={{
                            justifyContent: "end",
                            alignItems: "end",
                            textAlign: "end",
                            marginTop: 10,
                          }}
                          span={24}
                        >
                          <MyText
                            size={14}
                            weight={400}
                            color={setThemeColor(
                              theme,
                              DefaultTokenTheme.textSubtitle,
                              DefaultTokenTheme.subtitleDark
                            )}
                          >
                            Год основания
                          </MyText>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <Card
                        bordered={true}
                        style={{
                          borderColor: setThemeColor(
                            theme,
                            DefaultTokenTheme.line,
                            DefaultTokenTheme.lineDark
                          ),
                        }}
                      >
                        <Row justify="space-between" align="middle">
                          <Col span={18}>
                            <Row>
                              <Col span={24}>
                                <MyText
                                  size={30}
                                  weight={700}
                                  color={setThemeColor(
                                    theme,
                                    DefaultTokenTheme.textTitle,
                                    "#fff"
                                  )}
                                >
                                  12
                                </MyText>
                              </Col>
                              <Col span={24}>
                                <MyText
                                  size={14}
                                  weight={400}
                                  color={setThemeColor(
                                    theme,
                                    DefaultTokenTheme.textSubtitle,
                                    DefaultTokenTheme.subtitleDark
                                  )}
                                >
                                  Аудиторий
                                </MyText>
                              </Col>
                            </Row>
                          </Col>

                          <Col>
                            <MyImage src={Building} />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col span={6}>
                      <Card
                        bordered={true}
                        style={{
                          borderColor: setThemeColor(
                            theme,
                            DefaultTokenTheme.line,
                            DefaultTokenTheme.lineDark
                          ),
                        }}
                      >
                        <Row justify="space-between" align="middle">
                          <Col span={18}>
                            <Row>
                              <Col span={24}>
                                <MyText
                                  size={30}
                                  weight={700}
                                  color={setThemeColor(
                                    theme,
                                    DefaultTokenTheme.textTitle,
                                    "#fff"
                                  )}
                                >
                                  5
                                </MyText>
                              </Col>
                              <Col span={24}>
                                <MyText
                                  size={14}
                                  weight={400}
                                  color={setThemeColor(
                                    theme,
                                    DefaultTokenTheme.textSubtitle,
                                    DefaultTokenTheme.subtitleDark
                                  )}
                                >
                                  Специальности
                                </MyText>
                              </Col>
                            </Row>
                          </Col>

                          <Col>
                            <MyImage src={Projects} />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col span={6}>
                      <Card
                        bordered={true}
                        style={{
                          borderColor: setThemeColor(
                            theme,
                            DefaultTokenTheme.line,
                            DefaultTokenTheme.lineDark
                          ),
                        }}
                      >
                        <Row justify="space-between" align="middle">
                          <Col span={18}>
                            <Row>
                              <Col span={24}>
                                <MyText
                                  size={30}
                                  weight={700}
                                  color={setThemeColor(
                                    theme,
                                    DefaultTokenTheme.textTitle,
                                    "#fff"
                                  )}
                                >
                                  50
                                </MyText>
                              </Col>
                              <Col span={24}>
                                <MyText
                                  size={14}
                                  weight={400}
                                  color={setThemeColor(
                                    theme,
                                    DefaultTokenTheme.textSubtitle,
                                    DefaultTokenTheme.subtitleDark
                                  )}
                                >
                                  Сотрудников
                                </MyText>
                              </Col>
                            </Row>
                          </Col>

                          <Col>
                            <MyImage src={GroupIc} />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col span={6}>
                      <Card
                        bordered={true}
                        style={{
                          borderColor: setThemeColor(
                            theme,
                            DefaultTokenTheme.line,
                            DefaultTokenTheme.lineDark
                          ),
                        }}
                      >
                        <Row justify="space-between" align="middle">
                          <Col span={18}>
                            <Row>
                              <Col span={24}>
                                <MyText
                                  size={30}
                                  weight={700}
                                  color={setThemeColor(
                                    theme,
                                    DefaultTokenTheme.textTitle,
                                    "#fff"
                                  )}
                                >
                                  350
                                </MyText>
                              </Col>
                              <Col span={24}>
                                <MyText
                                  size={14}
                                  weight={400}
                                  color={setThemeColor(
                                    theme,
                                    DefaultTokenTheme.textSubtitle,
                                    DefaultTokenTheme.subtitleDark
                                  )}
                                >
                                  Студенты
                                </MyText>
                              </Col>
                            </Row>
                          </Col>

                          <Col>
                            <MyImage src={Meeting} />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={17}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card
                  headStyle={{
                    color: setThemeColor(
                      theme,
                      DefaultTokenTheme.textTitle,
                      "#fff"
                    ),
                    backgroundColor: setThemeColor(
                      theme,
                      "#fff",
                      DefaultTokenTheme.plateDark
                    ),
                    borderBottomColor: setThemeColor(
                      theme,
                      DefaultTokenTheme.line,
                      DefaultTokenTheme.lineDark
                    ),
                    borderBottomWidth: 2,
                  }}
                  style={{
                    borderColor: setThemeColor(
                      theme,
                      DefaultTokenTheme.line,
                      DefaultTokenTheme.lineDark
                    ),
                  }}
                  bodyStyle={{
                    backgroundColor: setThemeColor(
                      theme,
                      "#fff",
                      DefaultTokenTheme.plateDark
                    ),
                    display: "flex",

                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <h2
                      style={{
                        fontWeight: "500",
                        fontSize: 18,
                        marginTop: -10,
                      }}
                    >
                      Распределение студентов по курсам
                      <Divider
                        style={{
                          position: "absolute",
                          marginLeft: -25,
                          marginTop: 5,
                        }}
                      />
                    </h2>

                    <div
                      style={{
                        width: "60%",
                        marginLeft: "100%",
                        textAlign: "start",
                        height: 40,

                        marginBottom: 20,
                        marginTop: 20,
                        justifyContent: "end",
                        alignItems: "end",
                        border: "1px solid #E5E7E8",
                        borderRadius: 6,
                        padding: "8px 15px 8px 10px",
                      }}
                    >
                      <b>350</b> студентов
                    </div>
                    <div>
                      <BarChart
                        width={500}
                        height={320}
                        data={data}
                        layout="vertical"
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" domain={[0, "dataMax"]} />
                        <YAxis dataKey="name" type="category" width={0} />
                        <Tooltip />

                        <Bar
                          radius={[0, 10, 10, 0]}
                          barSize={20}
                          dataKey="value"
                        >
                          {dataPadding.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % colors.length]}
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </div>
                  </div>

                  <div style={{ width: "100%", marginTop: 20 }}>
                    <h3>Статистика:</h3>
                    {data.map((item, index) => (
                      <>
                        <p key={index}>
                          <span
                            style={{
                              width: 10,
                              height: 10,
                              backgroundColor: colors[index % colors.length],
                              display: "inline-block",
                              marginRight: 7,
                            }}
                          ></span>
                          {item.name}
                          <span style={{ marginLeft: "72%" }}>
                            {item.value}
                          </span>
                          <Divider style={{ width: "100%" }} />
                        </p>
                      </>
                    ))}
                  </div>
                </Card>
              </Col>

              <Col span={24}>
                <Card
                  headStyle={{
                    color: setThemeColor(
                      theme,
                      DefaultTokenTheme.textTitle,
                      "#fff"
                    ),
                    backgroundColor: setThemeColor(
                      theme,
                      "#fff",
                      DefaultTokenTheme.plateDark
                    ),
                    borderBottomColor: setThemeColor(
                      theme,
                      DefaultTokenTheme.line,
                      DefaultTokenTheme.lineDark
                    ),
                    borderBottomWidth: 2,
                  }}
                  style={{
                    borderColor: setThemeColor(
                      theme,
                      DefaultTokenTheme.line,
                      DefaultTokenTheme.lineDark
                    ),
                  }}
                  bodyStyle={{
                    backgroundColor: setThemeColor(
                      theme,
                      "#fff",
                      DefaultTokenTheme.plateDark
                    ),
                  }}
                >
                  <h2
                    style={{ fontWeight: "500", fontSize: 18, marginTop: -10 }}
                  >
                    Мониторинг студентов
                  </h2>
                  <Divider style={{ position: "absolute", marginLeft: -25 }} />
                  <Row style={{ marginTop: -80 }}>
                    <Col span={12}>
                      <PieChart width={400} height={400}>
                        <Pie
                          data={data}
                          cx={120}
                          cy={200}
                          innerRadius={60}
                          outerRadius={80}
                          fill="#8884d8"
                          paddingAngle={5}
                          dataKey="value"
                        >
                          {data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % colors.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend
                          layout="vertical"
                          align="right"
                          verticalAlign="middle"
                        />
                      </PieChart>

                      <div
                        style={{
                          width: 120,
                          textAlign: "end",
                          height: 40,
                          position: "absolute",
                          top: 110,
                          left: 300,
                          border: "1px solid #E5E7E8",
                          borderRadius: 6,
                          padding: "8px 15px 8px 10px",
                        }}
                      >
                        <b>350</b> студентов
                      </div>
                    </Col>
                    <Col span={12}>
                      <PieChart width={400} height={400}>
                        <Pie
                          data={gender}
                          cx={150}
                          cy={200}
                          innerRadius={60}
                          outerRadius={80}
                          fill="#8884d8"
                          paddingAngle={5}
                          dataKey="value"
                        >
                          {gender.map((_, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % colors.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />

                        <Legend
                          radius={100}
                          style={{ marginBottom: 10 }}
                          layout="vertical"
                          align="right"
                          verticalAlign="middle"
                        />
                      </PieChart>

                      <div
                        style={{
                          borderRight: "1px solid #E5E7E8",
                          height: "297px",
                          marginTop: -320,
                          marginLeft: 20,
                          position: "absolute",
                        }}
                      ></div>

                      <div
                        style={{
                          width: 120,
                          textAlign: "end",
                          height: 40,
                          position: "absolute",
                          top: 110,
                          left: 300,
                          border: "1px solid #E5E7E8",
                          borderRadius: 6,
                          padding: "8px 15px 8px 10px",
                        }}
                      >
                        <b>300</b> студентов
                      </div>
                    </Col>
                  </Row>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: -70,
                    }}
                  >
                    <div>
                      <Divider style={{ width: "100%" }} />
                      <div
                        style={{ marginLeft: 20, display: "flex", padding: 10 }}
                      >
                        {data.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: 20,
                            }}
                          >
                            <div
                              style={{
                                width: 15,
                                height: 15,
                                backgroundColor: colors[index % colors.length],
                                marginRight: 10,
                                borderRadius: 100,
                              }}
                            ></div>
                            {(
                              (item.value /
                                data.reduce(
                                  (acc, curr) => acc + curr.value,
                                  0
                                )) *
                              100
                            ).toFixed(0)}
                            %
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col style={{ height: 450 }} span={7}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card
                  headStyle={{
                    color: setThemeColor(
                      theme,
                      DefaultTokenTheme.textTitle,
                      "#fff"
                    ),
                    backgroundColor: setThemeColor(
                      theme,
                      "#fff",
                      DefaultTokenTheme.plateDark
                    ),
                    borderBottomColor: setThemeColor(
                      theme,
                      DefaultTokenTheme.line,
                      DefaultTokenTheme.lineDark
                    ),
                    borderBottomWidth: 2,
                    borderTopWidth: 2,
                  }}
                  style={{
                    borderColor: setThemeColor(
                      theme,
                      DefaultTokenTheme.line,
                      DefaultTokenTheme.lineDark
                    ),
                  }}
                  bodyStyle={{
                    backgroundColor: setThemeColor(
                      theme,
                      "#fff",
                      DefaultTokenTheme.plateDark
                    ),
                    height: 400,

                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      marginTop: -10,
                    }}
                  >
                    Календарь
                  </h2>
                  <Divider style={{ position: "absolute", marginTop: 25 }} />
                  <div
                    style={{
                      width: "100%",
                      flex: "1",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <Calendar />
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            style={{
              height: 450,
              top: -300,

              justifyContent: "end",
              marginLeft: "auto",
            }}
            span={7}
          >
            <Row gutter={[16, 16]}>
              <Col
                style={{ position: "absolute", marginTop: -150, width: "100%" }}
                span={24}
              >
                <Card
                  headStyle={{
                    color: setThemeColor(
                      theme,
                      DefaultTokenTheme.textTitle,
                      "#fff"
                    ),
                    backgroundColor: setThemeColor(
                      theme,
                      "#fff",
                      DefaultTokenTheme.plateDark
                    ),
                    borderBottomColor: setThemeColor(
                      theme,
                      DefaultTokenTheme.line,
                      DefaultTokenTheme.lineDark
                    ),
                    borderBottomWidth: 2,
                  }}
                  style={{
                    borderColor: setThemeColor(
                      theme,
                      DefaultTokenTheme.line,
                      DefaultTokenTheme.lineDark
                    ),
                  }}
                  bodyStyle={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: setThemeColor(
                      theme,
                      "#fff",
                      DefaultTokenTheme.plateDark
                    ),
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      marginTop: -10,
                    }}
                  >
                    Активность в системе
                  </h2>
                  <Divider
                    style={{
                      position: "absolute",

                      marginTop: 25,
                    }}
                  />

                  <BarChart
                    style={{ justifyContent: "center" }}
                    width={320}
                    height={350}
                    data={dataPadding}
                    margin={{ right: 5, left: 5, bottom: 10 }}
                  >
                    <XAxis dataKey="day" tick={{ fontSize: 12 }} />
                    <YAxis hide={true} />
                    <Tooltip />
                    <Bar barSize={20} dataKey="value">
                      {dataPadding.map((_, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={colors[index % colors.length]}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </MainPage>
  );
};
