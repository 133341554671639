import { Cookies } from "react-cookie";
import { COOKIES_ENUM } from "./main";
const cookies = new Cookies();

const accessToken = cookies.get(COOKIES_ENUM.ACCESSTOKEN);

export const HeadersAuth = {
    headers: {
      Auth: `${accessToken}`,
    },
  };


  export const headersFetch = {
      Auth: `${accessToken}`,
  }
