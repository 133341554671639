import styled from "styled-components";
import {Tabs} from "antd";
import {DefaultTokenTheme} from "../../../assets/themes/defaultTheme";
import {setThemeColor} from "../../../utils/setThemeColor";

export const ThirdLevelTabsStyles = styled(Tabs)`
  & > .ant-tabs-nav {
    background-color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.tableHeader, DefaultTokenTheme.plateDark)};
    margin-bottom: 0;
    
    &:before{
      border-color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};;
    }

    .ant-tabs-tab {
      padding: 2px 8px;
      margin: 10px 0px 10px 24px;
      border: 1px solid ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};
      border-radius: 4px;
    }
    
    .ant-tabs-tab-active{
      border-color: ${DefaultTokenTheme.primary};
      background-color: ${DefaultTokenTheme.primary};
      
      .ant-tabs-tab-btn{
        color: #fff;
      }
    }

    .ant-tabs-ink-bar{
      display: none !important;
    }
  }

  & > .ant-tabs-content-holder {
    background-color: ${({theme}) => setThemeColor(theme.current, '#fff', DefaultTokenTheme.plateDark)};
  }
`
