import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Row,
  Table,
  Form,
  Typography,
  TabsProps,
  Tabs,
} from "antd";
import { MyBreadcrumb, FirstLevelTabs } from "../../../components";
import "../../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import { Cookies } from "react-cookie";
import {
  dataAbEdu,
  dataAuth,
  dataAwards,
  dataContact,
  dataDorm,
  dataID,
  dataIDBirth,
  dataJob,
  dataLg,
  dataMeal,
  dataPD,
  dataPassport,
  dataSocGroup,
} from "./TableStudent";
import { PlusOutlined } from "@ant-design/icons";
import { columns } from "./TableExperience";
export const StudentView = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const { Title } = Typography;
  const [rowColorIndex, setRowColorIndex] = useState(0);

  const [inputStates, setInputStates] = useState({
    nameRu: false,
    nameKz: false,
    typeDiscipline: false,
    teacher: false,
    faculty: false,
  });
  const handleInputChange = (inputName) => (value) => {
    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [inputName]: !!value,
    }));
  };

  const renderRowClassName = (index) => {
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Социальные группы",
      children: (
        <Content
          style={{
            width: "100%",
            height: "1090px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row
              className="tableEmployee"
              style={{ padding: "10px 20px 10px 20px" }}
              gutter={[20, 20]}
            >
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={dataSocGroup}
                  bordered
                  showHeader={false}
                  pagination={false}
                  rowClassName={renderRowClassName}
                ></Table>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Редактировать</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "2",
      label: "Общежитие",
      children: (
        <Content
          style={{
            width: "100%",
            height: "270px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row
              className="tableEmployee"
              style={{ padding: "10px 20px 10px 20px" }}
              gutter={[20, 20]}
            >
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={dataDorm}
                  bordered
                  showHeader={false}
                  pagination={false}
                  rowClassName={renderRowClassName}
                ></Table>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Редактировать</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "3",
      label: "Питание",
      children: (
        <Content
          style={{
            width: "100%",
            height: "270px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row
              className="tableEmployee"
              style={{ padding: "10px 20px 10px 20px" }}
              gutter={[20, 20]}
            >
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={dataMeal}
                  bordered
                  showHeader={false}
                  pagination={false}
                  rowClassName={renderRowClassName}
                ></Table>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Редактировать</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
  ];

  const firstLevelTabs = [
    {
      key: "1",
      label: "Личные данные",
      children: (
        <Content
          style={{
            width: "100%",
            height: "740px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form>
            <Row
              className="tableEmployee"
              style={{ padding: "10px 20px 10px 20px" }}
              gutter={[20, 20]}
            >
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={dataPD}
                  bordered
                  showHeader={false}
                  pagination={false}
                  rowClassName={renderRowClassName}
                ></Table>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Редактировать</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "2",
      label: "Контакты",
      children: (
        <Content
          style={{
            width: "100%",
            height: "360px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={dataContact}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Редактировать</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "3",
      label: "Документы",
      children: (
        <Content
          style={{
            width: "100%",
            height: "860px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Title level={3}>Свидетельство о рождении</Title>
              <Table
                style={{ marginTop: "20px" }}
                columns={columns}
                dataSource={dataIDBirth}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>

          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Title level={3}>Удостоверение личности</Title>
              <Table
                style={{ marginTop: "20px" }}
                columns={columns}
                dataSource={dataID}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>
          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Title level={3}>Паспорт</Title>
              <Table
                style={{ marginTop: "20px" }}
                columns={columns}
                dataSource={dataPassport}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Редактировать</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "4",
      label: "Авторизационные записи",
      children: (
        <Content
          style={{
            width: "100%",
            height: "230px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={dataAuth}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Редактировать</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "5",
      label: "Родственники",
      children: (
        <Content
          style={{
            width: "100%",
            height: "480px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Title level={3}>Награды</Title>
              <Table
                style={{ marginTop: "20px" }}
                columns={columns}
                dataSource={dataAwards}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>

          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Title level={3}>Языки</Title>
              <Table
                style={{ marginTop: "20px" }}
                columns={columns}
                dataSource={dataLg}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Редактировать</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "6",
      label: "Приказы",
      children: (
        <Content
          style={{
            width: "100%",
            height: "270px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={dataJob}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Редактировать</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "7",
      label: "Данные об обучении",
      children: (
        <Content
          style={{
            width: "100%",
            height: "290px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Row
            className="tableEmployee"
            style={{ padding: "10px 20px 10px 20px" }}
            gutter={[20, 20]}
          >
            <Col span={24}>
              <Table
                style={{ padding: 10 }}
                columns={columns}
                dataSource={dataAbEdu}
                bordered
                showHeader={false}
                pagination={false}
                rowClassName={renderRowClassName}
              ></Table>
            </Col>
          </Row>
          <Row>
            <Divider style={{ marginTop: "40px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/institution_discipline")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Назад
              </Button>
              <Button className="CreateButton">Редактировать</Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
    {
      key: "8",
      label: "Социальные группы",
      children: (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Tabs
              tabBarStyle={{ padding: "0 20px 0 20px" }}
              tabPosition="top"
              size="large"
              defaultActiveKey="1"
              items={items}
            />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Просмотр данных обучающегося" />
      <Row justify="center">
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="1" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>

      <p />
    </>
  );
};
