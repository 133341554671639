import styled from "styled-components";
import {Button} from "antd";

export const MyBackButtonStyles = styled(Button)`
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 4px;
  
  .subtitle{
    color: rgba(0, 0, 0, 0.45);
    margin-left: 6px;
  }

  .number{
    color: rgba(107, 100, 255, 1);
    margin-left: 6px;
    font-weight: 600;
  }
`