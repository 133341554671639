import {createSelector} from "@reduxjs/toolkit";

import {RootState} from "./index";

const select = (state: RootState) => state;

const selectCommon = (select: RootState) => select.common;
const selectSchedule = (select: RootState) => select.schedule;
const selectJournal = (select: RootState) => select.journal;
const selectProfile = (select: RootState) => select.profile;
const selectPlans = (select: RootState) => select.plans;
const selectPrintJournal = (select: RootState) => select.printJournal;
const selectRup = (select: RootState) => select.rup;

export const selectStore = createSelector(
  selectCommon,
  selectSchedule,
  selectJournal,
  selectProfile,
  selectPlans,
  selectPrintJournal,
  selectRup,

  (
    common,
    schedule,
    journal,
    profile,
    plans,
    printJournal,
    rup,

  ) => (

    {
      common,
      schedule,
      journal,
      profile,
      plans,
      printJournal,
      rup,

    }

  ));
