import {FETCH, DONE, FAIL, SCHEDULE, LESSON, VIEW} from "../../constants";

import reducerStatuses from "../../status";
import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {TFetch} from "../../types";

const initialState: TFetch = {
  schedule: {
    ...reducerStatuses,
    data: null as any,
  },
};

export default createReducer(initialState, builder =>
  builder
    .addCase(SCHEDULE + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      schedule: {
        ...state.schedule,
        loading: true,
      },
    }))
    .addCase(SCHEDULE + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      schedule: {
        ...state.schedule,
        success: true,
        loading: false,
        data: payload,
      },
    }))
    .addCase(SCHEDULE + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      schedule: {
        ...state.schedule,
        loading: false,
        failed: true,
      },
    }))
)
