import {all, call, put, select, takeEvery, takeLatest} from "redux-saga/effects";
import {
  addModuleWithoutDisciplineApi,
  addNewQualificationApi,
  addNewRupApi,
  addRupFromRecommendedApi,
  changeQualificationApi, changeRupHoursApi, deleteHourRowApi, deleteQualificationApi,
  deleteRupApi,
  duplicateRupApi, editRowApi,
  editRupApi, editSemestersSettingsApi,
  getAllProfilesApi,
  getAllRupsApi,
  getDeletedRupsApi, getDisciplinesApi,
  getGroupForRupApi, getNewHourRowApi,
  getProfileDirectionsSpecializationsSpecialtyApi,
  getQualificationsApi,
  getRecommendedRupsApi, getRupApi, getRupHoursTypesForTranslateApi,
  getSemestersSettingsDataApi,
  getSpecializationIdApi, getSpecializationsSpecialtyApi, postDisciplineForRupApi, restoreStructureApi, rupHoursApi,
  setGroupForRupApi
} from "./api";
import {
  addModuleWithoutDiscDone, addModuleWithoutDiscFail, addNewRup,
  addNewRupDone,
  addNewRupFail,
  addQualificationDone,
  addQualificationFail, changeRupInfoDone,
  changeRupInfoFail,
  createNewDisciplineRowDone, createNewDisciplineRowFail,
  createNewRowDone, createNewRowFail,
  createNewRupFromRecommendedActionDone,
  createNewRupFromRecommendedActionFail,
  deleteQualificationDone,
  deleteQualificationFail,
  deleteRowDone, deleteRowFail,
  deleteRupsDone,
  deleteRupsFail,
  duplicateRup,
  duplicateRupDone,
  duplicateRupFail,
  editQualificationDone,
  editQualificationFail,
  editRowDone, editRowFail,
  editRups,
  editRupsDone,
  editRupsFail, editSemesterSettingsActionDone,
  editSemesterSettingsActionFail,
  fetchCurrentRupDone,
  fetchCurrentRupFail,
  fetchDisciplinesDone,
  fetchDisciplinesFail,
  fetchQualificationsDone,
  fetchQualificationsFail,
  fetchQualificationsOptionsDone,
  fetchRecommendedRupsDone,
  fetchRecommendedRupsFail,
  fetchRupDone,
  fetchRupFail,
  fetchRups,
  fetchRupsDone,
  fetchRupsFail,
  fetchSemesterSettingsActionDone,
  fetchSemesterSettingsActionFail,
  fetchSpecializationIdFail,
  getRupHelpers,
  getRupHelpersDone,
  getRupHelpersFail, getRupInfoDone, getRupInfoFail, getRupInfoTypesDone,
  getRupInfoTypesFail,
  restoreStructureDone,
  restoreStructureFail,
  revertRups,
  setGroupForRup,
  setGroupForRupDone,
  setGroupForRupFail,
  deleteRups,
  fetchRecommendedRups,
  createNewRupFromRecommendedAction,
  fetchQualifications,
  fetchSpecializationId,
  addQualification,
  editQualification,
  deleteQualification,
  fetchCurrentRup,
  fetchRup,
  restoreStructure,
  fetchDisciplines,
  fetchSemesterSettingsAction,
  createNewDisciplineRow,
  createNewRow,
  addModuleWithoutDisc,
  deleteRow,
  editRow,
  editSemesterSettingsAction,
  getRupInfoTypes,
  getRupInfo,
  changeRupInfo, fetchSpecializationIdDone, fetchQualificationsOptionsFail,
} from "./actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {selectStore} from "../../selector";
import {RootState} from "../../index";
import {message} from "antd";

function* getRupsFlow({payload}: PayloadAction<any>): any {
  const {rup: {rups: dataState}}: RootState = yield select(selectStore)

  if(dataState?.success){
    yield put(fetchRupsDone({all: dataState.data[0].data, deleted: dataState.data[1].data}));
  } else {
    try {
      const {allRup, deletedRup} = yield all({
        allRup: call(getAllRupsApi),
        deletedRup: call(getDeletedRupsApi),
      })

      if (allRup?.data?.data?.newRups && deletedRup?.data?.data?.newRups) {
        let data = {
          all: allRup.data.data.newRups,
          deleted: deletedRup.data.data.newRups
        }

        yield put(fetchRupsDone(data));
      }
    } catch (e) {
      console.warn(e);
      yield put(fetchRupsFail());
    }
  }
}

function* addNewRupFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(addNewRupApi, payload);
    if (data.data.data.addNewRup) {
      yield put(addNewRupDone(data.data.data.addNewRup));
      // @ts-ignore
      yield call(setGroupForRupFlow, {payload: {...payload, id: data.data.data.addNewRup.id}})
    } else {
      message.error({
        content: data.data.errors[0].message, style: {
          marginTop: '50vh'
        }
      })
      yield put(addNewRupFail())
    }
  } catch (e) {
    console.error(e);
    yield put(addNewRupFail())
  }
}

function* getRupHelpersFlow({payload}: PayloadAction<any>): any {
  const {rup: {rupsHelpers: {linkGroups, profile, speciality}}}: RootState = yield select(selectStore)

  let arr = [
    {
      key: 'linkGroups',
      api: getGroupForRupApi,
      value: 'getGroupForRup',
    },
    {
      key: 'profile',
      api: getAllProfilesApi,
      value: 'specialities',
    },
    {
      key: 'speciality',
      api: getProfileDirectionsSpecializationsSpecialtyApi,
      value: 'specialities',
    }
  ]

  let key = arr.find(item => item.key === payload.key)

  try {
    // @ts-ignore
    const data = yield call(key?.api, payload?.value)

    if (data) {
      // @ts-ignore
      yield put(getRupHelpersDone({key: payload.key, value: data?.data?.data[key?.value]}))
    }
  } catch (e) {
    console.error(e);
    yield put(getRupHelpersFail({key: payload.key, value: undefined}))
  }
}

function* setGroupForRupFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(setGroupForRupApi, payload);
    if (data.data.data.addRupGroups) {
      yield put(setGroupForRupDone(data.data.data.addRupGroups));
    } else {
      yield put(setGroupForRupFail())
    }
  } catch (e) {
    console.error(e);
    yield put(setGroupForRupFail())
  }
}

function* editRupsFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(editRupApi, payload)
    if (data.data.data.changeRup) {
      if (payload.editType === 'revert') {
        let body = {
          ...payload,
          status: 2
        }
        yield put(revertRups(body))
        message.success({
          content: `РУП ${body.id} успешно восстановлен!`, style: {
            marginTop: '50vh'
          }
        })
      }
      yield put(editRupsDone(data.data.data.changeRup))
      // @ts-ignore
      yield call(setGroupForRupFlow, {payload: {...payload, id: data.data.data.changeRup.id}})
    } else {
      message.error({
        content: data.data.errors[0].message, style: {
          marginTop: '50vh'
        }
      })
      yield put(editRupsFail())
    }
  } catch (e) {
    console.error(e);
    yield put(editRupsFail())
  }
}

function* duplicateRupFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(duplicateRupApi, payload);
    if (data.data.data.duplicateRup) {
      yield put(duplicateRupDone(data.data.data.duplicateRup));
    } else {
      message.error({
        content: data.data.errors[0].message, style: {
          marginTop: '50vh'
        }
      })
      yield put(duplicateRupFail())
    }
  } catch (e) {
    console.error(e);
    yield put(duplicateRupFail())
  }
}

function* deleteRupFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(deleteRupApi, payload);
    if (data.data.data.deleteRup) {
      yield put(deleteRupsDone(data.data.data.deleteRup));
    }
  } catch (e) {
    console.error(e);
    yield put(deleteRupsFail())
  }
}

function* getRecommendedRupsFlow({payload}: PayloadAction<any>): any {
  const {rup: {recommendedRups: dataState}}: RootState = yield select(selectStore)

  if(dataState?.success){
    yield put(fetchRecommendedRupsDone(dataState?.data));
  } else {
    try {
      // @ts-ignore
      const data = yield call(getRecommendedRupsApi, payload);
      if (data) {
        yield put(fetchRecommendedRupsDone(data.recommended));
      }
    } catch (e) {
      console.error(e);
      yield put(fetchRecommendedRupsFail())
    }
  }
}

function* createNewRupFromRecommendationFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(addRupFromRecommendedApi, payload);
    if (data.data.data.addRupFromRecommend) {
      yield put(createNewRupFromRecommendedActionDone({...data.data.data.addRupFromRecommend, index: payload.index}));
    } else {
      message.error({
        content: data.data.errors[0].message, style: {
          marginTop: '50vh'
        }
      })
      yield put(createNewRupFromRecommendedActionFail())
    }
  } catch (e) {
    console.error(e);
    yield put(createNewRupFromRecommendedActionFail())
  }
}

function* getQualificationsFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(getQualificationsApi, payload)
    if (data.data.data.rupQualifications) {
      yield put(fetchQualificationsDone(data.data.data.rupQualifications))
    }
  } catch (e) {
    console.error(e);
    yield put(fetchQualificationsFail())
  }
}

function* getSpecializationIdFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(getSpecializationIdApi, payload)
    if (data.data.data.newRup) {
      yield put(fetchSpecializationIdDone(data.data.data.newRup))
      // @ts-ignore
      yield getQualificationsOptionsFlow({payload: data.data.data.newRup.speciality_id})
    }
  } catch (e) {
    console.error(e);
    yield put(fetchSpecializationIdFail())
  }
}

function* getQualificationsOptionsFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(getSpecializationsSpecialtyApi, payload)
    if (data.data.data.specialities) {
      yield put(fetchQualificationsOptionsDone(data.data.data.specialities))
    }
  } catch (e) {
    console.error(e);
    yield put(fetchQualificationsOptionsFail())
  }
}

function* addQualificationFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(addNewQualificationApi, payload)
    if (data.data.data.addQual) {
      let body = {
        ...data.data.data.addQual,
        new: true
      }
      yield put(addQualificationDone(body))
    }
  } catch (e) {
    console.error(e);
    yield put(addQualificationFail())
  }
}

function* editQualificationFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(changeQualificationApi, payload)
    if (data.data.data.changeQual) {
      yield put(editQualificationDone(data.data.data.changeQual))
    }
  } catch (e) {
    console.error(e);
    yield put(editQualificationFail())
  }
}

function* deleteQualificationFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(deleteQualificationApi, payload)
    if (data.data.data.deleteQual) {
      yield put(deleteQualificationDone(data.data.data.deleteQual))
    }
  } catch (e) {
    console.error(e);
    yield put(deleteQualificationFail())
  }
}

function* getCurrentRupDataFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(getSpecializationIdApi, payload);
    if (data.data.data.newRup) {
      yield put(fetchCurrentRupDone(data.data.data.newRup));
    } else {
      yield put(fetchCurrentRupFail())
      message.error({
        content: data.data.errors[0].message, style: {
          marginTop: '50vh'
        }
      })
    }
  } catch (e) {
    console.error(e);
    yield put(fetchCurrentRupFail())
  }
}

function* getRupFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(getRupApi, payload);
    if (data) {
      yield put(fetchRupDone(data));
    }
  } catch (e) {
    console.error(e);
    yield put(fetchRupFail())
  }
}

function* restoreStructureFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(restoreStructureApi, payload);
    if (!data.data.errors) {
      yield put(restoreStructureDone(data));
      message.success({
        content: data.data.data.restoreStructure, style: {
          marginTop: '50vh'
        }
      })

      const rups = yield call(getRupApi, payload);

      if (rups) {
        yield put(fetchRupDone(rups))
      }
    } else {
      yield put(restoreStructureFail())
    }
  } catch (e) {
    console.error(e);
    yield put(restoreStructureFail())
  }
}

function* getRupDisciplinesFlow({payload}: PayloadAction<any>): any {
  try {
    // @ts-ignore
    const data = yield call(getDisciplinesApi, payload)
    if (data.data.data.disciplines) {
      yield put(fetchDisciplinesDone(data.data.data.disciplines))
    }
  } catch (e) {
    console.error(e);
    yield put(fetchDisciplinesFail())
  }
}

function* getSemesterSettingsDataFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(getSemestersSettingsDataApi, payload);
    if (data.data.data.semester) {
      yield put(fetchSemesterSettingsActionDone(data.data.data.semester));
    }
  } catch (e) {
    console.error(e);
    yield put(fetchSemesterSettingsActionFail())
  }
}

function* postNewDisciplineRowForRupFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(postDisciplineForRupApi, payload);
    if (data.data.data.addDiscipline) {
      yield put(createNewDisciplineRowDone(data.data.data.addDiscipline));
      // @ts-ignore
      yield getRupFlow({payload: data.data.data.addDiscipline.rup_id})
    } else {
      message.error({
        content: data.data.errors[0].message, style: {
          marginTop: '50vh'
        }
      })
      yield put(createNewDisciplineRowFail())
    }
  } catch (e) {
    console.error(e);
    yield put(createNewDisciplineRowFail())
  }
}

function* generateNewHourFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(getNewHourRowApi, payload)
    if (data.data.data.addHour) {
      yield put(createNewRowDone(data.data.data.addHour))
      // @ts-ignore
      yield getRupFlow({payload: data.data.data.addHour.rup_id})
    } else {
      message.error({
        content: data.data.errors[0].message, style: {
          marginTop: '50vh'
        }
      })
      yield put(createNewRowFail())
    }
  } catch (e) {
    console.error(e);
    yield put(createNewRowFail())
  }
}

function* addModuleWithoutDisciplineFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(addModuleWithoutDisciplineApi, payload);
    if (data.data.data.addModuleWithoutDisciplines) {
      yield put(addModuleWithoutDiscDone(data.data.data.addModuleWithoutDisciplines));
      // @ts-ignore
      yield getRupFlow({payload: data.data.data.addModuleWithoutDisciplines.rup_id})
    } else {
      message.error({
        content: data.data.errors[0].message, style: {
          marginTop: '50vh'
        }
      })
      yield put(addModuleWithoutDiscFail())
    }
  } catch (e) {
    console.error(e);
    yield put(addModuleWithoutDiscFail())
  }
}

function* deleteHourRowFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(deleteHourRowApi, payload.key)
    if (data.data.data.deleteHour) {
      yield put(deleteRowDone(payload))
      // @ts-ignore
      yield getRupFlow({payload: payload.rup_id})
    } else {
      message.error({
        content: data.data.errors[0].message, style: {
          marginTop: '50vh'
        }
      })
      // @ts-ignore
      yield put(deleteRowFail())
    }
  } catch (e) {
    console.error(e);
    yield put(deleteRowFail())
  }
}

function* editHourRowFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(editRowApi, payload)
    if (data.data.data) {
      yield put(editRowDone(payload))
    }
  } catch (e) {
    console.error(e);
    yield put(editRowFail())
  }
}

function* editSemesterSettingsFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(editSemestersSettingsApi, payload);
    if (data.data.data.changeSemester) {
      yield put(editSemesterSettingsActionDone(data.data.data.changeSemester));
    } else {
      message.error({
        content: data.data.errors[0].message, style: {
          marginTop: '50vh'
        }
      })
      yield put(editSemesterSettingsActionFail())
    }
  } catch (e) {
    console.error(e);
    yield put(editSemesterSettingsActionFail())
  }
}

function* getRupHoursTypesForTranslateFlow({payload}: PayloadAction<any>): any {
  try {
    // @ts-ignore
    const data = yield call(getRupHoursTypesForTranslateApi, payload);
    if (data.data.data.getRupHoursTypesForTranslate) {
      yield put(getRupInfoTypesDone(data.data.data.getRupHoursTypesForTranslate));
    } else {
      yield put(getRupInfoTypesFail())
    }
  } catch (e) {
    console.error(e);
    yield put(getRupInfoTypesFail())
  }
}


function* getRupInfoFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(rupHoursApi, payload);
    if (data.data.data.rupHours) {
      yield put(getRupInfoDone(data.data.data.rupHours));
    } else {
      yield put(getRupInfoFail())
    }
  } catch (e) {
    console.error(e);
    yield put(getRupInfoFail())
  }
}

function* changeRupInfoFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(changeRupHoursApi, payload);
    if (data.data.data.changeHour) {
      yield put(changeRupInfoDone(data.data.data.changeHour));
    } else {
      yield put(changeRupInfoFail())
    }
  } catch (e) {
    console.error(e);
    yield put(changeRupInfoFail())
  }
}

export default function* RupSaga() {
  yield takeLatest(fetchRups, getRupsFlow);
  yield takeLatest(addNewRup, addNewRupFlow);
  yield takeEvery(getRupHelpers, getRupHelpersFlow);
  yield takeLatest(setGroupForRup, setGroupForRupFlow);
  yield takeLatest(editRups, editRupsFlow);
  yield takeLatest(duplicateRup, duplicateRupFlow);
  yield takeLatest(deleteRups, deleteRupFlow);
  yield takeLatest(fetchRecommendedRups, getRecommendedRupsFlow);
  yield takeLatest(createNewRupFromRecommendedAction, createNewRupFromRecommendationFlow);
  yield takeLatest(fetchQualifications, getQualificationsFlow);
  yield takeLatest(fetchSpecializationId, getSpecializationIdFlow);
  yield takeLatest(addQualification, addQualificationFlow);
  yield takeLatest(editQualification, editQualificationFlow);
  yield takeLatest(deleteQualification, deleteQualificationFlow);
  yield takeLatest(fetchCurrentRup, getCurrentRupDataFlow);
  yield takeLatest(fetchRup, getRupFlow);
  yield takeLatest(restoreStructure, restoreStructureFlow);
  yield takeLatest(fetchDisciplines, getRupDisciplinesFlow);
  yield takeLatest(fetchSemesterSettingsAction, getSemesterSettingsDataFlow);
  yield takeLatest(createNewDisciplineRow, postNewDisciplineRowForRupFlow);
  yield takeLatest(createNewRow, generateNewHourFlow);
  yield takeLatest(addModuleWithoutDisc, addModuleWithoutDisciplineFlow);
  yield takeLatest(deleteRow, deleteHourRowFlow);
  yield takeLatest(editRow, editHourRowFlow);
  yield takeLatest(editSemesterSettingsAction, editSemesterSettingsFlow);
  yield takeLatest(getRupInfoTypes, getRupHoursTypesForTranslateFlow);
  yield takeLatest(getRupInfo, getRupInfoFlow);
  yield takeLatest(changeRupInfo, changeRupInfoFlow);
}

