import styled from "styled-components";
import {Card, List, Row} from "antd";
import {setThemeColor} from "../../utils/setThemeColor";
import {DefaultTokenTheme} from "../../assets/themes/defaultTheme";

export const CardItem = styled(Card)`
  border: 1px solid ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)};
  background-color: ${({theme}) => setThemeColor(theme.current, '#fff', DefaultTokenTheme.borderDark)};
  transition: all 0.2s;
  cursor: pointer;

  .ant-card-head {
    padding: 6px 12px;
    min-height: auto;
    background-color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.secondary, DefaultTokenTheme.pressed)};
    border-bottom: none;

    .ant-card-head-title {
      color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.primary, '#fff')};
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1px;
      font-weight: 700;
      text-transform: capitalize;
    }
  }

  .ant-card-body {
    padding: 8px 12px 12px 12px;
  }
`

export const StyledJournalTableContainer = styled(Row)`

  //.ant-table-thead {
  //  th {
  //    background-color: #fafafa !important;
  //  }
  //}
  
  .ant{
    &-badge{
      margin-top: 12px;
      &-status-text{

        font-size: 16px !important;
      }
    }
    
    &-table{
      &-cell{
        .ant-btn{
          background: ${({theme}) => setThemeColor(theme.current, "#fff", "#212B49")};
          border-color: ${({theme}) => setThemeColor(theme.current, "#d9d9d9", "#37405B")};
          color: ${({theme}) => setThemeColor(theme.current, "#000", "#fff")}
        }
      }
      
      &-thead{
        .ant-table-cell{
          background: ${({theme}) => setThemeColor(theme.current, "#F6F1FF" ,"rgba(146,100,202,0.4)")};

          .ant-typography{
            color: ${({theme}) => setThemeColor(theme.current, "#6B6C7E" ,"#fff")} !important;
            font-size: 12px !important;
            line-height: 14px !important;
            font-weight: 500 !important;
          }
        }
        .ant-table-cell-fix-left {
          text-align: center;
          vertical-align: center;
        }

        .ant-btn{
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      
      
      &-tbody .ant-table-cell-fix-left{
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }
  
  .successMark:before {
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: ${({theme}) => setThemeColor(theme.current, DefaultTokenTheme.primary, DefaultTokenTheme.pressed)};
    animation-name: fadein;
    animation-duration: 7s;
    animation-fill-mode: forwards;
  }

  @keyframes fadein {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 0;
    }
  }
`

export const StyledList = styled(List)`
  .ant-list-items {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    margin: 16px;
    
    .ant-list-item{
      padding: 0;
    }
  }
`
