import axios from 'axios';
import { message } from "antd";
import {CANCEL} from "redux-saga";
import {Cookies} from "react-cookie";
import {COOKIES_ENUM, LANG} from "../constants";
import {getFromUrl} from "./getFromUrl";
import dayjs from "dayjs";

const cookies = new Cookies()

const cancelTokenSource = axios.CancelToken.source();

let promise = axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    accept: 'application/json, text/plain, */*',
  },
  cancelToken: cancelTokenSource.token,
  params: {
    debug: process.env.REACT_APP_ENV === 'production' ? null : true
  }
})

//@ts-ignore
promise[CANCEL] = () => cancelTokenSource.cancel();
// @ts-ignore
const axiosConfig = promise

const resetLogin = () => {
  cookies.remove(COOKIES_ENUM.JWT, {path: '/', domain: 'local'})
  cookies.remove(COOKIES_ENUM.THEMES, {path: '/', domain: '.itulek.kz'})
  cookies.remove(COOKIES_ENUM.LANG, {path: '/', domain: '.itulek.kz'})
  cookies.remove(COOKIES_ENUM.ACCESSTOKEN, {path: '/', domain: '.itulek.kz'})

  // window.location.replace(`${process.env.REACT_APP_FULLSTACK_URL}/login`)
  window.location.replace(`/`)
  // setTimeout(() => {
  //   window.location.reload()
  // }, 2000)
}

axiosConfig.interceptors.request.use(
  async (config) => {
    let accesstoken = cookies.get(COOKIES_ENUM.JWT)
    let lang = cookies.get(COOKIES_ENUM.LANG) || getFromUrl(COOKIES_ENUM.LANG) || LANG.RU

    if(accesstoken){
      config.headers["Authorization"] = `${accesstoken}`
      config.headers["Accept-Language"] = lang;
    }
    return config
  },
  error => {

    if(error.response.status == 401){
      resetLogin()
    }

    return Promise.reject(error)
  }
)
axiosConfig.interceptors.response.use(
  async (config) => {
    return config
  },
  error => {
    message.error(error.response.data.message)

    if(error.response.status == 401){
      resetLogin()
    }

    return Promise.reject(error)
  }
)

export {axiosConfig}
