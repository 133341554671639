import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Row, Table, Tooltip, message } from "antd";
import { MyBreadcrumb, FirstLevelTabs } from "../../components";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import "../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import { PlusOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import { EmployeeData } from "../InterfacesApi/InterfacesApi";
import { HeadersAuth } from "../../constants/Auth";

export const Employee = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState<EmployeeData | null>(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const [qualificationsData, setQualificationsData] = useState({
    current_page: 1,
    data: [],
    last_page: 1,
    per_page: 20,
    total: 0,
  });

  const editIconClick = (id, item) => {
    navigate(`/employee/view/${id}`, {
      state: {
        speciality_id: item.id,
        special_qual_id: item.special_qual_id,
      },
    });
  };

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  const fetchEmployeeData = async () => {
    try {
      const response = await axios.get<EmployeeData>(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getEmployee?college_id=1`,
        HeadersAuth
      );

      const { current_page, data, last_page, per_page, total } = response.data;
      setEmployeeData(response.data);
      setQualificationsData({
        current_page,
        data: [],
        last_page,
        per_page,
        total,
      });
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
    }
  };

  const handleFireEmployee = async () => {
    if (selectedRowKeys.length === 0) {
      message.error("Пожалуйста, выберите сотрудника для увольнения.");
      return;
    }

    const personIdToFire = selectedRowKeys[0];
    try {
      await axios.post(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/EmployeeFiredTrue?person_id=${personIdToFire}`,
        null,
        HeadersAuth
      );
      fetchEmployeeData();
      setSelectedRowKeys([]);
      message.success("Сотрудник успешно уволен.");
    } catch (error) {
      console.error("Ошибка при увольнении сотрудника:", error);
      message.error(
        "Ошибка при увольнении сотрудника. Пожалуйста, попробуйте еще раз."
      );
    }
  };

  const columns = [
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>№</span>,
      dataIndex: "id",
      key: "id",
      style: { fontSize: "26px", fontWeight: "bold", color: "#773DBD" },
      width: 50,
    },
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>Фамилия</span>,
      dataIndex: "middlename",
      key: "middlename",
      style: { fontSize: "26px", color: "#773DBD" },
      sorter: false,
      width: 120,
    },
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>Имя</span>,
      dataIndex: "firstname",
      key: "firstname",
      style: { fontSize: "26px", color: "#773DBD" },
      sorter: false,
      width: 120,
    },
    {
      title: (
        <span style={{ color: "#773DBD", padding: "10px" }}>Отчество</span>
      ),
      dataIndex: "secondname",
      key: "secondname",
      style: { fontSize: "26px", color: "#773DBD" },
      sorter: false,
      width: 120,
    },
    {
      title: (
        <span style={{ color: "#773DBD", padding: "10px" }}>Дата рождения</span>
      ),
      dataIndex: "date_birth",
      key: "date_birth",
      style: { fontSize: "26px" },
      width: 200,
    },
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>ИИН</span>,
      dataIndex: "iin",
      key: "iin",
      style: { fontSize: "26px" },
      width: 200,
    },
    {
      title: (
        <span style={{ color: "#773DBD", padding: "10px" }}>Должность</span>
      ),
      dataIndex: "role_ids",
      key: "role_ids",
      style: { fontSize: "26px" },
      width: 400,
    },

    {
      title: <span style={{ padding: "2px", color: "#773DBD" }}>Действие</span>,
      dataIndex: "delete",
      key: "delete",
      width: 20,
      render: (_, record) => (
        <span>
          <Tooltip title="Редактировать">
            <EditOutlined
              onClick={() => editIconClick(record.id, record)}
              className="speciality_edit"
              style={{
                display: "inline",
                fontSize: "24px",
                marginLeft: "25px",
              }}
              twoToneColor="red"
            />
          </Tooltip>
          <Tooltip title="Удалить"></Tooltip>
        </span>
      ),
    },
  ];

  const firstLevelTabs = [
    {
      key: "1",
      label: "Сотрудники",
      children: (
        <Content
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={16}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            >
              <span style={{ fontWeight: "500", fontSize: "24px" }}>
                Сотрудники ({qualificationsData.total})
              </span>
            </Col>

            <Col
              span={8}
              style={{
                textAlign: "end",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "0 20px 0 0",
              }}
            >
              <Button
                onClick={handleFireEmployee}
                className="speciality_delete_button"
              >
                <CloseOutlined
                  style={{ display: "inline-block", fontSize: "16px" }}
                />
                <span
                  style={{
                    fontWeight: "400",
                    marginTop: "-20px",
                    lineHeight: "0px",
                  }}
                >
                  Уволить
                </span>
              </Button>
              <Button
                onClick={() => navigate("/employee/create")}
                className="speciality_create_button"
              >
                <PlusOutlined
                  style={{ display: "inline-block", fontSize: "16px" }}
                />
                <span
                  style={{
                    fontWeight: "400",
                    marginTop: "-20px",
                    lineHeight: "0px",
                  }}
                >
                  Добавить
                </span>
              </Button>
            </Col>
          </Row>
          <div
            className="speciality_table"
            style={{ fontSize: "20px", padding: "20px" }}
          >
            <Table
              dataSource={employeeData?.data}
              bordered
              rowSelection={rowSelection}
              columns={columns}
              rowKey="id"
              pagination={{
                current: qualificationsData.current_page,
                pageSize: qualificationsData.per_page,
                total: qualificationsData.total,
                onChange: fetchEmployeeData,
              }}
            />
          </div>

          <Row>
            <Col
              span={12}
              style={{ paddingBottom: "30px", marginTop: "-55px" }}
            >
              <span
                style={{
                  marginLeft: "28px",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >{`Показаны ${
                (qualificationsData.current_page - 1) *
                  qualificationsData.per_page +
                1
              }-${Math.min(
                qualificationsData.current_page * qualificationsData.per_page,
                qualificationsData.total
              )} из ${qualificationsData.total} записи`}</span>
            </Col>
          </Row>
        </Content>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Кадровый учет" />
      <Row justify="center">
        <Col span={22}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="1" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>
      <p />
    </>
  );
};
