import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {
  FETCH,
  DONE,
  FAIL,
  FORM,
  GET, CTP, PERIOD, EXTRA, FILTER, GROUPS, RESET,
} from "../constants";

import reducerStatuses from "../status";
import {FORM_SECTION_TYPES, FORM_TYPES, JOURNAL_FORM_TYPES, PLANS_FORM_TYPES} from "../../constants";
import {IFormType} from "./types";

const formPlanInitialState = {
  [FORM_TYPES.ADD]: {
    ...reducerStatuses, data: [] as any
  },
  [FORM_TYPES.UPDATE]: {
    ...reducerStatuses, data: [] as any
  },
  [FORM_TYPES.COPY]: {
    ...reducerStatuses, data: [] as any
  },
}

const formJournalInitialState = {
  [FORM_TYPES.ADD]: {
    ...reducerStatuses, data: [] as any
  },
  [FORM_TYPES.UPDATE]: {
    ...reducerStatuses, data: [] as any
  },
  [FORM_TYPES.VISIT]: {
    ...reducerStatuses, data: [] as any
  },
}


const initialState = {
  form: {
    [FORM_SECTION_TYPES.JOURNAL]: {
      [JOURNAL_FORM_TYPES.LESSON]: formJournalInitialState,
      [JOURNAL_FORM_TYPES.BALL]: formJournalInitialState,
      [JOURNAL_FORM_TYPES.INDIVIDUAL]: formJournalInitialState,
      [JOURNAL_FORM_TYPES.THEME]: formJournalInitialState,

      [JOURNAL_FORM_TYPES.LESSON_THEME]: formJournalInitialState,
      [JOURNAL_FORM_TYPES.INDIVIDUAL_LESSON]: formJournalInitialState,
      [JOURNAL_FORM_TYPES.BALL_VISIT]: formJournalInitialState,
      [JOURNAL_FORM_TYPES.INCENTIVE_POINT]: formJournalInitialState,
      [JOURNAL_FORM_TYPES.EXAM]: formJournalInitialState,
      [JOURNAL_FORM_TYPES.PASS]: formJournalInitialState,
      [JOURNAL_FORM_TYPES.LESSON_PASS_EXAM]: formJournalInitialState,
    },
    [FORM_SECTION_TYPES.PLANS]: {
      [PLANS_FORM_TYPES.PLAN]: formPlanInitialState,
      [PLANS_FORM_TYPES.TESTS]: formPlanInitialState,
      [PLANS_FORM_TYPES.MATERIALS]: formPlanInitialState,
      [PLANS_FORM_TYPES.TASKS]: formPlanInitialState,
      [PLANS_FORM_TYPES.LESSON]: formPlanInitialState,
    },
    [FORM_SECTION_TYPES.PROFILE]: {},
    [FORM_SECTION_TYPES.SCHEDULE]: {},
  },
  ctp: {...reducerStatuses, data: null as any},
  ctpFilter: {...reducerStatuses, data: null as any},
  period: {...reducerStatuses, data: [] as any[]},
  periodByGroup: {...reducerStatuses, data: [] as any[]},
};

export default createReducer(initialState, builder =>
  builder
    .addCase(GET + FORM + FETCH, (state, {payload}: PayloadAction<IFormType>) => ({
      ...state,
      form: {
        ...state.form,
        [payload.section_type]: {
          ...state.form[payload.section_type],
          [payload.section_inner_type]: {
            ...state.form[payload.section_type][payload.section_inner_type],
            [payload.form_type]: {
              ...state.form[payload.section_type][payload.section_inner_type][payload.form_type],
              loading: true,
            }
          },
        }
      }
    }))
    .addCase(GET + FORM + FETCH + DONE, (state, {payload}: PayloadAction<IFormType>) => ({
      ...state,
      form: {
        ...state.form,
        [payload.section_type]: {
          ...state.form[payload.section_type],
          [payload.section_inner_type]: {
            ...state.form[payload.section_type][payload.section_inner_type],
            [payload.form_type]: {
              ...state.form[payload.section_type][payload.section_inner_type][payload.form_type],
              loading: false,
              success: true,
              data: payload.data,
            }
          },
        }
      }
    }))
    .addCase(GET + FORM + FETCH + FAIL, (state, {payload}: PayloadAction<IFormType>) => ({
      ...state,
      form: {
        ...state.form,
        [payload.section_type]: {
          ...state.form[payload.section_type],
          [payload.section_inner_type]: {
            ...state.form[payload.section_type][payload.section_inner_type],
            [payload.form_type]: {
              ...state.form[payload.section_type][payload.section_inner_type][payload.form_type],
              loading: false,
              failed: true,
            }
          },
        }
      }
    }))


    .addCase(GET + CTP + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      ctp: {
        ...state.ctp,
        loading: true,
      },
    }))
    .addCase(GET + CTP + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      ctp: {
        ...initialState.ctp,
        loading: false,
        success: true,
        data: payload,
      },
    }))
    .addCase(GET + CTP + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      ctp: {
        ...initialState.ctp,
        failed: true,
      },
    }))

    .addCase(GET + CTP + FILTER + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      ctpFilter: {
        ...state.ctpFilter,
        loading: true,
      },
    }))
    .addCase(GET + CTP + FILTER + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      ctpFilter: {
        ...initialState.ctpFilter,
        success: true,
        data: payload,
      },
    }))
    .addCase(GET + CTP + FILTER + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      ctpFilter: {
        ...initialState.ctpFilter,
        failed: true,
      },
    }))


    .addCase(GET + PERIOD + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      period: {
        ...state.period,
        loading: true,
      },
    }))
    .addCase(GET + PERIOD + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      period: {
        ...initialState.period,
        loading: false,
        success: true,
        data: payload,
      },
    }))
    .addCase(GET + PERIOD + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      period: {
        ...initialState.period,
        failed: true,
      },
    }))

    .addCase(GET + PERIOD + GROUPS + FETCH + RESET, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      periodByGroup: {
        ...initialState.periodByGroup,
      },
    }))
    .addCase(GET + PERIOD + GROUPS + FETCH, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      periodByGroup: {
        ...initialState.periodByGroup,
        loading: true,
      },
    }))
    .addCase(GET + PERIOD + GROUPS + FETCH + DONE, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      periodByGroup: {
        ...initialState.periodByGroup,
        success: true,
        data: payload,
      },
    }))
    .addCase(GET + PERIOD + GROUPS + FETCH + FAIL, (state, {payload}: PayloadAction<any>) => ({
      ...state,
      periodByGroup: {
        ...initialState.periodByGroup,
        failed: true,
      },
    }))

    .addCase(GET + FORM + EXTRA + FETCH, (state, {payload}: PayloadAction<IFormType>) => ({
      ...state,
      form: {
        ...state.form,
        [payload.section_type]: {
          ...state.form[payload.section_type],
          [payload.section_inner_type]: {
            ...state.form[payload.section_type][payload.section_inner_type],
            [payload.form_type]: {
              ...state.form[payload.section_type][payload.section_inner_type][payload.form_type],
              loading: true,
            }
          },
        }
      }
    }))
    .addCase(GET + FORM + EXTRA + FETCH + DONE, (state, {payload}: PayloadAction<IFormType>) => ({
      ...state,
      form: {
        ...state.form,
        [payload.section_type]: {
          ...state.form[payload.section_type],
          [payload.section_inner_type]: {
            ...state.form[payload.section_type][payload.section_inner_type],
            [payload.form_type]: {
              ...state.form[payload.section_type][payload.section_inner_type][payload.form_type],
              loading: false,
              success: true,
              data: payload.data,
            }
          },
        }
      }
    }))
    .addCase(GET + FORM + EXTRA + FETCH + FAIL, (state, {payload}: PayloadAction<IFormType>) => ({
      ...state,
      form: {
        ...state.form,
        [payload.section_type]: {
          ...state.form[payload.section_type],
          [payload.section_inner_type]: {
            ...state.form[payload.section_type][payload.section_inner_type],
            [payload.form_type]: {
              ...state.form[payload.section_type][payload.section_inner_type][payload.form_type],
              failed: true,
            }
          },
        }
      }
    }))
)


