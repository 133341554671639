import React, { useCallback, useState } from "react";
import { Row, Col, Form, theme as configTheme, Select, Checkbox } from "antd";
import cl from "classnames";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  MyButton,
  MyImage,
  MyInput,
  MyPassword,
  MyText,
} from "../../components";
import AuthImage from "../../assets/images/auth-image.png";
import { useAuth, useTheme } from "../../hooks";
import { AuthPage, LeftCol, RightCol } from "./index.styles";
import { useSelector } from "react-redux";
import { MyAliasToken } from "../../assets/themes/defaultTheme";
import { RootState } from "../../store";
import { selectStore } from "../../store/selector";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LogoImage from "../../assets/icons/logo.svg";

const { useToken } = configTheme;

export const Auth = (): React.ReactElement => {
  const { t } = useTranslation("common");
  const { theme, themeConfig } = useTheme();

  const { handleLogin } = useAuth();

  const [authForm] = Form.useForm();

  // @ts-ignore

  const {
    profile: { userProfile: profileDataState },
  }: RootState = useSelector(selectStore);

  const onFinish = useCallback(
    async (values: any) => {
      try {
        await handleLogin(values);
      } catch (error) {
        console.error("Ошибка входа");
      }
    },
    [handleLogin]
  );

  return (
    <AuthPage>
      <LeftCol>
        <Row justify="center">
          <Col span={14}>
            <Row>
              <Col span={24} style={{ marginBottom: 90 }}>
                <MyImage src={LogoImage} width={164} height={40} />
              </Col>
              <Col span={24} style={{ marginBottom: 50 }}>
                <Row justify="center" align="middle">
                  <Col span={24} style={{ marginBottom: 50 }}>
                    <MyText size={40} color="#262626" weight={700}>
                      {t("welcome")}
                    </MyText>
                  </Col>
                  <Col span={24}>
                    <MyText
                      size={18}
                      weight={500}
                      color={themeConfig.token.primary}
                      className={cl("line")}
                    >
                      {t("loginAndPasswordEnter")}
                    </MyText>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form onFinish={onFinish} form={authForm}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label={t("login")}
                        name="login"
                        labelCol={{
                          span: 24,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                      >
                        <MyInput placeholder={t("enterLogin")} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label={t("password")}
                        name="password"
                        labelCol={{
                          span: 24,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                      >
                        <MyPassword placeholder={t("enterPassword")} />
                      </Form.Item>
                    </Col>

                    {/* <Col span={24}>
                      <Form.Item>
                        <Select
                          style={{ width: "100%" }}
                          onSelect={(value, option) =>
                            authForm.setFieldsValue(option.item)
                          }
                          options={usersArray.map((item) => ({
                            value: item.login,
                            item: {
                              login: item.login,
                              password: item.password,
                            },
                            label: item.login,
                          }))}
                        />
                      </Form.Item>
                    </Col> */}

                    <Col span={24}>
                      <Row justify="space-between" align="middle">
                        <Col>
                          <Form.Item
                            name="agree"
                            valuePropName="checked"
                            rules={[
                              {
                                validator: (_, value) =>
                                  value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error(
                                          "Подтвердите соглашение с условиями! "
                                        )
                                      ),
                              },
                            ]}
                          >
                            <Checkbox>
                              {t("Я согласен с правилами и условиями")}
                            </Checkbox>
                          </Form.Item>
                        </Col>

                        <Col>
                          <Link to="/">Забыли пароль?</Link>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Form.Item>
                        <MyButton
                          width="100%"
                          type="primary"
                          ghost
                          htmlType="submit"
                          title={t("signIn")}
                          disabled={profileDataState.loading}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </LeftCol>

      <RightCol>
        <MyImage
          src={AuthImage}
          rootClassName={cl("background")}
          className={cl("image")}
        />
      </RightCol>
    </AuthPage>
  );
};
