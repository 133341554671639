import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Row,
  Spin,
  Table,
  Tooltip,
  Input,
  Popconfirm,
} from "antd";
import { MyBreadcrumb, FirstLevelTabs } from "../../components";
import { useTheme } from "../../hooks";
import { position } from "polished";
import "../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { Cookies } from "react-cookie";
import {
  QualificationDataVM,
  QualificationDataItemVM,
} from "../InterfacesApi/InterfacesApi";
import { HeadersAuth } from "../../constants/Auth";

export const Institution_Department = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { theme } = useTheme();
  const editIcon = (department_id, departmentCaption, speciality_id) => (
    <EditOutlined
      onClick={() =>
        redirectToInstitutionDepartmentCreate(
          department_id,
          departmentCaption,
          speciality_id
        )
      }
      className="speciality_edit"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
    />
  );
  const deleteIcon = (
    <DeleteOutlined
      className="speciality_delete"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
    />
  );

  const [qualificationsData, setQualificationsData] =
    useState<QualificationDataVM>({
      current_page: 1,
      data: [],
      last_page: 1,
      per_page: 20,
      total: 0,
    });

  const fetchQualificationsData = async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/index-departments-main?college_id=1&page=${page}`,
        HeadersAuth
      );

      setQualificationsData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (department_id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/department/delete?department_id=${department_id}`,
        HeadersAuth
      );

      fetchQualificationsData(qualificationsData.current_page);
    } catch (error) {
      console.error("Неудачное удаление записи: ", error);
    }
  };

  useEffect(() => {
    fetchQualificationsData(1);
  }, []);

  const redirectToInstitutionDepartmentCreate = (
    id,
    departmentCaption,
    speciality_id
  ) => {
    navigate(`/institution_department/edit/${id}`, {
      state: {
        departmentCaption,
        speciality_id,
      },
    });
  };

  const columns = [
    {
      title: <span style={{ padding: "10px", color: "#773DBD" }}>#</span>,
      dataIndex: "department_id",
      key: "department_id",
      style: { fontSize: "26px", fontWeight: "bold", color: "#773DBD" },
      sorter: false,
      width: 50,
    },
    {
      title: (
        <span style={{ color: "#773DBD", padding: "5px" }}>
          Название на русском
        </span>
      ),
      dataIndex: "departmentCaption",
      key: "departmentCaption",
      style: { fontSize: "26px", color: "#773DBD" },
      sorter: false,
      width: 250,
    },
    {
      title: (
        <span style={{ color: "#773DBD", padding: "5px" }}>
          Название на казахском
        </span>
      ),
      dataIndex: "departmentCaption",
      key: "departmentCaption",
      style: { fontSize: "26px", color: "#773DBD" },
      sorter: false,
      width: 250,
    },
    {
      title: (
        <span style={{ padding: "5px", color: "#773DBD" }}>Специальность</span>
      ),
      dataIndex: "specialityTitle",
      key: "specialityTitle",
      style: { fontSize: "26px" },
      width: 250,
    },
    {
      title: (
        <span style={{ padding: "5px", color: "#773DBD" }}>Преподаватели</span>
      ),
      dataIndex: "qualify",
      key: "qualify",
      style: { fontSize: "26px" },
    },

    {
      title: <span style={{ padding: "2px", color: "#773DBD" }}>Действие</span>,
      dataIndex: "delete",
      key: "delete",
      width: 90,
      render: (_, record) => (
        <span>
          <Tooltip title="Редактировать">
            {editIcon(
              record.department_id,
              record.departmentCaption,
              record.specialityTitle
            )}
          </Tooltip>
          <Tooltip title="Удалить">
            <Popconfirm
              title="Вы уверены, что хотите удалить?"
              onConfirm={() => handleDelete(record.department_id)}
              okText="Да"
              cancelText="Нет"
            >
              {deleteIcon}
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ];

  const data = qualificationsData.data.map((item) => ({
    id: item.id,
    department_id: item.department.id,
    departmentCaption: item.department.caption,
    specialityTitle: item.speciality.title,
    speciality_id: item.speciality.id,
  }));

  const redirectToSpeciality = () => {
    navigate("/speciality");
  };
  const redirectToInstitutionDiscipline = () => {
    navigate("/institution_discipline");
  };
  const redirectGroup = () => {
    navigate("/group");
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Content
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              marginTop: "20px",
              borderRadius: "0px 8px 8px 8px",
            }}
          >
            <p />
            <Row
              style={{ height: "20" }}
              justify="space-between"
              align="middle"
            >
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0 20px 0 20px",
                }}
              >
                <span style={{ fontWeight: "500", fontSize: "24px" }}>
                  Добавленные кафедры
                </span>
              </Col>
              <Col
                span={12}
                style={{
                  textAlign: "end",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  padding: "0 20px 0 0",
                }}
              >
                <Button
                  onClick={() => navigate("/institution_department/create")}
                  className="speciality_create_button"
                >
                  <PlusOutlined
                    style={{ display: "inline-block", fontSize: "16px" }}
                  />
                  <span
                    style={{
                      fontWeight: "400",
                      marginTop: "-20px",
                      lineHeight: "0px",
                    }}
                  >
                    Добавить
                  </span>
                </Button>
              </Col>
            </Row>
            <div
              className="speciality_table"
              style={{ fontSize: "20px", padding: "20px" }}
            >
              <Table dataSource={data} bordered columns={columns} />
            </div>
            <Row>
              <Col
                span={12}
                style={{ paddingBottom: "30px", marginTop: "-55px" }}
              >
                <span
                  style={{
                    marginLeft: "28px",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Показаны 1-{Math.min(data.length, 20)} из {data.length} записи
                </span>
              </Col>
            </Row>
          </Content>
        </Col>
      </Row>

      <p />
    </>
  );
};
