import {call, put, select, takeLatest} from "redux-saga/effects";
import {
  fetchAuth,
  fetchAuthDone,
  fetchAuthFail,
  fetchGetDashboardAction,
  fetchGetDashboardActionDone,
  fetchGetDashboardActionFail,
  fetchGetNotificationAction,
  fetchGetNotificationActionDone,
  fetchGetNotificationActionFail,
  fetchSetNotificationAction,
  fetchSetNotificationActionDone,
  fetchSetNotificationActionFail,
  fetchUserProfileAction,
  fetchUserProfileActionDone,
  fetchUserProfileActionFail,
  getMenuAction,
  getMenuActionDone,
  getMenuActionFail,
} from "./actions";
import {
  apiAuth,
  // apiGetDashboard,
  
  apiGetMenuUser,
  // apiGetNotification,
  apiGetUserProfile,
  // apiSetNotification
} from "./api";
import {setTokenCookie} from "../../../hooks/useAuth";
import {PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../index";
import {selectStore} from "../../selector";
import {LANG} from "../../../constants";

function* authFlow({payload}: PayloadAction<any>): any {
  try {
    const data = yield call(apiAuth, payload)
    if (data.user) {
      yield put(fetchAuthDone(data.user))

      let body = {
        'Authorization': `${data.accesstoken}`,
        'Accept-Language': LANG.RU,
      }

      window.location.replace(`${process.env.REACT_APP_FULLSTACK_URL}/`)

      // yield call(apiSetAuth, body)

      yield call(setTokenCookie, data.accesstoken)
    }

  } catch (e) {
    yield put(fetchAuthFail())
  }
}

function* getUserProfileFlow({payload}: PayloadAction<any>): any {
  const {profile: {userProfile: dataState}}: RootState = yield select(selectStore)
  // if (dataState.success) {
  //   yield put(fetchUserProfileActionDone(dataState.data));
  // } else {
  try {
    const data = yield call(apiGetUserProfile);
    if (data) {
      yield put(fetchUserProfileActionDone(data.data));
    }
  } catch (e: any) {
    console.warn(e);
    yield put(fetchUserProfileActionFail());
  }
  // }
}

function* getMenuFlow({payload}: PayloadAction<any>): any {
  const {profile: {menu: dataState}}: RootState = yield select(selectStore)
  try {
    const data = yield call(apiGetMenuUser);
    if (data.data) {
      yield put(getMenuActionDone(data.data));
    }
  } catch (e) {
    console.error(e);
    yield put(getMenuActionFail());
  }
}

// function* getNotificationFlow({payload}: PayloadAction<any>): any {
//   try {
//     const data = yield call(apiGetNotification);
//     if (data.data) {
//       yield put(fetchGetNotificationActionDone(data.data?.notifications));
//     }
//   } catch (e) {
//     console.error(e);
//     yield put(fetchGetNotificationActionFail());
//   }
// }

// function* setNotificationFlow({payload}: PayloadAction<any>): any {
//   try {
//     const data = yield call(apiSetNotification);
//     if (data.data) {
//       yield put(fetchSetNotificationActionDone(data));
//     }
//   } catch (e) {
//     console.error(e);
//     yield put(fetchSetNotificationActionFail());
//   }
// }

// function* getDashboardFlow({payload}: PayloadAction<any>): any {
//   try {
//     const data = yield call(apiGetDashboard);
//     if (data.data) {
//       yield put(fetchGetDashboardActionDone(data.data));
//     }
//   } catch (e) {
//     console.error(e);
//     yield put(fetchGetDashboardActionFail());
//   }
// }


export default function* profileSaga() {
  yield takeLatest(fetchAuth, authFlow);
  // yield takeLatest(fetchUserProfileAction, getUserProfileFlow);
  // yield takeLatest(getMenuAction, getMenuFlow);
  yield takeLatest(getMenuAction, getMenuFlow);
  // yield takeLatest(fetchGetNotificationAction, getNotificationFlow);
  // yield takeLatest(fetchSetNotificationAction, setNotificationFlow);
  // yield takeLatest(fetchGetDashboardAction, getDashboardFlow);
}
