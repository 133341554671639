import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Row, Table, Tooltip, Popconfirm, message } from "antd";
import { MyBreadcrumb, FirstLevelTabs } from "../../components";
import { useTheme } from "../../hooks";
import "../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { Cookies } from "react-cookie";
import { QualificationDataVM } from "../InterfacesApi/InterfacesApi";
import { HeadersAuth } from "../../constants/Auth";
import { Building } from "./Building";
import { ClassroomVM } from "./ClassroomInterface";

export const Classroom = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { theme } = useTheme();
  const editIcon = (
    id,
    title,
    number,
    type_title,
    type_id,
    building_id,
    area
  ) => (
    <EditOutlined
      onClick={() =>
        redirectToInstitutionDepartmentCreate(
          id,
          title,
          number,
          type_title,
          type_id,
          building_id,
          area
        )
      }
      className="speciality_edit"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
    />
  );

  const deleteIcon = (
    <DeleteOutlined
      className="speciality_delete"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
    />
  );

  const fetchClassroomAPI = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/auditfund/v1/getClassrooms?college_id=1`,
        HeadersAuth
      );

      setClassroomData(response.data);
    } catch (error) {
      console.error("Ошибка получения данных:", error);
    }
  };

  useEffect(() => {
    fetchClassroomAPI();
  }, []);

  const [classroomData, setClassroomData] = useState<ClassroomVM>({
    current_page: 1,
    data: [],
    last_page: 1,
    per_page: 20,
    total: 0,
  });

  const redirectToInstitutionDepartmentCreate = (
    id,
    title,
    number,
    type_title,
    type_id,
    building_id,
    area
  ) => {
    navigate(`/classroom/edit/item/${id}`, {
      state: {
        id,
        title,
        number,
        type_title,
        type_id,
        building_id,
        area,
      },
    });
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/auditfund/v1/deleteClassroom?id=${id}`,
        HeadersAuth
      );

      setClassroomData((prevData) => {
        const newData = prevData.data.filter((item) => item.id !== id);
        return {
          ...prevData,
          data: newData,
        };
      });

      message.success("Аудитория была удалена!");
    } catch (error) {
      message.error("Ошибка удаления аудитории");
    }
  };

  const data = classroomData.data.map((item) => ({
    id: item.id,
    number: item.number,
    title: item.title,
    area: item.area,
    building_id: item.building_id,
    type_id: item.type_id,
    type_title: item.type_title,
    status: item.status,
  }));

  const columns = [
    {
      title: <span style={{ padding: "10px", color: "#773DBD" }}>#</span>,
      dataIndex: "id",
      key: "id",
      style: { fontSize: "26px", fontWeight: "bold", color: "#773DBD" },
      sorter: false,
      width: 50,
    },
    {
      title: <span style={{ color: "#773DBD", padding: "5px" }}>Название</span>,
      dataIndex: "title",
      key: "title",
      style: { fontSize: "26px", color: "#773DBD" },
      sorter: false,
      width: 250,
    },
    {
      title: <span style={{ color: "#773DBD", padding: "5px" }}>Номер</span>,
      dataIndex: "number",
      key: "number",
      style: { fontSize: "26px", color: "#773DBD" },
      sorter: false,
      width: 250,
    },
    {
      title: <span style={{ padding: "5px", color: "#773DBD" }}>Корпус</span>,
      dataIndex: "building_id",
      key: "building_id",
      style: { fontSize: "26px" },
      width: 250,
    },
    {
      title: <span style={{ padding: "5px", color: "#773DBD" }}>Тип</span>,
      dataIndex: "type_title",
      key: "type_title",
      style: { fontSize: "26px" },
    },

    {
      title: <span style={{ padding: "2px", color: "#773DBD" }}>Действие</span>,
      dataIndex: "delete",
      key: "delete",
      width: 90,
      render: (_, record) => (
        <span>
          <Tooltip title="Редактировать">
            {editIcon(
              record.id,
              record.title,
              record.number,
              record.type_title,
              record.type_id,
              record.building_id,
              record.area
            )}
          </Tooltip>
          <Tooltip title="Удалить">
            <Popconfirm
              title="Вы уверены, что хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => handleDelete(record.id)}
            >
              {deleteIcon}
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ];

  //   const data = qualificationsData.data.map((item) => ({
  //     id: item.id,
  //     department_id: item.department.id,
  //     departmentCaption: item.department.caption,
  //     specialityTitle: item.speciality.title,
  //     speciality_id: item.speciality.id,
  //   }));

  const firstLevelTabs = [
    {
      key: "1",
      label: <div style={{ cursor: "pointer" }}>Аудитории</div>,
      children: (
        <Content
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            >
              <span style={{ fontWeight: "500", fontSize: "24px" }}>
                <span style={{ fontWeight: "500", fontSize: "24px" }}>
                  {t("Аудитории")} ({classroomData.data.length})
                </span>
              </span>
            </Col>
            <Col
              span={12}
              style={{
                textAlign: "end",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "0 20px 0 0",
              }}
            >
              <Button
                onClick={() => navigate("/classroom/create")}
                className="speciality_create_button"
              >
                <PlusOutlined
                  style={{ display: "inline-block", fontSize: "16px" }}
                />
                <span
                  style={{
                    fontWeight: "400",
                    marginTop: "-20px",
                    lineHeight: "0px",
                  }}
                >
                  Добавить
                </span>
              </Button>
            </Col>
          </Row>
          <div
            className="speciality_table"
            style={{ fontSize: "20px", padding: "20px" }}
          >
            <Table dataSource={data} bordered columns={columns} />
          </div>
          <Row>
            <Col
              span={12}
              style={{ paddingBottom: "30px", marginTop: "-55px" }}
            >
              <span
                style={{
                  marginLeft: "28px",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                Показаны 1-{Math.min(data.length, 20)} из {data.length} записи
              </span>
            </Col>
          </Row>
        </Content>
      ),
    },

    {
      key: "2",
      label: "Корпуса",
      children: <Building />,
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Аудитории" />
      <Row justify="center">
        <Col span={22}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="1" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>
      <p />
    </>
  );
};
