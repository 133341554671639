import { createAction } from "@reduxjs/toolkit";

import {
  FETCH,
  DONE,
  FAIL,
  RUPS,
  ADD,
  RUP,
  RESET,
  GET,
  HELPER,
  SET,
  EDIT,
  DUPLICATE,
  DELETE,
  REVERT,
  RECOMMENDED,
  ONE,
  QUALIFICATIONS,
  SPECIALITIES,
  ID,
  OPTIONS,
  CURRENT,
  STRUCTURE,
  DISCIPLINES,
  RESTORE,
  SEMESTER, SETTINGS, TYPES, INFO, GROUPS, MODULE
} from "../../constants";

export const fetchRups = createAction<null>(RUPS + FETCH);
export const fetchRupsDone = createAction<any>(RUPS + FETCH + DONE);
export const fetchRupsFail = createAction<undefined>(RUPS + FETCH + FAIL);

export const addNewRup = createAction<any>(ADD + RUP + FETCH);
export const addNewRupDone = createAction<any>(ADD + RUP + FETCH + DONE);
export const addNewRupFail = createAction<undefined>(ADD + RUP + FETCH + FAIL);

export const addNewRupState = createAction<any>(ADD + RUP + SET);
export const resetNewRupState = createAction<undefined>(RESET + RUP + SET);

export const getRupHelpers = createAction<any>(GET + RUP + HELPER + FETCH);
export const getRupHelpersDone = createAction<any>(GET + RUP + HELPER + FETCH+ DONE);
export const getRupHelpersFail = createAction<any>(GET + RUP + HELPER + FETCH + FAIL);

export const setGroupForRup = createAction(SET + GROUPS + RUP + FETCH);
export const setGroupForRupDone = createAction(SET + GROUPS + RUP + FETCH + DONE);
export const setGroupForRupFail = createAction(SET + GROUPS + RUP  + FETCH+ FAIL);

export const editRups = createAction<any>(RUPS + EDIT + FETCH);
export const editRupsDone = createAction<any>(RUPS + EDIT + FETCH + DONE);
export const editRupsFail = createAction<undefined>(RUPS + EDIT + FETCH + FAIL);

export const duplicateRup = createAction<any>(DUPLICATE + RUP + FETCH);
export const duplicateRupDone = createAction<any>(DUPLICATE + RUP + FETCH+ DONE);
export const duplicateRupFail = createAction<undefined>(DUPLICATE + RUP + FETCH+ FAIL);

export const deleteRups = createAction<any>(RUPS + DELETE + FETCH);
export const deleteRupsDone = createAction<any>(RUPS + DELETE + FETCH + DONE);
export const deleteRupsFail = createAction<undefined>(RUPS + DELETE + FETCH + FAIL);

export const revertRups = createAction<any>(RUPS + REVERT);

export const fetchRecommendedRups = createAction<null>(RUPS + RECOMMENDED + FETCH);
export const fetchRecommendedRupsDone = createAction<any>(RUPS + RECOMMENDED + FETCH + DONE);
export const fetchRecommendedRupsFail = createAction<undefined>(RUPS + RECOMMENDED + FETCH + FAIL);

export const createNewRupFromRecommendedAction = createAction<any>(RUPS + ADD + ONE + FETCH);
export const createNewRupFromRecommendedActionDone = createAction<any>(RUPS + ADD + ONE + FETCH + DONE);
export const createNewRupFromRecommendedActionFail = createAction<undefined>(RUPS + ADD + ONE + FETCH + FAIL);

export const fetchQualifications = createAction<any>(RUP + QUALIFICATIONS + FETCH);
export const fetchQualificationsDone = createAction<any>(RUP + QUALIFICATIONS + FETCH + DONE);
export const fetchQualificationsFail = createAction<undefined>(RUP + QUALIFICATIONS + FETCH + FAIL);

export const fetchSpecializationId = createAction<any>(RUP + SPECIALITIES + ID + FETCH);
export const fetchSpecializationIdDone = createAction<any>(RUP + SPECIALITIES + ID + FETCH + DONE);
export const fetchSpecializationIdFail = createAction<undefined>(RUP + SPECIALITIES + ID + FETCH + FAIL);

export const fetchQualificationsOptions = createAction<any>(RUP + QUALIFICATIONS + OPTIONS + FETCH);
export const fetchQualificationsOptionsDone = createAction<any>(RUP + QUALIFICATIONS + OPTIONS + FETCH + DONE);
export const fetchQualificationsOptionsFail = createAction<undefined>(RUP + QUALIFICATIONS + OPTIONS + FETCH + FAIL);

export const addQualification = createAction<any>(RUP + QUALIFICATIONS + ADD + FETCH);
export const addQualificationDone = createAction<any>(RUP + QUALIFICATIONS + ADD + FETCH + DONE);
export const addQualificationFail = createAction<undefined>(RUP + QUALIFICATIONS + ADD + FETCH + FAIL);

export const editQualification = createAction<any>(RUP + QUALIFICATIONS + EDIT + FETCH);
export const editQualificationDone = createAction<any>(RUP + QUALIFICATIONS + EDIT + FETCH + DONE);
export const editQualificationFail = createAction<undefined>(RUP + QUALIFICATIONS + EDIT + FETCH + FAIL);

export const deleteQualification = createAction<any>(RUP + QUALIFICATIONS + DELETE+ FETCH);
export const deleteQualificationDone = createAction<any>(RUP + QUALIFICATIONS + DELETE + FETCH + DONE);
export const deleteQualificationFail = createAction<undefined>(RUP + QUALIFICATIONS + DELETE + FETCH+ FAIL);

export const fetchCurrentRup = createAction<any>(RUP + CURRENT + FETCH);
export const fetchCurrentRupDone = createAction<any>(RUP + CURRENT + FETCH + DONE);
export const fetchCurrentRupFail = createAction<undefined>(RUP + CURRENT + FETCH + FAIL);

export const fetchRup = createAction<any>(RUP + FETCH);
export const fetchRupDone = createAction<any>(RUP + FETCH + DONE);
export const fetchRupFail = createAction<undefined>(RUP + FETCH + FAIL);

export const restoreStructure = createAction<any>(RUP + STRUCTURE + RESTORE + FETCH);
export const restoreStructureDone = createAction<any>(RUP + STRUCTURE + RESTORE + FETCH + DONE);
export const restoreStructureFail = createAction<undefined>(RUP + STRUCTURE + RESTORE + FETCH + FAIL);

export const fetchDisciplines = createAction<null>(RUP + DISCIPLINES + FETCH);
export const fetchDisciplinesDone = createAction<any>(RUP + DISCIPLINES + FETCH + DONE);
export const fetchDisciplinesFail = createAction<undefined>(RUP + DISCIPLINES + FETCH + FAIL);

export const fetchSemesterSettingsAction = createAction<any>(GET + SEMESTER + SETTINGS + FETCH);
export const fetchSemesterSettingsActionDone = createAction<any>(GET + SEMESTER + SETTINGS + FETCH + DONE);
export const fetchSemesterSettingsActionFail = createAction<undefined>(GET + SEMESTER + SETTINGS + FETCH + FAIL);

export const createNewDisciplineRow = createAction<any>(RUP + ONE + ADD + FETCH);
export const createNewDisciplineRowDone = createAction<any>(RUP + ONE +  ADD + FETCH+ DONE);
export const createNewDisciplineRowFail = createAction<undefined>(RUP + ONE + ADD + FETCH + FAIL);

export const createNewRow = createAction<any>(RUP + ADD + FETCH);
export const createNewRowDone = createAction<any>(RUP + ADD + FETCH + DONE);
export const createNewRowFail = createAction<undefined>(RUP + ADD + FETCH + FAIL);

export const addModuleWithoutDisc = createAction<any>(RUP + MODULE + DISCIPLINES + FETCH);
export const addModuleWithoutDiscDone = createAction<any>(RUP + MODULE + DISCIPLINES + FETCH + DONE);
export const addModuleWithoutDiscFail = createAction<undefined>(RUP + MODULE + DISCIPLINES + FETCH + FAIL);

export const deleteRow = createAction<any>(RUP + DELETE + FETCH);
export const deleteRowDone = createAction<any>(RUP + DELETE + FETCH + DONE);
export const deleteRowFail = createAction<undefined>(RUP + DELETE + FETCH + FAIL);

export const editRow = createAction<any>(RUP + EDIT + FETCH);
export const editRowDone = createAction<any>(RUP + EDIT + FETCH + DONE);
export const editRowFail = createAction<undefined>(RUP + DELETE + FETCH + FAIL);

export const editSemesterSettingsAction = createAction<any>(SEMESTER + SETTINGS + EDIT + FETCH);
export const editSemesterSettingsActionDone = createAction<any>(SEMESTER + SETTINGS + EDIT + FETCH + DONE);
export const editSemesterSettingsActionFail = createAction<undefined>(SEMESTER + SETTINGS + EDIT + FETCH + FAIL);

export const getRupInfoTypes = createAction<null>(GET + RUP + TYPES + INFO + FETCH);
export const getRupInfoTypesDone = createAction<any>(GET + RUP + TYPES + INFO + FETCH+ DONE);
export const getRupInfoTypesFail = createAction<undefined>(GET + RUP + TYPES + INFO + FETCH + FAIL);

export const getRupInfo = createAction<any>(GET + RUP + INFO + FETCH);
export const getRupInfoDone = createAction<any>(GET + RUP + INFO + FETCH + DONE);
export const getRupInfoFail = createAction<undefined>(GET + RUP + INFO + FETCH + FAIL);

export const changeRupInfo = createAction<any>(EDIT + RUP + INFO + FETCH );
export const changeRupInfoDone = createAction<any>(EDIT + RUP + INFO + FETCH + DONE);
export const changeRupInfoFail = createAction<undefined>(EDIT + RUP + INFO + FETCH + FAIL);