import React, { useState } from "react";
import { Button, Col, Divider, Row, Input, Form, Modal } from "antd";
import { MyBreadcrumb } from "../../../../components";
import "../../../Speciality/Speciality.scss";
import "./ScheduleSemester.scss";
import { Content } from "antd/es/layout/layout";
import { HeadersAuth } from "../../../../constants/Auth";
import { useNavigate } from "react-router-dom";

export const ScheduleSemesterCreate = () => {
  const [form] = Form.useForm();
  const [attemptedSave, setAttemptedSave] = useState(false);
  const navigate = useNavigate();
  const [inputStates, setInputStates] = useState({
    title: false,
  });
  const [modalVisible, setModalVisible] = useState(false);

  const handleInputChange = (inputName) => (e) => {
    let inputValue = e?.target ? e.target.value : e;

    const isValuePresent =
      inputValue !== null && inputValue !== undefined && inputValue !== "";

    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [inputName]: isValuePresent,
    }));
  };

  const handleSave = async () => {
    await form.validateFields();
    setAttemptedSave(true);

    const title = form.getFieldValue("title");

    const url = `${
      process.env.REACT_APP_FULLSTACK_URL
    }/api/groups/schedule/v1/createAcademicSemester?title=${encodeURIComponent(
      title
    )}`;

    const requestOptions = {
      method: "POST",
      headers: HeadersAuth.headers,
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setModalVisible(true);
      })
      .catch((_) => {
        console.error("Ошибка при сохранении");
      });
  };

  return (
    <>
      <MyBreadcrumb title="Добавление семестра" />
      <Row justify="center" style={{ paddingBottom: "20px" }}>
        <Col span={20}>
          <Row>
            <Col span={24}>
              <Content
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "white",
                  marginTop: "20px",
                  borderRadius: "0px 8px 8px 8px",
                }}
              >
                <p />
                <Row
                  style={{ height: "20" }}
                  justify="space-between"
                  align="middle"
                >
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0 20px 0 20px",
                    }}
                  ></Col>
                </Row>
                <Form form={form}>
                  <Row style={{ padding: "20px 0px 0 20px" }}>
                    <Col span={24}></Col>
                    <Col
                      span={24}
                      style={{ fontWeight: "500", fontSize: "14px" }}
                    >
                      Название семестра
                      <p />
                      <Form.Item
                        hasFeedback
                        name="title"
                        validateStatus={
                          attemptedSave && !inputStates.title
                            ? "error"
                            : inputStates.title
                            ? "success"
                            : ""
                        }
                        help={
                          attemptedSave &&
                          !inputStates.title &&
                          "Незаполненное поле"
                        }
                      >
                        <Input
                          placeholder="Обязательное поле"
                          onChange={handleInputChange("title")}
                          style={{
                            width: "98%",
                            borderColor: inputStates.title
                              ? "#8B00FF"
                              : undefined,
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Divider style={{ marginTop: "40px" }} plain />
                  <Col
                    span={24}
                    style={{ textAlign: "center", marginTop: "20px" }}
                  >
                    <Button onClick={handleSave} className="CreateButtonLesson">
                      Добавить
                    </Button>
                  </Col>
                </Row>
                <Row></Row>
              </Content>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        title={
          <span
            style={{
              marginLeft: "160px",
              fontSize: "20px",
            }}
          >
            Уведомление
          </span>
        }
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        centered
        style={{
          flexDirection: "column",
          justifyContent: "center",
        }}
        footer={[
          <Button
            key="continue"
            className="CreateButtonLesson"
            onClick={() => navigate("/schedule")}
            style={{
              height: "35px",
              marginRight: "auto",
              marginLeft: "auto",
              lineHeight: "0",
              justifyContent: "center",
            }}
          >
            Продолжить
          </Button>,
        ]}
      >
        <p style={{ textAlign: "center" }}>Семестр успешно добавлен!</p>
        <Divider style={{ marginTop: "20px" }} plain />
      </Modal>
    </>
  );
};
