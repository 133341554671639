import { RouteObject } from "react-router";
import { ROUTE_PATH } from "../constants";
import {
  Auth,
  FacultativeJournal,
  IndividualJournal,
  IndustrialJournal,
  Journal,
  Main,
  Plans,
  PlansView,
  LessonView,
  Restore,
  TheoreticalJournal,
  TestView,
  Test,
  Rup,
  StudentSchedule,
  ScheduleStudentPlan,
  ScheduleStudentTest,
  Record,
  PrintJournal,
  Speciality,
  SpecialityCreate,
  Institution_Discipline,
  Group,
  Institution_Department,
  InstitutionDisciplineCreate,
  InstitutionDepartmentCreate,
  GroupCreate,
  SpecialityEdit,
  InstitutionDisciplineEdit,
  GroupEdit,
  InstitutionDepartmentEdit,
  Employee,
  EmployeeCreate,
  EmployeeEdit,
  EmployeeView,
  Students,
  StudentCreate,
  StudentEdit,
  StudentView,
  Schedule,
  Classroom,
  Building,
  ClassroomCreate,
  BuildingCreate,
  ClassroomEdit,
  BuildingEdit,
  ScheduleCreate,
  ScheduleShiftCreate,
  ScheduleShiftEdit,
  ScheduleSemesterCreate,
  ScheduleSemesterEdit,
} from "../pages";
import {
  DefaultProtectedLayout,
  ProtectedLayout,
  PublicLayout,
  RootLayout,
} from "../layout";

const protectedRoutesArr: RouteObject[] = [
  {
    element: <ProtectedLayout />,
    children: [
      {
        path: ROUTE_PATH.MAIN,
        element: <Main />,
      },
      {
        path: ROUTE_PATH.JOURNAL,
        children: [
          {
            element: <Journal />,
            index: true,
          },
          {
            path: ROUTE_PATH.JOURNAL_INDIVIDUAL,
            children: [
              {
                element: <IndividualJournal />,
                index: true,
              },
            ],
          },
          {
            path: ROUTE_PATH.JOURNAL_THEORETICAL,
            children: [
              {
                element: <TheoreticalJournal />,
                index: true,
              },
            ],
          },
          {
            path: ROUTE_PATH.JOURNAL_INDUSTRIAL,
            children: [
              {
                element: <IndustrialJournal />,
                index: true,
              },
            ],
          },
          {
            path: ROUTE_PATH.JOURNAL_FACULTATIVE,
            children: [
              {
                element: <FacultativeJournal />,
                index: true,
              },
            ],
          },
        ],
      },
      {
        path: ROUTE_PATH.PRINT_JOURNAL,
        element: <PrintJournal />,
      },
      {
        path: ROUTE_PATH.STUDENTS_SCHEDULE,
        children: [
          {
            element: <StudentSchedule />,
            index: true,
          },
          {
            path: ROUTE_PATH.STUDENTS_SCHEDULE_PLAN,
            children: [
              {
                element: <ScheduleStudentPlan />,
                index: true,
              },
              {
                path: ROUTE_PATH.STUDENTS_SCHEDULE_PLAN_TEST,
                element: <ScheduleStudentTest />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTE_PATH.RECORD,
        element: <Record />,
      },
      {
        path: ROUTE_PATH.SPECIALITY,
        element: <Speciality />,
      },
      {
        path: ROUTE_PATH.SPECIALITY_CREATE,
        element: <SpecialityCreate />,
      },
      {
        path: ROUTE_PATH.SPECIALITY_EDIT,
        element: <SpecialityEdit />,
      },

      {
        path: ROUTE_PATH.INSTITUTION_DISCIPLINE,
        element: <Institution_Discipline />,
      },
      {
        path: ROUTE_PATH.INSTITUTION_DISCIPLINE_CREATE,
        element: <InstitutionDisciplineCreate />,
      },
      {
        path: ROUTE_PATH.INSTITUTION_DISCIPLINE_EDIT,
        element: <InstitutionDisciplineEdit />,
      },
      {
        path: ROUTE_PATH.GROUP,
        element: <Group />,
      },
      {
        path: ROUTE_PATH.GROUP_CREATE,
        element: <GroupCreate />,
      },
      {
        path: ROUTE_PATH.GROUP_EDIT,
        element: <GroupEdit />,
      },
      {
        path: ROUTE_PATH.INSTITUTION_DEPARTMENT,
        element: <Institution_Department />,
      },
      {
        path: ROUTE_PATH.INSTITUTION_DEPARTMENT_CREATE,
        element: <InstitutionDepartmentCreate />,
      },
      {
        path: ROUTE_PATH.INSTITUTION_DEPARTMENT_EDIT,
        element: <InstitutionDepartmentEdit />,
      },
      {
        path: ROUTE_PATH.RUP,
        children: [
          {
            element: <Rup />,
            index: true,
          },
        ],
      },
      //EMPLOYEE
      {
        path: ROUTE_PATH.EMPLOYEE,
        element: <Employee />,
      },
      {
        path: ROUTE_PATH.EMPLOYEE_CREATE,
        element: <EmployeeCreate />,
      },
      {
        path: ROUTE_PATH.EMPLOYEE_EDIT,
        element: <EmployeeEdit />,
      },
      {
        path: ROUTE_PATH.EMPLOYEE_ID,
        element: <EmployeeView />,
      },
      {
        path: ROUTE_PATH.STUDENT,
        element: <Students />,
      },
      {
        path: ROUTE_PATH.STUDENT_CREATE,
        element: <StudentCreate />,
      },
      {
        path: ROUTE_PATH.STUDENT_EDIT,
        element: <StudentEdit />,
      },
      {
        path: ROUTE_PATH.STUDENT_ID,
        element: <StudentView />,
      },
      {
        path: ROUTE_PATH.SCHEDULE_TABLE,
        children: [
          {
            element: <Schedule />,
            index: true,
          },
          {
            path: ROUTE_PATH.SCHEDULE_CREATE,
            element: <ScheduleCreate />,
          },
          {
            path: ROUTE_PATH.SCHEDULE_SHIFT_CREATE,
            element: <ScheduleShiftCreate />,
          },
          {
            path: ROUTE_PATH.SCHEDULE_SHIFT_EDIT,
            element: <ScheduleShiftEdit />,
          },
          {
            path: ROUTE_PATH.SCHEDULE_SEMESTER_CREATE,
            element: <ScheduleSemesterCreate />,
          },
          {
            path: ROUTE_PATH.SCHEDULE_SEMESTER_EDIT,
            element: <ScheduleSemesterEdit />,
          },
        ],
      },

      {
        path: ROUTE_PATH.CLASSROOM,
        element: <Classroom />,
      },
      {
        path: ROUTE_PATH.CLASSROOM_CREATE,
        element: <ClassroomCreate />,
      },
      {
        path: ROUTE_PATH.CLASSROOM_EDIT,
        element: <ClassroomEdit />,
      },
      {
        path: ROUTE_PATH.BUILDING,
        element: <Building />,
      },
      {
        path: ROUTE_PATH.BUILDING_CREATE,
        element: <BuildingCreate />,
      },
      {
        path: ROUTE_PATH.BUILDING_EDIT,
        element: <BuildingEdit />,
      },
      //EMPLOYEE
      {
        path: ROUTE_PATH.PLANS,
        children: [
          {
            element: <Plans />,
            index: true,
          },
          {
            path: ROUTE_PATH.PLANS_VIEW,
            children: [
              {
                element: <PlansView />,
                index: true,
              },
              {
                path: ROUTE_PATH.LESSON_VIEW,
                children: [
                  {
                    element: <LessonView />,
                    index: true,
                  },
                  {
                    path: ROUTE_PATH.LESSON_TEST,
                    children: [
                      {
                        element: <Test />,
                        index: true,
                      },
                      {
                        path: ROUTE_PATH.LESSON_TEST_VIEW,
                        element: <TestView />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const publicRoutesArr: RouteObject[] = [
  {
    element: <PublicLayout />,
    children: [
      {
        path: ROUTE_PATH.AUTH,
        element: <Auth />,
      },
      {
        path: ROUTE_PATH.RESTORE,
        element: <Restore />,
      },
    ],
  },
];

const defaultProtectedArr: RouteObject[] = [
  {
    element: <DefaultProtectedLayout />,
    children: [],
  },
];

export const ROUTES_CONFIG: RouteObject[] = [
  {
    element: <RootLayout />,
    loader: async ({ params }) => {
      // console.log('loader', {params: params})
      return null;
    },
    action: async ({ params, request }) => {
      // console.log('action', {params, request})
      return null;
    },
    children: [
      ...publicRoutesArr,
      ...protectedRoutesArr,
      ...defaultProtectedArr,
    ],
  },
];
