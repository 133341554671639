import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Tooltip,
  Popconfirm,
  message,
  Select,
  Flex,
} from "antd";
import "../../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "./ScheduleCreate.scss";
import { useEffect, useState } from "react";
import { HeadersAuth } from "../../../constants/Auth";
import axios from "axios";
import { Semester, Teacher } from "../ScheduleInterface";

interface Schedule {
  id: number;
  group_id: number;
  discipline_id: number;
  discipline_title: string;
  teacher_id: number;
  classroom_id: number;
  semester_id: number;
  classroom_title: string;
  teacher_name: string;
  shift_time_id: number;
  college_id: number;
  year: string;
  discipline_type_title: string | null;
  discipline_type_id: number | null;
  date_of_month: string;
  start_time: string;
  end_time: string;
  academic_semester: {
    id: number;
    title: string;
  };
}

interface FridaySchedule {
  date_of_month: string;
  schedules: Schedule[];
}

interface ScheduleData {
  Friday: FridaySchedule;
}

export const ScheduleList = () => {
  const [teachers, setTeachers] = useState<
    { value: number; label: string }[] | null
  >(null);
  const [semesters, setSemesters] = useState<
    { value: number; label: string }[] | null
  >(null);
  const [scheduleData, setScheduleData] = useState<ScheduleData | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchScheduleData = async () => {
      try {
        const response = await axios.get<ScheduleData>(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/schedule/v1/getSchedulesByDayAndGroup?groupId=3`,
          HeadersAuth
        );

        setScheduleData(response.data);
      } catch (error) {
        console.error("Ошибка при получении данных из API:", error);
      }
    };

    fetchScheduleData();
  }, []);

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await axios.get<Teacher[]>(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/person/v1/employee/getTeacherWithRolesHandbook?college_id=1`,
          HeadersAuth
        );

        const teacherOptions = response.data.map((teacher) => ({
          value: teacher.id,
          label: teacher.name,
        }));

        const responseSemester = await axios.get<Semester[]>(
          `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/schedule/v1/showAcademicSemester`,
          HeadersAuth
        );

        const responseSemesterOptions = responseSemester.data.map(
          (semester) => ({
            value: semester.id,
            label: semester.title,
          })
        );
        setTeachers(teacherOptions);
        setSemesters(responseSemesterOptions);
      } catch (error) {
        console.error(
          "Ошибка при получении данных о преподавателях из API:",
          error
        );
      }
    };

    fetchTeachers();
  }, []);

  const generateAcademicYearsOptions = (): {
    value: number;
    label: string;
  }[] => {
    const currentYear = new Date().getFullYear();
    const years: { value: number; label: string }[] = [];

    for (let year = 2022; year <= currentYear; year++) {
      years.push({
        value: year,
        label: `${year} - ${year + 1}`,
      });
    }

    return years.reverse();
  };

  const academicYearsOptions = generateAcademicYearsOptions();

  const handleDelete = async (scheduleId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/groups/schedule/v1/deleteSchedule?schedule_id=${scheduleId}`,
        HeadersAuth
      );
      message.success("Элемент успешно удален");
    } catch (error) {
      message.error("Произошла ошибка при удалении элемента");
    }
  };
  const editIcon = (
    id,
    title,
    foundation_year,
    territory_area,
    building_area,
    owned_area,
    leased_area,
    educational_capacity,
    dining_capacity,
    gym_area,
    hall_area,
    dormitory_area,
    pcs_count,
    pcs_internet_access,
    interactive_sets
  ) => (
    <EditOutlined
      onClick={() =>
        redirectToInstitutionDepartmentCreate(
          id,
          title,
          foundation_year,
          territory_area,
          building_area,
          owned_area,
          leased_area,
          educational_capacity,
          dining_capacity,
          gym_area,
          hall_area,
          dormitory_area,
          pcs_count,
          pcs_internet_access,
          interactive_sets
        )
      }
      className="speciality_edit"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
    />
  );
  const deleteIcon = (scheduleId) => (
    <DeleteOutlined
      className="speciality_delete"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
      onClick={() => handleDelete(scheduleId)}
    />
  );

  const redirectToInstitutionDepartmentCreate = (
    id,
    title,
    foundation_year,
    territory_area,
    building_area,
    owned_area,
    leased_area,
    educational_capacity,
    dining_capacity,
    gym_area,
    hall_area,
    dormitory_area,
    pcs_count,
    pcs_internet_access,
    interactive_sets
  ) => {
    navigate(`/building/edit/item/${id}`, {
      state: {
        id,
        title,
        foundation_year,
        territory_area,
        building_area,
        owned_area,
        leased_area,
        educational_capacity,
        dining_capacity,
        gym_area,
        hall_area,
        dormitory_area,
        pcs_count,
        pcs_internet_access,
        interactive_sets,
      },
    });
  };

  const columns = [
    {
      title: (
        <span
          style={{
            color: "#773DBD",
            display: "flex",
            justifyContent: "center",
          }}
        >
          №
        </span>
      ),
      dataIndex: "id",
      key: "id",
      sorter: false,
      render: (text: number) => (
        <span
          style={{
            display: "block",
            textAlign: "center",
          }}
        >
          {text}
        </span>
      ),
      centered: true,
    },
    {
      title: <span style={{ color: "#773DBD" }}>Преподаватель</span>,
      dataIndex: "teacher_name",
      key: "",
      sorter: false,
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: <span style={{ color: "#773DBD" }}>Группа</span>,
      dataIndex: "group_id",
      key: "group_id",
      sorter: false,
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: <span style={{ color: "#773DBD" }}>Дисциплина</span>,
      dataIndex: "discipline_title",
      key: "discipline_title",
      sorter: false,
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: <span style={{ color: "#773DBD" }}>Учебный год</span>,
      dataIndex: "year",
      key: "year",
      sorter: false,
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: <span style={{ color: "#773DBD" }}>Семестр</span>,
      dataIndex: "semester_id",
      key: "semester_id",
      sorter: false,
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: <span style={{ color: "#773DBD" }}>Кабинет</span>,
      dataIndex: "classroom_title",
      key: "classroom_title",
      sorter: false,
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: (
        <span
          style={{ color: "#773DBD", display: "block", textAlign: "center" }}
        >
          Действие
        </span>
      ),
      dataIndex: "delete",
      key: "delete",
      width: 120,
      render: (_, record) => (
        <span style={{ display: "block", textAlign: "center" }}>
          <Tooltip title="Редактировать">
            {editIcon(
              record.id,
              record.title,
              record.foundation_year,
              record.territory_area,
              record.building_area,
              record.owned_area,
              record.leased_area,
              record.educational_capacity,
              record.dining_capacity,
              record.gym_area,
              record.hall_area,
              record.dormitory_area,
              record.pcs_count,
              record.pcs_internet_access,
              record.interactive_sets
            )}
          </Tooltip>
          <Tooltip title="Удалить">
            <Popconfirm
              title="Вы уверены, что хотите удалить?"
              okText="Да"
              cancelText="Нет"
            >
              {deleteIcon(record.id)}
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ];

  return (
    <>
      <Col span={24}>
        <Content
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <Row
            style={{ height: 72, padding: "0 20px" }}
            justify="space-between"
            align="middle"
          >
            <Flex gap={20} style={{ width: "85%" }}>
              <Button
                onClick={() => navigate("/schedule/create")}
                className="schedule_create_button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 14,
                  flex: 2,
                }}
              >
                <PlusOutlined style={{ display: "inline-block" }} />
                <span>Добавить</span>
              </Button>
              <Select
                defaultValue="Учебный год"
                onChange={() => {}}
                options={academicYearsOptions}
                className="Schedule_header_select"
                style={{ flex: 3 }}
              />
              <Select
                defaultValue="Семестры"
                onChange={() => {}}
                options={semesters ? semesters : []}
                className="Schedule_header_select"
                style={{ flex: 3 }}
              />
              <Select
                defaultValue="Преподаватели"
                onChange={() => {}}
                options={teachers ? teachers : []}
                className="Schedule_header_select"
                style={{ flex: 3 }}
              />

              <Button
                className="schedule_create_button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                  }}
                >
                  Поиск
                </span>
              </Button>
            </Flex>
          </Row>
          <div className="schedule_module">
            <Table
              dataSource={scheduleData ? scheduleData.Friday.schedules : []}
              bordered
              columns={columns}
              pagination={{ position: ["bottomRight"] }}
            />
          </div>
          <Row>
            <Col
              span={12}
              style={{ paddingBottom: "30px", marginTop: "-55px" }}
            >
              <span
                style={{
                  marginLeft: "28px",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                Показаны 1-{scheduleData?.Friday.schedules.length ?? 0} из{" "}
                {scheduleData?.Friday.schedules.length ?? 0} записи
              </span>
            </Col>
          </Row>
        </Content>
      </Col>
    </>
  );
};
