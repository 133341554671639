import {StyledFooter} from "./Footer.styles";
import {Col, Row} from "antd";
import FooterIcon from "../../assets/icons/footer-logo-icon.svg";
import {MyImage, MyText} from "../../components";
import dayjs from "dayjs";
import {useTheme} from "../../hooks";

export const Footer = () => {
  const {theme, themeConfig} = useTheme()

  return (
    <StyledFooter>
      <Row justify='center'>
        <Col span={20}>
          <Row justify='space-between' align='middle'>
            <Col>
              <MyImage src={FooterIcon} width={98} height={24}/>
            </Col>

            <Col>
              <MyText size={16} weight={400} color={themeConfig.token.label}>
                {dayjs().get("year")} © Все права защищены
              </MyText>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledFooter>
  )
}
