import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect, useId, useMemo, useState} from "react";
import {Card, Col, Empty, Row, Spin, Table, Tooltip} from "antd";
import {MyBreadcrumb, MyButton, MyText} from "../../components";
import {selectStore} from "../../store/selector";
import {RootState} from "../../store";
import {fetchScheduleAction} from "../../store/pages/schedule/actions";
import {createSearchParams, generatePath, useNavigate} from "react-router-dom";
import {JOURNAL_QUERY_PARAMS, ROUTE_PATH} from "../../constants";
import dayjs from "dayjs";
import {setThemeColor} from "../../utils/setThemeColor";
import {useTheme} from "../../hooks";
import {DefaultTokenTheme} from "../../assets/themes/defaultTheme";


export const StudentSchedule = () => {
  const {t} = useTranslation("common")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {theme} = useTheme()

  const tableInitialState = {
    columns: [] as any,
    data: [] as any
  }

  const {schedule: {schedule: scheduleState}}: RootState = useSelector(selectStore)

  const [tableData, setTableData] = useState(tableInitialState)


  const handlePeriodClick = useCallback((ts: any) => {
    dispatch(fetchScheduleAction({ts: ts}))
  }, [])

  const headerContent = () => {
    const headerItem = scheduleState.data?.header
    return (
      <Row justify='space-between' align='middle'>
        <Col>
          <MyButton ghost type='primary' title={headerItem?.previous?.dates}
                    onClick={() => handlePeriodClick(headerItem?.previous?.ts)}/>
        </Col>

        <Col>
          <MyText weight={500} size={18} color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {headerItem?.current?.dates}
          </MyText>
        </Col>

        <Col>
          <MyButton ghost type='primary' title={headerItem?.next?.dates}
                    onClick={() => handlePeriodClick(headerItem?.next?.ts)}/>
        </Col>
      </Row>
    )
  }

  const scheduleItemContent = useCallback((item: any, record: any, index: any) => {
    if (item) {
      return (
        <Row onClick={() => handleNavigate(item['lesson-id'])} style={{cursor: 'pointer'}}>
          {
            item['lesson-module'] &&
              <Col span={24}>
                  <MyText color={setThemeColor(theme, DefaultTokenTheme.icon, DefaultTokenTheme.disabled)} weight={500}
                          size={12}>
                      Модуль:
                  </MyText>
                  <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')} size={14} weight={700}>
                    {item['lesson-module']}
                  </MyText>
              </Col>
          }
          {
            item['lesson-subject'] &&
              <Col span={24}>
                  <MyText color={setThemeColor(theme, DefaultTokenTheme.icon, DefaultTokenTheme.disabled)} weight={500}
                          size={12}>
                      Предмет:
                  </MyText>
                  <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')} size={14} weight={600}>
                    {item['lesson-subject']}
                  </MyText>
              </Col>
          }
          {
            item['lesson-theme'] &&
              <Col span={24}>
                  <MyText color={setThemeColor(theme, DefaultTokenTheme.icon, DefaultTokenTheme.disabled)} weight={500}
                          size={12}>
                      Тема:
                  </MyText>
                  <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')} size={14} weight={700}>
                    {item['lesson-theme']}
                  </MyText>
              </Col>
          }

        </Row>
      )
    }
  }, [theme])

  useMemo(() => {
    const columns = scheduleState.data?.columns?.map((column: any, columnIdx: any) => {
      return {
        id: `${columnIdx}-${column.title}-${column.dataIndex}`,
        key: `${columnIdx}-${column.title}-${column.dataIndex}`,
        title: (
          <Row gutter={[8, 8]} justify='space-between' align='middle'>
            <Col span={24}>
              <MyText size={14} align='center' style={{display: "block"}}
                      color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                {column.title}
              </MyText>
            </Col>

            <Col span={24}>
              <MyText size={12} weight={500} align='center'
                      color={setThemeColor(theme, DefaultTokenTheme.icon, DefaultTokenTheme.disabled)}
                      style={{display: "block"}}>
                {dayjs(column.date, 'DD.MM.YYYY').format('DD.MM')}
              </MyText>
            </Col>
          </Row>
        ),
        dataIndex: column.dataIndex,
        width: 160,
        render: (text: any, record: any, index: any) => {
          return (
            <Row justify="center" align="middle" key={`col-${index}-${columnIdx}-${column.dataIndex}`}>
              <Col>
                {scheduleItemContent(text, record, index)}
              </Col>
            </Row>
          )
        }
      }
    })

    setTableData({
      columns: columns,
      data: scheduleState.data?.data?.map((item: any, index: any) => ({...item, id: index}))
    })
  }, [scheduleState.data, theme])

  const mainContent = () => {
    return (
      <Table columns={tableData.columns} dataSource={tableData.data} bordered pagination={false}
             rowKey={(record) => record.id} locale={{
        emptyText: <Empty description={t('noRecords')}/>
      }}/>
    )
  }

  const handleNavigate = useCallback((id: any) => {
    const path = {
      pathname: generatePath(ROUTE_PATH.STUDENTS_SCHEDULE_PLAN, {lessonId: id}),
    }

    navigate(path);
  }, [])

  return (
    <>
      <MyBreadcrumb title="Расписание"/>

      <Row justify='center'>
        <Col span={20}>
          <Card>
            <Spin spinning={scheduleState.loading}>
              <Row gutter={[16, 32]}>
                <Col span={24}>
                  {headerContent()}
                </Col>

                <Col span={24}>
                  {mainContent()}
                </Col>
              </Row>
            </Spin>
          </Card>
        </Col>
      </Row>

    </>
  );
}
