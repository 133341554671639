import {MyBreadcrumbStyles} from "./MyBreadcrumb.styles";
import {MyBreadcrumbProps} from "./MyBreadcrumb.props";
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {Col, Row} from "antd";
import {MyButton} from "../MyButton/MyButton";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {setThemeColor} from "../../../utils/setThemeColor";
import {useTheme} from "../../../hooks";
import {DefaultTokenTheme} from "../../../assets/themes/defaultTheme";

export const MyBreadcrumb = ({title, extra, isBack, link, ...props}: MyBreadcrumbProps): React.ReactElement => {
  const {theme} = useTheme()
  const navigate = useNavigate()

  return (
    <MyBreadcrumbStyles>
      <Col span={20} style={{margin: "auto"}}>
        <Row justify="space-between" align="middle" style={{height: 64,}}>
          <Col>
            <Row align="middle">
              {isBack ? <MyButton icon={<ArrowLeftOutlined style={{color: setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}}/>} onClick={() => {
                link ? navigate(link) : navigate(-1)
              }} type='text'/> : null}
              <h1 style={{color: setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}}>{title}</h1>
            </Row>
          </Col>
          {extra ? extra : null}
        </Row>
      </Col>
    </MyBreadcrumbStyles>
    // <MyBreadcrumbStyles {...props} items={items} itemRender={(route, params, routes, paths) => {
    //   return (
    //     route?.path ? <Link to={route?.path}>{route?.title}</Link> : route?.title
    //   )
    // }}/>
  )
}
