import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {RootState} from "../../store";
import {selectStore} from "../../store/selector";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Badge, Card, Col, Divider, Empty, Row, Spin, Tooltip} from "antd";
import {SecondLevelTabs, JournalTable, MyBreadcrumb, MyButton, MyText} from "../../components";
import {StyledJournalTableContainer} from "../Journal/index.styles";
import {getGetPerformance} from "../../store/pages/journal/actions";
import dayjs from "dayjs";
import cl from "classnames";
import {setThemeColor} from "../../utils/setThemeColor";
import {useTheme} from "../../hooks";

export const Record = () => {
  const {t} = useTranslation("common")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {theme} = useTheme()


  const tableInitialState = {
    columns: [] as any,
    data: [] as any
  }

  const cellLegend = [
    {
      label: t('resultOfStudy'),
      color: '#009CF8'
    },
    {
      label: t('rating'),
      color: '#C29DDD'
    },
    {
      label: t('bonusMark'),
      color: '#FFF0A4'
    },
    {
      label: t('exam'),
      color: '#FFDAD8'
    },
    {
      label: t('totalOfSemester'),
      color: '#DCF3D1'
    }
  ]


  const {
    common: {period: periodState, periodByGroup: periodByGroupState, form: formState},
    journal: {performance: tableState},
    plans: {oneCtp: oneCtpState}
  }: RootState = useSelector(selectStore)


  const [currentPeriod, setCurrentPeriod] = useState<any>({})

  const [tableData, setTableData] = useState(tableInitialState)

  const getJournalTable = (period: any) => {
    let bodyCtp = {
      start_date: period?.start_date,
      end_date: period?.end_date,
    }
    dispatch(getGetPerformance(bodyCtp))
    setCurrentPeriod(period)
  }

  useEffect(() => {
    if(periodByGroupState.success){
      if (periodByGroupState.data?.length > 0) {
        getJournalTable(periodByGroupState.data?.[0])
      } else {
        if (periodState.data?.length > 0) {
          getJournalTable(periodState.data?.[0])
        }
      }
    }
  }, [periodByGroupState.success])


  const handleChangeTab = (key: any) => {
    let period = periodByGroupState.data?.length > 0 ? periodByGroupState.data : periodState.data
    let body = period?.find((item: any) => item.name === key)

    getJournalTable(body)
  }

  const titleTableItemRender = useCallback((item: any) => {
    switch (item.key) {
      case 'number':
        return "№"
      case 'subject':
        return 'Предмет'
      default:
        return item.title
    }
  }, [])


  const widthTableRender = useCallback((item: any) => {
    switch (item.key) {
      case 'number':
        return 50
      case 'subject':
        return 160
      case 'lesson':
        return 500
      default:
        if(item.type === 'basic'){
          return 100
        } else {
          return 400
        }
    }
  }, [])


  const semesterTableItemRender = (item: any, record: any, lessonId: any) => {
    const marks = item?.marks || []

    switch (item?.type) {
      case 'common':
        return (
          <Row gutter={[16, 16]}>
            {marks?.map((mark: any) => (
              <Col key={mark?.key}>
                <Tooltip title={(
                  <Row>
                    <Col span={24}>
                      <MyText color={setThemeColor(theme, '#fff', '#fff')}>
                        {t('theme')}: {item.theme}
                      </MyText>
                    </Col>
                  </Row>
                )}>
                  <div style={{width: 50, height: 50, padding: 0, overflow: 'hidden', borderRadius: 6, cursor: 'pointer', border: '1px solid rgba(232, 232, 232, 1)' }}>
                    <Row style={{height: '100%'}} justify='center' align='middle'>
                      <Col span={24} style={{backgroundColor: 'var(--c-light-hover)', textAlign: 'center', borderBottom: '1px solid rgba(232, 232, 232, 1)', height: '50%'}}>
                        {dayjs(mark?.date_at).format('DD.MM')}
                      </Col>
                      <Col span={24} style={{textAlign: 'center', backgroundColor: '#fff', height: '50%'}}>
                        {mark?.mark}
                      </Col>
                    </Row>
                  </div>
                </Tooltip>
              </Col>
            ))}
          </Row>
        )
      case 'system':
      case 'basic':
        return (
          <Row gutter={[16, 16]}>
            {marks?.map((mark: any) => (
              <Col key={mark?.key}>
                <div style={{width: 50, height: 50, padding: 0, overflow: 'hidden', borderRadius: 6, cursor: 'pointer', border: '1px solid rgba(232, 232, 232, 1)' }}>
                  <Row style={{height: '100%'}} justify='center' align='middle'>
                    <Col span={24} style={{textAlign: 'center',display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff', height: '100%'}}>
                      {mark?.mark}
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        )
      default:
        console.log('default', item)
        break
    }
  }

  useMemo(() => {
    const columns = tableState.data.columns.map((column: any, columnIdx: any) => {
      return {
        id: `${columnIdx}-${column.title}`,
        key: `${columnIdx}-${column.title}`,
        title: titleTableItemRender(column),
        width: widthTableRender(column),
        fixed: (columnIdx === 0 || columnIdx === 1) && 'left',
        dataIndex: column.dataIndex,
        onCell: (record: any, rowIndex: any) => {
          return {
            style: {
              backgroundColor: column[`background-color`]
            }
          }
        },
        render: (text: any, record: any, index: any) => {
          return (
            <Row justify="start" align="middle" key={`col-${index}`}>
              <Col>
                {
                  typeof text === 'object' ?
                    semesterTableItemRender(text, record, column.key)
                    :
                    <MyText>
                      {text}
                    </MyText>
                }
              </Col>
            </Row>
          )
        }
      }
    })

    setTableData({columns: columns, data: tableState.data.data})
  }, [tableState, currentPeriod])


  const semesterTabContent = () => {
    let period = periodByGroupState.data?.length > 0 ? periodByGroupState.data : periodState.data

    return period?.map((item: any) => ({
      key: item.name,
      label: item.name,
      children: (
        <StyledJournalTableContainer gutter={[16, 16]} style={{padding: '16px 0'}}>
          <Col>
            <JournalTable columns={tableData.columns}
                          dataSource={tableData.data}
                          loading={tableState.loading}
                          bordered
                          rowKey={(record: any) => (`${record.id}-${record.subject}-${record.number}`)}
                          locale={{
                            emptyText: <Empty description={t('noRecords')}/>
                          }}
            />
          </Col>

          <Col span={24}>
            <Row gutter={16} align='middle'>
              {
                cellLegend.map(item => (
                  <Col key={item.color}>
                    <Badge color={item.color} text={item.label}/>
                  </Col>
                ))
              }
            </Row>
          </Col>
        </StyledJournalTableContainer>
      ),
    }))
  }

  return (
    <>
      <MyBreadcrumb title="Успеваемость"/>

      <Row justify='center'>
        <Col span={20}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card>
                <SecondLevelTabs items={semesterTabContent()} onChange={(key: any) => handleChangeTab(key)}/>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
