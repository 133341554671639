import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { selectStore } from "../../store/selector";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Row,
  Spin,
  Table,
  Tabs,
  TabsProps,
  Tooltip,
  Input,
  Popconfirm,
} from "antd";
import {
  SecondLevelTabs,
  JournalTable,
  MyBreadcrumb,
  MyButton,
  MyText,
  FirstLevelTabs,
} from "../../components";
import { useTheme } from "../../hooks";
import { Cookies } from "react-cookie";

import "./Speciality.scss";
import { Content } from "antd/es/layout/layout";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { COOKIES_ENUM } from "../../constants";
import { HeadersAuth } from "../../constants/Auth";
import { Institution_Discipline } from "../Institution_Discipline/Institution_Discipline";
import { Group } from "../Group/Group";
import { Institution_Department } from "../Kafedra/Kafedra";

export const Speciality = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const deleteIcon = (
    <DeleteOutlined
      className="speciality_delete"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
    />
  );
  const editIcon = (
    <EditOutlined
      onClick={() => navigate("/speciality/edit")}
      className="speciality_edit"
      style={{ display: "inline", fontSize: "24px", marginLeft: "10px" }}
      twoToneColor="red"
    />
  );

  const [qualificationsData, setQualificationsData] = useState({
    current_page: 1,
    data: [],
    last_page: 1,
    per_page: 20,
    total: 0,
  });

  const fetchQualificationsData = async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/qualificationsIndex?college_id=1&page=${page}`,
        HeadersAuth
      );

      setQualificationsData(response.data);
    } catch (error) {
      console.error("Ошибка получения данных:", error);
    }
  };

  const handleDelete = async (item) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/v1/qualificationsDelete?id=${item.special_qual_id}`,
        HeadersAuth
      );

      fetchQualificationsData(qualificationsData.current_page);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  useEffect(() => {
    fetchQualificationsData(1);
  }, []);

  const handlePageChange = (page) => {
    fetchQualificationsData(page);
  };

  const editIconClick = (id, item) => {
    const isValidSpecialQualId =
      Number.isInteger(item.special_qual_id) && item.special_qual_id > 0;

    if (isValidSpecialQualId) {
      navigate(`/speciality/edit/${id}`, {
        state: {
          speciality_id: item.id,
          special_qual_id: item.special_qual_id,
        },
      });
    } else {
      console.error(`Invalid special_qual_id: ${item.special_qual_id}`);
    }
  };

  const columns = [
    {
      title: <span style={{ color: "#773DBD", padding: "10px" }}>ID</span>,
      dataIndex: "special_qual_id",
      key: "special_qual_id",
      style: { fontSize: "26px", fontWeight: "bold", color: "#773DBD" },
      width: 50,
    },
    {
      title: (
        <span style={{ color: "#773DBD", padding: "10px" }}>Специальность</span>
      ),
      dataIndex: "caption",
      key: "caption",
      style: { fontSize: "26px", color: "#773DBD" },
      sorter: false,
      width: 400,
    },
    {
      title: (
        <span style={{ color: "#773DBD", padding: "10px" }}>
          Код квалификации
        </span>
      ),
      dataIndex: "qualification_code",
      key: "qualification_code",
      style: { fontSize: "26px" },
      width: 250,
    },
    {
      title: (
        <span style={{ color: "#773DBD", padding: "10px" }}>
          Добавленные квалификации
        </span>
      ),
      dataIndex: "title",
      key: "title",
      style: { fontSize: "26px" },
    },

    {
      title: <span style={{ padding: "2px", color: "#773DBD" }}>Действие</span>,
      dataIndex: "delete",
      key: "delete",
      width: 90,
      render: (_, record) => (
        <span>
          <Tooltip title="Редактировать">
            <EditOutlined
              onClick={() => editIconClick(record.id, record)}
              className="speciality_edit"
              style={{
                display: "inline",
                fontSize: "24px",
                marginLeft: "10px",
              }}
              twoToneColor="red"
            />
          </Tooltip>
          <Tooltip title="Удалить">
            <Popconfirm
              title="Вы уверены, что хотите удалить?"
              onConfirm={() => handleDelete(record)}
              okText="Да"
              cancelText="Нет"
            >
              {deleteIcon}
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ];

  const redirectToKafedra = () => {
    navigate("/institution_department");
  };
  const redirectToInstitutionDiscipline = () => {
    navigate("/institution_discipline");
  };
  const redirectGroup = () => {
    navigate("/group");
  };
  const firstLevelTabs = [
    {
      key: "1",
      label: "Специальности",
      children: (
        <Content
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />
          {/* <Tabs onTabClick={(key) => handleTabClick(key)} defaultActiveKey="1" items={items} /> */}
          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            >
              <span style={{ fontWeight: "500", fontSize: "24px" }}>
                Добавленные специальности
              </span>
            </Col>
            <Col
              span={12}
              style={{
                textAlign: "end",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "0 20px 0 0",
              }}
            >
              <Button
                onClick={() => navigate("/speciality/create")}
                className="speciality_create_button"
              >
                <PlusOutlined
                  style={{ display: "inline-block", fontSize: "16px" }}
                />
                <span
                  style={{
                    fontWeight: "400",
                    marginTop: "-20px",
                    lineHeight: "0px",
                  }}
                >
                  Добавить
                </span>
              </Button>
            </Col>
          </Row>
          <div
            className="speciality_table"
            style={{ fontSize: "20px", padding: "20px" }}
          >
            <Table
              dataSource={qualificationsData.data.flatMap((item: any) =>
                item.qualifications.map((qualification: any) => ({
                  key: qualification.special_qual_id,
                  id: item.id,
                  caption: item.title,
                  title: qualification.title,
                  speciality: item.title,
                  speciality_code: item.speciality_code,
                  delete: [editIcon, deleteIcon],
                  special_qual_id: qualification.special_qual_id,
                  qualification_code: qualification.qualification_code,
                }))
              )}
              bordered
              columns={columns}
              pagination={{
                current: qualificationsData.current_page,
                pageSize: qualificationsData.per_page,
                total: qualificationsData.total,
                onChange: handlePageChange,
              }}
            />
          </div>

          <Row>
            <Col
              span={12}
              style={{ paddingBottom: "30px", marginTop: "-55px" }}
            >
              <span
                style={{
                  marginLeft: "28px",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >{`Показаны ${
                (qualificationsData.current_page - 1) *
                  qualificationsData.per_page +
                1
              }-${Math.min(
                qualificationsData.current_page * qualificationsData.per_page,
                qualificationsData.total
              )} из ${qualificationsData.total} записи`}</span>
            </Col>
          </Row>
        </Content>
      ),
    },
    {
      key: "2",
      label: "Дисциплины",
      children: <Institution_Discipline />,
    },
    {
      key: "3",
      label: "Группы",
      children: <Group />,
    },
    {
      key: "4",
      label: "Кафедры",
      children: <Institution_Department />,
    },
  ];

  return (
    <>
      <MyBreadcrumb title="Организация учебного процесса" />
      <Row justify="center">
        <Col span={22}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="1" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>
      <p />
    </>
  );
};
