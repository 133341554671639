import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Row,
  Input,
  Cascader,
  Form,
  message,
  Switch,
} from "antd";
import { MyBreadcrumb, FirstLevelTabs } from "../../../components";
import { useTheme } from "../../../hooks";
import "../../Speciality/Speciality.scss";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import {
  BuildingDataVM,
  BuildingDictionaryVM,
  ClassroomTypesDictionaryVM,
} from "../ClassroomInterface";
import { HeadersAuth } from "../../../constants/Auth";
import { Area } from "recharts";

export const BuildingEdit = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const {
    id,
    title,
    foundation_year,
    territory_area,
    building_area,
    owned_area,
    leased_area,
    educational_capacity,
    dining_capacity,
    gym_area,
    hall_area,
    dormitory_area,
    pcs_count,
    pcs_internet_access,
    interactive_sets,
  } = location.state;
  console.log(location.state);
  const { theme } = useTheme();
  const [buildingData, setBuildingData] = useState<BuildingDataVM[]>([]);
  const [inputStates, setInputStates] = useState({
    college_id: false,
    id: false,
    title: false,
    foundation_year: false,
    territory_area: false,
    building_area: false,
    owned_area: false,
    leased_area: false,
    educational_capacity: false,
    dining_capacity: false,
    gym_area: false,
    hall_area: false,
    dormitory_area: false,
    pcs_count: false,
    pcs_internet_access: false,
    interactive_sets: false,
    nameKz: false,
    nameRu: false,
    status: false,
  });

  const [formData, setFormData] = useState({
    id: id || 0,
    title: title || "",
    foundation_year: foundation_year || 0,
    territory_area: territory_area || 0,
    building_area: building_area || 0,
    owned_area: owned_area || 0,
    leased_area: leased_area || 0,
    educational_capacity: educational_capacity || 0,
    dining_capacity: dining_capacity || 0,
    gym_area: gym_area || 0,
    hall_area: hall_area || 0,
    dormitory_area: dormitory_area || 0,
    pcs_count: pcs_count || 0,
    pcs_internet_access: pcs_internet_access || 0,
    interactive_sets: interactive_sets || 0,
  });

  useEffect(() => {
    const {
      id,
      title,
      foundation_year,
      territory_area,
      building_area,
      owned_area,
      leased_area,
      educational_capacity,
      dining_capacity,
      gym_area,
      hall_area,
      dormitory_area,
      pcs_count,
      pcs_internet_access,
      interactive_sets,
    } = location.state;

    setFormData({
      id: id || 0,
      title: title || "",
      foundation_year: foundation_year || 0,
      territory_area: territory_area || 0,
      building_area: building_area || 0,
      owned_area: owned_area || 0,
      leased_area: leased_area || 0,
      educational_capacity: educational_capacity || 0,
      dining_capacity: dining_capacity || 0,
      gym_area: gym_area || 0,
      hall_area: hall_area || 0,
      dormitory_area: dormitory_area || 0,
      pcs_count: pcs_count || 0,
      pcs_internet_access: pcs_internet_access || 0,
      interactive_sets: interactive_sets || 0,
    });
  }, []);

  const handleInputChange = (inputName) => (e) => {
    const inputValue = e.target ? e.target.value : e;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: inputValue,
    }));

    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [inputName]: inputValue !== "",
    }));
  };

  const updateBuildingData = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_FULLSTACK_URL}/api/colleges/auditfund/v1/updateBuilding?id=${id}`,
        {
          id: formData.id,
          title: formData.title,
          foundation_year: formData.foundation_year,
          territory_area: formData.territory_area,
          building_area: formData.building_area,
          owned_area: formData.owned_area,
          leased_area: formData.leased_area,
          educational_capacity: formData.educational_capacity,
          dining_capacity: formData.dining_capacity,
          gym_area: formData.gym_area,
          hall_area: formData.hall_area,
          dormitory_area: formData.dormitory_area,
          pcs_count: formData.pcs_count,
          pcs_internet_access: formData.pcs_internet_access,
          interactive_sets: formData.interactive_sets,
        },
        HeadersAuth
      );
      message.success("Данные успешно обновлены");
    } catch (error) {
      message.error("Ошибка обновления данных");
    }
  };

  const firstLevelTabs = [
    {
      key: "1",
      label: <div style={{ cursor: "pointer" }}>Аудитории</div>,
      children: (
        <Content
          style={{
            width: "100%",
            height: "720px",
            backgroundColor: "white",
            marginTop: "20px",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <p />

          <Row style={{ height: "20" }} justify="space-between" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
              }}
            ></Col>
          </Row>
          <Form form={form}>
            <Row style={{ padding: "20px 0px 0 20px" }}>
              <Col span={24}></Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Название на русском
                <p />
                <Form.Item
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Обязательное поле",
                    },
                  ]}
                  required
                  validateStatus={inputStates.title ? "success" : ""}
                >
                  <Input
                    value={formData.title}
                    required
                    style={{
                      width: "90%",
                      borderColor: inputStates.title ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("title")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Название на казахском
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.title ? "success" : ""}
                >
                  <Input
                    value={formData.title}
                    style={{
                      width: "90%",
                      borderColor: inputStates.title ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("title")}
                  />
                </Form.Item>
              </Col>

              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                Год основания
                <p />
                <Form.Item
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Обязательное поле",
                    },
                  ]}
                  required
                  validateStatus={inputStates.foundation_year ? "success" : ""}
                >
                  <Input
                    value={formData.foundation_year}
                    required
                    style={{
                      width: "90%",
                      borderColor: inputStates.foundation_year
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("foundation_year")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Площадь территории (га)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.territory_area ? "success" : ""}
                >
                  <Input
                    value={formData.territory_area}
                    style={{
                      width: "90%",
                      borderColor: inputStates.territory_area
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("territory_area")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Площадь зданий (м.кв)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.building_area ? "success" : ""}
                >
                  <Input
                    value={formData.building_area}
                    style={{
                      width: "90%",
                      borderColor: inputStates.building_area
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("building_area")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Из них собственная (м.кв)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.owned_area ? "success" : ""}
                >
                  <Input
                    value={formData.owned_area}
                    style={{
                      width: "90%",
                      borderColor: inputStates.owned_area
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("owned_area")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Арендованная (м.кв)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.leased_area ? "success" : ""}
                >
                  <Input
                    value={formData.leased_area}
                    style={{
                      width: "90%",
                      borderColor: inputStates.leased_area
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("leased_area")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Проектная мощнасть заведения (уч.мест)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    inputStates.educational_capacity ? "success" : ""
                  }
                >
                  <Input
                    value={formData.educational_capacity}
                    style={{
                      width: "90%",
                      borderColor: inputStates.educational_capacity
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("educational_capacity")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Проектная мощность столовой (посадочных мест)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.dining_capacity ? "success" : ""}
                >
                  <Input
                    value={formData.dining_capacity}
                    style={{
                      width: "90%",
                      borderColor: inputStates.dining_capacity
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("dining_capacity")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Площадь спортзала (м.кв)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.gym_area ? "success" : ""}
                >
                  <Input
                    value={formData.gym_area}
                    style={{
                      width: "90%",
                      borderColor: inputStates.gym_area ? "#8B00FF" : undefined,
                    }}
                    onChange={handleInputChange("gym_area")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Площадь актового зала (м.кв)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.hall_area ? "success" : ""}
                >
                  <Input
                    value={formData.hall_area}
                    style={{
                      width: "90%",
                      borderColor: inputStates.hall_area
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("hall_area")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Площадь общежитий (м.кв)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.dormitory_area ? "success" : ""}
                >
                  <Input
                    value={formData.dormitory_area}
                    style={{
                      width: "90%",
                      borderColor: inputStates.dormitory_area
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("dormitory_area")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Количество ПК, используемый в уч.процессе (единиц)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.pcs_count ? "success" : ""}
                >
                  <Input
                    value={formData.pcs_count}
                    style={{
                      width: "90%",
                      borderColor: inputStates.pcs_count
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("pcs_count")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Из них имеют доступ к сети интернет (единиц)
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={
                    inputStates.pcs_internet_access ? "success" : ""
                  }
                >
                  <Input
                    value={formData.pcs_internet_access}
                    style={{
                      width: "90%",
                      borderColor: inputStates.pcs_internet_access
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("pcs_internet_access")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={{ fontWeight: "500", fontSize: "14px" }}>
                <p />
                Кол-во комплектов интерактивного оборудования
                <p />
                <Form.Item
                  hasFeedback
                  validateStatus={inputStates.interactive_sets ? "success" : ""}
                >
                  <Input
                    value={formData.interactive_sets}
                    style={{
                      width: "90%",
                      borderColor: inputStates.interactive_sets
                        ? "#8B00FF"
                        : undefined,
                    }}
                    onChange={handleInputChange("interactive_sets")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Divider style={{ marginTop: "80px" }} plain />
            <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                onClick={() => navigate("/classroom")}
                style={{
                  background: "#F6F1FF",
                  color: "#773DBD",
                  height: "40px",
                  lineHeight: "0px",
                  marginRight: "17px",
                }}
              >
                Отмена
              </Button>
              <Button onClick={updateBuildingData} className="CreateButton">
                Сохранить
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </Content>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb title={`Редактирование корпуса: Корпус № ${id}`} />
      <Row justify="center">
        <Col span={20}>
          <Row>
            <Col span={24}>
              <FirstLevelTabs defaultActiveKey="2" items={firstLevelTabs} />
            </Col>
          </Row>
        </Col>
      </Row>
      <p />
    </>
  );
};
