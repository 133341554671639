import {MyBreadcrumb, MyButton, MyModalForm, MyText, SecondLevelTabs} from "../../../../components";
import {
  Badge,
  Card,
  Col,
  Collapse,
  Form,
  Popconfirm,
  Row,
  Spin,
  TabsProps
} from "antd";
import {StyledInfoContainer} from "../index.styles";
import {createSearchParams, generatePath, Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
  COMPONENT_TYPES_ENUM,
  FORM_SECTION_TYPES,
  FORM_TYPES,
  JOURNAL_QUERY_PARAMS,
  PLANS_API,
  PLANS_FORM_TYPES,
  ROUTE_PATH
} from "../../../../constants";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  addOneLessonAction,
  deleteOneLessonAction, deleteTabInnerContentAction, editLessonAction,
  getLessonAction, getOneCtpAction,
  getOneLessonAction, updateTabInnerContentAction,
} from "../../../../store/pages/plans/actions";
import {getFormAction, setFormAction} from "../../../../store/common/actions";
import {selectStore} from "../../../../store/selector";
import {RootState} from "../../../../store";
import dayjs from "dayjs";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useTheme} from "../../../../hooks";
import {setThemeColor} from "../../../../utils/setThemeColor";
import {DefaultTokenTheme} from "../../../../assets/themes/defaultTheme";

interface IInitialState {
  visible: boolean,
  item?: any
}

export const LessonView = () => {
  const {t} = useTranslation("common")
  const {theme} = useTheme()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const {planId, lessonId} = useParams()

  const [formAdd] = Form.useForm()
  const [formEditPlan] = Form.useForm()
  const [formTabContent] = Form.useForm()

  const FORM_NAME = {
    PLANS_TAB_ADD: 'PLANS_TAB_ADD',
    PLANS_TAB_UPDATE: 'PLANS_TAB_UPDATE',
    PLANS_LESSON_UPDATE: 'PLANS_LESSON_UPDATE',
  }

  const initialState: IInitialState = {
    visible: false,
    item: {}
  }

  const modalInitialState = {
    [FORM_NAME.PLANS_TAB_ADD]: initialState,
    [FORM_NAME.PLANS_TAB_UPDATE]: initialState,
    [FORM_NAME.PLANS_LESSON_UPDATE]: initialState,
  }

  const [currentTab, setCurrentTab] = useState(PLANS_FORM_TYPES.MATERIALS)

  const [formModalData, setFormModalData] = useState(modalInitialState)

  const [extraFieldsArr, setExtraFieldsArr] = useState([])

  let typeFromSearchParam = searchParams.get("type");
  let isSharedFromSearchParam = searchParams.get("is_shared");

  let currentPeriodParam = {
    start_date: searchParams.get("start_date") || '',
    end_date: searchParams.get("end_date") || '',
  }

  useEffect(() => {
    dispatch(getLessonAction({
      ctp_id: planId,
      start_date: currentPeriodParam.start_date,
      end_date: currentPeriodParam.end_date
    }))
    dispatch(getOneLessonAction({
      lesson_id: lessonId,
    }))
    dispatch(getOneCtpAction(planId))
  }, []);

  const {
    common: {ctp: ctpState, form: formState},
    plans: {lessons: lessonState, oneLesson: oneLessonState, oneCtp: oneCtpState}
  }: RootState = useSelector(selectStore)


  useEffect(() => {
    if (formModalData[FORM_NAME.PLANS_LESSON_UPDATE].visible) {
      const main: any = formState[FORM_SECTION_TYPES.PLANS][PLANS_FORM_TYPES.LESSON][FORM_TYPES.UPDATE].data[0]

      let typeCheck = main?.fields?.find((item: any) => item.name === 'type')
      let typesChildren = typeCheck?.fieldValues

      const setValue = (name: any, value: any) => {
        switch (name) {
          case 'date_at':
            return dayjs(value)

          case 'type':
            return typesChildren?.find((item: any) => item.label === value)?.value

          default :
            return value
        }
      }

      let formArr = [...(oneLessonState?.data?.view || []).map((item: any) => {
        return {
          name: item.name,
          value: setValue(item.name, item.value)
        }
      }), {name: 'date', value: dayjs(oneLessonState?.data?.date)}]

      if (formArr) {
        formEditPlan.setFields(formArr)
      }
    }
  }, [oneLessonState.data, formState, formModalData[FORM_NAME.PLANS_LESSON_UPDATE].visible])

  const handleDeleteLesson = useCallback(() => {
    dispatch(deleteOneLessonAction({lesson_id: lessonId}))

    navigate(-1)
  }, [])

  const handleAddPlan = useCallback(() => {
    setFormModalData(prevState => ({
      ...prevState,
      [FORM_NAME.PLANS_TAB_ADD]: {...prevState[FORM_NAME.PLANS_TAB_ADD], visible: true}
    }))

    let apiUrl = PLANS_API[`${FORM_TYPES.ADD}_${currentTab}`]

    dispatch(getFormAction({
      api: apiUrl,
      section_type: FORM_SECTION_TYPES.PLANS,
      section_inner_type: currentTab,
      form_type: FORM_TYPES.ADD
    }))
  }, [currentTab])

  const handleEditPlan = useCallback(() => {
    setFormModalData(prevState => ({
      ...prevState,
      [FORM_NAME.PLANS_LESSON_UPDATE]: {...prevState[FORM_NAME.PLANS_LESSON_UPDATE], visible: true}
    }))


    let apiUrl = PLANS_API[`${FORM_TYPES.UPDATE}_${PLANS_FORM_TYPES.PLAN}`]

    dispatch(getFormAction({
      api: apiUrl,
      section_type: FORM_SECTION_TYPES.PLANS,
      section_inner_type: PLANS_FORM_TYPES.LESSON,
      form_type: FORM_TYPES.UPDATE
    }))
  }, [])

  const headerExtraButtonRender = () => {
    return (
      <Row gutter={16}>
        <Col>
          <MyButton onClick={handleEditPlan}
                    disabled={typeFromSearchParam === '0' || isSharedFromSearchParam === '1'}
                    warning
                    height={40}
                    htmlType="submit"
                    icon={<EditOutlined/>}
                    title={t('edit')}/>
        </Col>

        <Col>
          <Popconfirm
            placement="bottomRight"
            title={t('planDeleteTitle')}
            description={t('planDeleteDescription')}
            onConfirm={handleDeleteLesson}
            okText={t('yes')}
            cancelText={t('no')}
            disabled={typeFromSearchParam === '0' || isSharedFromSearchParam === '1'}
          >
            <MyButton danger type='primary' ghost
                      disabled={typeFromSearchParam === '0' || isSharedFromSearchParam === '1'}
                      height={40}
                      icon={<DeleteOutlined/>}
                      htmlType="submit"
                      title={t('delete')}
            />
          </Popconfirm>
        </Col>
      </Row>
    )
  }

  const handleFormModalTabAddOk = useCallback((values: any) => {
    const main: any = formState[FORM_SECTION_TYPES.PLANS][currentTab][FORM_TYPES.ADD].data[0]

    let body = {
      ...values,
      lesson_id: lessonId,
      currentTab: currentTab,
      ...(values.is_shared ? {is_shared: true} : {is_shared: false}),
      ...(values.draft ? {draft: true} : {draft: false})
    }

    dispatch(setFormAction({
      method: main?.method,
      path: main?.action,
      fields: body,
      action: addOneLessonAction
    }))

    handleFormModalClose()
  }, [formState])

  const handleFormUpdatePlanModalOk = useCallback((values: any) => {
    const main: any = formState[FORM_SECTION_TYPES.PLANS][PLANS_FORM_TYPES.LESSON][FORM_TYPES.UPDATE].data[0]

    let body = {
      ...values,
      lesson_id: lessonId,
      date_at: dayjs(values.date_at).format('YYYY-MM-DD hh:mm:ss'),
    }

    dispatch(setFormAction({
      method: main?.method,
      path: `${main?.action}/${lessonId}`,
      fields: body,
      action: editLessonAction
    }))

    handleFormModalClose()
  }, [formState])

  const handleFormModalTabUpdateOk = useCallback((values: any) => {
    const main: any = formState[FORM_SECTION_TYPES.PLANS][currentTab][FORM_TYPES.UPDATE].data[0]

    let body = {
      ...values,
      id: formModalData[FORM_NAME.PLANS_TAB_UPDATE]?.item?.id,
      lesson_id: lessonId,
      currentTab: currentTab
    }

    dispatch(setFormAction({
      method: main?.method,
      path: `${main?.action}/${formModalData[FORM_NAME.PLANS_TAB_UPDATE]?.item?.id}`,
      fields: body,
      action: updateTabInnerContentAction
    }))

    handleFormModalClose()
  }, [formState, currentTab, formModalData])

  const handleFormModalClose = useCallback(() => {
    setFormModalData(modalInitialState)
    setExtraFieldsArr([])
  }, [])

  const handleNavigate = useCallback((url: any, id: any) => {
    const path = {
      // @ts-ignore
      pathname: generatePath(url, {lessonId: lessonId, planId: planId, testId: id}),
      search: createSearchParams({
        // @ts-ignore
        [JOURNAL_QUERY_PARAMS.START_DATE]: currentPeriodParam[JOURNAL_QUERY_PARAMS.START_DATE],
        // @ts-ignore
        [JOURNAL_QUERY_PARAMS.END_DATE]: currentPeriodParam[JOURNAL_QUERY_PARAMS.END_DATE],
        ...(isSharedFromSearchParam ? {is_shared: isSharedFromSearchParam} : {}),
        ...(typeFromSearchParam ? {type: typeFromSearchParam} : {})
      }).toString(),
    }

    navigate(path, {state: id});
  }, [currentPeriodParam])

  const materialItemsArray = (item: any) => {
    return [
      {
        label: t('public'),
        value: item.is_shared,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.is_shared ? t('yes') : t('no')}
          </MyText>
        )
      },
      {
        label: t('videoUrlAddress'),
        value: item.video_url,
        component: (
          <MyText color={DefaultTokenTheme.link}>
            {item.video_url}
          </MyText>
        )
      },
      {
        label: t('source'),
        value: item.source,
        component: (
          <MyText color={DefaultTokenTheme.link}>
            {item.source}
          </MyText>
        )
      },
      {
        label: t('annotation'),
        value: item.content,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.content}
          </MyText>
        )
      },
      {
        label: t('urlAddress'),
        value: item.link_url,
        component: (
          <MyText color={DefaultTokenTheme.link}>
            {item.link_url}
          </MyText>
        )
      },
      {
        label: t('textLink'),
        value: item.link_text,
        component: (
          <MyText color={DefaultTokenTheme.link}>
            {item.link_text}
          </MyText>
        )
      },
      {
        label: t('broadcastsStartTime'),
        value: item.live_time,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.live_time}
          </MyText>
        )
      },
      {
        label: t('broadcastsStartDate'),
        value: item.live_date,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.live_date}
          </MyText>
        )
      },
      {
        label: t('welcomeText'),
        value: item.welcome,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.welcome}
          </MyText>
        )
      },
      {
        label: t('pdf'),
        value: item.presentation,
        component: (
          item.presentation?.map((file: any, index: any) => (
            <Link to={file} key={index} style={{color:DefaultTokenTheme.link}}>
              {file.split('/')[file.split('/').length - 1]}
            </Link>
          )))
      },
      {
        label: t('zoomUrlAddress'),
        value: item.url,
        component: (
          <MyText color={DefaultTokenTheme.link}>
            {item.url}
          </MyText>
        )
      },
      {
        label: t('file'),
        value: item.file,
        component: (
          Array.isArray(item.file) ? item.file?.map((file: any, index: any) => (
              <Link to={file} key={index} style={{color: DefaultTokenTheme.link}}>
                {file?.split('/')[file?.split('/').length - 1]}
              </Link>
            ))
            : <Link to={item.file} style={{color: DefaultTokenTheme.link}}>
              {item.file}
            </Link>
        )
      },
    ]
  }

  const tasksItemsArray = (item: any) => {
    return [
      {
        label: t('public'),
        value: item.is_shared,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.is_shared ? t('yes') : t('no')}
          </MyText>
        )
      },
      {
        label: t('content'),
        value: item.content,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.content}
          </MyText>
        )
      },
      {
        label: t('file'),
        value: item.files,
        component: (
          Array.isArray(item.files) ? item.files?.map((file: any, index: any) => (
              <Link to={file} key={index} style={{color: DefaultTokenTheme.link}}>
                {file?.split('/')[file?.split('/').length - 1]}
              </Link>
            ))
            : <Link to={item.files} style={{color: DefaultTokenTheme.link}}>
              {item.files}
            </Link>
        )
      },
    ]
  }

  const testsItemsArray = (item: any) => {
    return [
      {
        label: t('public'),
        value: item.is_shared,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.is_shared ? t('yes') : t('no')}
          </MyText>
        )
      },
      {
        label: t('description'),
        value: item.content,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.content}
          </MyText>
        )
      },
      {
        label: t('questionCount'),
        value: item.qcount,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.qcount}
          </MyText>
        )
      },
      {
        label: t('timeToCompleteOnMinute'),
        value: item.time,
        component: (
          <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
            {item.time}
          </MyText>
        )
      },
    ]
  }

  const innerRenderCurrentTab = (item: any) => {
    switch (currentTab) {
      case PLANS_FORM_TYPES.MATERIALS:
        return (
          <Row gutter={[16, 16]}>
            {
              materialItemsArray(item).filter((inner: any) => inner.value).map((inner: any, index: any) => (
                <Col key={index} span={24}>
                  <Row align='middle' gutter={6}>
                    <Col span={7}>
                      <MyText
                        color={setThemeColor(theme, DefaultTokenTheme.textSubtitle, DefaultTokenTheme.subtitleDark)}
                        weight={500}>
                        {inner.label}:
                      </MyText>
                    </Col>

                    <Col>
                      {inner.component}
                    </Col>
                  </Row>
                </Col>
              ))
            }
            <Col span={24}>
              <Row gutter={16}>
                <Col>
                  <MyButton title={t('edit')} warning type='default' icon={<EditOutlined/>}
                            onClick={() => handleEditTabInnerContent(item)}/>
                </Col>
                <Col>
                  <Popconfirm
                    title={t('planDeleteTitle')}
                    description={t('planDeleteDescription')}
                    onConfirm={(e) => handleDeleteInnerTabContent(e, item)}
                    okText={t('yes')}
                    cancelText={t('no')}
                  >
                    <MyButton danger type='default' icon={<DeleteOutlined/>}>Удалить</MyButton>
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          </Row>
        )
      case PLANS_FORM_TYPES.TASKS:
        return (
          <Row gutter={[16, 16]}>
            {
              tasksItemsArray(item).filter((inner: any) => inner.value).map((inner: any, index: any) => (
                <Col key={index} span={24}>
                  <Row align='middle' gutter={6}>
                    <Col span={7}>
                      <MyText color={setThemeColor(theme, DefaultTokenTheme.textSubtitle, DefaultTokenTheme.subtitleDark)} weight={500}>
                        {inner.label}:
                      </MyText>
                    </Col>

                    <Col>
                      {inner.component}
                    </Col>
                  </Row>
                </Col>
              ))
            }
            <Col span={24}>
              <Row gutter={16}>
                <Col>
                  <MyButton title={t('edit')} warning type='default' icon={<EditOutlined/>}
                            onClick={() => handleEditTabInnerContent(item)}/>
                </Col>
                <Col>
                  <Popconfirm
                    title={t('planDeleteTitle')}
                    description={t('planDeleteDescription')}
                    onConfirm={(e) => handleDeleteInnerTabContent(e, item)}
                    okText={t('yes')}
                    cancelText={t('no')}
                  >
                    <MyButton danger type='default' icon={<DeleteOutlined/>}>Удалить</MyButton>
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          </Row>
        )
      case PLANS_FORM_TYPES.TESTS:
        return (
          <Row gutter={[16, 16]}>
            {
              testsItemsArray(item).filter((inner: any) => inner.value).map((inner: any, index: any) => (
                <Col key={index} span={24}>
                  <Row align='middle' gutter={6}>
                    <Col span={7}>
                      <MyText color={setThemeColor(theme, DefaultTokenTheme.textSubtitle, DefaultTokenTheme.subtitleDark)} weight={500}>
                        {inner.label}:
                      </MyText>
                    </Col>

                    <Col>
                      {inner.component}
                    </Col>
                  </Row>
                </Col>
              ))
            }
            <Col span={24}>
              <Popconfirm
                title={t('planDeleteTitle')}
                description={t('planDeleteDescription')}
                onConfirm={(e) => handleDeleteInnerTabContent(e, item)}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <MyButton danger type='default' icon={<DeleteOutlined/>}>Удалить</MyButton>
              </Popconfirm>
            </Col>

            <Col span={24}>
              <Row gutter={16}>
                <Col>
                  <MyButton disabled={typeFromSearchParam === '0' || isSharedFromSearchParam === '1'}
                            ghost height={40}
                            type="primary"
                            onClick={() => handleNavigate(ROUTE_PATH.LESSON_TEST, item.id)}>
                    {item?.questions?.length > 0 ? t('QAEditTest') : t('QAAddTest')}
                  </MyButton>
                </Col>
                {
                  item?.questions?.length > 0 &&
                    <Col>
                        <MyButton disabled={typeFromSearchParam === '0' || isSharedFromSearchParam === '1'}
                                  ghost height={40}
                                  type="primary"
                                  onClick={() => handleNavigate(ROUTE_PATH.LESSON_TEST_VIEW, item.id)}>
                          {t('QAViewTest')}
                        </MyButton>
                    </Col>
                }
              </Row>
            </Col>
          </Row>
        )

      default:
        return false

    }
  }

  const handleEditTabInnerContent = useCallback((item: any) => {
    setFormModalData(prevState => ({
      ...prevState,
      [FORM_NAME.PLANS_TAB_UPDATE]: {...prevState[FORM_NAME.PLANS_TAB_UPDATE], visible: true, item: item}
    }))

    let apiUrl = PLANS_API[`${FORM_TYPES.UPDATE}_${currentTab}`]

    dispatch(getFormAction({
      api: apiUrl,
      section_type: FORM_SECTION_TYPES.PLANS,
      section_inner_type: currentTab,
      form_type: FORM_TYPES.UPDATE
    }))
  }, [currentTab])

  const handleDeleteInnerTabContent = useCallback((e: any, item: any) => {
    let current = currentTab === PLANS_FORM_TYPES.MATERIALS ? 'material' : currentTab === PLANS_FORM_TYPES.TASKS ? 'task' : currentTab === PLANS_FORM_TYPES.TESTS ? 'test' : ''

    dispatch(setFormAction({
      method: 'delete',
      path: `/sdot/${current}/${item.id}`,
      fields: {...item, currentTab},
      action: deleteTabInnerContentAction
    }))
  }, [currentTab])

  const innerRender = () => {
    const items = oneLessonState.data && oneLessonState.data[currentTab]?.map((item: any) => ({
      key: item.id,
      label: item.name,
      children: innerRenderCurrentTab(item),
      extra: (
        <Row gutter={16}>
          <Col>
            <Badge status={'success'} text={t('published')}/>
          </Col>

          {
            item.updated_at &&
              <Col>
                  <MyText color={setThemeColor(theme, DefaultTokenTheme.icon, DefaultTokenTheme.disabled)}>
                    {dayjs(item.updated_at).format('DD.MM.YYYY HH:mm')}
                  </MyText>
              </Col>
          }

        </Row>
      )
    }))

    return (
      <Card bordered={false} loading={oneLessonState.loading}>
        <Row gutter={[16, 32]}>
          <Col span={24}>
            <MyButton disabled={typeFromSearchParam === '0' || isSharedFromSearchParam === '1'} ghost height={40}
                      type="primary" onClick={handleAddPlan}>{t('add')}</MyButton>
          </Col>

          <Col span={24}>
            <Collapse items={items} defaultActiveKey={['1']}/>
          </Col>
        </Row>
      </Card>
    )
  }

  const tabItems: TabsProps["items"] = [
    {
      key: PLANS_FORM_TYPES.MATERIALS,
      label: t('materials'),
      children: innerRender()
    },
    {
      key: PLANS_FORM_TYPES.TASKS,
      label: t('tasks'),
      children: innerRender()
    },
    {
      key: PLANS_FORM_TYPES.TESTS,
      label: t('tests'),
      children: innerRender()
    },
  ]

  const handleTabChange = useCallback((key: any) => {
    setCurrentTab(key)
  }, [])

  const collapseData = () => {
    return oneLessonState.data?.view?.filter((item: any, index: any) => {
      if (item.template === 'accordion') {
        return {
          key: index,
          label: item.label,
          children: item.value
        }
      }
    }).map((item: any) => ({...item, children: item.value, className: item.value ? 'fulfilled' : 'empty'}))
  }

  const headerListRender = () =>
    oneLessonState.data?.view?.filter((item: any, index: any) => item.template !== 'accordion' && item.value).map((item: any) => (
      <Col key={item.label} span={12}>
        <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
          {item.label}: {(item.label === 'Дата' || item.label === 'Күні') ? dayjs(item.value).format('DD.MM.YYYY') : item.value}
        </MyText>
      </Col>
    ))

  const modalChangeValues = useCallback((changedFields: any, allFields: any, type: any) => {
    const fieldsArr = formState[FORM_SECTION_TYPES.PLANS][currentTab][type].data[0]?.fields
    let fieldName = Object.keys(changedFields)[0]
    let fieldValue = Object.values(changedFields)[0]
    let getObject = fieldsArr.find((fieldItem: any) => fieldItem.name === fieldName)
    let getExtraField = getObject?.fieldValues?.find((fieldItem: any) => fieldItem.value === fieldValue)

    switch (getObject?.type) {
      case COMPONENT_TYPES_ENUM.TYPE_SELECT:
        if (getExtraField?.fields) {
          setExtraFieldsArr(getExtraField?.fields)
        } else {
          setExtraFieldsArr([])
        }
        break

      default:
        break
    }
  }, [currentTab, formState])

  return (
    <>
      <MyBreadcrumb title={t('back')} isBack extra={headerExtraButtonRender()}/>

      <Row justify='center'>
        <Col span={20}>
          <StyledInfoContainer>
            <Spin spinning={oneCtpState.loading || lessonState.loading}>
              <Row style={{
                borderBottom: `1px solid ${setThemeColor(theme, DefaultTokenTheme.line, DefaultTokenTheme.lineDark)}`,
                padding: 24
              }}>
                <Col>
                  <h1 style={{color: setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}}>{t('lessonsPlan')}</h1>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{padding: 24}}>
                {
                  oneCtpState.data?.group &&

                    <Col span={12}>
                        <MyText
                            color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                          {t('group')}: {oneCtpState.data?.group}
                        </MyText>
                    </Col>
                }

                {
                  oneCtpState.data?.module &&
                    <Col span={12}>
                        <MyText
                            color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                          {t('module')}: {oneCtpState.data?.module}
                        </MyText>
                    </Col>
                }

                {
                  oneCtpState.data?.subject &&
                    <Col span={12}>
                        <MyText
                            color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                          {t('discipline')}: {oneCtpState.data?.subject}
                        </MyText>
                    </Col>
                }

                {headerListRender()}
              </Row>
            </Spin>
          </StyledInfoContainer>

          <StyledInfoContainer>
            <Col style={{padding: 24}}>
              <Spin spinning={oneLessonState.loading}>
                <Collapse
                  items={collapseData()}
                />
              </Spin>
            </Col>
          </StyledInfoContainer>

          <StyledInfoContainer>
            <SecondLevelTabs activeKey={currentTab} items={tabItems} onChange={handleTabChange}/>
          </StyledInfoContainer>
        </Col>
      </Row>

      <MyModalForm
        formName={FORM_NAME.PLANS_TAB_ADD}
        form={formAdd}
        title={formState[FORM_SECTION_TYPES.PLANS][currentTab][FORM_TYPES.ADD].data[0]?.title}
        visible={formModalData[FORM_NAME.PLANS_TAB_ADD].visible}
        state={formState[FORM_SECTION_TYPES.PLANS][currentTab][FORM_TYPES.ADD]}
        onCancel={handleFormModalClose}
        onFinish={handleFormModalTabAddOk}
        period={currentPeriodParam}
        onValuesChange={(changedFields: any, allFields: any) => modalChangeValues(changedFields, allFields, FORM_TYPES.ADD)}
        extraFieldsArr={extraFieldsArr}
      />

      <MyModalForm
        formName={FORM_NAME.PLANS_LESSON_UPDATE}
        form={formEditPlan}
        title={formState[FORM_SECTION_TYPES.PLANS][PLANS_FORM_TYPES.LESSON][FORM_TYPES.UPDATE].data[0]?.title}
        visible={formModalData[FORM_NAME.PLANS_LESSON_UPDATE].visible}
        state={formState[FORM_SECTION_TYPES.PLANS][PLANS_FORM_TYPES.LESSON][FORM_TYPES.UPDATE]}
        onCancel={handleFormModalClose}
        onFinish={handleFormUpdatePlanModalOk}
        period={currentPeriodParam}
      />

      <MyModalForm
        formName={FORM_NAME.PLANS_TAB_UPDATE}
        form={formTabContent}
        title={formState[FORM_SECTION_TYPES.PLANS][currentTab][FORM_TYPES.UPDATE].data[0]?.title}
        visible={formModalData[FORM_NAME.PLANS_TAB_UPDATE].visible}
        state={formState[FORM_SECTION_TYPES.PLANS][currentTab][FORM_TYPES.UPDATE]}
        onCancel={handleFormModalClose}
        onFinish={handleFormModalTabUpdateOk}
        period={currentPeriodParam}
        onValuesChange={(changedFields: any, allFields: any) => modalChangeValues(changedFields, allFields, FORM_TYPES.UPDATE)}
        extraFieldsArr={extraFieldsArr}
      />
    </>
  )
}
