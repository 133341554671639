import {createAction} from "@reduxjs/toolkit";

import {
  DONE,
  FAIL,
  CTP,
  GET,
  FETCH,
  PERIOD,
  SELECT,
  JOURNAL,
  FILTER,
  TABLE,
  RESET,
  LESSON,
  ADD,
  FORM,
  MARK,
  VISIT,
  POST,
  EDIT,
  SET,
  LESSON_ID, DATA, LIST, CURRENT, ITEM, INDIVIDUAL, COLUMN, PERFORMANCE,
} from "../../constants";
import {IJournalTable, IJournalType} from "./types";

export const getJournalListAction = createAction<IJournalType>(GET + JOURNAL + LIST + FETCH);
export const getJournalListActionDone = createAction<IJournalType>(GET + JOURNAL + LIST + FETCH + DONE);
export const getJournalListActionDoneData = createAction<IJournalType>(GET + JOURNAL + LIST + FETCH + DONE + DATA);
export const getJournalListActionFail = createAction<IJournalType>(GET + JOURNAL + LIST + FETCH + FAIL);

export const getJournalTableAction = createAction<IJournalTable>(GET + JOURNAL + TABLE + FETCH);
export const getJournalTableActionDone = createAction<IJournalTable>(GET + JOURNAL + TABLE + FETCH + DONE);
export const getJournalTableActionFail = createAction<IJournalTable>(GET + JOURNAL + TABLE + FETCH + FAIL);

export const postJournalLessonAction = createAction<undefined>(POST + JOURNAL + LESSON + ADD);
export const postJournalLessonActionDone = createAction<undefined>(POST + JOURNAL + LESSON + ADD + DONE);
export const postJournalLessonActionFail = createAction<undefined>(POST + JOURNAL + LESSON + ADD + FAIL);


export const journalMarkAction = createAction<any>(JOURNAL + MARK + EDIT);
export const journalMarkActionDone = createAction<undefined>(JOURNAL + MARK + EDIT + DONE);
export const journalMarkActionFail = createAction<undefined>(JOURNAL + MARK + EDIT + FAIL);

export const journalIndividualMarkEditAction = createAction<any>(JOURNAL + INDIVIDUAL + MARK + EDIT);
export const journalIndividualMarkEditActionDone = createAction<undefined>(JOURNAL + INDIVIDUAL + MARK + EDIT + DONE);
export const journalIndividualMarkEditActionFail = createAction<undefined>(JOURNAL + INDIVIDUAL + MARK + EDIT + FAIL);

export const journalIndividualMarkAddAction = createAction<any>(JOURNAL + INDIVIDUAL + MARK + ADD);
export const journalIndividualMarkAddActionDone = createAction<undefined>(JOURNAL + INDIVIDUAL + MARK + ADD + DONE);
export const journalIndividualMarkAddActionFail = createAction<undefined>(JOURNAL + INDIVIDUAL + MARK + ADD + FAIL);

export const journalIndividualColumnUpdateAction = createAction<any>(JOURNAL + INDIVIDUAL + COLUMN + EDIT);
export const journalIndividualColumnUpdateActionDone = createAction<undefined>(JOURNAL + INDIVIDUAL + COLUMN + EDIT + DONE);
export const journalIndividualColumnUpdateActionFail = createAction<undefined>(JOURNAL + INDIVIDUAL + COLUMN + EDIT + FAIL);


export const getGetPerformance = createAction<any>(GET + PERFORMANCE + FETCH);
export const getGetPerformanceDone = createAction<any>(GET + PERFORMANCE + FETCH + DONE);
export const getGetPerformanceFail = createAction<undefined>(GET + PERFORMANCE + FETCH + FAIL);