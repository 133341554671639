import {SecondLevelTabs, JournalTable, MyBreadcrumb, MyButton, MyModalForm, MyText} from "../../../components";
import {Badge, Button, Card, Col, Divider, Empty, Form, Row, Spin, Tooltip} from "antd";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {selectStore} from "../../../store/selector";
import {
  getJournalTableAction,
  postJournalLessonAction,
} from "../../../store/pages/journal/actions";
import {getFormAction, setFormAction} from "../../../store/common/actions";
import {
  COMPONENT_TYPES_ENUM,
  FORM_SECTION_TYPES,
  FORM_TYPES, JOURNAL_API,
  JOURNAL_FORM_TYPES,
  JOURNAL_QUERY_PARAMS, THEME,
} from "../../../constants";
import dayjs from "dayjs";
import {getOneCtpAction} from "../../../store/pages/plans/actions";
import {StyledJournalTableContainer} from "../index.styles";
import {CheckOutlined, CloseOutlined, QuestionOutlined} from "@ant-design/icons";
import {setThemeColor} from "../../../utils/setThemeColor";
import {DefaultTokenTheme} from "../../../assets/themes/defaultTheme";
import {useTheme} from "../../../hooks";

interface IInitialState {
  visible: boolean,
  item?: any,
  record?: any,
  lessonId?: any,
  form?: {
    section_type?: any
    section_inner_type?: any
    form_type?: any
  }
}

export const IndividualJournal = () => {
  const {theme} = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const {id} = useParams()
  const [searchParams] = useSearchParams();
  const {t} = useTranslation("common")
  const dispatch = useDispatch();

  const [modalBallForm] = Form.useForm();
  const [modalAddColumnForm] = Form.useForm();
  const [modalUpdateLessonForm] = Form.useForm();

  const FORM_NAME = {
    JOURNAL_BALL: 'JOURNAL_BALL',
    JOURNAL_COLUMN_ADD: 'JOURNAL_COLUMN_ADD',
    JOURNAL_LESSON_UPDATE: 'JOURNAL_LESSON_UPDATE'
  }

  const tableInitialState = {
    columns: [] as any,
    data: [] as any,
    loading: false
  }

  const initialState: IInitialState = {
    visible: false,
    item: {},
    record: {},
    lessonId: null,
    form: {
      section_type: null,
      section_inner_type: null,
      form_type: null
    }
  }

  const cellLegend = [
    {
      label: t('resultOfStudy'),
      color: '#009CF8'
    },
    {
      label: t('rating'),
      color: '#C29DDD'
    },
    {
      label: t('bonusMark'),
      color: '#FFF0A4'
    },
    {
      label: t('exam'),
      color: '#FFDAD8'
    },
    {
      label: t('totalOfSemester'),
      color: '#DCF3D1'
    }
  ]

  const {
    common: {period: periodState, periodByGroup: periodByGroupState, form: formState},
    journal: {table: tableState},
    plans: {oneCtp: oneCtpState}
  }: RootState = useSelector(selectStore)

  const currentTypeNumber = searchParams.get(JOURNAL_QUERY_PARAMS.TYPE) || ''
  const currentTypeString = location.pathname.split('/')[2]
  const currentYear = oneCtpState?.data?.view[0]?.value || ''

  const modalInitialState = {
    [FORM_NAME.JOURNAL_BALL]: initialState,
    [FORM_NAME.JOURNAL_COLUMN_ADD]: initialState,
    [FORM_NAME.JOURNAL_LESSON_UPDATE]: initialState,
  }

  const [currentPeriod, setCurrentPeriod] = useState<any>({})

  const [tableData, setTableData] = useState(tableInitialState)

  const [formModalData, setFormModalData] = useState(modalInitialState)

  const [extraFieldsArr, setExtraFieldsArr] = useState([])

  useEffect(() => {
    if (id) {
      dispatch(getOneCtpAction(id))
    }
  }, [id]);

  const getJournalTable = (period: any) => {
    let bodyCtp = {
      ctp_id: id,
      start_date: period?.start_date,
      end_date: period?.end_date,
    }

    dispatch(getJournalTableAction(bodyCtp))

    setCurrentPeriod(period)
  }

  useEffect(() => {
    if(periodByGroupState.success){
      if (periodByGroupState.data?.length > 0) {
        getJournalTable(periodByGroupState.data?.[0])
      } else {
        if (periodState.data?.length > 0) {
          getJournalTable(periodState.data?.[0])
        }
      }
    }
  }, [periodByGroupState.success])

  const handleChangeTab = (key: any) => {
    let period = periodByGroupState.data?.length > 0 ? periodByGroupState.data : periodState.data
    let body = period?.find((item: any) => item.name === key)

    getJournalTable(body)
  }

  const titleTableItemRender = useCallback((item: any) => {
    switch (item.key) {
      case 'number':
        return <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>"№"</MyText>
      case 'fio':
        return <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>{t('fio')}</MyText>
      default:
        if (item.form) {
          return <Tooltip
            title={(
              <Row>
                <Col span={24}>
                 <MyText color={setThemeColor(theme, '#fff', '#fff')}>
                    {t('date')}: {dayjs(item.date_at).format('DD.MM.YYYY')}
                 </MyText>
                </Col>
                {
                  item.theme &&
                  <Col span={24}>
                    <MyText color={setThemeColor(theme, '#fff', '#fff')}>
                        {t('theme')}: {item.theme}
                    </MyText>
                  </Col>
                }
              </Row>
            )}>
            <MyButton width={'100%'} onClick={() => handleUpdateTheme(item)}
                      loading={item.isLoading || false}>{item.title}</MyButton>
          </Tooltip>
        } else {
          return item.title
        }
    }
  }, [theme])

  const widthTableRender = useCallback((item: any) => {
    switch (item.key) {
      case 'number':
        return 60
      case 'fio':
        return 150
      default:
        if (item['background-color']) {
          return 80
        } else if (item.form) {
          return 80
        } else {
          return 160
        }
    }
  }, [])

  const handleButtonClick = (item: any) => {
    dispatch(setFormAction({
      method: item?.method,
      path: item?.url,
      fields: {
        ...item?.data,
        ctp_id: id,
        start_date: currentPeriod.start_date,
        end_date: currentPeriod.end_date,
      },
      action: postJournalLessonAction
    }))
  }

  const icon = {
    'check': <CheckOutlined style={{color: 'rgba(82, 196, 26, 1)'}}/>,
    'delete': <CloseOutlined style={{color: 'rgba(243, 45, 45, 1)'}}/>
  }

  useEffect(() => {
    const columns = tableState.data.columns.map((column: any, columnIdx: any) => {
      return {
        id: `${columnIdx}-${column.title}`,
        key: `${columnIdx}-${column.title}`,
        title: titleTableItemRender(column),
        width: widthTableRender(column),
        dataIndex: column.dataIndex,
        fixed: (column.key === 'number' || column.key === 'fio') && 'left',
        onCell: (record: any, rowIndex: any) => {
          return {
            style: {
              backgroundColor: column[`background-color`] ? column[`background-color`] : (theme === THEME.LIGHT ? (rowIndex % 2 === 0 ? '#fff' : '#FBFBFB') : null)
            }
          }
        },
        // shouldCellUpdate: (record: any, prevRecord: any) => !shallowEqual(record, prevRecord),
        render: (text: any, record: any, index: any) => {
          if (typeof text !== 'object') {
            return <MyText color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>{text}</MyText>
          } else {
            return semesterTableItemRender(record[column.key], {
              id: record?.id,
              fio: record?.fio,
              number: record?.number
            }, column.key)
          }
        }
      }
    })

    setTableData({columns: columns, data: tableState.data.data, loading: tableState.loading})
  }, [tableState, theme])

  const semesterTableItemRender = (item: any, record: any, lessonId: any) => {
    const marks = (item?.marks && item?.marks) || []

    const isAdd = item?.isAdd

    switch (item?.type) {
      case 'common':
        return (
          <Row gutter={[16, 16]} justify='center' align='middle'>
            {marks?.map((mark: any, index: any) => {
              return (
                <Col key={`common-${mark?.key}-${index}`} span={24}>
                  <Row align='middle' gutter={6} justify='center'>
                    <Col span={mark.button ? 12 : 24}>
                      <Tooltip title={(
                        <Row>
                          <Col span={24}>
                            <MyText color={setThemeColor(theme, '#fff', '#fff')}>
                              {t('theme')}: {mark.theme}
                            </MyText>
                          </Col>
                        </Row>
                      )}>
                        <MyButton disabled={!mark?.isEdit}
                                  onClick={() => handleClickMark(mark, record, lessonId)}
                                  background={setThemeColor(theme, "rgba(255,255,255, 0.8)", "#0C172B")}
                                  style={{padding: 0, overflow: 'hidden'}} height={50} width={50}
                                  loading={mark?.isLoading || false}>
                          <Row style={{height: '100%'}}>
                            <Col span={24} style={{backgroundColor: 'var(--c-light-hover)'}}>
                              {dayjs(mark?.date_at).format('DD.MM')}
                            </Col>
                            <Divider/>
                            <Col span={24}>
                              {mark?.mark}
                            </Col>
                          </Row>
                        </MyButton>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              )
            })}
            {isAdd && (
              <Col>
                <MyButton style={{color: theme === THEME.LIGHT ? "#773DBD" : "#fff"}} onClick={() => handleClickMark(item, record, lessonId)} height={50} width={50}>
                  +
                </MyButton>
              </Col>
            )}
          </Row>
        )

      case 'system':
        return (
          <Row gutter={[16, 16]} justify='center' align='middle'>
            {marks?.map((mark: any, index: any) => {
              return (
                <Col key={`system-${mark?.key}-${index}`} span={24}>
                  <Row align='middle' gutter={6} justify='center'>
                    <Col span={mark.button ? 12 : 24}>
                      <Spin spinning={mark?.isLoading || false}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <div style={{
                            width: 50,
                            height: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                            <h1>{mark?.mark}</h1>
                          </div>
                        </div>
                      </Spin>
                    </Col>
                    {
                      mark.button &&
                      <Col span={12}>
                        {/*// @ts-ignore*/}
                        <Button type='text' icon={icon[mark.button.icon] ? icon[mark.button.icon] :
                          <QuestionOutlined style={{color: 'rgba(251, 173, 47, 1)'}}/>}
                                onClick={() => handleButtonClick(mark.button)}/>
                      </Col>
                    }
                  </Row>
                </Col>
              )
            })}
            {isAdd && (
              <Col>
                <MyButton onClick={() => handleClickMark(item, record, lessonId)} height={50} width={50}>
                  +
                </MyButton>
              </Col>
            )}
          </Row>
        )

      case 'basic':
        return (
          <Row gutter={[16, 16]} justify='center' align='middle'>
            {marks?.map((mark: any, index: any) => {
              return (
                <Col key={`basic-${mark?.key}-${index}`}>
                  <Row align='middle' gutter={6} justify='center'>
                    <Col key={mark?.key} span={mark.button ? 12 : 24}>
                      <Tooltip title={(
                        <Row>
                          <Col span={24}>
                           <MyText color={setThemeColor(theme, '#fff', '#fff')}>
                             {t('theme')}: {mark.theme}
                           </MyText>
                          </Col>
                        </Row>
                      )}>
                        <MyButton disabled={!mark?.isEdit}
                                  onClick={() => handleClickMark(mark, record, lessonId)}
                                  style={{padding: 0, overflow: 'hidden'}} height={50} width={50}
                                  loading={mark?.isLoading || false}>
                          {mark?.mark}
                        </MyButton>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>

              )
            })}
            {isAdd && (
              <Col>
                <MyButton onClick={() => handleClickMark(item, record, lessonId)} height={50} width={50}>
                  +
                </MyButton>
              </Col>
            )}
          </Row>
        )

      case 'main-system':
        return (
          <Row gutter={[16, 16]} justify='center' align='middle'>
            {marks?.map((mark: any, index: any) => {
              return (
                <Col key={`basic-${mark?.key}-${index}`}>
                  <Row align='middle' gutter={6} justify='center'>
                    <Col key={mark?.key} span={mark.button ? 12 : 24}>
                      <MyButton type='text'
                                disabled={!mark?.isEdit}
                                onClick={() => handleClickMark(mark, record, lessonId)}
                                style={{padding: 0, overflow: 'hidden'}} height={50} width={50}
                                loading={mark?.isLoading || false}>
                        <MyText weight={500} color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                          {mark?.mark}
                        </MyText>
                      </MyButton>
                    </Col>
                  </Row>
                </Col>
              )
            })}
            {isAdd && (
              <Col>
                <MyButton onClick={() => handleClickMark(item, record, lessonId)} height={50} width={50}>
                  +
                </MyButton>
              </Col>
            )}
          </Row>
        )

      default:
        break
    }
  }

  const handleFormModalClose = useCallback(() => {
    setFormModalData(modalInitialState)
    setExtraFieldsArr([])
    modalBallForm.resetFields()
    modalAddColumnForm.resetFields()
    modalUpdateLessonForm.resetFields()
  }, [])

  const handleModalBallFormSave = (values: any) => {
    const state: IInitialState = formModalData[FORM_NAME.JOURNAL_BALL]

    const main: any = formState[state?.form?.section_type]?.[state?.form?.section_inner_type]?.[state?.form?.form_type]?.data[0]

    let body = {
      ...values,
      lesson_id: state.item.lesson_id,
      tableLessonId: state.lessonId,
      student_id: state.record.id,
      id: state.item.key,
      ctp_id: id,
      date_at: dayjs(values.date_at).format("YYYY-MM-DD"),
      start_date: currentPeriod.start_date,
      end_date: currentPeriod.end_date,
    }

    dispatch(setFormAction({
      method: main?.method,
      path: main?.method === 'POST' ? main?.action : `${main?.action}/${body.id}`,
      fields: body,
      action: postJournalLessonAction
    }))

    handleFormModalClose()
  }

  const handleModalBallDeleteFormSave = () => {
    const state: IInitialState = formModalData[FORM_NAME.JOURNAL_BALL]

    const main: any = formState[state?.form?.section_type]?.[state?.form?.section_inner_type]?.[state?.form?.form_type]?.data[0]

    let body = {
      id: state.item.key,
      ctp_id: id,
      start_date: currentPeriod.start_date,
      end_date: currentPeriod.end_date,
    }

    dispatch(setFormAction({
      method: 'DELETE',
      path: `${main?.action}/${body.id}`,
      fields: body,
      action: postJournalLessonAction
    }))


    handleFormModalClose()
  }

  const handleModalAddColumnFormSave = (values: any) => {
    const state: IInitialState = formModalData[FORM_NAME.JOURNAL_COLUMN_ADD]
    const main: any = formState[formModalData[FORM_NAME.JOURNAL_COLUMN_ADD]?.form?.section_type]?.[formModalData[FORM_NAME.JOURNAL_COLUMN_ADD]?.form?.section_inner_type]?.[formModalData[FORM_NAME.JOURNAL_COLUMN_ADD]?.form?.form_type]?.data[0]

    let body = {
      ...values,
      ctp_id: id,
      date_at: dayjs(values.date_at).format("YYYY-MM-DD"),
      start_date: currentPeriod.start_date,
      end_date: currentPeriod.end_date,
    }

    dispatch(setFormAction({
      method: main?.method,
      path: main?.action,
      fields: body,
      action: postJournalLessonAction
    }))

    handleFormModalClose()
  }

  const handleModalUpdateLessonFormSave = (values: any) => {
    const state: IInitialState = formModalData[FORM_NAME.JOURNAL_LESSON_UPDATE]
    const main: any = formState[state?.form?.section_type]?.[state?.form?.section_inner_type]?.[state?.form?.form_type]?.data[0]

    let body = {
      ...values,
      ctp_id: id,
      tableLessonId: state.item.lesson_id,
      date_at: dayjs(values.date_at).format("YYYY-MM-DD HH:mm:ss"),
      start_date: currentPeriod.start_date,
      end_date: currentPeriod.end_date,
    }

    dispatch(setFormAction({
      method: main?.method,
      path: `${main?.action}/${state.item.lesson_id}`,
      fields: body,
      action: postJournalLessonAction,
      // action: journalIndividualColumnUpdateAction
    }))

    handleFormModalClose()
  }

  const handleModalDeleteLessonFormSave = () => {
    const state: IInitialState = formModalData[FORM_NAME.JOURNAL_LESSON_UPDATE]
    const main: any = formState[state?.form?.section_type]?.[state?.form?.section_inner_type]?.[state?.form?.form_type]?.data[0]

    let body = {
      ctp_id: id,
      start_date: currentPeriod.start_date,
      end_date: currentPeriod.end_date,
    }

    dispatch(setFormAction({
      method: 'DELETE',
      path: `${main?.action}/${state.item.lesson_id}`,
      fields: body,
      action: postJournalLessonAction
    }))


    handleFormModalClose()
  }

  const handleUpdateTheme = useCallback((item: any) => {
    console.log('theme', item)

    let isBall = item.form.includes(`_${JOURNAL_FORM_TYPES.BALL}_`)
    let isLesson = item.form.includes(`_${JOURNAL_FORM_TYPES.LESSON}_`)
    let isIndividual = item.form.includes(`_${JOURNAL_FORM_TYPES.INDIVIDUAL}_`)
    let isTheme = item.form.includes(`_${JOURNAL_FORM_TYPES.THEME}_`)
    let isLessonTheme = item.form.includes(`_${JOURNAL_FORM_TYPES.LESSON_THEME}_`)
    let isIndividualLesson = item.form.includes(`_${JOURNAL_FORM_TYPES.INDIVIDUAL_LESSON}_`)
    let isBallVisit = item.form.includes(`_${JOURNAL_FORM_TYPES.BALL_VISIT}_`)
    let isIncentivePoint = item.form.includes(`_${JOURNAL_FORM_TYPES.INCENTIVE_POINT}_`)
    let isPass = item.form.includes(`_${JOURNAL_FORM_TYPES.PASS}_`)
    let isExam = item.form.includes(`_${JOURNAL_FORM_TYPES.EXAM}_`)
    let isLessonPassExam = item.form.includes(`_${JOURNAL_FORM_TYPES.LESSON_PASS_EXAM}_`)

    let section_type = FORM_SECTION_TYPES.JOURNAL
    let section_inner_type = isBall ? JOURNAL_FORM_TYPES.BALL : isLesson ? JOURNAL_FORM_TYPES.LESSON : isIndividual ? JOURNAL_FORM_TYPES.INDIVIDUAL : isTheme ? JOURNAL_FORM_TYPES.THEME : isLessonTheme ? JOURNAL_FORM_TYPES.LESSON_THEME : isIndividualLesson ? JOURNAL_FORM_TYPES.INDIVIDUAL_LESSON : isBallVisit ? JOURNAL_FORM_TYPES.BALL_VISIT : isIncentivePoint ? JOURNAL_FORM_TYPES.INCENTIVE_POINT : isPass ? JOURNAL_FORM_TYPES.PASS : isExam ? JOURNAL_FORM_TYPES.EXAM : isLessonPassExam ? JOURNAL_FORM_TYPES.LESSON_PASS_EXAM : null
    let form_type = FORM_TYPES.UPDATE

    dispatch(getFormAction({
      api: item.form,
      section_type: section_type,
      section_inner_type: section_inner_type,
      form_type: form_type,
    }))

    setFormModalData(prevState => ({
      ...prevState,
      [FORM_NAME.JOURNAL_LESSON_UPDATE]: {
        ...prevState[FORM_NAME.JOURNAL_LESSON_UPDATE],
        visible: true,
        item,
        form: {
          section_type: section_type,
          section_inner_type: section_inner_type,
          form_type: form_type
        }
      }
    }))

    modalUpdateLessonForm.setFields([
      {
        name: 'date_at',
        value: dayjs(item.date_at)
      },
      {
        name: 'theme',
        value: item.theme
      }
    ])
  }, [])

  const handleClickMark = useCallback((item: any, record: any, lessonId: any) => {
    console.log('mark', item)


    let isAdd = item.form.includes(FORM_TYPES.ADD)
    let isVisit = item.form.includes(FORM_TYPES.VISIT)
    let isUpdate = item.form.includes(FORM_TYPES.UPDATE)

    let isBall = item.form.includes(`_${JOURNAL_FORM_TYPES.BALL}_`)
    let isLesson = item.form.includes(`_${JOURNAL_FORM_TYPES.LESSON}_`)
    let isIndividual = item.form.includes(`_${JOURNAL_FORM_TYPES.INDIVIDUAL}_`)
    let isTheme = item.form.includes(`_${JOURNAL_FORM_TYPES.THEME}_`)
    let isLessonTheme = item.form.includes(`_${JOURNAL_FORM_TYPES.LESSON_THEME}_`)
    let isIndividualLesson = item.form.includes(`_${JOURNAL_FORM_TYPES.INDIVIDUAL_LESSON}_`)
    let isBallVisit = item.form.includes(`_${JOURNAL_FORM_TYPES.BALL_VISIT}_`)
    let isIncentivePoint = item.form.includes(`_${JOURNAL_FORM_TYPES.INCENTIVE_POINT}_`)
    let isPass = item.form.includes(`_${JOURNAL_FORM_TYPES.PASS}_`)
    let isExam = item.form.includes(`_${JOURNAL_FORM_TYPES.EXAM}_`)
    let isLessonPassExam = item.form.includes(`_${JOURNAL_FORM_TYPES.LESSON_PASS_EXAM}_`)

    let section_type = FORM_SECTION_TYPES.JOURNAL
    let section_inner_type = isBall ? JOURNAL_FORM_TYPES.BALL : isLesson ? JOURNAL_FORM_TYPES.LESSON : isIndividual ? JOURNAL_FORM_TYPES.INDIVIDUAL : isTheme ? JOURNAL_FORM_TYPES.THEME : isLessonTheme ? JOURNAL_FORM_TYPES.LESSON_THEME : isIndividualLesson ? JOURNAL_FORM_TYPES.INDIVIDUAL_LESSON : isBallVisit ? JOURNAL_FORM_TYPES.BALL_VISIT : isIncentivePoint ? JOURNAL_FORM_TYPES.INCENTIVE_POINT : isPass ? JOURNAL_FORM_TYPES.PASS : isExam ? JOURNAL_FORM_TYPES.EXAM : isLessonPassExam ? JOURNAL_FORM_TYPES.LESSON_PASS_EXAM : null
    let form_type = isAdd ? FORM_TYPES.ADD : isVisit ? FORM_TYPES.VISIT : isUpdate ? FORM_TYPES.UPDATE : null

    dispatch(getFormAction({
      api: item.form,
      section_type: section_type,
      section_inner_type: section_inner_type,
      form_type: form_type,
    }))

    setFormModalData(prevState => ({
      ...prevState,
      [FORM_NAME.JOURNAL_BALL]: {
        ...prevState[FORM_NAME.JOURNAL_BALL],
        visible: true,
        item,
        record,
        lessonId,
        form: {
          section_type: section_type,
          section_inner_type: section_inner_type,
          form_type: form_type
        }
      }
    }))

    modalBallForm.setFields([
      {
        name: 'theme',
        value: item.theme
      },
      {
        name: 'date_at',
        value: dayjs(item.date_at)
      },
      {
        name: 'percent',
        value: item.mark_types_value_id ? null : item.mark
      },
      {
        name: 'mark_types_value_id',
        value: item.mark_types_value_id
      }
    ])
  }, [])

  const handleAddLesson = useCallback(() => {
    let section_type = FORM_SECTION_TYPES.JOURNAL
    let section_inner_type = JOURNAL_FORM_TYPES.INDIVIDUAL_LESSON
    let form_type = FORM_TYPES.ADD

    dispatch(getFormAction({
      api: JOURNAL_API[`${form_type}_${section_inner_type}`],
      section_type: section_type,
      section_inner_type: section_inner_type,
      form_type: form_type,
    }))

    setFormModalData(prevState => ({
      ...prevState,
      [FORM_NAME.JOURNAL_COLUMN_ADD]: {
        ...prevState[FORM_NAME.JOURNAL_COLUMN_ADD],
        visible: true,
        form: {section_type, section_inner_type, form_type}
      }
    }))

    modalAddColumnForm.setFields([
      {
        name: 'date_at',
        value: dayjs()
      }
    ])
  }, [])

  const handleMarkValuesChange = useCallback((changedFields: any, allFields: any) => {
    switch (Object.keys(changedFields)[0]) {
      case 'mark_types_value_id':
        modalBallForm.setFields([
          {
            name: 'mark_types_value_id',
            value: changedFields.mark_types_value_id
          },
          {
            name: 'percent',
            value: null
          }
        ])
        break
      case 'percent':
        modalBallForm.setFields([
          {
            name: 'mark_types_value_id',
            value: null
          },
          {
            name: 'percent',
            value: changedFields.percent
          }
        ])
        break
      default:
        return false
    }
  }, [])

  const modalChangeValues = (changedFields: any, allFields: any) => {
    const fieldsArr = formState[formModalData[FORM_NAME.JOURNAL_COLUMN_ADD]?.form?.section_type]?.[formModalData[FORM_NAME.JOURNAL_COLUMN_ADD]?.form?.section_inner_type]?.[formModalData[FORM_NAME.JOURNAL_COLUMN_ADD]?.form?.form_type]?.data[0]?.fields
    let fieldName = Object.keys(changedFields)[0]
    let fieldValue = Object.values(changedFields)[0]
    let getObject = fieldsArr.find((fieldItem: any) => fieldItem.name === fieldName)
    let getExtraField = getObject?.fieldValues?.find((fieldItem: any) => fieldItem.value === fieldValue)

    switch (getObject?.type) {
      case COMPONENT_TYPES_ENUM.TYPE_SELECT:
        if (getExtraField?.fields) {
          setExtraFieldsArr(getExtraField?.fields)
        } else {
          setExtraFieldsArr([])
        }
        break

      default:
        break
    }
  }

  const tableMemo = useMemo(() => {
    return (
      <JournalTable columns={tableData.columns}
                    dataSource={tableData.data}
                    loading={tableData.loading}
                    virtual
                    bordered
                    locale={{
                      emptyText: <Empty description={t('noRecords')}/>
                    }}/>
    )
  }, [tableData, theme])

  const semesterTabContent = () => {
    let period = periodByGroupState.data?.length > 0 ? periodByGroupState.data : periodState.data

    return period?.map((item: any) => ({
      key: item.name,
      label: item.name,
      children: (
        <StyledJournalTableContainer gutter={[16, 16]} style={{padding: '16px 0'}}>
          <Col span={24}>
            <Row gutter={16} align='middle' justify='space-between'>
              <Col>
                <Row gutter={16} align='middle'>
                  {
                    cellLegend.map(item => (
                      <Col key={item.color}>
                        <Badge color={item.color} text={item.label}/>
                      </Col>
                    ))
                  }
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            {tableMemo}
          </Col>
        </StyledJournalTableContainer>
      ),
    }))
  }

  return (
    <>
      <MyBreadcrumb title={t('back')} isBack/>

      <Row justify='center'>
        <Col span={20}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Spin spinning={oneCtpState?.loading}>
                <Card>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Row align='middle' justify='space-between'>
                        <Col>
                          <MyText size={24} weight={500}
                                  color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                            {t('viewIndividualJournal')} {Number(currentYear)}-{Number(currentYear) + 1}
                          </MyText>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <hr style={{
                        backgroundColor: setThemeColor(theme, DefaultTokenTheme.line, DefaultTokenTheme.lineDark),
                        height: 1
                      }}/>
                    </Col>

                    <Col span={24}>
                      <Row>
                        <Col span={12}>
                          <Row>
                            {
                              oneCtpState.data?.group &&
                                <Col span={24}>
                                    <MyText
                                        color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                                      {t('group')}: {oneCtpState.data?.group}
                                    </MyText>
                                </Col>
                            }
                            {
                              oneCtpState.data?.subject &&
                                <Col span={24}>
                                    <MyText
                                        color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                                      {t('subject')}: {oneCtpState.data?.subject}
                                    </MyText>
                                </Col>
                            }

                          </Row>
                        </Col>

                        <Col span={12}>
                          <Row>
                            {
                              oneCtpState.data?.module &&
                                <Col span={24}>
                                    <MyText
                                        color={setThemeColor(theme, DefaultTokenTheme.textTitle, '#fff')}>
                                      {t('module')}: {oneCtpState.data?.module}
                                    </MyText>
                                </Col>
                            }

                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Spin>
            </Col>

            <Col span={24}>
              <Card style={{backgroundColor: setThemeColor(theme, '#fff', '#131E35')}}>
                <SecondLevelTabs tabBarExtraContent={<>
                  <Row justify='end' align='middle'>
                    <MyButton style={{background: setThemeColor(theme, "rgba(255,255,255, 0.8)", "#773DBD"), color: setThemeColor(theme, "#773DBD", "#fff"), borderColor: setThemeColor(theme, "#773DBD", "#773DBD"), padding: '2px 18px', marginRight: 14, fontSize: 16, height: 38}} title={t('add')} onClick={handleAddLesson}/>
                  </Row>
                </>} items={semesterTabContent()} onChange={(key: any) => handleChangeTab(key)}/>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <MyModalForm
        formName={FORM_NAME.JOURNAL_BALL}
        title={`${formState[formModalData[FORM_NAME.JOURNAL_BALL]?.form?.section_type]?.[formModalData[FORM_NAME.JOURNAL_BALL]?.form?.section_inner_type]?.[formModalData[FORM_NAME.JOURNAL_BALL]?.form?.form_type]?.data[0]?.title ? formState[formModalData[FORM_NAME.JOURNAL_BALL]?.form?.section_type]?.[formModalData[FORM_NAME.JOURNAL_BALL]?.form?.section_inner_type]?.[formModalData[FORM_NAME.JOURNAL_BALL]?.form?.form_type]?.data[0]?.title : ''} - ${formModalData[FORM_NAME.JOURNAL_BALL]?.record?.fio}`}
        visible={formModalData[FORM_NAME.JOURNAL_BALL].visible}
        state={formState[formModalData[FORM_NAME.JOURNAL_BALL]?.form?.section_type]?.[formModalData[FORM_NAME.JOURNAL_BALL]?.form?.section_inner_type]?.[formModalData[FORM_NAME.JOURNAL_BALL]?.form?.form_type]}
        onCancel={handleFormModalClose}
        onFinish={handleModalBallFormSave}
        period={currentPeriod}
        form={modalBallForm}
        onValuesChange={handleMarkValuesChange}
        onDelete={handleModalBallDeleteFormSave}
      />

      <MyModalForm
        formName={FORM_NAME.JOURNAL_COLUMN_ADD}
        title={formState[formModalData[FORM_NAME.JOURNAL_COLUMN_ADD]?.form?.section_type]?.[formModalData[FORM_NAME.JOURNAL_COLUMN_ADD]?.form?.section_inner_type]?.[formModalData[FORM_NAME.JOURNAL_COLUMN_ADD]?.form?.form_type]?.data[0]?.title}
        visible={formModalData[FORM_NAME.JOURNAL_COLUMN_ADD].visible}
        state={formState[formModalData[FORM_NAME.JOURNAL_COLUMN_ADD]?.form?.section_type]?.[formModalData[FORM_NAME.JOURNAL_COLUMN_ADD]?.form?.section_inner_type]?.[formModalData[FORM_NAME.JOURNAL_COLUMN_ADD]?.form?.form_type]}
        onCancel={handleFormModalClose}
        onFinish={handleModalAddColumnFormSave}
        period={currentPeriod}
        form={modalAddColumnForm}
        extraFieldsArr={extraFieldsArr}
        onValuesChange={(changedFields: any, allFields: any) => modalChangeValues(changedFields, allFields)}
      />
      <MyModalForm
        formName={FORM_NAME.JOURNAL_LESSON_UPDATE}
        title={formState[formModalData[FORM_NAME.JOURNAL_LESSON_UPDATE]?.form?.section_type]?.[formModalData[FORM_NAME.JOURNAL_LESSON_UPDATE]?.form?.section_inner_type]?.[formModalData[FORM_NAME.JOURNAL_LESSON_UPDATE]?.form?.form_type]?.data[0]?.title}
        visible={formModalData[FORM_NAME.JOURNAL_LESSON_UPDATE]?.visible}
        state={formState[formModalData[FORM_NAME.JOURNAL_LESSON_UPDATE]?.form?.section_type]?.[formModalData[FORM_NAME.JOURNAL_LESSON_UPDATE]?.form?.section_inner_type]?.[formModalData[FORM_NAME.JOURNAL_LESSON_UPDATE]?.form?.form_type]}
        onCancel={handleFormModalClose}
        onFinish={handleModalUpdateLessonFormSave}
        period={currentPeriod}
        form={modalUpdateLessonForm}
        onDelete={handleModalDeleteLessonFormSave}
      />
    </>
  )
}
