export const customNoDataText = {
    emptyText: 'Не найдено дисциплин на данный день',
  };

export const daysOfWeek = [
    { english: "Monday", russian: "Понедельник" },
    { english: "Tuesday", russian: "Вторник" },
    { english: "Wednesday", russian: "Среда" },
    { english: "Thursday", russian: "Четверг" },
    { english: "Friday", russian: "Пятница" },
    { english: "Saturday", russian: "Суббота" },
  ];