import {AliasToken} from "antd/es/theme/interface";

export interface MyAliasToken {
// export interface MyAliasToken extends AliasToken {
  backgroundDark: string,
  headerDark: string,
  tableHeaderDark: string,
  plateDark: string,
  lineDark: string,
  borderDark: string,
  disabledDark: string,
  subtitleDark: string,
  textDark: string,
  secondary: string,
  iconNav: string,
  yellow: string,
  primary: string,
  hover: string,
  pressed: string,
  link: string,
  info: string,
  success: string,
  warning: string,
  error: string,
  icon: string,
  disabled: string,
  line: string,
  backgroundLight: string,
  textTitle: string,
  textSubtitle: string,
  label: string,
  tableHeader: string,
}


export const DefaultTokenTheme: MyAliasToken = {
  backgroundDark: '#061122',
  headerDark: "#071235",
  tableHeaderDark: "#0C172B",
  plateDark: "#131E35",
  lineDark: "#212B49",
  borderDark: "#37405B",
  disabledDark: "#5B6576", //inactive text
  subtitleDark: "#7E8891",
  textDark: "#959FA3", //text/icon
  secondary: '#F6F1FF',
  iconNav: '#B99CDD',
  yellow: '#FFC44D',
  primary: '#773DBD',
  hover: '#51258F',
  pressed: '#4C2779',
  link: '#005CE8',
  info: '#5E94FF',
  success: '#0FAF62',
  warning: '#FF8840',
  error: '#E84646',
  icon: '#626C70',
  disabled: '#959FA3',
  line: '#E5E7E8',
  backgroundLight: '#F5F6F7',
  textTitle: '#191B1C',
  textSubtitle: '#4A5154',
  label: '#6B6C7E',
  tableHeader: '#FBFBFB',
}
